import React from 'react';
import { PlusCircle, ClipboardList, CheckCircle2, Clock, AlertCircle, Trash2, Calendar, Plane, Home } from 'lucide-react';
import { Country, Task } from '../types/todo';

interface CountryCardProps {
  country: Country;
  onCreateList: (code: string) => void;
  onManageList: (code: string) => void;
  onDeleteList?: (code: string, fromDashboard: boolean) => void;
  tasks?: Task[];
  departureDate?: string;
  returnDate?: string;
}

export function CountryCard({
  country,
  onCreateList,
  onManageList,
  onDeleteList,
  tasks = [],
  departureDate,
  returnDate
}: CountryCardProps) {
  const handleClick = (action: () => void) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(action, 100);
  };

  const stats = React.useMemo(() => {
    if (!tasks.length) return null;

    const total = tasks.length;
    const completed = tasks.filter(t => t.status === 'completed').length;
    const inProgress = tasks.filter(t => t.status === 'in-progress').length;
    const notStarted = total - completed - inProgress;
    const progress = Math.round((completed / total) * 100);

    return { total, completed, inProgress, notStarted, progress };
  }, [tasks]);

  const hasChecklist = tasks.length > 0;

  return (
    <div className="group relative overflow-hidden bg-white dark:bg-gray-800 rounded-xl shadow-premium hover:shadow-premium-hover transition-all duration-300 transform hover:-translate-y-1">
      <div className="relative aspect-video overflow-hidden">
        <img
          src={country.image}
          alt={country.name}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
        
        {/* Content overlay */}
        <div className="absolute inset-0 p-6 flex flex-col justify-between">
          {/* Header with PVT Badge */}
          <div className="flex items-center justify-between">
            <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-lg px-4 py-2">
              <p className="text-sm font-medium text-white">PVT {country.name}</p>
            </div>
            {hasChecklist && onDeleteList && (
              <button
                onClick={() => onDeleteList(country.code, true)}
                className="p-2 text-white hover:text-red-500 rounded-lg transition-colors backdrop-blur-sm bg-black/20 hover:bg-black/30"
                title="Supprimer la checklist"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            )}
          </div>

          {/* Progress overlay */}
          {hasChecklist && stats && (
            <div className="flex flex-wrap gap-2">
              <div className="px-3 py-1.5 bg-white/10 backdrop-blur-sm rounded-lg border border-white/20">
                <div className="flex items-center gap-2">
                  <CheckCircle2 className="w-4 h-4 text-emerald-400" />
                  <span className="text-sm font-medium text-white">
                    {stats.progress}% terminé
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className="p-6">
        {/* Stats */}
        {stats && (
          <div className="mb-6">
            {/* Progress bar */}
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-900 dark:text-white">
                Progression
              </span>
              <span className="text-sm text-gray-600 dark:text-gray-400">
                {stats.completed}/{stats.total} tâches
              </span>
            </div>
            <div className="h-2 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden mb-4">
              <div
                className="h-full bg-gradient-to-r from-primary-500 to-accent-500 transition-all duration-500"
                style={{ width: `${stats.progress}%` }}
              />
            </div>

            {/* Stats grid */}
            <div className="grid grid-cols-3 gap-2">
              <div className="flex flex-col items-center p-3 bg-emerald-50 dark:bg-emerald-900/20 rounded-xl border border-emerald-100/50 dark:border-emerald-800/30">
                <CheckCircle2 className="w-5 h-5 text-emerald-500 mb-1" />
                <span className="text-lg font-bold text-emerald-600 dark:text-emerald-400">
                  {stats.completed}
                </span>
                <span className="text-xs text-emerald-600/70 dark:text-emerald-400/70">
                  Terminées
                </span>
              </div>

              <div className="flex flex-col items-center p-3 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100/50 dark:border-amber-800/30">
                <Clock className="w-5 h-5 text-amber-500 mb-1" />
                <span className="text-lg font-bold text-amber-600 dark:text-amber-400">
                  {stats.inProgress}
                </span>
                <span className="text-xs text-amber-600/70 dark:text-amber-400/70">
                  En cours
                </span>
              </div>

              <div className="flex flex-col items-center p-3 bg-red-50 dark:bg-red-900/20 rounded-xl border border-red-100/50 dark:border-red-800/30">
                <AlertCircle className="w-5 h-5 text-red-500 mb-1" />
                <span className="text-lg font-bold text-red-600 dark:text-red-400">
                  {stats.notStarted}
                </span>
                <span className="text-xs text-red-600/70 dark:text-red-400/70">
                  À faire
                </span>
              </div>
            </div>
          </div>
        )}

        {/* Travel Dates */}
        {departureDate && (
          <div className="mb-6 bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl p-4 border border-primary-100/50 dark:border-primary-800/30">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-full bg-primary-100 dark:bg-primary-900/50 flex items-center justify-center">
                  <Plane className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                </div>
                <div>
                  <p className="text-xs text-gray-600 dark:text-gray-400">Départ</p>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {new Date(departureDate).toLocaleDateString('fr-FR', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    })}
                  </p>
                </div>
              </div>

              {returnDate && (
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 rounded-full bg-accent-100 dark:bg-accent-900/50 flex items-center justify-center">
                    <Home className="w-4 h-4 text-accent-600 dark:text-accent-400" />
                  </div>
                  <div>
                    <p className="text-xs text-gray-600 dark:text-gray-400">Retour</p>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {new Date(returnDate).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                      })}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Action button */}
        {hasChecklist ? (
          <button
            onClick={() => handleClick(() => onManageList(country.code))}
            className="w-full flex items-center justify-center space-x-2 bg-gradient-to-br from-accent-500/90 to-accent-600/90 hover:from-accent-500 hover:to-accent-600 text-white px-4 py-2.5 rounded-lg transition-all duration-300 transform hover:scale-[1.02] focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900 shadow-sm hover:shadow-md"
          >
            <ClipboardList className="w-5 h-5" />
            <span>Gérer ma checklist</span>
          </button>
        ) : (
          <button
            onClick={() => handleClick(() => onCreateList(country.code))}
            className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-accent-500 to-accent-600 hover:from-accent-600 hover:to-accent-700 text-white px-4 py-2.5 rounded-lg transition-all duration-300 transform hover:scale-[1.02] focus:ring-2 focus:ring-accent-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
          >
            <PlusCircle className="w-5 h-5" />
            <span>Créer ma checklist</span>
          </button>
        )}
      </div>
    </div>
  );
}