import { useEffect } from 'react';

export function useExternalLinks() {
  useEffect(() => {
    const handleExternalLinks = () => {
      // Sélectionne tous les liens qui ne commencent pas par # ou /
      const externalLinks = document.querySelectorAll('a[href^="http"]');
      
      externalLinks.forEach(link => {
        // Ne modifie que les liens qui n'ont pas déjà target="_blank"
        if (!link.hasAttribute('target')) {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer');
        }
      });
    };

    // Exécute une première fois
    handleExternalLinks();

    // Observe les changements dans le DOM
    const observer = new MutationObserver(handleExternalLinks);
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Nettoyage
    return () => observer.disconnect();
  }, []);
}