import React from 'react';

interface VideoContextType {
  currentlyPlaying: HTMLVideoElement | null;
  setCurrentlyPlaying: (video: HTMLVideoElement | null) => void;
}

export const VideoContext = React.createContext<VideoContextType>({
  currentlyPlaying: null,
  setCurrentlyPlaying: () => {}
});

export function VideoProvider({ children }: { children: React.ReactNode }) {
  const [currentlyPlaying, setCurrentlyPlaying] = React.useState<HTMLVideoElement | null>(null);

  // Add effect to pause other videos when one starts playing
  React.useEffect(() => {
    const handlePlay = (event: Event) => {
      const video = event.target as HTMLVideoElement;
      if (currentlyPlaying && currentlyPlaying !== video) {
        currentlyPlaying.pause();
      }
      setCurrentlyPlaying(video);
    };

    // Add play event listener to all video elements
    document.querySelectorAll('video').forEach(video => {
      video.addEventListener('play', handlePlay);
    });

    return () => {
      // Clean up event listeners
      document.querySelectorAll('video').forEach(video => {
        video.removeEventListener('play', handlePlay);
      });
    };
  }, [currentlyPlaying]);

  const handleSetCurrentlyPlaying = (video: HTMLVideoElement | null) => {
    if (currentlyPlaying && currentlyPlaying !== video) {
      currentlyPlaying.pause();
    }
    setCurrentlyPlaying(video);
  };

  return (
    <VideoContext.Provider 
      value={{ 
        currentlyPlaying, 
        setCurrentlyPlaying: handleSetCurrentlyPlaying 
      }}
    >
      {children}
    </VideoContext.Provider>
  );
}