import React from 'react';
import { Send } from 'lucide-react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase'; 
import { useAuth } from '../../lib/auth-context';

interface MessageInputProps {
  threadCode: string;
  threadType: 'country' | 'general' | 'bug';
  userId: string;
  userEmail: string;
  replyingTo: { id: string; userEmail: string; username: string; userId: string } | null;
  onCancelReply: () => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const MAX_MESSAGE_LENGTH = 2000;
const LINE_HEIGHT = 20; // Approximate line height in pixels
const MAX_LINES = 3;
const MAX_HEIGHT = LINE_HEIGHT * MAX_LINES;

export function MessageInput({
  threadCode,
  threadType,
  userId,
  userEmail,
  replyingTo,
  onCancelReply,
  scrollContainerRef
}: MessageInputProps) {
  const { userInfo } = useAuth();
  const [content, setContent] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  // Auto-resize textarea
  React.useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    
    // Calculate new height
    const newHeight = Math.min(textarea.scrollHeight, MAX_HEIGHT);
    textarea.style.height = `${newHeight}px`;
  }, [content]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!content.trim() || isSubmitting || !userInfo?.user?.username) {
      setError('Impossible d\'envoyer le message. Veuillez réessayer.');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // Préparer les données du message avec des valeurs valides pour Firestore
      const messageData = {
        content: content.trim(),
        user_id: userId,
        user_email: userEmail,
        username: userInfo.user.username,
        created_at: new Date(),
        likes: [],
        dislikes: [],
        status: 'active',
        thread_code: threadCode,
        thread_type: threadType,
        ...(replyingTo && {
          reply_to: {
            id: replyingTo.id,
            user_email: replyingTo.userEmail,
            username: replyingTo.username,
            user_id: replyingTo.userId
          }
        })
      };

      await addDoc(collection(db, 'messages'), messageData);
      setContent('');

      if (replyingTo) {
        onCancelReply();
      }
      
      if (scrollContainerRef?.current) {
        setTimeout(() => {
          const container = scrollContainerRef.current;
          container?.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth'
          });
        }, 100);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-4 pb-8 sm:pb-4">
      {error && (
        <div className="mb-3 p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 text-sm rounded-lg">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="relative">
        <div className="flex justify-end mb-1">
          <span className={`text-xs ${
            content.length > MAX_MESSAGE_LENGTH * 0.9 
              ? 'text-red-500 dark:text-red-400' 
              : 'text-gray-500 dark:text-gray-400'
          }`}>
            {content.length}/{MAX_MESSAGE_LENGTH}
          </span>
        </div>
        <div className="relative flex items-center gap-2">
          <textarea
            ref={textareaRef}
            value={content}
            onChange={(e) => {
              if (e.target.value.length <= MAX_MESSAGE_LENGTH) {
                setContent(e.target.value);
              }
            }}
            maxLength={MAX_MESSAGE_LENGTH}
            placeholder="Écrivez votre message..."
            className="flex-1 pl-4 pr-12 py-2.5 message-text bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white resize-none overflow-y-hidden"
            style={{
              minHeight: `${LINE_HEIGHT}px`,
              maxHeight: `${MAX_HEIGHT}px`,
              lineHeight: `${LINE_HEIGHT}px`
            }}
            rows={1}
            required
            disabled={isSubmitting}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />
          <button
            type="submit"
            disabled={!content.trim() || isSubmitting}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 bg-primary-500 hover:bg-primary-600 disabled:bg-gray-400 text-white rounded-lg transition-all duration-200 transform hover:scale-105 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
            title="Envoyer"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
        {replyingTo && (
          <div className="flex items-center gap-2 mb-2 text-sm text-gray-600 dark:text-gray-400">
            <span>Réponse à</span>
            <span className="font-medium text-gray-700 dark:text-gray-300">
              {replyingTo.username || 'Anonyme'}
            </span>
            <button
              onClick={onCancelReply}
              className="text-xs text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            >
              Annuler
            </button>
          </div>
        )}
      </form>
    </div>
  );
}