import React from 'react';
import { Languages, X } from 'lucide-react';
import { Language, Interest } from '../../../types/matching';

interface StepThreeProps {
  formData: {
    languages: Language[];
    interests: Interest[];
  };
  onFormDataChange: (data: any) => void;
  errors: string[];
}

const LANGUAGES = [
  { code: 'fr', name: 'Français' },
  { code: 'en', name: 'Anglais' },
  { code: 'es', name: 'Espagnol' },
  { code: 'de', name: 'Allemand' },
  { code: 'it', name: 'Italien' },
  { code: 'pt', name: 'Portugais' },
  { code: 'ja', name: 'Japonais' },
  { code: 'ko', name: 'Coréen' },
  { code: 'zh', name: 'Chinois' }
];

const INTERESTS = [
  // Outdoor & Aventure
  { id: 'hiking', name: 'Randonnée', icon: '🥾', category: 'outdoor' },
  { id: 'camping', name: 'Camping', icon: '⛺', category: 'outdoor' },
  { id: 'climbing', name: 'Escalade', icon: '🧗', category: 'outdoor' },
  { id: 'surfing', name: 'Surf', icon: '🏄', category: 'outdoor' },
  { id: 'diving', name: 'Plongée', icon: '🤿', category: 'outdoor' },
  { id: 'skiing', name: 'Ski', icon: '⛷️', category: 'outdoor' },

  // Culture & Arts
  { id: 'museums', name: 'Musées', icon: '🏛️', category: 'culture' },
  { id: 'history', name: 'Histoire', icon: '📚', category: 'culture' },
  { id: 'art', name: 'Art', icon: '🎨', category: 'culture' },
  { id: 'music', name: 'Musique', icon: '🎵', category: 'culture' },
  { id: 'theater', name: 'Théâtre', icon: '🎭', category: 'culture' },
  { id: 'cinema', name: 'Cinéma', icon: '🎬', category: 'culture' },

  // Gastronomie & Lifestyle
  { id: 'food', name: 'Gastronomie', icon: '🍽️', category: 'lifestyle' },
  { id: 'wine', name: 'Vin', icon: '🍷', category: 'lifestyle' },
  { id: 'coffee', name: 'Café', icon: '☕', category: 'lifestyle' },
  { id: 'cooking', name: 'Cuisine', icon: '👨‍🍳', category: 'lifestyle' },
  { id: 'vegan', name: 'Végan', icon: '🌱', category: 'lifestyle' },
  { id: 'nightlife', name: 'Vie nocturne', icon: '🌙', category: 'lifestyle' },

  // Sports & Bien-être
  { id: 'yoga', name: 'Yoga', icon: '🧘', category: 'wellness' },
  { id: 'fitness', name: 'Fitness', icon: '💪', category: 'wellness' },
  { id: 'running', name: 'Running', icon: '🏃', category: 'wellness' },
  { id: 'cycling', name: 'Vélo', icon: '🚲', category: 'wellness' },
  { id: 'meditation', name: 'Méditation', icon: '🧘‍♂️', category: 'wellness' },
  { id: 'swimming', name: 'Natation', icon: '🏊', category: 'wellness' }
];

export function StepThree({ formData, onFormDataChange, errors }: StepThreeProps) {
  // Ajouter le français comme langue native par défaut si aucune langue n'est sélectionnée
  React.useEffect(() => {
    if (formData.languages.length === 0) {
      onFormDataChange({
        ...formData,
        languages: [{
          code: 'fr',
          name: 'Français',
          level: 'native'
        }]
      });
    }
  }, []);

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
          <Languages className="w-5 h-5 text-primary-500" />
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Langues et centres d'intérêt
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Partagez vos compétences linguistiques et passions
          </p>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Langues parlées
          <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="space-y-3">
          {formData.languages.map((lang, index) => (
            <div key={lang.code} className="flex flex-col sm:flex-row sm:items-center gap-3">
              <select
                value={lang.code}
                onChange={(e) => {
                  const newLanguages = [...formData.languages];
                  const isNewLanguage = e.target.value !== lang.code;
                  newLanguages[index] = {
                    ...newLanguages[index],
                    code: e.target.value,
                    name: LANGUAGES.find(l => l.code === e.target.value)?.name || '',
                    level: e.target.value === 'fr' ? 'native' : isNewLanguage ? 'basic' : lang.level
                  };
                  onFormDataChange({ ...formData, languages: newLanguages });
                }}
                className="flex-1 px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
              >
                <option value="">Sélectionnez une langue</option>
                {LANGUAGES.map(l => (
                  <option 
                    key={`lang-${index}-${l.code}`} 
                    value={l.code}
                    disabled={formData.languages.some((lang, i) => i !== index && lang.code === l.code)}
                  >
                    {l.name}
                  </option>
                ))}
              </select>
              <select
                value={lang.level}
                onChange={(e) => {
                  const newLanguages = [...formData.languages];
                  newLanguages[index] = {
                    ...newLanguages[index],
                    level: e.target.value as Language['level']
                  };
                  onFormDataChange({ ...formData, languages: newLanguages });
                }}
                disabled={lang.code === 'fr'} // Désactiver la sélection du niveau pour le français
                className="w-full sm:w-40 px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
              >
                <option value="basic">Basique</option>
                <option value="intermediate">Intermédiaire</option>
                <option value="fluent">Courant</option>
                <option value="native">Natif</option>
              </select>
              <button
                type="button"
                disabled={lang.code === 'fr'} // Empêcher la suppression du français
                onClick={() => {
                  const newLanguages = formData.languages.filter((_, i) => i !== index);
                  onFormDataChange({ ...formData, languages: newLanguages });
                }}
                className={`p-2 rounded-lg transition-colors ${
                  lang.code === 'fr'
                    ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed'
                    : 'text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20'
                }`}
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          ))}
          {formData.languages.length < 5 && (
            <button
              type="button"
              onClick={() => {
                const availableLanguage = LANGUAGES.find(l => 
                  !formData.languages.some(lang => lang.code === l.code)
                );
                if (availableLanguage) {
                  onFormDataChange({
                    ...formData,
                    languages: [...formData.languages, { 
                      code: availableLanguage.code, 
                      name: availableLanguage.name, 
                      level: 'basic' 
                    }]
                  });
                }
              }}
              className="w-full px-4 py-2 text-sm font-medium text-primary-600 dark:text-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/20 rounded-lg transition-colors"
            >
              + Ajouter une langue
            </button>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Centres d'intérêt
          <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
          {INTERESTS.map(interest => (
            <button
              key={interest.id}
              type="button"
              onClick={() => {
                const isSelected = formData.interests.some(i => i.id === interest.id);
                if (isSelected) {
                  onFormDataChange({
                    ...formData,
                    interests: formData.interests.filter(i => i.id !== interest.id)
                  });
                } else if (formData.interests.length < 6) {
                  onFormDataChange({
                    ...formData,
                    interests: [...formData.interests, interest]
                  });
                }
              }}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm transition-colors ${
                formData.interests.some(i => i.id === interest.id)
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
              disabled={formData.interests.length >= 6 && !formData.interests.some(i => i.id === interest.id)}
            >
              <span>{interest.icon}</span>
              <span>{interest.name}</span>
            </button>
          ))}
        </div>
        <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
          Sélectionnez jusqu'à 6 centres d'intérêt
        </p>
      </div>
    </div>
  );
}