import React from 'react';
import { Shield, ArrowRight, ExternalLink } from 'lucide-react';

interface HeroSectionProps {
  image: string;
  title: string;
  content: string;
}

export function HeroSection({ image, title, content }: HeroSectionProps) {
  return (
    <div className="relative min-h-[500px] sm:min-h-[600px] flex items-center">
      <div className="absolute inset-0">
        <img 
          src={image}
          alt="Globe PVT Insurance"
          className="w-full h-full object-cover opacity-90 dark:opacity-70"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/70 to-black/80"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="max-w-3xl">
          <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-4 sm:mb-6">
            <Shield className="w-4 h-4 sm:w-5 sm:h-5 text-primary-400" />
            <span className="text-[13px] sm:text-sm font-medium text-white">Recommandé par pvtistes.net</span>
          </div>

          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 sm:mb-6">
            {title}
          </h1>

          <div 
            className="text-[15px] sm:text-lg text-white/90 leading-relaxed prose prose-invert prose-p:text-white/90 max-w-none"
            dangerouslySetInnerHTML={{ __html: content }}
          />

          <div className="mt-8 flex flex-col sm:flex-row gap-3 sm:gap-4">
            <a
              href="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white text-[15px] sm:text-base font-medium rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 w-full sm:w-auto"
            >
              <span>Souscrire maintenant</span>
              <ArrowRight className="w-5 h-5" />
            </a>
            <a
              href="https://clems.acs-ami.com/fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 px-6 py-3 bg-white/10 hover:bg-white/20 backdrop-blur-sm text-white text-[15px] sm:text-base font-medium rounded-xl transition-colors border border-white/20 w-full sm:w-auto"
            >
              <span>Faire une demande de remboursement</span>
              <ExternalLink className="w-5 h-5" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}