import React from 'react';
import { Globe2 } from 'lucide-react';
import { budgetCountries } from '../../data/budget-countries';

interface MatchingFiltersProps {
  onFilterChange: (filters: { destination?: string }) => void;
}

export function MatchingFilters({ onFilterChange }: MatchingFiltersProps) {
  const [selectedDestination, setSelectedDestination] = React.useState<string>('');

  const handleDestinationChange = (destination: string) => {
    setSelectedDestination(destination);
    // Utiliser directement le code pays sans conversion
    onFilterChange({ destination: destination });
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 p-4">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
          <Globe2 className="w-5 h-5 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <h3 className="text-base font-medium text-gray-900 dark:text-white">
            Destination
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Filtrer par pays de destination
          </p>
        </div>
      </div>

      <select
        value={selectedDestination}
        onChange={(e) => handleDestinationChange(e.target.value)}
        className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white text-base"
      >
        <option value="">Toutes les destinations</option>
        {budgetCountries.map((country) => (
          <option key={country.code} value={country.code}>
            {country.flag} {country.name}
          </option>
        ))}
      </select>
    </div>
  );
}