import React from 'react';
import { DollarSign, Info } from 'lucide-react';

interface BudgetSummaryProps {
  data: {
    totalRequired: number;
    currentSavings: number;
    monthlyExpenses: number;
    initialCosts: number;
    setupCosts: number;
    survivalMonths: number;
    pvtDuration: string;
  };
}

export function BudgetSummary({ data }: BudgetSummaryProps) {
  const { totalRequired, currentSavings, monthlyExpenses, initialCosts, setupCosts } = data;

  // Format numbers with 2 decimal places
  const formatNumber = (num: number) => num.toLocaleString('fr-FR', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  });

  return (
    <div className="space-y-8">
      {/* Détails des dépenses */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6">
        <div className="bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-gray-700 flex flex-col h-full">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-emerald-50 dark:bg-emerald-900/20 rounded-lg">
              <DollarSign className="w-5 h-5 text-emerald-500" />
            </div>
            <span className="text-sm text-gray-600 dark:text-gray-400">Dépenses mensuelles</span>
          </div>
          <div className="text-lg font-bold text-gray-900 dark:text-white mt-auto pt-2">
            {formatNumber(monthlyExpenses)} €
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
           Incluant le loyer
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-gray-700 flex flex-col h-full">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
              <DollarSign className="w-5 h-5 text-blue-500" />
            </div>
            <span className="text-sm text-gray-600 dark:text-gray-400">Avant le départ</span>
          </div>
          <div className="text-lg font-bold text-gray-900 dark:text-white mt-2">
            {formatNumber(initialCosts)} €
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
            Billet d'avion, PVT et assurance
          </div>
          <div className="flex-grow"></div>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-gray-700 flex flex-col h-full">
          <div className="flex items-center gap-3 mb-2">
            <div className="p-2 bg-purple-50 dark:bg-purple-900/20 rounded-lg">
              <DollarSign className="w-5 h-5 text-purple-500" />
            </div>
            <span className="text-sm text-gray-600 dark:text-gray-400">Coûts d'installation</span>
          </div>
          <div className="text-lg font-bold text-gray-900 dark:text-white mt-2">
            {formatNumber(setupCosts)} €
          </div>
          <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
            Caution, mobilier et premiers achats
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl p-4 sm:p-6 border border-primary-100/50 dark:border-primary-800/30">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm font-medium text-gray-800 dark:text-gray-200">Budget total requis</span>
          <span className="text-lg font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
            {formatNumber(totalRequired)} €
          </span>
        </div>
        <div className="text-xs text-gray-600 dark:text-gray-400 mb-4">
          <div className="flex justify-between mb-1">
            <span>Dépenses mensuelles × {data.pvtDuration} mois</span>
            <span>{formatNumber(monthlyExpenses * Number(data.pvtDuration))} €</span>
          </div>
          <div className="flex justify-between mb-1">
            <span>Dépenses avant le départ</span>
            <span>+ {formatNumber(initialCosts)} €</span>
          </div>
          <div className="flex justify-between">
            <span>Coûts d'installation</span>
            <span>+ {formatNumber(setupCosts)} €</span>
          </div>
        </div>
        <div className="flex items-center justify-between pt-4 border-t border-gray-200/50 dark:border-gray-700/50">
          <span className="text-sm font-medium text-gray-800 dark:text-gray-200">Économies actuelles</span>
          <span className="text-lg font-bold text-gray-900 dark:text-white">
            {formatNumber(currentSavings)} €
          </span>
        </div>
      </div>
    </div>
  );
}