import { UserInfo } from './auth-context';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

// Generic API call function with auth
async function apiCall<T>(
  endpoint: string,
  options: RequestInit = {},
  userInfo?: UserInfo | null
): Promise<T> {
  if (!userInfo?.token) {
    throw new Error('Authentication required');
  }

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${userInfo.token}`,
    ...options.headers
  };

  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers
  });

  if (!response.ok) {
    const data = await response.json().catch(() => null);
    throw new Error(data?.message || `HTTP error! status: ${response.status}`);
  }

  return response.json();
}

// News API functions
export async function fetchNews(page: number, perPage: number, country?: string, sort?: string, userInfo?: UserInfo | null) {
  let endpoint = `/news?perPage=${perPage}&page=${page}`;
  if (country) endpoint += `&country=${country}`;
  if (sort) endpoint += `&sort=${sort}`;
  return apiCall(endpoint, {}, userInfo);
}

export async function fetchNewsById(id: string, userInfo?: UserInfo | null) {
  return apiCall(`/news/${id}`, {}, userInfo);
}

// Dossiers API functions
export async function fetchDossiers(page: number, perPage: number, country?: string, sort?: string, userInfo?: UserInfo | null) {
  let endpoint = `/dossiers?perPage=${perPage}&page=${page}`;
  if (country) endpoint += `&country=${country}`;
  if (sort) endpoint += `&sort=${sort}`;
  return apiCall(endpoint, {}, userInfo);
}

export async function fetchDossierById(id: string, userInfo?: UserInfo | null) {
  return apiCall(`/dossiers/${id}`, {}, userInfo);
}

// Interviews API functions
export async function fetchInterviews(page: number, perPage: number, country?: string, sort?: string, userInfo?: UserInfo | null) {
  let endpoint = `/interviews?perPage=${perPage}&page=${page}`;
  if (country) endpoint += `&country=${country}`;
  if (sort) endpoint += `&sort=${sort}`;
  return apiCall(endpoint, {}, userInfo);
}

export async function fetchInterviewById(id: string, userInfo?: UserInfo | null) {
  return apiCall(`/interviews/${id}`, {}, userInfo);
}

// Email API functions
export async function sendEmail(subject: string, content: string, toEmail: string, userInfo?: UserInfo | null) {
  return apiCall('/email/send', {
    method: 'POST',
    body: JSON.stringify({ subject, content, toEmail })
  }, userInfo);
}

// Email API functions
export async function sendEmailToUser(subject: string, content: string, toUserId: number, userInfo?: UserInfo | null) {
  return apiCall('/email/send-to-user', {
    method: 'POST',
    body: JSON.stringify({ subject, content, toUserId })
  }, userInfo);
}