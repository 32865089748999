import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserX, AlertTriangle, ArrowLeft } from 'lucide-react';
import { useAuth } from '../lib/auth-context';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function DeleteAccountPage() {
  const { user, userInfo, logout } = useAuth();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [existingRequest, setExistingRequest] = React.useState<{
    status: 'pending' | 'approved' | 'rejected';
    requestedAt: Date;
  } | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // Vérifier si une demande existe déjà
  React.useEffect(() => {
    const checkExistingRequest = async () => {
      if (!user?.uid) return;
      
      try {
        const requestsRef = collection(db, 'accountDeletionRequests');
        const q = query(requestsRef, where('userId', '==', user.uid));
        const snapshot = await getDocs(q);
        
        if (!snapshot.empty) {
          const request = snapshot.docs[0].data();
          setExistingRequest({
            status: request.status,
            requestedAt: request.requestedAt.toDate()
          });
        }
      } catch (error) {
        console.error('Error checking deletion request:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkExistingRequest();
  }, [user?.uid]);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const handleRequestAccountDeletion = async () => {
    try {
      if (!user?.uid || !userInfo?.user?.email) return;
      setIsSubmitting(true);
      
      await addDoc(collection(db, 'accountDeletionRequests'), {
        userId: user.uid,
        email: userInfo.user.email,
        requestedAt: new Date(),
        status: 'pending'
      });
      
      // Show success message
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 px-6 py-3 rounded-xl shadow-lg border border-emerald-100 dark:border-emerald-800/30 z-50 flex items-center gap-3 animate-fade-in';
      toast.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Votre demande de suppression a été enregistrée</span>
      `;
      document.body.appendChild(toast);
      setTimeout(() => toast.remove(), 5000);
      
      navigate('/');
    } catch (error) {
      console.error('Error requesting account deletion:', error);
      
      // Show error message
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-3 rounded-xl shadow-lg border border-red-100 dark:border-red-800/30 z-50 flex items-center gap-3 animate-fade-in';
      toast.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <span>Une erreur est survenue</span>
      `;
      document.body.appendChild(toast);
      setTimeout(() => toast.remove(), 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user || isLoading) return null;

  const getStatusMessage = () => {
    if (!existingRequest) return null;
    
    const formattedDateTime = existingRequest.requestedAt.toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    switch (existingRequest.status) {
      case 'pending':
        return {
          title: 'Demande de suppression en cours',
          message: `Votre demande de suppression a été enregistrée le ${formattedDateTime}. Vous serez notifié par email une fois la suppression effectuée.`,
          description: 'Votre compte sera supprimé dans les prochains jours. Vous pouvez continuer à utiliser votre compte en attendant.',
          color: 'amber'
        };
      case 'approved':
        return {
          title: 'Demande approuvée',
          message: `Votre demande de suppression du ${formattedDateTime} a été approuvée.`,
          description: 'Votre compte sera supprimé dans les prochaines heures. Vous serez déconnecté automatiquement.',
          color: 'emerald'
        };
      case 'rejected':
        return {
          title: 'Demande rejetée',
          message: `Votre demande de suppression du ${formattedDateTime} a été rejetée.`,
          description: 'Veuillez contacter le support pour plus d\'informations.',
          color: 'red'
        };
      default:
        return null;
    }
  };

  const statusInfo = getStatusMessage();
  const showForm = !existingRequest;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      {/* Background gradient */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(239,65,54,0.1),rgba(0,0,0,0)_50%)] dark:bg-[radial-gradient(circle_at_50%_120%,rgba(239,65,54,0.05),rgba(0,0,0,0)_50%)]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_20%,rgba(239,65,54,0.05),rgba(0,0,0,0)_50%)] dark:bg-[radial-gradient(circle_at_80%_20%,rgba(239,65,54,0.025),rgba(0,0,0,0)_50%)]" />
      </div>

      {/* Back button */}
      <div className="fixed top-4 left-4 z-50">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 px-3 py-2 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-xl shadow-sm hover:shadow-md border border-gray-200/50 dark:border-gray-700/50 transition-all duration-200 hover:bg-white dark:hover:bg-gray-800"
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 text-gray-600 dark:text-gray-400" />
          <span className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300">Retour</span>
        </button>
      </div>

      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-2xl bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
          <div className="p-6 sm:p-8">
            {/* Header with gradient background */}
            <div className="relative overflow-hidden bg-gradient-to-br from-red-50 to-red-100 dark:from-red-900/20 dark:to-red-800/20 -mx-8 -mt-8 p-8 mb-8 border-b border-red-100/50 dark:border-red-800/30 shadow-[inset_0_1px_1px_rgba(255,255,255,0.1)]">
              <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-red-100/20 to-red-200/20 dark:from-red-800/10 dark:to-red-900/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
              
              <div className="relative flex items-center gap-4">
                <div className="p-3 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-xl shadow-sm">
                  <UserX className="w-6 h-6 text-red-600 dark:text-red-400" />
                </div>
                <div>
                  <h1 className="text-xl sm:text-2xl font-bold text-red-900 dark:text-red-300">
                    Supprimer votre compte
                  </h1>
                  <p className="text-sm sm:text-base text-red-600/90 dark:text-red-400/90">
                    Cette action est irréversible
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-6">
              {/* Status Message */}
              {statusInfo && (
                <div className={`bg-${statusInfo.color}-50 dark:bg-${statusInfo.color}-900/20 rounded-xl p-6 border border-${statusInfo.color}-100 dark:border-${statusInfo.color}-800/30 shadow-[inset_0_1px_1px_rgba(255,255,255,0.05)] relative overflow-hidden`}>
                  <div className={`absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-${statusInfo.color}-100/20 to-${statusInfo.color}-200/20 dark:from-${statusInfo.color}-800/10 dark:to-${statusInfo.color}-900/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none`} />
                  
                  <div className="flex items-start gap-3">
                    <AlertTriangle className={`w-5 h-5 text-${statusInfo.color}-600 dark:text-${statusInfo.color}-400 flex-shrink-0 mt-0.5`} />
                    <div className="relative space-y-3">
                      <h3 className={`text-base font-medium text-${statusInfo.color}-800 dark:text-${statusInfo.color}-300`}>
                        {statusInfo.title}
                      </h3>
                      <p className={`text-sm text-${statusInfo.color}-700 dark:text-${statusInfo.color}-400`}>
                        {statusInfo.message}
                      </p>
                      <p className={`text-sm text-${statusInfo.color}-600/90 dark:text-${statusInfo.color}-300 border-t border-${statusInfo.color}-100/50 dark:border-${statusInfo.color}-800/30 pt-3 mt-3`}>
                        {statusInfo.description}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {showForm && <div className="bg-amber-50 dark:bg-amber-900/20 rounded-xl p-6 border border-amber-100 dark:border-amber-800/30 shadow-[inset_0_1px_1px_rgba(255,255,255,0.05)]">
                <div className="flex items-start gap-3">
                  <AlertTriangle className="w-5 h-5 text-amber-600 dark:text-amber-400 flex-shrink-0 mt-0.5" />
                  <div className="space-y-2">
                    <h3 className="text-base font-medium text-amber-800 dark:text-amber-300">
                      Conséquences de la suppression
                    </h3>
                    <ul className="space-y-2 text-sm text-amber-700 dark:text-amber-400">
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-amber-500/50 ring-2 ring-amber-500/20" />
                        Toutes vos checklists seront supprimées
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-amber-500/50 ring-2 ring-amber-500/20" />
                        Tous vos budgets sauvegardés seront effacés
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-amber-500/50 ring-2 ring-amber-500/20" />
                        Votre profil PVTmates sera supprimé
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-amber-500/50 ring-2 ring-amber-500/20" />
                        Vos photos et commentaires seront retirés
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-amber-500/50 ring-2 ring-amber-500/20" />
                        Votre compte sera définitivement supprimé
                      </li>
                    </ul>
                  </div>
                </div>
              </div>}

              {showForm && <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50 shadow-[inset_0_1px_1px_rgba(255,255,255,0.05)]">
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Si vous souhaitez continuer, cliquez sur le bouton ci-dessous pour demander la suppression de votre compte. Vous serez automatiquement déconnecté et votre compte sera supprimé dans les prochains jours.
                </p>
              </div>}

              <div className="flex flex-col sm:flex-row justify-end gap-3 pt-6 border-t border-gray-200/50 dark:border-gray-700/50">
                <button
                  onClick={() => navigate(-1)}
                  className="px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-xl transition-all duration-200 order-2 sm:order-1 border border-gray-200 dark:border-gray-700 hover:shadow-md"
                >
                  {existingRequest ? 'Retour' : 'Annuler'}
                </button>
                {showForm && (
                  <button
                    onClick={handleRequestAccountDeletion}
                    disabled={isSubmitting}
                    className="relative flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-red-600 to-red-700 hover:from-red-700 hover:to-red-800 disabled:from-gray-400 disabled:to-gray-500 text-white rounded-xl transition-all duration-300 transform hover:scale-[1.02] shadow-sm hover:shadow-md order-1 sm:order-2 overflow-hidden group"
                  >
                    <div className="absolute inset-0 bg-gradient-to-r from-white/[0.07] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    {isSubmitting ? (
                      <>
                        <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                        <span>Traitement en cours...</span>
                      </>
                    ) : (
                      <>
                        <UserX className="w-4 h-4" />
                        <span>Supprimer mon compte</span>
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}