import React from 'react';
import { X } from 'lucide-react';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function TermsModal({ isOpen, onClose }: TermsModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium w-full max-w-4xl h-[90vh] flex flex-col">
        {/* Header - Fixed */}
        <div className="flex-shrink-0 flex items-center justify-between p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white">
            Conditions Générales d'Utilisation (CGU)
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Content - Scrollable */}
        <div className="flex-1 overflow-y-auto custom-scrollbar">
          <div className="p-4">
            <div className="prose prose-sm sm:prose dark:prose-invert max-w-none">
              <h3 className="text-base sm:text-lg font-semibold">1. Présentation de l'interface</h3>
              <p className="text-sm sm:text-base">
                L'interface PVT Checklist est proposée par Yoodac Web Agency, société immatriculée en Ontario, Canada. 
                Elle est accessible via l'URL app.pvtistes.net.
              </p>
              <p className="text-sm sm:text-base">
                PVT Checklist fait partie de l'écosystème géré par la même équipe derrière app.pvtistes.net, 
                whvaustralie.com et pvtistes.net.
              </p>
              <p className="text-sm sm:text-base">
                Pour toute question, vous pouvez nous contacter via le{' '}
                <a href="https://pvtistes.net/forum/sendmessage.php" target="_blank" rel="noopener noreferrer">
                  formulaire de contact de pvtistes.net
                </a>
                .
              </p>

              <h3 className="text-base sm:text-lg font-semibold">2. Objet et utilisation</h3>
              <p className="text-sm sm:text-base">
                PVT Checklist est un outil gratuit permettant aux utilisateurs de créer et suivre leurs propres 
                checklists pour organiser leur départ en PVT et toute autre expatriation. L'accès à l'interface 
                est libre et sans frais.
              </p>
              <p className="text-sm sm:text-base">
                L'utilisation de l'interface est soumise à l'acceptation des présentes CGU.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">3. Données collectées</h3>
              <p className="text-sm sm:text-base">L'interface enregistre certaines données des utilisateurs, notamment :</p>
              <ul className="text-sm sm:text-base">
                <li>L'adresse e-mail (pour la création et l'accès aux checklists personnalisées).</li>
                <li>Les checklists enregistrées (associées à chaque compte utilisateur).</li>
              </ul>
              <p className="text-sm sm:text-base">
                Ces données sont stockées de manière sécurisée et ne sont pas partagées avec des tiers sans 
                consentement explicite.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">4. Responsabilités</h3>
              <ul className="text-sm sm:text-base">
                <li>PVT Checklist est un outil indicatif. Nous ne garantissons pas l'exactitude ou l'exhaustivité des informations fournies.</li>
                <li>L'utilisateur est responsable de la vérification des démarches officielles auprès des autorités compétentes.</li>
                <li>Nous ne pouvons être tenus responsables en cas d'erreur, d'oubli ou de problème résultant de l'utilisation de l'interface.</li>
                <li>L'interface peut contenir des liens vers des sites tiers (gouvernements, partenaires, etc.), dont nous ne maîtrisons ni le contenu ni les mises à jour.</li>
              </ul>

              <h3 className="text-base sm:text-lg font-semibold">5. Modification et suppression des données</h3>
              <ul className="text-sm sm:text-base">
                <li>Les utilisateurs peuvent modifier ou supprimer leurs checklists à tout moment.</li>
                <li>En cas de violation des présentes CGU, nous nous réservons le droit de suspendre ou restreindre l'accès à l'interface.</li>
              </ul>

              <h3 className="text-base sm:text-lg font-semibold">6. Évolutions de l'interface et des CGU</h3>
              <p className="text-sm sm:text-base">
                Nous nous réservons le droit de modifier les présentes CGU à tout moment, notamment en cas 
                d'évolution de l'interface. Les utilisateurs en seront informés via l'interface ou par e-mail 
                si nécessaire.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">7. Droit applicable</h3>
              <p className="text-sm sm:text-base">
                Les présentes CGU sont régies par le droit canadien. En cas de litige, les tribunaux compétents 
                seront ceux de la province d'Ontario, Canada.
              </p>
            </div>
          </div>
        </div>

        {/* Footer - Fixed */}
        <div className="flex-shrink-0 p-4 border-t dark:border-gray-700 bg-gray-50 dark:bg-gray-800/50">
          <button
            onClick={onClose}
            className="w-full sm:w-auto px-6 py-2.5 bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium rounded-xl transition-colors"
          >
            J'ai compris
          </button>
        </div>
      </div>
    </div>
  );
}