import React from 'react';
import { Save, X, Mail } from 'lucide-react';

interface SaveBudgetModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  isSaving: boolean;
  willSendEmail?: boolean;
}

export function SaveBudgetModal({ isOpen, onClose, onSave, isSaving, willSendEmail }: SaveBudgetModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium max-w-md w-full transform animate-fade-in">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6">
          {/* Icon */}
          <div className="mx-auto w-16 h-16 bg-primary-100 dark:bg-primary-900/30 rounded-full flex items-center justify-center mb-6">
            <Save className="w-8 h-8 text-primary-600 dark:text-primary-400" />
          </div>

          {/* Content */}
          <div className="text-center mb-8">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Souhaitez-vous sauvegarder votre simulation budgétaire ?
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              La sauvegarde vous permettra de retrouver et modifier votre budget ultérieurement
            </p>
            {willSendEmail && (
              <div className="mt-4 bg-primary-50 dark:bg-primary-900/20 rounded-lg p-3 border border-primary-100/50 dark:border-primary-800/30">
                <div className="flex items-center gap-2">
                  <Mail className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                  <p className="text-sm text-primary-600 dark:text-primary-400">
                    Un récapitulatif détaillé vous sera envoyé par email
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Actions */}
          <div className="flex flex-col sm:flex-row gap-3">
            <button
              onClick={onClose}
              className="flex-1 px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
            >
              Pas maintenant
            </button>
            <button
              onClick={onSave}
              disabled={isSaving}
              className="flex-1 px-4 py-2.5 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:bg-gray-400 dark:disabled:bg-gray-600 rounded-xl transition-colors flex items-center justify-center gap-2"
            >
              {isSaving ? (
                <>
                  <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  <span>Sauvegarde...</span>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  <span>Sauvegarder mon budget</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}