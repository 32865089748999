import React from 'react';
import { Calculator, ArrowRight, PiggyBank, Globe2, Building2 } from 'lucide-react';
import { Footer } from '../components/Footer';
import { LandingNav } from '../components/landing/LandingNav';
import { useTheme } from '../lib/theme-context';

interface LandingPageProps {
  onLogin: () => void;
}

export function BudgetSimulatorLandingPage({ onLogin }: LandingPageProps) {
  const { theme, toggleTheme } = useTheme();
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <LandingNav 
        onLogin={onLogin} 
        theme={theme} 
        onToggleTheme={toggleTheme} 
      />
      {/* Hero Section */}
      <div className="relative min-h-[600px] sm:min-h-[700px] flex items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=2671&auto=format&fit=crop"
            alt="Budget Planning"
            className="w-full h-full object-cover opacity-90 dark:opacity-70"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent" />
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="max-w-xl">
            <div className="inline-flex items-center gap-2 px-3 py-1.5 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-6">
              <Calculator className="w-4 h-4 text-primary-400" />
              <span className="text-sm font-medium text-white">Nouveau</span>
            </div>

            <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
              Simulateur de budget PVT
            </h1>

            <p className="text-xl text-white/90 leading-relaxed mb-8">
              Planifiez votre budget PVT avec précision grâce à notre simulateur alimenté par des données réelles et actualisées.
            </p>

            <button
              onClick={onLogin}
              className="inline-flex items-center gap-3 px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white rounded-xl transition-all duration-300 transform hover:scale-105 shadow-lg"
            >
              <span className="text-lg font-medium">Commencer la simulation</span>
              <ArrowRight className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-24 bg-white dark:bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl p-6 border border-primary-100/50 dark:border-primary-800/30">
              <div className="w-12 h-12 bg-white dark:bg-gray-800 rounded-xl flex items-center justify-center mb-4">
                <Globe2 className="w-6 h-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Données réelles
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                Prix à jour pour toutes les villes PVT, basés sur des données vérifiées.
              </p>
            </div>

            <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl p-6 border border-primary-100/50 dark:border-primary-800/30">
              <div className="w-12 h-12 bg-white dark:bg-gray-800 rounded-xl flex items-center justify-center mb-4">
                <Building2 className="w-6 h-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Mode nomade
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                Options d'hébergement flexibles : auberges, van, volontariat et plus encore.
              </p>
            </div>

            <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl p-6 border border-primary-100/50 dark:border-primary-800/30">
              <div className="w-12 h-12 bg-white dark:bg-gray-800 rounded-xl flex items-center justify-center mb-4">
                <PiggyBank className="w-6 h-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
                Sauvegarde
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                Enregistrez vos simulations et comparez différents scénarios.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}