import React from 'react';
import { Task } from '../types/todo';
import { Plane, Building, Home, CheckCircle2, Clock, Circle, ChevronDown, ChevronUp, Search, ListX, ListChecks } from 'lucide-react';

interface TaskNavigationProps {
  tasks: Task[];
  onTaskClick: (taskId: string) => void;
  onUpdateStatus?: (taskId: string, status: string) => void;
}

export function TaskNavigation({ tasks, onTaskClick, onUpdateStatus }: TaskNavigationProps) {
  const [expandedCategories, setExpandedCategories] = React.useState<Set<string>>(new Set(['before-departure', 'after-arrival', 'return']));
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedStatus, setSelectedStatus] = React.useState<string>('all');

  const tasksByCategory = React.useMemo(() => {
    const filteredTasks = tasks.filter(task => {
      const matchesSearch = task.title.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesStatus = selectedStatus === 'all' || task.status === selectedStatus;
      return matchesSearch && matchesStatus;
    });

    const grouped = filteredTasks.reduce((acc, task) => {
      const category = task.category || 'uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(task);
      return acc;
    }, {} as Record<string, Task[]>);

    const orderedCategories = ['before-departure', 'after-arrival', 'return'];
    return orderedCategories.reduce((acc, category) => {
      if (grouped[category]) {
        acc[category] = grouped[category];
      }
      return acc;
    }, {} as Record<string, Task[]>);
  }, [tasks, searchQuery, selectedStatus]);

  const hasFilteredTasks = Object.keys(tasksByCategory).length > 0;

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'before-departure':
        return <ListChecks className="w-5 h-5 text-blue-500" />;
      case 'after-arrival':
        return <Plane className="w-5 h-5 text-green-500" />;
      case 'return':
        return <Building className="w-5 h-5 text-purple-500" />;
      default:
        return null;
    }
  };

  const getCategoryLabel = (category: string) => {
    switch (category) {
      case 'before-departure':
        return 'Préparer votre départ';
      case 'after-arrival':
        return 'Le jour du départ';
      case 'return':
        return 'Les démarches à l\'arrivée';
      default:
        return category;
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 className="w-4 h-4 text-emerald-500" />;
      case 'in-progress':
        return <Clock className="w-4 h-4 text-amber-500" />;
      default:
        return <Circle className="w-4 h-4 text-red-500" />;
    }
  };

  const getNextStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case 'not-started':
        return 'in-progress';
      case 'in-progress':
        return 'completed';
      case 'completed':
        return 'not-started';
      default:
        return 'not-started';
    }
  };

  const handleStatusClick = (e: React.MouseEvent, taskId: string, currentStatus: string) => {
    e.stopPropagation(); // Prevent task click
    if (onUpdateStatus) {
      onUpdateStatus(taskId, getNextStatus(currentStatus));
    }
  };

  const toggleCategory = (category: string) => {
    setExpandedCategories(prev => {
      const next = new Set(prev);
      if (next.has(category)) {
        next.delete(category);
      } else {
        next.add(category);
      }
      return next;
    });
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-premium border border-gray-100 dark:border-gray-700 flex flex-col h-full">
      <div className="p-4 border-b border-gray-100 dark:border-gray-700">
        <h3 className="text-base font-medium text-gray-900 dark:text-white mb-3">
          Navigation rapide
        </h3>
        
        {/* Barre de recherche */}
        <div className="relative mb-3">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Rechercher une tâche..."
            className="w-full pl-9 pr-3 py-2 text-sm border border-gray-200 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
          />
        </div>

        {/* Filtres par statut avec icônes */}
        <div className="flex items-center justify-around">
          <button
            onClick={() => setSelectedStatus('all')}
            className={`p-2 rounded-lg transition-colors ${
              selectedStatus === 'all'
                ? 'bg-gray-100 dark:bg-gray-700'
                : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
            }`}
            title="Toutes les tâches"
          >
            <ListX className={`w-5 h-5 ${
              selectedStatus === 'all'
                ? 'text-gray-900 dark:text-white'
                : 'text-gray-400 dark:text-gray-500'
            }`} />
          </button>
          <button
            onClick={() => setSelectedStatus('completed')}
            className={`p-2 rounded-lg transition-colors ${
              selectedStatus === 'completed'
                ? 'bg-emerald-50 dark:bg-emerald-900/20'
                : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
            }`}
            title="Tâches terminées"
          >
            <CheckCircle2 className={`w-5 h-5 ${
              selectedStatus === 'completed'
                ? 'text-emerald-600 dark:text-emerald-400'
                : 'text-gray-400 dark:text-gray-500'
            }`} />
          </button>
          <button
            onClick={() => setSelectedStatus('in-progress')}
            className={`p-2 rounded-lg transition-colors ${
              selectedStatus === 'in-progress'
                ? 'bg-amber-50 dark:bg-amber-900/20'
                : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
            }`}
            title="Tâches en cours"
          >
            <Clock className={`w-5 h-5 ${
              selectedStatus === 'in-progress'
                ? 'text-amber-600 dark:text-amber-400'
                : 'text-gray-400 dark:text-gray-500'
            }`} />
          </button>
          <button
            onClick={() => setSelectedStatus('not-started')}
            className={`p-2 rounded-lg transition-colors ${
              selectedStatus === 'not-started'
                ? 'bg-red-50 dark:bg-red-900/20'
                : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
            }`}
            title="Tâches à faire"
          >
            <Circle className={`w-5 h-5 ${
              selectedStatus === 'not-started'
                ? 'text-red-600 dark:text-red-400'
                : 'text-gray-400 dark:text-gray-500'
            }`} />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-3 space-y-1 custom-scrollbar">
        {hasFilteredTasks ? (
          Object.entries(tasksByCategory).map(([category, categoryTasks]) => (
            <div key={category} className="mb-2">
              <button
                onClick={() => toggleCategory(category)}
                className="w-full flex items-center justify-between p-2.5 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-lg transition-colors group"
              >
                <div className="flex items-center gap-2.5 min-w-0">
                  <div className="flex-shrink-0">
                    {getCategoryIcon(category)}
                  </div>
                  <div className="min-w-0 flex-1">
                    <span className="text-base font-medium text-gray-900 dark:text-white block truncate">
                      {getCategoryLabel(category)}
                    </span>
                  </div>
                </div>
                <div className="flex-shrink-0 ml-2">
                  {expandedCategories.has(category) ? (
                    <ChevronUp className="w-5 h-5 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-400" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-400 group-hover:text-gray-600 dark:text-gray-500 dark:group-hover:text-gray-400" />
                  )}
                </div>
              </button>
              <div
                className={`overflow-hidden transition-all duration-200 ${
                  expandedCategories.has(category) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <div className="pl-4 pr-2 py-1 space-y-1">
                  {categoryTasks.map(task => (
                    <div
                      key={task.id}
                      className="flex items-center gap-2 group"
                    >
                      <button
                        onClick={(e) => onUpdateStatus && handleStatusClick(e, task.id, task.status)}
                        className="flex-shrink-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                        title="Changer le statut"
                      >
                        {getStatusIcon(task.status)}
                      </button>
                      <button
                        onClick={() => onTaskClick(task.id)}
                        className="flex-1 p-2 text-left hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
                      >
                        <span className={`text-sm text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-100 line-clamp-2 ${
                          task.status === 'completed' ? 'line-through' : ''
                        }`}>
                          {task.title}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-center p-4">
            <ListX className="w-8 h-8 text-gray-400 mb-2" />
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {searchQuery
                ? 'Aucune tâche ne correspond à votre recherche'
                : selectedStatus !== 'all'
                ? 'Aucune tâche avec ce statut'
                : 'Aucune tâche disponible'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}