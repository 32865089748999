import React from 'react';
import { Calendar, Globe2, Wallet, MapPin } from 'lucide-react';
import { TravelProfile } from '../../../types/matching';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { budgetCountries } from '../../../data/budget-countries';
import { translateTravelStyle } from '../../../utils/matching';

interface ProfilePreferencesProps {
  profile: TravelProfile;
}

export function ProfilePreferences({ profile }: ProfilePreferencesProps) {
  return (
    <div>
      <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
        Préférences de voyage
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Départ prévu
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {format(
                profile.preferences.startDate.toDate?.() || new Date(profile.preferences.startDate),
                'MMMM yyyy',
                { locale: fr }
              )}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Globe2 className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Style de voyage
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white capitalize">
              {translateTravelStyle(profile.preferences.style)}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Wallet className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Budget mensuel
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {profile.preferences.budget.min.toLocaleString('fr-FR')} - {profile.preferences.budget.max.toLocaleString('fr-FR')} €
            </span>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Durée prévue
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {Math.ceil((
                (profile.preferences.endDate.toDate?.() || new Date(profile.preferences.endDate)).getTime() -
                (profile.preferences.startDate.toDate?.() || new Date(profile.preferences.startDate)).getTime()
              ) / (1000 * 60 * 60 * 24 * 30))} mois
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <MapPin className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Destination
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              {profile.preferences.city}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Flexibilité
              </span>
            </div>
            <span className="text-sm font-medium text-gray-900 dark:text-white">
              ± {profile.preferences.flexibility} jours
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}