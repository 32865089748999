import React from 'react';
import { useTheme } from '../lib/theme-context';
import { Country } from '../types/todo';
import { LandingNav } from '../components/landing/LandingNav';
import { HeroSection } from '../components/landing/HeroSection';
import { CountriesGrid } from '../components/landing/CountriesGrid';
import { FeaturesSection } from '../components/landing/FeaturesSection';
import { BudgetSimulatorPromo } from '../components/landing/BudgetSimulatorPromo';
import { FaqSection } from '../components/landing/FaqSection';
import { PvtmatesPromo } from '../components/landing/PvtmatesPromo';
import { MessageCenterPromo } from '../components/landing/MessageCenterPromo';
import { Footer } from '../components/Footer';
import { budgetCountries } from '../data/budget-countries';

interface LandingPageProps {
  onLogin: () => void;
}

export function LandingPage({ onLogin }: LandingPageProps) {
  const { theme, toggleTheme } = useTheme();
  const countries = budgetCountries;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 overflow-x-hidden">
      <LandingNav 
        onLogin={onLogin} 
        theme={theme} 
        onToggleTheme={toggleTheme} 
      />
      <HeroSection 
        onLogin={onLogin} 
        countriesCount={countries.length} 
      />
      <FeaturesSection />
      <CountriesGrid 
        countries={countries} 
        isLoading={false} 
      />
      <PvtmatesPromo />
      <BudgetSimulatorPromo />
      <div className="bg-white dark:bg-gray-800">
        <FaqSection />
      </div>
      <MessageCenterPromo />
      <Footer />
    </div>
  );
}