import React from 'react';
import { ArrowRight, Shield } from 'lucide-react';

interface PricingCardProps {
  title: string;
  monthlyPrice: string;
  yearlyPrice?: string;
  description: string;
  subscriptionLink: string;
}

function PricingCard({ title, monthlyPrice, yearlyPrice, description, subscriptionLink }: PricingCardProps) {
  return (
    <div className="relative overflow-hidden bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 p-4 sm:p-8">
      <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-16 -translate-y-16 blur-2xl" />
      
      <div className="relative">
        <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
          {title}
        </h3>

        <div className="space-y-6">
          {/* Monthly Price */}
          <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl p-4 border border-primary-100/50 dark:border-primary-800/30">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">À partir de</p>
            <div className="flex items-baseline gap-2">
              <span className="text-3xl font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
                {monthlyPrice}
              </span>
              <span className="text-sm text-gray-600 dark:text-gray-400">/ mois</span>
            </div>
              <p className="text-xs text-gray-600 dark:text-gray-400 mt-2 truncate">
                {description}
              </p>
              <a
              href={subscriptionLink}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 w-full mt-4 px-4 py-2 bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium rounded-lg transition-colors"
            >
              <span>Souscrire maintenant</span>
              <ArrowRight className="w-4 h-4" />
            </a>
          </div>

          {/* Yearly Price */}
          {yearlyPrice && (
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
              <div className="flex items-baseline gap-2">
                <span className="text-2xl font-bold text-gray-900 dark:text-white">
                  {yearlyPrice}
                </span>
                <span className="text-sm text-gray-600 dark:text-gray-400">/ an</span>
              </div>
              <a
                href={subscriptionLink}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center gap-2 w-full mt-4 px-4 py-2 bg-white dark:bg-gray-800 text-primary-600 dark:text-primary-400 text-sm font-medium rounded-lg transition-colors border border-primary-100 dark:border-primary-800/30 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <span>Souscrire maintenant</span>
                <ArrowRight className="w-4 h-4" />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function PricingSection() {
  return (
    <div className="py-8 sm:py-20 bg-gradient-to-b from-white via-gray-50/50 to-white dark:from-gray-800 dark:via-gray-900/50 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-primary-50 dark:bg-primary-900/20 border border-primary-100/50 dark:border-primary-800/30 mb-6">
            <Shield className="w-5 h-5 text-primary-500" />
            <span className="text-sm font-medium text-primary-600 dark:text-primary-400">Tarifs Globe PVT</span>
          </div>
          <h2 className="text-xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-3 sm:mb-4">
            Une assurance adaptée à votre destination
          </h2>
          <p className="text-sm sm:text-lg text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Choisissez la formule qui correspond à votre PVT
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Canada */}
          <PricingCard
            title="Globe PVT - Canada"
            monthlyPrice="31,84 €"
            yearlyPrice="382,08 €"
            description="Prix mensuel pour le PVT Canada"
            subscriptionLink="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
          />

          {/* Autres Destinations */}
          <PricingCard
            title="Globe PVT - Autres Destinations"
            monthlyPrice="35,70 €"
            yearlyPrice="428,40 €"
            description="Prix mensuel pour les PVT hors Canada"
            subscriptionLink="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
          />

          {/* France & Belgique */}
          <PricingCard
            title="Globe PVT - France & Belgique"
            monthlyPrice="48,70 €"
            yearlyPrice="584,40 €"
            description="Prix mensuel pour les PVT France & Belgique"
            subscriptionLink="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
          />
        </div>
      </div>
    </div>
  );
}