import React from 'react';

interface MonthlyTotalProps {
  total: number;
  housingCost?: number;
}

export function MonthlyTotal({ total, housingCost }: MonthlyTotalProps) {
  return (
    <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl p-4 sm:p-6 border border-primary-100/50 dark:border-primary-800/30">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-3 sm:gap-0">
        <div className="space-y-1">
          <h4 className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
            Total des dépenses mensuelles avec logement
          </h4>
          {housingCost && (
            <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
              Dont {housingCost.toLocaleString('fr-FR')} € de loyer
            </p>
          )}
        </div>
        <div className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
          {total.toLocaleString('fr-FR')} €
        </div>
      </div>
    </div>
  );
}