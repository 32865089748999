import React from 'react';

interface ReadingProgressProps {
  color?: string;
}

export function ReadingProgress({ color = 'primary' }: ReadingProgressProps) {
  const [readingProgress, setReadingProgress] = React.useState(0);

  React.useEffect(() => {
    const calculateReadingProgress = () => {
      const element = document.documentElement;
      const totalHeight = element.scrollHeight - element.clientHeight;
      const windowScrollTop = window.scrollY || document.documentElement.scrollTop;
      
      if (totalHeight > 0) {
        const newProgress = (windowScrollTop / totalHeight) * 100;
        setReadingProgress(newProgress);
      }
    };

    window.addEventListener('scroll', calculateReadingProgress);
    calculateReadingProgress();

    return () => window.removeEventListener('scroll', calculateReadingProgress);
  }, []);

  return (
    <div className="fixed top-16 left-0 right-0 h-1 z-50">
      <div className="relative w-full h-full bg-gray-200 dark:bg-gray-700">
        <div
          className={`absolute top-0 left-0 h-full bg-gradient-to-r from-${color}-500 to-${color}-600 transition-all duration-200 ease-out`}
          style={{ width: `${readingProgress}%` }}
        />
      </div>
    </div>
  );
}