import React from 'react';
import { Info } from 'lucide-react';

interface BudgetNoticeProps {
  title?: string;
  message: string;
}

export function BudgetNotice({ title = "Estimations et moyennes", message }: BudgetNoticeProps) {
  return (
    <div className="bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/20 dark:to-amber-800/20 rounded-xl p-4 border border-amber-100/50 dark:border-amber-800/30 mb-8">
      <div className="flex gap-3">
        <div className="flex-shrink-0">
          <Info className="w-5 h-5 text-amber-600 dark:text-amber-400" />
        </div>
        <div>
          <h3 className="text-sm font-medium text-amber-800 dark:text-amber-300 mb-1">
            {title}
          </h3>
          <p className="text-sm text-amber-700 dark:text-amber-400">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}