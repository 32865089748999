import React from 'react';
import { Globe2, ChevronDown } from 'lucide-react';
import { Country } from '../../../types/todo';

interface CountrySelectorProps {
  formData: {
    country: string;
    city: string;
    housingType: string;
    pvtDuration: string;
  };
  countries: Country[];
  onFormDataChange: (data: any) => void;
  required?: boolean;
}

export function CountrySelector({ formData, countries, onFormDataChange, required = false }: CountrySelectorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const selectRef = React.useRef<HTMLDivElement>(null);

  // Fermer le menu lors d'un clic à l'extérieur
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedCountry = countries.find(c => c.code === formData.country);

  return (
    <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
          <Globe2 className="w-5 h-5 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <label className="block text-base font-medium text-gray-900 dark:text-white">
            Pays de destination {required && <span className="text-red-500">*</span>}
          </label>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            Où souhaitez-vous partir ?
          </p>
        </div>
      </div>

      <div className="relative" ref={selectRef}>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={`w-full flex items-center justify-between px-4 py-3 bg-white dark:bg-gray-800 border ${
            isOpen 
              ? 'border-primary-500 dark:border-primary-400 ring-2 ring-primary-500/20 dark:ring-primary-400/20' 
              : 'border-gray-300 dark:border-gray-600 hover:border-gray-400 dark:hover:border-gray-500'
          } rounded-xl transition-all duration-200`}
        >
          <div className="flex items-center gap-3">
            {selectedCountry ? (
              <>
                <span className="text-2xl">{selectedCountry.flag}</span>
                <span className="text-base text-gray-900 dark:text-white">
                  {selectedCountry.name}
                </span>
              </>
            ) : (
              <span className="text-base text-gray-500 dark:text-gray-400">
                Sélectionnez un pays
              </span>
            )}
          </div>
          <ChevronDown className={`w-5 h-5 text-gray-400 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`} />
        </button>

        {isOpen && (
          <div className="absolute z-50 w-full mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl shadow-lg overflow-hidden">
            <div className="max-h-60 overflow-y-auto custom-scrollbar">
              {countries.map((country) => (
                <button
                  key={country.code}
                  type="button"
                  onClick={() => {
                    onFormDataChange({ 
                      ...formData, 
                      country: country.code, 
                      city: '', 
                      housingType: '',
                      pvtDuration: country.code === 'ca' ? '24' : formData.pvtDuration
                    });
                    setIsOpen(false);
                  }}
                  className={`w-full flex items-center gap-3 px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors ${
                    formData.country === country.code 
                      ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400' 
                      : 'text-gray-900 dark:text-white'
                  }`}
                >
                  <span className="text-2xl">{country.flag}</span>
                  <span className="text-base">{country.name}</span>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}