import React from 'react';
import { Clock, ChevronDown, ChevronUp } from 'lucide-react';
import { Task, TaskStatus } from '../../types/todo';
import { TaskPriority } from '../TaskPriority';
import { TaskStatus as TaskStatusComponent } from '../TaskStatus';

interface TaskHeaderProps {
  task: Task;
  isPreview: boolean;
  isCollapsed: boolean;
  onToggleCollapse: (e: React.MouseEvent) => void;
  daysRemaining: number | null;
  onUpdateStatus: (taskId: string, status: TaskStatus) => void;
  onUpdatePriority: (taskId: string, priority: string) => void;
}

export function TaskHeader({
  task,
  isPreview,
  isCollapsed,
  onToggleCollapse,
  daysRemaining,
  onUpdateStatus,
  onUpdatePriority
}: TaskHeaderProps) {
  const handleStatusClick = () => {
    if (isPreview) return;
    const nextStatus: TaskStatus = 
      task.status === 'not-started' ? 'in-progress' :
      task.status === 'in-progress' ? 'completed' : 'not-started';
    onUpdateStatus(task.id, nextStatus);
  };

  const handlePriorityClick = () => {
    if (isPreview) return;
    const nextPriority = 
      task.priority === 'low' ? 'medium' :
      task.priority === 'medium' ? 'high' : 'low';
    onUpdatePriority(task.id, nextPriority);
  };

  const getDaysRemainingStyles = (days: number) => {
    if (days <= 7) {
      return 'bg-red-100 dark:bg-red-900/20 text-red-600 dark:text-red-400';
    } else if (days <= 30) {
      return 'bg-amber-100 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400';
    }
    return 'bg-emerald-100 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400';
  };

  return (
    <div>
      {/* Header with title and badges */}
      <div className="flex flex-col sm:flex-row sm:items-start gap-3">
        {/* Title */}
        <div className="flex-1 min-w-0 order-2 sm:order-1 flex items-start gap-2">
          <button
            onClick={onToggleCollapse}
            className="flex-shrink-0 p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors mt-1"
          >
            {isCollapsed ? (
              <ChevronDown className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            ) : (
              <ChevronUp className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            )}
          </button>
          <h3 className={`text-base sm:text-lg font-medium text-gray-900 dark:text-white ${
            task.status === 'completed' ? 'line-through' : ''
          }`}>
            {task.title}
            {task.subtasks.length > 0 && (
              <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">
                ({task.subtasks.filter(st => st.completed).length}/{task.subtasks.length})
              </span>
            )}
          </h3>
        </div>

        {/* Status badges */}
        <div className="flex flex-wrap items-center gap-2 order-1 sm:order-2">
          <button
            onClick={handleStatusClick}
            disabled={isPreview}
            className="cursor-pointer"
          >
            <TaskStatusComponent status={task.status} isPreview={isPreview} />
          </button>
          <TaskPriority
            priority={task.priority}
            isPreview={isPreview}
            onClick={handlePriorityClick}
          />
          {daysRemaining !== null && daysRemaining > 0 && (
            <div className={`flex items-center gap-1 px-2 py-1 rounded-full text-sm font-medium whitespace-nowrap ${getDaysRemainingStyles(daysRemaining)}`}>
              <Clock className="w-4 h-4" />
              <span>J-{daysRemaining}</span>
            </div>
          )}
        </div>
      </div>

      {/* Description */}
      {task.description && !isCollapsed && task.status !== 'completed' && (
        <div className="mt-4 border-t dark:border-gray-700/50 pt-4">
          <div 
            className="task-description"
            dangerouslySetInnerHTML={{ __html: task.description }}
          />
        </div>
      )}
    </div>
  );
}