import { Building, Tent, Ban } from 'lucide-react';

export const NOMAD_HOUSING_TYPES = [
  {
    id: 'hostel_city',
    name: 'Auberge en grande ville',
    cost: 180 * 4, // ~180€/semaine
    description: 'Idéal pour rencontrer d\'autres voyageurs et rester flexible',
    icon: Building
  },
  {
    id: 'hostel_town',
    name: 'Auberge en petite ville',
    cost: 110 * 4, // ~110€/semaine
    description: 'Option économique dans les zones moins touristiques',
    icon: Building
  },
  {
    id: 'mixed_hostel',
    name: 'Mix auberge + free camp',
    cost: 55 * 4, // ~130€/semaine
    description: 'Alternance entre auberges et camping gratuit',
    icon: Tent
  },
  {
    id: 'vanlife',
    name: 'Vanlife',
    cost: 2000, // Coût mensuel incluant essence, camping, entretien, etc.
    description: 'Liberté totale. Location ~80€/jour. Achat possible à ajouter à l\'étape 4.',
    icon: Ban,
    hasExtraInfo: true,
    note: "Si vous envisagez d’acheter un van, indiquez 0 ici, car vous pourrez ajouter ce coût dans le budget d’installation à l’étape 4."
  },
  {
    id: 'volunteering',
    name: 'Volontariat',
    cost: 0,
    description: 'Logement gratuit contre quelques heures de travail',
    icon: Building
  }
];