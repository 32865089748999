import React from 'react';
import { Calculator, Calendar, Wallet, Info, Users } from 'lucide-react';
import { Country } from '../../../types/todo';
import { CountrySelector } from './CountrySelector';
import { CityAndHousing } from './CityAndHousing';
import { BudgetNotice } from '../BudgetNotice';
import { FLIGHT_RANGES } from '../../../constants/visa-costs';
import { Switch } from '../../../components/ui/Switch';

interface StepOneProps {
  formData: {
    country: string;
    city: string;
    housingType: string;
    initialSavings: string;
    pvtDuration: string;
    isCouple: boolean;
  };
  countries: Country[];
  onFormDataChange: (data: any) => void;
}

export function StepOne({ formData, countries, onFormDataChange }: StepOneProps) {
  // Mettre à jour les valeurs par défaut quand le pays change
  React.useEffect(() => {
    if (formData.country) {
      const flightRange = FLIGHT_RANGES[formData.country];
      onFormDataChange({
        ...formData,
        pvtDuration: formData.country === 'ca' ? '24' : '12',
        initialSavings: formData.initialSavings || '5000',
        flightCost: flightRange ? flightRange.avg.toString() : '700'
      });
    }
  }, [formData.country]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
      <BudgetNotice 
        message="Les montants affichés sont des estimations basées sur des moyennes issues de l'API Numbeo, reconnue pour sa fiabilité. Les coûts réels peuvent varier en fonction de votre situation, de la période et de la zone géographique."
      />

      <div className="flex items-center gap-4 mb-8">
        <div className="p-3 bg-primary-50 dark:bg-primary-900/20 rounded-xl">
          <Calculator className="w-6 h-6 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            Configuration initiale
          </h3>
          <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
            Commençons par les informations de base
          </p>
        </div>
      </div>

      <div className="space-y-8">
        {/* Mode Couple Toggle */}
        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
                <Users className="w-5 h-5 text-primary-500 dark:text-primary-400" />
              </div>
              <div>
                <h4 className="text-base font-medium text-gray-900 dark:text-white">
                  Mode couple
                </h4>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Calculez votre budget pour deux personnes
                </p>
              </div>
            </div>
            <Switch
              checked={formData.isCouple}
              onCheckedChange={(checked) => onFormDataChange({ ...formData, isCouple: checked })}
            />
          </div>
        </div>

        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
              <Wallet className="w-5 h-5 text-primary-500 dark:text-primary-400" />
            </div>
            <div>
              <label className="block text-base font-medium text-gray-900 dark:text-white">
                Économies <span className="text-red-500">*</span>
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Budget disponible
              </p>
            </div>
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <span className="text-gray-500 dark:text-gray-400">€</span>
            </div>
            <input
              type="number"
              value={formData.initialSavings}
              onChange={(e) => {
                const value = Math.max(0, Number(e.target.value));
                onFormDataChange({ ...formData, initialSavings: value.toString() });
              }}
              min="0"
              placeholder="2500"
              className="w-full pl-8 pr-4 py-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
              required
            />
          </div>
        </div>

        <CountrySelector
          formData={formData}
          countries={countries}
          onFormDataChange={onFormDataChange}
          required
        />

        {formData.country && (
          <CityAndHousing
            formData={formData}
            onFormDataChange={onFormDataChange}
            onError={() => {}}
            countries={countries}
            required
          />
        )}

        <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
              <Calendar className="w-5 h-5 text-primary-500 dark:text-primary-400" />
            </div>
            <div>
              <label className="block text-base font-medium text-gray-900 dark:text-white">
                Durée du PVT <span className="text-red-500">*</span>
              </label>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                En mois
              </p>
            </div>
          </div>
          <input
            type="number"
            value={formData.pvtDuration}
            onChange={(e) => onFormDataChange({ ...formData, pvtDuration: e.target.value })}
            min="1"
            max="24"
            className="w-full px-4 py-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
          />
          {formData.country === 'ca' && (
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 flex items-center gap-1.5">
              <Info className="w-4 h-4" />
              <span>Le PVT Canada peut durer jusqu'à 24 mois pour les Français et jusqu'à 12 mois pour les Belges.</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}