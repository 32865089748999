import React from 'react';
import { Check } from 'lucide-react';

interface Step {
  id: number;
  title: string;
  description: string;
  icon: React.ElementType;
}

interface BudgetStepsProps {
  steps: Step[];
  currentStep: number;
}

export function BudgetSteps({ steps, currentStep }: BudgetStepsProps) {
  return (
    <div className="mb-12">
      {/* Mobile Steps */}
      <div className="sm:hidden">
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200 dark:border-gray-700 p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-primary-600 dark:text-primary-400">
                Étape {currentStep} sur {steps.length}
              </p>
              <h3 className="mt-2 text-lg font-semibold text-gray-900 dark:text-white">
                {steps[currentStep - 1].title}
              </h3>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                {steps[currentStep - 1].description}
              </p>
            </div>
            <div className="w-14 h-14 rounded-2xl bg-gray-50 dark:bg-gray-700 flex items-center justify-center">
              {React.createElement(steps[currentStep - 1].icon, {
                className: "w-7 h-7 text-primary-500 dark:text-primary-400"
              })}
            </div>
          </div>
          <div className="mt-6">
            <div className="bg-gray-100 dark:bg-gray-700 rounded-full h-2.5 overflow-hidden">
              <div
                className="h-full bg-primary-500 transition-all duration-500"
                style={{ width: `${(currentStep / steps.length) * 100}%` }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Steps */}
      <div className="hidden sm:block">
        <nav aria-label="Progress" className="relative">
          <ol className="flex items-center w-full">
            {steps.map((step, stepIdx) => (
              <li key={step.id} className="relative flex-1 flex flex-col items-center">
                {/* Ligne de connexion */}
                {stepIdx !== steps.length - 1 && (
                  <div 
                    className="absolute top-5 left-[calc(50%+1.5rem)] w-[calc(100%-3rem)] h-0.5"
                    aria-hidden="true"
                  >
                    <div className="h-full bg-gray-200 dark:bg-gray-700">
                      <div
                        className="h-full bg-primary-500 transition-all duration-500"
                        style={{
                          width: currentStep > step.id ? '100%' : '0%',
                        }}
                      />
                    </div>
                  </div>
                )}
                
                {/* Étape */}
                <div className="relative flex flex-col items-center group">
                  {/* Icône */}
                  <div 
                    className={`w-12 h-12 rounded-2xl flex items-center justify-center transition-all duration-300 ${
                      step.id < currentStep
                        ? 'bg-primary-500 shadow-lg shadow-primary-500/20'
                        : step.id === currentStep
                        ? 'bg-primary-500 ring-4 ring-primary-100 dark:ring-primary-900/30 shadow-lg shadow-primary-500/20'
                        : 'bg-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700'
                    }`}
                  >
                    {step.id < currentStep ? (
                      <Check className="w-6 h-6 text-white" />
                    ) : (
                      React.createElement(step.icon, {
                        className: `w-6 h-6 ${
                          step.id === currentStep
                            ? 'text-white'
                            : 'text-gray-400 dark:text-gray-500'
                        }`
                      })
                    )}
                  </div>

                  {/* Texte */}
                  <div className="mt-4 text-center">
                    <span 
                      className={`text-sm font-medium ${
                        step.id <= currentStep
                          ? 'text-primary-600 dark:text-primary-400'
                          : 'text-gray-500 dark:text-gray-400'
                      }`}
                    >
                      {step.title}
                    </span>
                    <p className={`mt-1 text-xs ${
                      step.id === currentStep
                        ? 'text-gray-600 dark:text-gray-300'
                        : 'text-gray-500 dark:text-gray-400'
                    } max-w-[12rem]`}>
                      {step.description}
                    </p>
                  </div>

                  {/* Numéro de l'étape */}
                  <div 
                    className={`absolute -top-6 text-xs font-medium ${
                      step.id <= currentStep
                        ? 'text-primary-600 dark:text-primary-400'
                        : 'text-gray-500 dark:text-gray-400'
                    }`}
                  >
                    {step.id}
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}