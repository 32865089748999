import React from 'react';
import { Building2, Users, Wallet, ShoppingCart, Train, Zap, CreditCard, Film, PiggyBank, Utensils, Smartphone, Plus, ChevronDown } from 'lucide-react';
import { BudgetFormData, HousingType } from '../../types/budget';
import { NOMAD_HOUSING_TYPES } from '../../constants/housing';

interface MobileBudgetSummaryProps {
  formData: BudgetFormData;
  housingTypes: HousingType[];
  currentStep: number;
}

export function MobileBudgetSummary({ 
  formData, 
  housingTypes, 
  currentStep 
}: MobileBudgetSummaryProps) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);
  const pillRef = React.useRef<HTMLDivElement>(null);

  // Handle touch events
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isSwipeDown = distance < -50;
    
    if (isSwipeDown && isExpanded) {
      setIsExpanded(false);
    }
  };

  const selectedHousing = React.useMemo(() => {
    if (!formData.housingType) return null;
    if (formData.isNomadMode) {
      const nomadType = NOMAD_HOUSING_TYPES.find(h => h.id === formData.housingType);
      return nomadType ? {
        ...nomadType,
        cost: Number(formData.customHousingCost || nomadType.cost)
      } : null;
    }
    return housingTypes?.find(h => h.id === formData.housingType);
  }, [formData.housingType, formData.isNomadMode, formData.customHousingCost, housingTypes]);

  const formatPrice = (amount: number) => amount.toLocaleString('fr-FR');

  const monthlyTotal = React.useMemo(() => {
    let housingCost = 0;
    if (formData.isNomadMode && formData.customHousingCost) {
      housingCost = Number(formData.customHousingCost);
    } else if (selectedHousing) {
      housingCost = selectedHousing.costEUR || selectedHousing.cost;
    }

    const expensesTotal = [
      Number(formData.groceriesCost || 0) * (formData.isCouple ? 2 : 1),
      Number(formData.restaurantsCost || 0) * (formData.isCouple ? 2 : 1),
      Number(formData.transportCost || 0) * (formData.isCouple ? 2 : 1),
      Number(formData.utilitiesCost || 0),
      Number(formData.bankCost || 0) * (formData.isCouple ? 2 : 1),
      Number(formData.streamingCost || 0),
      Number(formData.leisureCost || 0) * (formData.isCouple ? 2 : 1),
      Number(formData.epargne || 0) * (formData.isCouple ? 2 : 1),
      ...(formData.expenseFields || []).map(expense => Number(expense.amount) * (formData.isCouple ? 2 : 1) || 0)
    ].reduce((sum, expense) => sum + expense, 0);

    return expensesTotal + housingCost;
  }, [formData, selectedHousing]);

  if (currentStep === 1 && !formData.housingType) return null;

  return (
    <div className="fixed bottom-20 left-0 right-0 z-50 xl:hidden">
      {/* Floating preview pill when collapsed */}
      <div className="absolute bottom-5 left-1/2 -translate-x-1/2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={`flex items-center gap-3 px-4 py-3 bg-primary-500 hover:bg-primary-600 text-white rounded-full shadow-lg transition-all duration-300 transform ${
            isExpanded ? 'scale-90 opacity-0' : 'scale-100 opacity-100'
          }`}
        >
          <PiggyBank className="w-5 h-5" />
          <div className="flex flex-col items-start">
            <span className="font-medium whitespace-nowrap">
              {formatPrice(monthlyTotal)} €/mois
            </span>
            {formData.isCouple && (
              <span className="text-xs text-white/90 flex items-center gap-1">
                <Users className="w-3 h-3" />
                Mode couple
              </span>
            )}
          </div>
        </button>
      </div>

      {/* Full summary panel */}
      <div 
        className={`fixed inset-x-0 bottom-0 bg-white dark:bg-gray-800 transform transition-transform duration-300 ease-out shadow-[0_-8px_30px_-12px_rgba(0,0,0,0.3)] ${
          isExpanded ? 'translate-y-0' : 'translate-y-full'
        }`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Header */}
        <div className="sticky top-0 bg-gradient-to-r from-primary-500 to-accent-500 text-white p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <PiggyBank className="w-5 h-5" />
              <div>
                <div className="flex items-center gap-2">
                  <p className="text-sm font-medium text-white/90">Budget mensuel</p>
                  {formData.isCouple && (
                    <div className="flex items-center gap-1 text-xs bg-white/20 px-2 py-0.5 rounded-full">
                      <Users className="w-3 h-3" />
                      <span>×2</span>
                    </div>
                  )}
                </div>
                <p className="text-xl font-bold">{formatPrice(monthlyTotal)} €</p>
              </div>
            </div>
            <button
              onClick={() => setIsExpanded(false)}
              className="p-2 hover:bg-white/10 rounded-full transition-colors"
            >
              <ChevronDown className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-4 space-y-4 max-h-[70vh] overflow-y-auto">
          {/* Step 2: Pre-departure */}
          {currentStep >= 2 && Number(formData.flightCost) > 0 && (
            <div className="bg-gradient-to-br from-accent-50 to-accent-100 dark:from-accent-900/20 dark:to-accent-900/30 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-3">
                <Building2 className="w-5 h-5 text-accent-500" />
                <h4 className="text-base font-medium text-gray-900 dark:text-white">
                  Dépenses avant le départ
                </h4>
              </div>
              <div className="space-y-2">
                {[
                  { label: 'Billet d\'avion', value: formData.flightCost, isDouble: true },
                  { label: 'Visa PVT', value: formData.pvtCost, isDouble: true },
                  { label: 'Assurance PVT', value: Number(formData.insuranceCost) * Number(formData.pvtDuration), isDouble: true }
                ].map(item => Number(item.value) > 0 && (
                  <div key={item.label} className="flex justify-between items-center">
                    <span className="text-gray-600 dark:text-gray-400">
                      {item.label}
                      {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="text-base font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}

                {/* Dépenses personnalisées avant départ */}
                {formData.preDepartureExpenses?.map(expense => (
                  <div key={expense.id} className="flex justify-between items-center">
                    <span className="text-gray-600 dark:text-gray-400">
                      {expense.name}
                      {formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="text-base font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(expense.amount) * (formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Step 3: Monthly Expenses */}
          {currentStep >= 3 && selectedHousing && (
            <div className="bg-gradient-to-br from-emerald-50 to-emerald-100 dark:from-emerald-900/20 dark:to-emerald-900/30 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-3">
                <Building2 className="w-5 h-5 text-emerald-500" />
                <h4 className="text-base font-medium text-gray-900 dark:text-white">
                  Dépenses mensuelles
                </h4>
              </div>
              <div className="space-y-2">
                {[
                  { label: 'Courses', value: formData.groceriesCost, isDouble: true },
                  { label: 'Restaurants', value: formData.restaurantsCost, isDouble: true },
                  { label: 'Transport', value: formData.transportCost, isDouble: true },
                  { label: 'Charges', value: formData.utilitiesCost, isDouble: false },
                  { label: 'Frais bancaires', value: formData.bankCost, isDouble: true },
                  { label: 'Services de streaming', value: formData.streamingCost, isDouble: false },
                  { label: 'Loisirs', value: formData.leisureCost, isDouble: true },
                  { label: 'Épargne', value: formData.epargne, isDouble: true },
                  ...(formData.expenseFields || []).map(expense => ({
                    label: expense.name,
                    value: expense.amount,
                    isDouble: true
                  }))
                ].map(item => Number(item.value) > 0 && (
                  <div key={item.label} className="flex justify-between items-center">
                    <span className="text-gray-600 dark:text-gray-400">
                      {item.label}
                      {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="text-base font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Step 4: Installation Costs */}
          {currentStep >= 4 && (
            <div className="bg-gradient-to-br from-purple-50 to-purple-100 dark:from-purple-900/20 dark:to-purple-900/30 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-3">
                <Building2 className="w-5 h-5 text-purple-500" />
                <h4 className="text-base font-medium text-gray-900 dark:text-white">
                  Coûts d'installation
                </h4>
              </div>
              <div className="space-y-2">
                {[
                  { label: 'Caution et avance', value: formData.firstLastMonthRent, isDouble: false },
                  { label: 'Mobilier', value: formData.furnitureCost, isDouble: false },
                  { label: 'Vêtements', value: formData.clothingCost, isDouble: true },
                  { label: 'Courses initiales', value: formData.groceryCost, isDouble: false }
                ].map(item => Number(item.value) > 0 && (
                  <div key={item.label} className="flex justify-between items-center">
                    <span className="text-gray-600 dark:text-gray-400">
                      {item.label}
                      {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="text-base font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}

                {/* Dépenses personnalisées d'installation */}
                {formData.setupExpenses?.map(expense => (
                  <div key={expense.id} className="flex justify-between items-center">
                    <span className="text-gray-600 dark:text-gray-400">
                      {expense.name}
                      {formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="text-base font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(expense.amount) * (formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}