import React from 'react';
import { ThumbsUp, ThumbsDown, Send, X } from 'lucide-react';
import { useAuth } from '../lib/auth-context';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface FeedbackWidgetProps {
  onClose: () => void;
}

export function FeedbackWidget({ onClose }: FeedbackWidgetProps) {
  const { user } = useAuth();
  const [hasVoted, setHasVoted] = React.useState(false);
  const [isPositive, setIsPositive] = React.useState<boolean | null>(null);
  const [feedback, setFeedback] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    if (!user || isSubmitting || !feedback.trim()) return;

    setIsSubmitting(true);
    try {
      // Save feedback to Firebase
      await addDoc(collection(db, 'feedback'), {
        userId: user.uid,
        userEmail: user.email,
        isPositive,
        feedback: feedback.trim(),
        createdAt: serverTimestamp(),
        url: window.location.href,
        userAgent: navigator.userAgent,
        status: 'new'
      });
      
      setHasVoted(true);
      
      // Close automatically after 3 seconds
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error saving feedback:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Overlay */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="relative bg-white dark:bg-gray-800 w-full max-w-md rounded-2xl shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Donnez-nous votre avis
          </h3>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {hasVoted ? (
            <div className="flex flex-col items-center justify-center text-center py-8">
              <div className="w-16 h-16 rounded-full bg-emerald-100 dark:bg-emerald-900/30 flex items-center justify-center mb-4">
                <ThumbsUp className="w-8 h-8 text-emerald-500" />
              </div>
              <h4 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
                Merci pour votre retour !
              </h4>
              <p className="text-base text-gray-600 dark:text-gray-400">
                Votre avis nous aide à améliorer l'application.
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              {isPositive === null ? (
                <>
                  <p className="text-center text-lg text-gray-900 dark:text-white font-medium mb-8">
                    Que pensez-vous de notre interface ?
                  </p>
                  <div className="flex justify-center gap-8">
                    <button
                      onClick={() => setIsPositive(true)}
                      className="flex flex-col items-center gap-2 p-4 hover:bg-emerald-50 dark:hover:bg-emerald-900/20 rounded-xl transition-colors group"
                    >
                      <ThumbsUp className="w-12 h-12 text-gray-400 group-hover:text-emerald-500 transition-colors" />
                      <span className="text-sm text-gray-600 dark:text-gray-400 group-hover:text-emerald-600 dark:group-hover:text-emerald-400">
                        J'aime
                      </span>
                    </button>
                    <button
                      onClick={() => setIsPositive(false)}
                      className="flex flex-col items-center gap-2 p-4 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-xl transition-colors group"
                    >
                      <ThumbsDown className="w-12 h-12 text-gray-400 group-hover:text-red-500 transition-colors" />
                      <span className="text-sm text-gray-600 dark:text-gray-400 group-hover:text-red-600 dark:group-hover:text-red-400">
                        Je n'aime pas
                      </span>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center mb-6">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 dark:bg-gray-700 mb-4">
                      {isPositive ? (
                        <ThumbsUp className="w-8 h-8 text-emerald-500" />
                      ) : (
                        <ThumbsDown className="w-8 h-8 text-red-500" />
                      )}
                    </div>
                    <p className="text-lg text-gray-900 dark:text-white font-medium">
                      {isPositive 
                        ? 'Super ! Qu\'est-ce qui vous plaît le plus ?'
                        : 'Désolé... Que pourrions-nous améliorer ?'
                      }
                    </p>
                  </div>

                  <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Votre message..."
                    className="w-full h-32 px-4 py-3 text-base bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white resize-none"
                  />

                  <div className="flex justify-end gap-3">
                    <button
                      onClick={() => setIsPositive(null)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                    >
                      Retour
                    </button>
                    <button
                      onClick={handleSubmit}
                      disabled={!feedback.trim() || isSubmitting}
                      className="flex items-center gap-2 px-6 py-2 bg-primary-600 hover:bg-primary-700 disabled:bg-gray-400 text-white rounded-lg transition-colors"
                    >
                      <Send className="w-4 h-4" />
                      <span>Envoyer</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}