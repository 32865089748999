import React from 'react';
import {
  ArrowLeft,
  PiggyBank,
  Save,
  Info,
  ExternalLink,
  Link2,
  Briefcase,
  X,
  Mail
} from 'lucide-react';
import { BudgetCharts } from './BudgetCharts';
import { BudgetSummaryBox } from './BudgetSummaryBox';
import { BudgetSummary } from './BudgetSummary';
import { SaveBudgetModal } from './SaveBudgetModal';
import { sendEmail } from '../../lib/api';
import { formatBudgetForEmail } from '../../utils/budget';
import { useExpenseCalculations } from '../../hooks/useExpenseCalculations';
import { MINIMUM_WAGES } from '../../constants/minimum-wages';
import { useAuth } from '../../lib/auth-context';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { budgetCountries } from '../../data/budget-countries';
import { CityDataPage } from '../../pages/CityDataPage';


interface BudgetResultsProps {
  data: {
    initialSavings: string;
    pvtDuration: string;
    country: string;
    city: string;
    housingType: string;
    housingTypes: any[];
    groceriesCost: string;
    restaurantsCost: string;
    transportCost: string;
    phoneCost: string;
    internetCost: string;
    utilitiesCost: string;
    bankCost: string;
    streamingCost: string;
    leisureCost: string;
    epargne: string;
    expenseFields: { id: string; name: string; amount: string }[];
    flightCost: string;
    pvtCost: string;
    insuranceCost: string;
    firstLastMonthRent: string;
    furnitureCost: string;
    clothingCost: string;
    groceryCost: string;
    preDepartureExpenses?: { id: string; name: string; amount: string }[];
    setupExpenses?: { id: string; name: string; amount: string }[];
  };
  onBack: () => void;
  isPopup?: boolean;
  onClose?: () => void;
}

export function BudgetResults({ data, onBack, isPopup = false, onClose }: BudgetResultsProps) {
  const {
    monthlyExpenses,
    preDepartureCosts,
    setupCosts,
    totalBudget,
    monthlyBreakdown,
    preDepartureBreakdown,
    setupBreakdown
  } = useExpenseCalculations(data);
  const { user, userInfo } = useAuth();
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [showSaveModal, setShowSaveModal] = React.useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = React.useState(false);
  const [showCityData, setShowCityData] = React.useState(false);
  const [showEmailModal, setShowEmailModal] = React.useState(false);
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);

  // Show save modal when reaching results
  React.useEffect(() => {
    if (!isPopup && user && !isSaved) {
      const timer = setTimeout(() => {
        setShowSaveModal(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isPopup, user, isSaved]);

  // Format numbers with 2 decimal places
  const formatNumber = (num: number) =>
    num.toLocaleString('fr-FR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const [showDetails, setShowDetails] = React.useState(false);
  const [includePreDeparture, setIncludePreDeparture] = React.useState(true);
  const [targetMonths, setTargetMonths] = React.useState(3);

  const handleMonthChange = (increment: boolean) => {
    setTargetMonths((prev) => {
      const newValue = increment ? prev + 1 : prev - 1;
      return Math.min(Math.max(newValue, 1), 24);
    });
  };

  const requiredSavings = React.useMemo(() => {
    return (
      (includePreDeparture ? preDepartureCosts : 0) +
      setupCosts +
      monthlyExpenses * targetMonths
    );
  }, [
    setupCosts,
    monthlyExpenses,
    targetMonths,
    includePreDeparture,
    preDepartureCosts,
  ]);

  const shortfall = totalBudget - Number(data.initialSavings);
  const hasSufficientFunds = Number(data.initialSavings) >= requiredSavings;

  // Calculer la durée de vie estimée avec les économies
  const survivalMonths = React.useMemo(() => {
    const totalSavings = Number(data.initialSavings);
    const monthlyBurn = monthlyExpenses;
    const setupCostsTotal = setupCosts + preDepartureCosts;

    if (monthlyBurn <= 0) return Infinity;

    const remainingAfterSetup = totalSavings - setupCostsTotal;
    if (remainingAfterSetup <= 0) return 0;

    return Math.floor(remainingAfterSetup / monthlyBurn);
  }, [data.initialSavings, monthlyExpenses, setupCosts, preDepartureCosts]);

  const getSurvivalMessage = () => {
    if (survivalMonths === Infinity)
      return 'Vos économies sont suffisantes pour couvrir vos dépenses indéfiniment !';
    if (survivalMonths <= 0)
      return 'Vos économies ne couvrent pas les coûts initiaux.';
    return `Vos économies vous permettent de survivre pendant ${survivalMonths} mois.`;
  };

  const handleSave = async () => {
    if (!user) return;

    setIsSaving(true);
    try {
      const budgetDoc = {
        userId: user.uid,
        country: data.country,
        city: data.city,
        monthlyExpenses,
        initialCosts: preDepartureCosts,
        preDepartureCosts,
        totalBudget,
        data: {
          ...data,
          housingTypes: data.housingTypes || [],
        },
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      await addDoc(collection(db, 'budgets'), budgetDoc);
      
      // Send email after successful save
      if (userInfo?.user?.email) {
        await sendEmail(
          `Budget PVT ${data.city}`,
          formatBudgetForEmail({
            monthlyExpenses,
            preDepartureCosts,
            setupCosts,
            totalBudget,
            monthlyBreakdown,
            preDepartureBreakdown,
            setupBreakdown,
            data
          }),
          userInfo.user.email,
          userInfo
        );
      }
      
      setShowSaveModal(false);
      setShowSaveConfirmation(true);
      setIsSaved(true);
      
      // Fermer automatiquement après 3 secondes
      setTimeout(() => {
        setShowSaveConfirmation(false);
      }, 3000);
    } catch (error) {
      console.error('Error saving budget:', error);
      // Show error modal
      const errorModal = document.createElement('div');
      errorModal.className = 'fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50';
      errorModal.innerHTML = `
        <div class="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium max-w-md w-full p-6 transform animate-fade-in">
          <div class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="relative">
              <div class="absolute inset-0 bg-gradient-to-br from-red-500/20 to-red-600/20 blur-xl rounded-full"></div>
              <div class="relative w-16 h-16 bg-red-100 dark:bg-red-900/30 rounded-full flex items-center justify-center">
                <svg class="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          
          <div class="text-center mt-8">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Erreur
            </h3>
            <p class="text-sm text-gray-600 dark:text-gray-400">
              Une erreur est survenue lors de la sauvegarde du budget. Veuillez réessayer.
            </p>
            
            <div class="mt-6 flex justify-center">
              <button onclick="this.closest('.fixed').remove()" class="px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors">
                Fermer
              </button>
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(errorModal);
      
      // Auto-remove after 5 seconds
      setTimeout(() => {
        errorModal.remove();
      }, 5000);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSendEmail = async () => {
    if (!userInfo?.user?.email) return;
    
    setIsSendingEmail(true);
    try {
      await sendEmail(
        `Budget PVT ${data.city}`,
        formatBudgetForEmail({
          monthlyExpenses,
          preDepartureCosts,
          setupCosts,
          totalBudget,
          monthlyBreakdown,
          preDepartureBreakdown,
          setupBreakdown,
          data
        }),
        userInfo.user.email,
        userInfo
      );
      setShowEmailModal(false);
      // Show success confirmation modal
      const successModal = document.createElement('div');
      successModal.className = 'fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50';
      successModal.innerHTML = `
        <div class="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium max-w-md w-full p-6 transform animate-fade-in">
          <div class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="relative">
              <div class="absolute inset-0 bg-gradient-to-br from-emerald-500/20 to-emerald-600/20 blur-xl rounded-full"></div>
              <div class="relative w-16 h-16 bg-emerald-100 dark:bg-emerald-900/30 rounded-full flex items-center justify-center">
                <svg class="w-8 h-8 text-emerald-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M22 2L11 13M22 2L15 22L11 13M22 2L2 9L11 13" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          
          <div class="text-center mt-8">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Budget envoyé !
            </h3>
            <p class="text-sm text-gray-600 dark:text-gray-400">
              Votre budget pour ${data.city} a été envoyé à ${userInfo.user.email}
            </p>
            
            <div class="mt-6 flex justify-center">
              <button onclick="this.closest('.fixed').remove()" class="px-4 py-2 text-sm font-medium text-emerald-600 dark:text-emerald-400 hover:bg-emerald-50 dark:hover:bg-emerald-900/20 rounded-lg transition-colors">
                Fermer
              </button>
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(successModal);
      
      // Auto-remove after 3 seconds
      setTimeout(() => {
        successModal.remove();
      }, 3000);
    } catch (error) {
      console.error('Error sending email:', error);
      // Show error modal
      const errorModal = document.createElement('div');
      errorModal.className = 'fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50';
      errorModal.innerHTML = `
        <div class="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium max-w-md w-full p-6 transform animate-fade-in">
          <div class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div class="relative">
              <div class="absolute inset-0 bg-gradient-to-br from-red-500/20 to-red-600/20 blur-xl rounded-full"></div>
              <div class="relative w-16 h-16 bg-red-100 dark:bg-red-900/30 rounded-full flex items-center justify-center">
                <svg class="w-8 h-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          
          <div class="text-center mt-8">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">
              Erreur d'envoi
            </h3>
            <p class="text-sm text-gray-600 dark:text-gray-400">
              Une erreur est survenue lors de l'envoi du budget. Veuillez réessayer.
            </p>
            
            <div class="mt-6 flex justify-center">
              <button onclick="this.closest('.fixed').remove()" class="px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors">
                Fermer
              </button>
            </div>
          </div>
        </div>
      `;
      document.body.appendChild(errorModal);
      
      // Auto-remove after 5 seconds
      setTimeout(() => {
        errorModal.remove();
      }, 5000);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const countryName =
    budgetCountries.find((c) => c.code === data.country)?.name ||
    'votre destination';

  return (
    <div className="grid grid-cols-1 xl:grid-cols-5 gap-4 sm:gap-8">
      {/* Main Content */}
      <div className={`${isPopup ? 'col-span-full' : 'xl:col-span-3'} relative`}>
        <div className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
          {/* Background image with overlay */}
          <div className="absolute inset-0">
            <img
              src="https://images.unsplash.com/photo-1501786223405-6d024d7c3b8d?q=80&w=2574&auto=format&fit=crop"
              alt="Travel background"
              className="w-full h-full object-cover opacity-[0.01] dark:opacity-[0.01]"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-white/90 to-white dark:from-gray-800/90 dark:to-gray-800" />
          </div>

          <div className="relative p-6">
            {/* Logo */}
            <div className="flex justify-center mb-8">
              <img
                src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png"
                alt="PVTistes Logo"
                className="h-12 w-auto dark:hidden"
              />
              <img
                src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png"
                alt="PVTistes Logo"
                className="h-12 w-auto hidden dark:block"
              />
            </div>

            <div className="text-center mb-8 sm:mb-12">
              <div className="inline-flex items-center justify-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-primary-50 dark:bg-primary-900/20 mb-4 sm:mb-6">
                <PiggyBank className="w-5 h-5 text-primary-500" />
                <span className="text-xs sm:text-sm font-medium text-primary-600 dark:text-primary-400">
                  Résultat de la simulation
                </span>
              </div>

              <h2 className="text-xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
                Votre budget prévisionnel pour
              </h2>
              <p className="text-lg sm:text-2xl text-gray-600 dark:text-gray-300 mt-2">
                {data.city && <span>{data.city}, </span>}
                <span className="font-semibold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
                  {countryName}
                </span>
              </p>
            </div>

            <div className="space-y-8">
              {/* Work Income Notice */}
              <div className="relative overflow-hidden bg-gradient-to-br from-emerald-50 to-teal-50 dark:from-emerald-900/20 dark:to-teal-900/20 rounded-xl border border-emerald-100/50 dark:border-emerald-800/30 p-6">
                <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-emerald-100/20 to-teal-100/20 dark:from-emerald-800/10 dark:to-teal-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />

                <div className="relative flex items-start gap-4">
                  <div className="flex-shrink-0">
                    <div className="p-3 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                      <Briefcase className="w-6 h-6 text-emerald-500 dark:text-emerald-400" />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      À propos des revenus
                    </h3>
                    <p className="text-base text-gray-700 dark:text-gray-300 leading-relaxed">
                      Ce budget vous permet d'estimer vos dépenses, mais gardez
                      à l'esprit que le PVT inclut souvent des périodes de
                      travail qui généreront des revenus. Votre capacité à
                      subvenir à vos besoins dépendra aussi de votre rythme de
                      vie et des opportunités que vous saisirez sur place.
                    </p>
                  </div>
                </div>
              </div>

              <BudgetSummary
                data={{
                  totalRequired: totalBudget,
                  currentSavings: Number(data.initialSavings),
                  monthlyExpenses,
                  initialCosts: preDepartureCosts,
                  setupCosts,
                  survivalMonths,
                  pvtDuration: data.pvtDuration,
                }}
              />

             

              {/* Projections Financières */}
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-xl p-6 border border-blue-100/50 dark:border-blue-800/30">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4 flex items-center gap-2">
                  📊 Analyse Financière Mensuelle
                </h3>

                {/* Scénarios de revenus */}
                <div className="space-y-6">
                  <div>
                    <h4 className="text-base font-medium text-gray-800 dark:text-gray-200 mb-3">
                      SCÉNARIOS DE REVENUS
                    </h4>
                    <div className="space-y-3">
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <div>
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            🔹 Budget Minimal
                          </span>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            pour couvrir les bases
                          </p>
                        </div>
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                          {formatNumber(monthlyExpenses)} €/mois
                        </span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <div>
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            🔹 Budget Confortable
                          </span>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            pour vivre sereinement
                          </p>
                        </div>
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                          {formatNumber(monthlyExpenses * 1.2)} €/mois
                        </span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <div>
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            🔹 Budget Optimal
                          </span>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            pour épargner et investir
                          </p>
                        </div>
                        <span className="text-base font-semibold text-gray-900 dark:text-white">
                          {formatNumber(monthlyExpenses * 1.5)} €/mois
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Calcul du temps de travail */}
                  <div>
                    <h4 className="text-base font-medium text-gray-800 dark:text-gray-200 mb-3">
                      ⏰ CALCUL DU TEMPS DE TRAVAIL
                    </h4>
                    {/* Afficher le taux horaire minimum */}
                    <div className="bg-white/80 dark:bg-gray-800/80 rounded-lg p-3 mb-4 border border-blue-100/50 dark:border-blue-800/30">
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Calculs basés sur le taux horaire minimum de{' '}
                        <span className="font-medium text-gray-900 dark:text-white">
                          {MINIMUM_WAGES[data.country]?.rate.toLocaleString(
                            'fr-FR'
                          )}{' '}
                          {MINIMUM_WAGES[data.country]?.currency}
                        </span>
                      </p>
                    </div>

                    <div className="space-y-3">
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          Budget Minimal
                        </span>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {Math.ceil(
                            monthlyExpenses / MINIMUM_WAGES[data.country]?.rate
                          )}{' '}
                          heures/mois (
                          {Math.ceil(
                            monthlyExpenses /
                              MINIMUM_WAGES[data.country]?.rate /
                              4
                          )}{' '}
                          h/semaine)
                        </span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          Budget Confortable
                        </span>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {Math.ceil(
                            (monthlyExpenses * 1.2) /
                              MINIMUM_WAGES[data.country]?.rate
                          )}{' '}
                          heures/mois (
                          {Math.ceil(
                            (monthlyExpenses * 1.2) /
                              MINIMUM_WAGES[data.country]?.rate /
                              4
                          )}{' '}
                          h/semaine)
                        </span>
                      </div>
                      <div className="flex justify-between items-center p-3 bg-white/50 dark:bg-gray-800/50 rounded-lg">
                        <span className="text-sm text-gray-700 dark:text-gray-300">
                          Budget Optimal
                        </span>
                        <span className="text-sm font-medium text-gray-900 dark:text-white">
                          {Math.ceil(
                            (monthlyExpenses * 1.5) /
                              MINIMUM_WAGES[data.country]?.rate
                          )}{' '}
                          heures/mois (
                          {Math.ceil(
                            (monthlyExpenses * 1.5) /
                              MINIMUM_WAGES[data.country]?.rate /
                              4
                          )}{' '}
                          h/semaine)
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Note */}
                  <div className="mt-4 p-4 bg-white/80 dark:bg-gray-800/80 rounded-lg border border-blue-100/50 dark:border-blue-800/30">
                    <div className="flex items-start gap-2">
                      <Info className="w-4 h-4 text-blue-500 mt-0.5 flex-shrink-0" />
                      <p className="text-xs text-gray-600 dark:text-gray-400">
                        Ces calculs sont basés sur le salaire minimum légal. Les
                        taux réels peuvent être plus élevés selon votre
                        expérience, le secteur d'activité et la région. Ajustez
                        ces estimations en fonction de votre situation
                        spécifique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-8">
                <div className="bg-gradient-to-br from-gray-50 to-white dark:from-gray-800/50 dark:to-gray-800 rounded-2xl p-4 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="space-y-6">
                    <div>
                      <label className="block text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3">
                        Durée prévue sans emploi
                      </label>
                      <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 mb-6">
                        Simulez votre budget en fonction de la durée pendant
                        laquelle vous souhaitez vivre sans emploi. Nous
                        conseillons de prévoir au minimum 3 mois de budget pour
                        partir.
                      </p>
                      <div className="flex items-center gap-2 mb-6">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={includePreDeparture}
                            onChange={() =>
                              setIncludePreDeparture(!includePreDeparture)
                            }
                          />
                          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></div>
                        </label>
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Inclure les dépenses avant le départ (
                          {formatNumber(preDepartureCosts)} €)
                        </span>
                      </div>
                      <div className="flex items-center gap-2 sm:gap-3">
                        <button
                          type="button"
                          onClick={() => handleMonthChange(false)}
                          disabled={targetMonths <= 1}
                          className="w-12 h-12 sm:w-14 sm:h-14 flex items-center justify-center bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed rounded-xl transition-all duration-200 transform hover:scale-105 border border-gray-200 dark:border-gray-700 shadow-sm"
                        >
                          <span className="text-2xl font-medium text-gray-700 dark:text-gray-300">
                            −
                          </span>
                        </button>
                        <div className="flex-1 text-center">
                          <div className="text-4xl sm:text-5xl font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent animate-pulse">
                            {targetMonths}
                          </div>
                          <div className="text-sm sm:text-base text-gray-600 dark:text-gray-400 mt-2">
                            mois
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => handleMonthChange(true)}
                          disabled={targetMonths >= 24}
                          className="w-14 h-14 flex items-center justify-center bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed rounded-xl transition-all duration-200 transform hover:scale-105 border border-gray-200 dark:border-gray-700 shadow-sm"
                        >
                          <span className="text-2xl font-medium text-gray-700 dark:text-gray-300">
                            +
                          </span>
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between mb-4">
                        <span className="text-lg font-semibold text-gray-900 dark:text-white">
                          Budget nécessaire
                        </span>
                        <span className="text-2xl font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
                          {requiredSavings.toLocaleString('fr-FR')} €
                        </span>
                      </div>

                      {/* Statut du budget */}
                      <div
                        className={`p-4 sm:p-6 rounded-xl border ${
                          hasSufficientFunds
                            ? 'bg-gradient-to-br from-emerald-50 to-emerald-100/50 dark:from-emerald-900/20 dark:to-emerald-800/20 border-emerald-100 dark:border-emerald-800/30'
                            : 'bg-gradient-to-br from-amber-50 to-amber-100/50 dark:from-amber-900/20 dark:to-amber-800/20 border-amber-100 dark:border-amber-800/30'
                        }`}
                      >
                        <div className="flex flex-col sm:flex-row sm:items-center gap-3">
                          <div
                            className={`p-3 rounded-xl ${
                              hasSufficientFunds
                                ? 'bg-emerald-100 dark:bg-emerald-900/30 text-emerald-600 dark:text-emerald-400 shadow-lg'
                                : 'bg-amber-100 dark:bg-amber-900/30 text-amber-600 dark:text-amber-400 shadow-lg animate-pulse'
                            } w-fit`}
                          >
                            {hasSufficientFunds ? '✅' : '⚠️'}
                          </div>
                          <div>
                            <p
                              className={`text-sm sm:text-base font-medium ${
                                hasSufficientFunds
                                  ? 'text-emerald-800 dark:text-emerald-300'
                                  : 'text-amber-800 dark:text-amber-300'
                              }`}
                            >
                              {hasSufficientFunds
                                ? 'Votre budget couvre cette durée'
                                : `Il vous manque ${Math.abs(
                                    Number(data.initialSavings) -
                                      requiredSavings
                                  ).toLocaleString(
                                    'fr-FR'
                                  )} € pour couvrir cette période`}
                            </p>
                            <p
                              className={`text-xs sm:text-sm mt-2 ${
                                hasSufficientFunds
                                  ? 'text-emerald-600 dark:text-emerald-400'
                                  : 'text-amber-600 dark:text-amber-400'
                              }`}
                            >
                              {hasSufficientFunds
                                ? "Vous avez suffisamment d'économies pour cette période"
                                : 'Suggestions : continuez à épargner ou réduisez la durée prévue sans emploi pour atteindre votre objectif'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pt-4 mt-2 border-t border-gray-200 dark:border-gray-700">
                      <div className="flex items-start gap-2">
                        <Info className="w-4 h-4 flex-shrink-0 mt-0.5 text-gray-400" />
                        <div className="flex-1">
                          <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 leading-relaxed space-y-1">
                            Ce montant comprend vos coûts d'installation (
                            {formatNumber(setupCosts)} €),
                            {includePreDeparture &&
                              `vos dépenses avant le départ (${formatNumber(
                                preDepartureCosts
                              )} €), `}
                            vos dépenses mensuelles (
                            {formatNumber(monthlyExpenses)} €/mois) pour{' '}
                            {targetMonths} mois.
                          </p>
                          <button
                            onClick={() => setShowCityData(true)}
                            className="inline-flex items-center gap-2 mt-3 px-4 py-2 bg-gradient-to-r from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 hover:from-primary-100 hover:to-accent-100 dark:hover:from-primary-900/30 dark:hover:to-accent-900/30 border border-primary-100/50 dark:border-primary-800/30 rounded-lg transition-all duration-200 group"
                          >
                            <Link2 className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                            <span className="text-sm font-medium bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
                              Voir tous les prix détaillés pour {data.city}
                            </span>
                            <ExternalLink className="w-4 h-4 text-primary-400 dark:text-primary-500 transform transition-transform duration-200 group-hover:translate-x-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Graphiques */}
                <BudgetCharts
                  data={{
                    monthlyExpenses: monthlyBreakdown,
                    preDepartureCosts: preDepartureBreakdown,
                    setupCosts: setupBreakdown
                  }}
                />
              </div>

              <div className="flex flex-col sm:flex-row justify-between gap-4 mt-12 pt-6 border-t border-gray-200 dark:border-gray-700">
                <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 w-full">
                  <button
                    onClick={() => setShowEmailModal(true)}
                    className="flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl transition-colors border border-gray-200 dark:border-gray-700 w-full sm:w-auto"
                  >
                    <Mail className="w-5 h-5" />
                    <span>Envoyer par email</span>
                  </button>
                  
                  {!isPopup && user && (
                    <button
                      onClick={() => setShowSaveModal(true)}
                      disabled={isSaving || isSaved}
                      className={`flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 text-white rounded-xl transition-colors w-full sm:w-auto ${
                        isSaved
                          ? 'bg-emerald-500 cursor-not-allowed'
                          : 'bg-primary-600 hover:bg-primary-700'
                      }`}
                    >
                      <Save className="w-5 h-5" />
                      <span>
                        {isSaved ? 'Budget sauvegardé' : 'Sauvegarder le budget'}
                      </span>
                    </button>
                  )}
                  
                  {isPopup && (
                    <button
                      onClick={onClose}
                      className="flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl transition-colors border border-gray-200 dark:border-gray-700 w-full sm:w-auto"
                    >
                      <X className="w-5 h-5" />
                      <span>Fermer</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {!isPopup && (
        <div className="hidden xl:block xl:col-span-2">
          <div className="sticky top-[8.5rem] pt-8">
            <BudgetSummaryBox
              formData={data}
              housingTypes={data.housingTypes}
              currency="EUR"
              city={data.city}
              countryName={countryName}
              currentStep={5}
            />
          </div>
        </div>
      )}

      {/* Save Budget Modal */}
      <SaveBudgetModal
        isOpen={showSaveModal}
        onClose={() => setShowSaveModal(false)}
        onSave={handleSave}
        isSaving={isSaving}
        willSendEmail={true}
      />

      {/* Save Confirmation Modal */}
      {showSaveConfirmation && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="relative bg-white dark:bg-gray-800 rounded-2xl shadow-premium max-w-md w-full p-6 transform animate-fade-in">
            <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-emerald-500/20 to-emerald-600/20 blur-xl rounded-full"></div>
                <div className="relative w-16 h-16 bg-emerald-100 dark:bg-emerald-900/30 rounded-full flex items-center justify-center">
                  <Save className="w-8 h-8 text-emerald-500" />
                </div>
              </div>
            </div>
            
            <div className="text-center mt-8">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">
                Budget sauvegardé !
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Votre budget pour {data.city} a été enregistré avec succès.
              </p>
              
              <div className="mt-6 flex justify-center">
                <button
                  onClick={() => setShowSaveConfirmation(false)}
                  className="px-4 py-2 text-sm font-medium text-emerald-600 dark:text-emerald-400 hover:bg-emerald-50 dark:hover:bg-emerald-900/20 rounded-lg transition-colors"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Email Modal */}
      {showEmailModal && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-premium w-full max-w-md transform animate-fade-in">
            <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
            
            <div className="relative">
            <div className="p-4 border-b dark:border-gray-700">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                  <Mail className="w-5 h-5 text-primary-500 dark:text-primary-400" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Envoyer le budget par email
                </h3>
              </div>
            </div>
            <div className="p-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-900 dark:text-white mb-2">
                  Adresse email
                </label>
                <div className="p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg border border-gray-200/50 dark:border-gray-700/50">
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {userInfo?.user?.email || ''}
                  </span>
                </div>
              </div>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowEmailModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={handleSendEmail}
                  disabled={!userInfo?.user?.email || isSendingEmail}
                  className="flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 text-white text-sm font-medium rounded-lg transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 shadow-sm hover:shadow-md"
                >
                  {isSendingEmail ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      <span>Envoi...</span>
                    </>
                  ) : (
                    <>
                      <Mail className="w-4 h-4" />
                      <span>Envoyer</span>
                    </>
                  )}
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}

      {/* City Data Modal */}
      {showCityData && (
        <CityDataPage
          isOpen={true}
          onClose={() => setShowCityData(false)}
          modalCountryCode={data.country}
          modalCity={data.city}
        />
      )}
    </div>
  );
}
