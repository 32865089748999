import React from 'react';
import { Task } from '../types/todo';
import { Plane, Building, ListChecks, PartyPopper, Clock, MessageSquare, Users } from 'lucide-react';
import { TaskItem } from './TaskItem';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface TaskCategoryProps {
  category: string;
  tasks: Task[];
  isPreview: boolean;
  departureDate?: string;
  returnDate?: string;
  onUpdateStatus: (taskId: string, status: string) => void;
  onDeleteTask: (taskId: string) => void;
  onAddSubtask: (taskId: string) => void;
  onToggleSubtask: (taskId: string, subtaskId: string) => void;
  onUpdateNotes: (taskId: string, notes: string) => void;
  onEditTask: (task: Task) => void;
  onDeleteSubtask: (taskId: string, subtaskId: string) => void;
  onUpdatePriority: (taskId: string, priority: string) => void;
}

export function TaskCategory({
  category,
  tasks,
  isPreview,
  departureDate,
  returnDate,
  onUpdateStatus,
  onDeleteTask,
  onAddSubtask,
  onToggleSubtask,
  onUpdateNotes,
  onEditTask,
  onDeleteSubtask,
  onUpdatePriority
}: TaskCategoryProps) {
  const [messageStats, setMessageStats] = React.useState<{
    totalMessages: number;
    activeUsers: number;
    lastMessageTime: Date | null;
  }>({
    totalMessages: 0,
    activeUsers: 0,
    lastMessageTime: null
  });

  React.useEffect(() => {
    const fetchMessageStats = async () => {
      try {
        const messagesRef = collection(db, 'messages');
        const q = query(
          messagesRef,
          where('thread_code', '==', category),
          where('status', '==', 'active')
        );
        const snapshot = await getDocs(q);
        
        const uniqueUsers = new Set(snapshot.docs.map(doc => doc.data().user_id));
        
        const messages = snapshot.docs.map(doc => ({
          ...doc.data(),
          created_at: doc.data().created_at?.toDate()
        }));
        const sortedMessages = messages.sort((a, b) => 
          b.created_at?.getTime() - a.created_at?.getTime()
        );

        setMessageStats({
          totalMessages: snapshot.size,
          activeUsers: uniqueUsers.size,
          lastMessageTime: sortedMessages[0]?.created_at || null
        });
      } catch (error) {
        console.error('Error fetching message stats:', error);
      }
    };

    if (!isPreview) {
      fetchMessageStats();
    }
  }, [category, isPreview]);

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'before-departure':
        return <ListChecks className="w-6 h-6 text-blue-500" />;
      case 'after-arrival':
        return <Plane className="w-6 h-6 text-green-500" />;
      case 'return':
        return <Building className="w-6 h-6 text-purple-500" />;
      default:
        return null;
    }
  };

  const getCategoryLabel = (category: string) => {
    switch (category) {
      case 'before-departure':
        return 'Préparer votre départ';
      case 'after-arrival':
        return 'Le jour du départ';
      case 'return':
        return 'Les démarches à l\'arrivée';
      default:
        return category;
    }
  };

  const getCategoryImage = (category: string): string => {
    switch (category) {
      case 'before-departure':
        return 'https://images.unsplash.com/photo-1488646953014-85cb44e25828?q=80&w=2670&auto=format&fit=crop';
      case 'after-arrival':
        return 'https://plus.unsplash.com/premium_photo-1661501562127-a8bb26defb35?q=80&w=2670&auto=format&fit=crop';
      case 'return':
        return 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?q=80&w=2674&auto=format&fit=crop';
      default:
        return '';
    }
  };

  const getCategoryStats = (tasks: Task[]) => {
    const total = tasks.length;
    const completed = tasks.filter(t => t.status === 'completed').length;
    const inProgress = tasks.filter(t => t.status === 'in-progress').length;
    const notStarted = total - completed - inProgress;
    const completionPercentage = Math.round((completed / total) * 100);

    const subtasks = tasks.reduce((acc, task) => acc + task.subtasks.length, 0);
    const completedSubtasks = tasks.reduce(
      (acc, task) => acc + task.subtasks.filter(st => st.completed).length,
      0
    );

    return {
      total,
      completed,
      inProgress,
      notStarted,
      completionPercentage,
      subtasks,
      completedSubtasks,
      allCompleted: completed === total && total > 0
    };
  };

  const getDaysUntilDeparture = () => {
    if (!departureDate) return null;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const departure = new Date(departureDate);
    const diffTime = departure.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const stats = getCategoryStats(tasks);
  const daysUntilDeparture = getDaysUntilDeparture();
  const categoryImage = getCategoryImage(category);

  const renderTimeMessage = () => {
    if (category !== 'before-departure' || !departureDate || isPreview) return null;

    if (stats.allCompleted) {
      return (
        <div className="bg-emerald-50 dark:bg-emerald-900/20 border border-emerald-100 dark:border-emerald-800/30 rounded-lg p-4 mb-4">
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <PartyPopper className="w-6 h-6 text-emerald-500 dark:text-emerald-400 animate-bounce" />
            </div>
            <div>
              <h4 className="text-base font-medium text-emerald-800 dark:text-emerald-300">
                Félicitations ! 🎉
              </h4>
              <p className="text-sm text-emerald-600 dark:text-emerald-400">
                Vous avez terminé toutes les tâches de cette étape. Vous êtes prêt{daysUntilDeparture && daysUntilDeparture > 0 ? ` et il vous reste ${daysUntilDeparture} jours avant le départ` : ''} !
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (daysUntilDeparture && daysUntilDeparture > 0) {
      const urgencyClass = daysUntilDeparture <= 7 
        ? 'bg-red-50 dark:bg-red-900/20 border-red-100 dark:border-red-800/30 text-red-800 dark:text-red-300'
        : daysUntilDeparture <= 30
        ? 'bg-amber-50 dark:bg-amber-900/20 border-amber-100 dark:border-amber-800/30 text-amber-800 dark:text-amber-300'
        : 'bg-blue-50 dark:bg-blue-900/20 border-blue-100 dark:border-blue-800/30 text-blue-800 dark:text-blue-300';

      return (
        <div className={`border rounded-lg p-4 mb-4 ${urgencyClass}`}>
          <div className="flex items-center space-x-3">
            <div className="flex-shrink-0">
              <Clock className="w-5 h-5" />
            </div>
            <div>
              <h4 className="text-base font-medium">
                {daysUntilDeparture} jour{daysUntilDeparture > 1 ? 's' : ''} avant le départ
              </h4>
              <p className="text-sm opacity-90">
                Il vous reste {stats.total - stats.completed} tâche{stats.total - stats.completed > 1 ? 's' : ''} à finaliser dans cette étape.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div id={`category-${category}`} className="space-y-4 scroll-mt-[136px]">
      <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-100 dark:border-gray-700 overflow-hidden">
        {categoryImage && (
          <div className="relative h-32 sm:h-48 overflow-hidden">
            <img
              src={categoryImage}
              alt={getCategoryLabel(category)}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/30 to-black/60" />
            <div className="absolute inset-0 flex flex-col justify-end p-3 sm:p-6">
              <div className="flex items-center space-x-3 mb-2">
                <div className="w-8 sm:w-10 h-8 sm:h-10 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center">
                  {getCategoryIcon(category)}
                </div>
                <h2 className="text-lg sm:text-2xl font-semibold text-white">
                  {getCategoryLabel(category)}
                </h2>
              </div>
              <div className="flex items-center gap-3 text-white/90">
                <div className="flex items-center gap-2">
                  <ListChecks className="w-4 sm:w-5 h-4 sm:h-5" />
                  <span className="text-sm">{stats.completed}/{stats.total}</span>
                </div>
                <div className="font-medium text-sm">
                  {stats.completionPercentage}%
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="p-2 sm:p-6">
          {renderTimeMessage()}

          {/* Message Center Stats */}
          {!isPreview && messageStats.totalMessages > 0 && (
            <div className="mb-6 bg-gradient-to-br from-accent-50 to-primary-50 dark:from-accent-900/20 dark:to-primary-900/20 rounded-xl border border-accent-100/50 dark:border-accent-800/30 p-4">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-white/80 dark:bg-gray-800/80 rounded-lg">
                  <MessageSquare className="w-5 h-5 text-accent-500" />
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                    Centre de messages
                  </h4>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Rejoignez la discussion
                  </p>
                </div>
                <button
                  onClick={() => window.dispatchEvent(new CustomEvent('openMessageCenter'))}
                  className="ml-auto flex items-center gap-2 px-3 py-1.5 bg-white/80 dark:bg-gray-800/80 hover:bg-white dark:hover:bg-gray-800 text-sm font-medium text-accent-600 dark:text-accent-400 rounded-lg transition-colors"
                >
                  <span>Participer</span>
                  <MessageSquare className="w-4 h-4" />
                </button>
              </div>

              <div className="grid grid-cols-3 gap-3">
                <div className="bg-white/50 dark:bg-gray-800/50 rounded-lg p-3 text-center">
                  <MessageSquare className="w-4 h-4 text-accent-500 mx-auto mb-1" />
                  <div className="text-lg font-bold text-gray-900 dark:text-white">
                    {messageStats.totalMessages}
                  </div>
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    Messages
                  </div>
                </div>

                <div className="bg-white/50 dark:bg-gray-800/50 rounded-lg p-3 text-center">
                  <Users className="w-4 h-4 text-accent-500 mx-auto mb-1" />
                  <div className="text-lg font-bold text-gray-900 dark:text-white">
                    {messageStats.activeUsers}
                  </div>
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    Participants
                  </div>
                </div>

                <div className="bg-white/50 dark:bg-gray-800/50 rounded-lg p-3 text-center">
                  <Clock className="w-4 h-4 text-accent-500 mx-auto mb-1" />
                  <div className="text-xs text-gray-600 dark:text-gray-400">
                    Dernier message
                  </div>
                  <div className="text-xs font-medium text-gray-900 dark:text-white">
                    {messageStats.lastMessageTime 
                      ? new Date(messageStats.lastMessageTime).toLocaleDateString('fr-FR', {
                          hour: '2-digit',
                          minute: '2-digit'
                        })
                      : 'Aucun'}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Version mobile compacte */}
          <div className="grid grid-cols-3 gap-2 sm:hidden bg-gray-50 dark:bg-gray-800/50 rounded-lg p-2 mb-4">
            <div className="text-center">
              <div className="text-emerald-600 dark:text-emerald-400 text-xs font-medium">Terminées</div>
              <div className="text-base font-bold text-emerald-700 dark:text-emerald-300">{stats.completed}</div>
            </div>
            <div className="text-center">
              <div className="text-amber-600 dark:text-amber-400 text-xs font-medium">En cours</div>
              <div className="text-base font-bold text-amber-700 dark:text-amber-300">{stats.inProgress}</div>
            </div>
            <div className="text-center">
              <div className="text-red-600 dark:text-red-400 text-xs font-medium">À faire</div>
              <div className="text-base font-bold text-red-700 dark:text-red-300">{stats.notStarted}</div>
            </div>
          </div>

          {/* Version desktop */}
          <div className="hidden sm:grid sm:grid-cols-3 gap-4 mb-6">
            <div className="bg-emerald-50 dark:bg-emerald-900/20 rounded-lg p-4">
              <div className="text-emerald-600 dark:text-emerald-400 text-base font-medium mb-1">Terminées</div>
              <div className="text-3xl font-bold text-emerald-700 dark:text-emerald-300">{stats.completed}</div>
              <div className="text-base text-emerald-600 dark:text-emerald-400">tâches</div>
            </div>
            <div className="bg-amber-50 dark:bg-amber-900/20 rounded-lg p-4">
              <div className="text-amber-600 dark:text-amber-400 text-base font-medium mb-1">En cours</div>
              <div className="text-3xl font-bold text-amber-700 dark:text-amber-300">{stats.inProgress}</div>
              <div className="text-base text-amber-600 dark:text-amber-400">tâches</div>
            </div>
            <div className="bg-red-50 dark:bg-red-900/20 rounded-lg p-4">
              <div className="text-red-600 dark:text-red-400 text-base font-medium mb-1">À commencer</div>
              <div className="text-3xl font-bold text-red-700 dark:text-red-300">{stats.notStarted}</div>
              <div className="text-base text-red-600 dark:text-red-400">tâches</div>
            </div>
          </div>

          <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden mb-4">
            <div
              className="h-full bg-emerald-500 transition-all duration-500"
              style={{ width: `${stats.completionPercentage}%` }}
            />
          </div>

          {stats.subtasks > 0 && (
            <div className="text-sm text-gray-600 dark:text-gray-400">
              Mémo(s) : {stats.completedSubtasks}/{stats.subtasks} terminées
            </div>
          )}
        </div>
      </div>

      <div className="space-y-2 sm:space-y-4">
        {tasks.map(task => (
          <TaskItem
            key={task.id}
            task={task}
            isPreview={isPreview}
            departureDate={departureDate}
            returnDate={returnDate}
            onUpdateStatus={onUpdateStatus}
            onEditTask={onEditTask}
            onDeleteTask={onDeleteTask}
            onAddSubtask={onAddSubtask}
            onToggleSubtask={onToggleSubtask}
            onUpdateNotes={onUpdateNotes}
            onDeleteSubtask={onDeleteSubtask}
            onUpdatePriority={onUpdatePriority}
          />
        ))}
      </div>
    </div>
  );
}