import React from 'react';
import { Info } from 'lucide-react';

interface RestaurantsBreakdownProps {
  priceItems: any[];
  currency: string;
  eurPrices?: any[];
}

export function RestaurantsBreakdown({ priceItems, currency, eurPrices }: RestaurantsBreakdownProps) {
  const RESTAURANT_ITEMS = [
    { id: 1, name: 'Repas dans un restaurant bon marché' },
    { id: 2, name: 'Repas pour 2 dans un restaurant moyen' },
    { id: 3, name: 'Menu McDonalds' }
  ];

  const getItemPrice = (itemId: number) => {
    const item = priceItems.find(item => item.item_id === itemId);
    const eurItem = eurPrices?.find(item => item.item_id === itemId);
    
    if (!item) return null;

    return {
      price: item.average_price,
      eurPrice: eurItem?.average_price
    };
  };

  return (
    <div className="mt-4 bg-gray-50 dark:bg-gray-700/30 rounded-xl p-4 sm:p-6">
      <div className="flex items-start sm:items-center gap-2 mb-4">
        <Info className="hidden sm:block w-4 h-4 text-gray-500 dark:text-gray-400" />
        <p className="text-sm text-gray-600 dark:text-gray-400">
          Prix moyens constatés pour vous aider à estimer votre budget
        </p>
      </div>

      <div className="space-y-2">
        {RESTAURANT_ITEMS.map(item => {
          const prices = getItemPrice(item.id);
          if (!prices) return null;

          return (
            <div key={item.id} className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50 last:border-0">
              <span className="text-sm text-gray-600 dark:text-gray-400 mb-1 sm:mb-0">
                {item.name}
              </span>
              <div className="text-right font-mono text-base sm:text-sm font-medium text-primary-600 dark:text-primary-400">
                {(prices.eurPrice || prices.price).toFixed(2)} €
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}