import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Calendar, MapPin, GraduationCap, Briefcase } from 'lucide-react';
import { scrollToTop } from '../../utils/scroll';
import { InterviewPreview } from '../../types/interviews';

interface InterviewCardProps {
  interview: InterviewPreview;
}

export function InterviewCard({ interview }: InterviewCardProps) {
  const handleClick = () => {
    // Scroll to top before navigating
    scrollToTop();
  };

  return (
    <Link
      to={`/interviews/${interview.id}`}
      onClick={handleClick}
      className="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md border border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-200 transform hover:-translate-y-1"
    >
      <div className="relative aspect-[16/9] overflow-hidden">
        <img
          src={interview.cover}
          alt={interview.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            if (!target.src.includes('fallback')) {
              target.src = 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2670&auto=format&fit=crop';
            }
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        
        <div className="absolute bottom-3 left-3 right-3">
          <h2 className="text-sm sm:text-base font-semibold text-white line-clamp-2 leading-snug sm:leading-snug">
            {interview.title}
          </h2>
        </div>
      </div>

      <div className="p-4">
        <div className="flex items-center gap-4 mb-4">
          <img
            src={interview.interviewee.avatar || `https://ui-avatars.com/api/?name=${interview.interviewee.firstname}&background=random`}
            alt={interview.interviewee.firstname}
            className="w-12 h-12 rounded-full"
          />
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
              {interview.interviewee.firstname} {interview.interviewee.lastname}
            </h3>
            <div className="flex flex-wrap items-center gap-x-3 gap-y-1 mt-1">
              {interview.interviewee.location && (
                <div className="flex items-center gap-1">
                  <MapPin className="w-3.5 h-3.5 text-gray-400" />
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {interview.interviewee.location}
                  </span>
                </div>
              )}
              {interview.interviewee.occupation && (
                <div className="flex items-center gap-1">
                  <Briefcase className="w-3.5 h-3.5 text-gray-400" />
                  <span className="text-xs text-gray-500 dark:text-gray-400">
                    {interview.interviewee.occupation}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <p className="text-sm text-gray-600 dark:text-gray-400 line-clamp-3 mb-4">
          {interview.shortDescription}
        </p>

        <div className="flex items-center justify-between">
          <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
            <Calendar className="w-3.5 h-3.5 mr-1.5" />
            <span>
              {format(parseISO(interview.created), 'dd MMM yyyy', { locale: fr })}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <img
              src={interview.author.avatar || `https://ui-avatars.com/api/?name=${interview.author.firstname}&background=random`}
              alt={interview.author.firstname}
              className="w-5 h-5 rounded-full"
            />
            <span className="text-xs font-medium text-gray-600 dark:text-gray-400">
              {interview.author.firstname}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}