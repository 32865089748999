import { db } from './firebase';
import { collection, doc, setDoc, getDocs, updateDoc, deleteDoc, query, where, writeBatch, orderBy, limit, Timestamp, getDoc } from 'firebase/firestore';
import { Task, TodoList, Country, Resource } from '../types/todo';
import { sendEmail } from './api';
import { formatChecklistForEmail } from '../utils/checklist';

// Créer une nouvelle liste
export const createUserList = async (userId: string, countryCode: string, tasks: Task[], userInfo?: any) => {
  const listRef = doc(collection(db, 'lists'));
  
  // Get country data for email
  const countriesRef = collection(db, 'countries');
  const countryQuery = query(countriesRef, where('code', '==', countryCode));
  const countrySnapshot = await getDocs(countryQuery);
  const country = countrySnapshot.docs[0].data() as Country;
  
  const todoList: TodoList = {
    id: listRef.id,
    userId,
    countryCode,
    tasks,
    createdAt: new Date(),
    updatedAt: new Date()
  };
  
  await setDoc(listRef, todoList);
  
  // Send confirmation email
  try {
    const userEmail = userInfo?.user?.email;
    console.log('Attempting to send checklist email:', {
      hasUserInfo: !!userInfo,
      userEmail,
      countryName: country.name
    });
    
    if (userEmail) {
      await sendEmail(
        `Checklist PVT ${country.name}`,
        formatChecklistForEmail({
          tasks,
          country,
          departureDate: todoList.departureDate,
          returnDate: todoList.returnDate
        }),
        userEmail,
        userInfo
      );
      console.log('Checklist email sent successfully');
    }
  } catch (error) {
    console.error('Error sending checklist email:', {
      error,
      userInfo,
      countryCode
    });
    // Don't throw error to avoid blocking list creation
  }
  
  return todoList;
};

// Récupérer les listes d'un utilisateur
export const getUserLists = async (userId: string) => {
  const listsRef = collection(db, 'lists');
  const q = query(listsRef, where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate(),
    updatedAt: doc.data().updatedAt.toDate()
  })) as TodoList[];
};

// Mettre à jour une liste
export const updateUserList = async (listId: string, tasks: Task[], departureDate?: string, returnDate?: string) => {
  const listRef = doc(db, 'lists', listId);
  await updateDoc(listRef, {
    tasks,
    ...(departureDate !== undefined && { departureDate }),
    ...(returnDate !== undefined && { returnDate }),
    updatedAt: new Date()
  });
};

// Supprimer une liste
export const deleteUserList = async (listId: string) => {
  const listRef = doc(db, 'lists', listId);
  await deleteDoc(listRef);
};

// Récupérer les pays depuis Firebase
export const getCountriesFromFirebase = async (): Promise<Country[]> => {
  const countriesRef = collection(db, 'countries');
  const querySnapshot = await getDocs(countriesRef);
  
  return querySnapshot.docs.map(doc => ({
    ...doc.data(),
    code: doc.id
  })) as Country[];
};

// Delete a saved budget
export const deleteBudget = async (budgetId: string) => {
  const budgetRef = doc(db, 'budgets', budgetId);
  await deleteDoc(budgetRef);
};

// Travel Profile Functions
export const createTravelProfile = async (profile: Omit<TravelProfile, 'id' | 'createdAt' | 'updatedAt' | 'lastActive'>) => {
  try {
    const profileRef = doc(collection(db, 'travel_profiles'));
    const now = new Date();
    
    // Convertir les dates en Timestamp Firestore
    const firestoreData = {
      ...profile,
      preferences: {
        ...profile.preferences,
        startDate: Timestamp.fromDate(profile.preferences.startDate),
        endDate: Timestamp.fromDate(profile.preferences.endDate)
      },
      id: profileRef.id,
      createdAt: Timestamp.fromDate(now),
      updatedAt: Timestamp.fromDate(now),
      lastActive: Timestamp.fromDate(now),
      isActive: true
    };
    
    await setDoc(profileRef, firestoreData);
    
    return profileRef.id; // Retourner l'ID pour pouvoir récupérer le profil ensuite
  } catch (error) {
    console.error('Error creating travel profile:', error);
    throw error;
  }
};