import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Home, Calculator, Book, Newspaper, Users, Settings, LogOut, Building2, Shield, Camera, UserX, X } from 'lucide-react';
import { useAuth } from '../../lib/auth-context';

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
  onAdminClick?: () => void;
  isAdmin?: boolean;
}

export function Menu({ isOpen, onClose, onAdminClick, isAdmin }: MenuProps) {
  const { user, userInfo, logout } = useAuth();
  const menuRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onClose();
    if (path !== '#') {
      setTimeout(() => {
        navigate(path);
      }, 300);
    }
  };

  // Handle click outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Only show items not present in the mobile nav
  const menuItems = [
    { name: 'Actualités', icon: Newspaper, path: '/content' },
    { name: 'Récits', icon: Users, path: '/interviews' },
    { name: 'Assurance PVT', icon: Shield, path: '/assurance' },
    { name: 'Ambassades et consulats', icon: Building2, path: '/ambassades' },
    { name: 'Déconnexion', icon: LogOut, path: '#', onClick: () => logout() },
  ];

  if (isAdmin) {
    menuItems.push({ name: 'Administration', icon: Settings, path: '#', onClick: onAdminClick });
  }

  const menuVariants = {
    hidden: { opacity: 0, x: '100%' },
    visible: { opacity: 1, x: 0 },
  };

  const displayName = userInfo?.user?.username || user?.displayName || '';

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      {/* Backdrop */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute inset-0 bg-black/30 dark:bg-black/50 backdrop-blur-sm cursor-pointer"
        onClick={() => {
          onClose(); 
        }}
      />

      {/* Menu Panel */}
      <motion.div
        ref={menuRef}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={menuVariants}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="absolute right-0 top-0 bottom-16 sm:bottom-0 w-[90vw] max-w-md bg-white dark:bg-gray-800 shadow-xl overflow-hidden"
      >
        {/* Header */}
        <div className="p-4 bg-gradient-to-r from-gray-50 to-white dark:from-gray-800 dark:to-gray-800/95 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <img 
              src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png" 
              alt="PVTistes Logo" 
              className="h-7 w-auto dark:hidden"
            />
            <img 
              src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png" 
              alt="PVTistes Logo" 
              className="h-7 w-auto hidden dark:block"
            />
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-all duration-200 active:scale-95"
              aria-label="Fermer le menu"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-col h-[calc(100%-60px)] pb-4 sm:pb-0">
          {/* User Info */}
          {user && (
            <div className="flex items-center space-x-3 p-3 m-4 rounded-xl bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 border border-primary-100/50 dark:border-primary-800/30">
              <div className="w-7 h-7 rounded-full bg-gradient-to-br from-primary-500 to-accent-500 flex items-center justify-center text-white text-xs font-medium">
                {displayName?.[0]?.toUpperCase()}
              </div>
              <span className="text-sm text-gray-600 dark:text-gray-300 truncate">
                {displayName}
              </span>
            </div>
          )}

          {/* Navigation Links */}
          <div className="flex-1 p-4 space-y-2 overflow-y-auto custom-scrollbar">
            {menuItems.map((item) => (
              <React.Fragment key={item.name}>
                {item.onClick ? (
                  <button
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      item.onClick?.();
                      onClose();
                    }}
                    className="flex items-center gap-3 w-full px-4 py-4 text-left text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-xl transition-all duration-200"
                  >
                    <item.icon className="w-5 h-5" />
                    <span className="text-sm font-medium">{item.name}</span>
                  </button>
                ) : (
                  <Link
                    to={item.path}
                    onClick={() => {
                      handleNavigation(item.path);
                    }}
                    className="flex items-center gap-3 px-4 py-4 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50 rounded-xl transition-all duration-200"
                  >
                    <item.icon className="w-5 h-5" />
                    <span className="text-sm font-medium">{item.name}</span>
                  </Link>
                )}
              </React.Fragment>
            ))}

            {/* Delete Account Button */}
            <button
              onClick={() => {
                onClose();
                navigate('/delete-account');
              }}
              className="flex items-center gap-3 px-4 py-3 mt-4 text-left text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 rounded-lg transition-colors text-xs"
            >
              <UserX className="w-4 h-4" />
              <span className="font-medium">Supprimer mon compte</span>
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}