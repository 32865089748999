import React from 'react';
import { Building2, Users, User, Building, Check, Info, ChevronDown, Tent } from 'lucide-react';
import { getCityLivingCosts } from '../../../lib/numbeo-service';
import { MAJOR_CITIES } from '../../../data/budget-countries';
import { Alert } from '../../Alert';
import { Country } from '../../../types/todo';
import { LoadingOverlay } from '../LoadingOverlay';
import { Switch } from '../../../components/ui/Switch';
import { NOMAD_HOUSING_TYPES } from '../../../constants/housing';

interface CityAndHousingProps {
  formData: {
    country: string;
    city: string;
    housingType: string;
    isNomadMode: boolean;
    customHousingCost?: string;
  };
  onFormDataChange: (data: any) => void;
  onError: (error: string | null) => void;
  countries: Country[];
  required?: boolean;
}

export function CityAndHousing({ formData, onFormDataChange, onError, countries, required = false }: CityAndHousingProps) {
  const [housingTypes, setHousingTypes] = React.useState<{id: string; name: string; cost: number; costEUR?: number}[]>([]);
  const [isLoadingHousing, setIsLoadingHousing] = React.useState(false);
  const [costData, setCostData] = React.useState<any>(null);
  const [validationError, setValidationError] = React.useState<string | null>(null);
  const loadingTimerRef = React.useRef<NodeJS.Timeout>();
  const [showNotePopup, setShowNotePopup] = React.useState<string | null>(null);

  const handleNomadModeToggle = () => {
    onFormDataChange({
      ...formData,
      isNomadMode: !formData.isNomadMode,
      housingType: '', // Reset housing selection
      customHousingCost: undefined // Reset custom cost
    });
  };

  const handleHousingTypeSelect = (typeId: string) => {
    const selectedType = formData.isNomadMode 
      ? NOMAD_HOUSING_TYPES.find(t => t.id === typeId)
      : housingTypes.find(t => t.id === typeId);

    onFormDataChange({
      ...formData,
      housingType: typeId,
      customHousingCost: selectedType?.cost.toString()
    });
  };

  const handleCustomCostChange = (value: string) => {
    onFormDataChange({
      ...formData,
      customHousingCost: value
    });
  };

  // Update costData fetching to include country code and loading delay
  React.useEffect(() => {
    const fetchData = async () => {
      if (!formData.city || !formData.country) return;

      setIsLoadingHousing(true);
      onError(null);
      setValidationError(null);
      
      try {
        const costData = await getCityLivingCosts(formData.city, formData.country);
        if (!costData) {
          onError('Impossible de récupérer les données pour cette ville. Veuillez réessayer plus tard.');
          return;
        }

        await new Promise(resolve => {
          loadingTimerRef.current = setTimeout(resolve, 3000);
        });
        
        setCostData(costData);
        if (costData?.housingTypes) {
          setHousingTypes(costData.housingTypes);
          onFormDataChange({
            ...formData,
            housingTypes: costData.housingTypes
          });
          if (formData.housingType) {
            const selectedHousing = costData.housingTypes.find(h => h.id === formData.housingType);
            if (selectedHousing) {
              onFormDataChange({
                ...formData,
                firstLastMonthRent: ((selectedHousing.costEUR || selectedHousing.cost) * 3).toString()
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        onError('Une erreur est survenue lors de la récupération des données.');
      } finally {
        setIsLoadingHousing(false);
      }
    };

    if (formData.city) {
      fetchData();
    } else {
      setCostData(null);
      setHousingTypes([]);
    }

    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, [formData.city, formData.country]);

  // Validation effect
  React.useEffect(() => {
    if (required && formData.city && !formData.housingType) {
      const availableTypes = formData.isNomadMode ? NOMAD_HOUSING_TYPES : housingTypes;
      if (availableTypes.length > 0) {
        setValidationError('Veuillez sélectionner un type de logement');
      }
    } else {
      setValidationError(null);
    }
  }, [required, formData.city, formData.housingType, formData.isNomadMode, housingTypes.length]);

  return (
    <div className="relative bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
      {/* Loading Overlay */}
      {isLoadingHousing && <LoadingOverlay city={formData.city} countryName={countries.find(c => c.code === formData.country)?.name} />}

      {/* Nomad Mode Toggle */}
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6 pb-6 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
            <Tent className="w-5 h-5 text-primary-500 dark:text-primary-400" />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-base font-medium text-gray-900 dark:text-white">Mode Nomade</h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2 sm:line-clamp-none">
              Activez ce mode pour des options de logement adaptées aux voyageurs nomades
            </p>
          </div>
        </div>
        <Switch
          checked={formData.isNomadMode}
          onCheckedChange={handleNomadModeToggle}
        />
      </div>

      {/* City Selection */}
      <div className="space-y-4">
        <div className="relative">
          <select
            value={formData.city}
            onChange={(e) => onFormDataChange({ ...formData, city: e.target.value, housingType: '' })}
            className={`w-full px-4 py-3 bg-white dark:bg-gray-800 border appearance-none ${
              formData.city
                ? 'border-primary-500 dark:border-primary-400 ring-2 ring-primary-500/20 dark:ring-primary-400/20'
                : 'border-gray-300 dark:border-gray-600 hover:border-gray-400 dark:hover:border-gray-500'
            } rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base pr-10`}
            required={required}
            disabled={isLoadingHousing}
          >
            <option value="" className="text-gray-500">Sélectionnez une ville</option>
            {MAJOR_CITIES[formData.country]?.map((city) => (
              <option key={city} value={city} className="text-gray-900 dark:text-white">
                {city}
              </option>
            ))}
          </select>
          <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400 pointer-events-none" />
        </div>

        {/* Housing Types Section */}
        {formData.city && (
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Type de logement souhaité {required && <span className="text-red-500">*</span>}
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {(formData.isNomadMode ? NOMAD_HOUSING_TYPES : housingTypes).map((type) => {
                const isSelected = formData.housingType === type.id;
                const Icon = type.icon || (type.id.includes('shared') ? Users : type.id.includes('studio') ? User : Building);
                
                return (
                  <div
                    key={type.id}
                    onClick={() => !isLoadingHousing && handleHousingTypeSelect(type.id)}
                    className={`group relative overflow-hidden p-4 sm:p-6 rounded-2xl border-2 transition-all duration-300 cursor-pointer hover:shadow-lg ${
                      isSelected
                        ? 'border-primary-500 dark:border-primary-400 bg-gradient-to-br from-primary-50/50 to-primary-50 dark:from-primary-900/20 dark:to-primary-900/10'
                        : 'border-gray-200 dark:border-gray-700 hover:border-primary-200 dark:hover:border-primary-700 bg-white dark:bg-gray-800'
                    }`}
                  >
                    <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-16 -translate-y-16 blur-2xl group-hover:scale-110 transition-transform duration-500" />

                    <div className="relative">
                      <div className={`p-2 sm:p-3 rounded-xl mb-3 sm:mb-4 ${
                        isSelected
                          ? 'bg-primary-100 dark:bg-primary-900/50 text-primary-600 dark:text-primary-400'
                          : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-400'
                      }`}>
                        <Icon className="w-5 h-5 sm:w-6 sm:h-6" />
                      </div>

                      <div className="space-y-4">
                        <div>
                          <h3 className={`text-base font-semibold mb-1 ${
                            isSelected
                              ? 'text-primary-900 dark:text-primary-100'
                              : 'text-gray-900 dark:text-white'
                          }`}>
                            {type.name}
                          </h3>
                          <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400 min-h-[40px] line-clamp-2 sm:line-clamp-none">
                            {formData.isNomadMode ? type.description : getHousingDescription(type.id)}
                          </p>
                        </div>

                        <div className="pt-4 border-t border-gray-100 dark:border-gray-700">
                          {isSelected && formData.isNomadMode ? (
                            <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                              <span className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">Prix mensuel :</span>
                              <input
                                type="number"
                                value={formData.customHousingCost || ''}
                                onChange={(e) => handleCustomCostChange(e.target.value)}
                                className="w-full sm:w-24 px-2 py-1 text-right border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white text-sm"
                                min="0"
                                step="1"
                              />
                              <span className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">€</span>
                            </div>
                          ) : (
                            <div className="flex items-baseline gap-2">
                              <span className={`text-base sm:text-lg font-bold ${
                                isSelected
                                  ? 'text-primary-600 dark:text-primary-400'
                                  : 'text-gray-900 dark:text-white'
                              }`}>
                                {type.costEUR?.toLocaleString('fr-FR') || type.cost.toLocaleString('fr-FR')} €
                              </span>
                              <span className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                                /mois
                              </span>
                            </div>
                          )}
                        </div>
                        {type.note && isSelected && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setShowNotePopup(type.id);
                            }}
                            className="mt-3 px-3 py-1.5 text-xs font-medium text-amber-600 dark:text-amber-400 bg-amber-50 dark:bg-amber-900/20 rounded-lg hover:bg-amber-100 dark:hover:bg-amber-900/30 transition-colors"
                            type="button"
                          >
                            Voir la note importante
                          </button>
                        )}
                      </div>

                      {isSelected && (
                        <div className="absolute top-4 right-4">
                          <div className="w-6 h-6 rounded-full bg-primary-500 dark:bg-primary-400 flex items-center justify-center">
                            <Check className="w-4 h-4 text-white" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {validationError && (
          <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 rounded-xl border border-red-100 dark:border-red-800/30">
            <p className="text-sm text-red-600 dark:text-red-400">
              {validationError}
            </p>
          </div>
        )}

      </div>
      
      {/* Note Popup */}
      {showNotePopup && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Note importante
              </h3>
            </div>
            <div className="p-4">
              <div className="bg-amber-50 dark:bg-amber-900/20 rounded-lg p-4 border border-amber-100/50 dark:border-amber-800/30">
                <div className="flex items-start gap-3">
                  <Info className="w-5 h-5 text-amber-500 flex-shrink-0 mt-0.5" />
                  <p className="text-sm text-amber-700 dark:text-amber-400">
                    {NOMAD_HOUSING_TYPES.find(t => t.id === showNotePopup)?.note}
                  </p>
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowNotePopup(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Helper function to get housing description
const getHousingDescription = (typeId: string): string => {
  if (typeId.includes('shared')) {
    return 'Idéal pour la vie en communauté et le partage des coûts';
  } else if (typeId.includes('studio')) {
    return 'Parfait pour une personne seule cherchant son indépendance';
  } else if (typeId.includes('one_bed')) {
    return 'Confortable pour une personne ou un couple';
  } else if (typeId.includes('two_bed')) {
    return 'Spacieux pour une colocation ou une famille';
  } else {
    return 'Grand espace avec plusieurs chambres';
  }
};