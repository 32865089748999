import React from 'react';
import { ArrowLeft, ArrowRight, MessageSquare, Users } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Footer } from '../components/Footer';

export function AboutPage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Back button */}
      <div className="fixed top-4 left-4 z-50">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 px-3 py-2 sm:px-4 sm:py-2 bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md border border-gray-200 dark:border-gray-700 transition-all duration-200"
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 text-gray-600 dark:text-gray-400" />
          <span className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300">Retour</span>
        </button>
      </div>

      {/* Hero Section */}
      <div className="relative py-16 sm:py-24 overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1539635278303-d4002c07eae3?q=80&w=2670&auto=format&fit=crop"
            alt="Young travelers"
            className="w-full h-full object-cover opacity-60 dark:opacity-30"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-50/70 via-gray-50/80 to-gray-50 dark:from-gray-900/70 dark:via-gray-900/80 dark:to-gray-900" />
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          {/* Logo */}
          <img 
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png" 
            alt="PVTistes Logo" 
            className="h-14 sm:h-20 w-auto mx-auto mb-6 sm:mb-8 dark:hidden"
          />
          <img 
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png" 
            alt="PVTistes Logo" 
            className="h-14 sm:h-20 w-auto mx-auto mb-6 sm:mb-8 hidden dark:block"
          />

          <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-6">
            Organisez votre PVT en toute simplicité
          </h1>
          <p className="text-lg sm:text-xl text-gray-600 dark:text-gray-300 mb-6 sm:mb-8">
            La checklist interactive qui vous accompagne à chaque étape de votre PVT !
          </p>
          <p className="max-w-3xl mx-auto text-base sm:text-lg text-gray-600 dark:text-gray-400 leading-relaxed mb-8 sm:mb-12">
            Ne laissez rien au hasard pour votre aventure à l'étranger ! Avec app.pvtistes.net, accédez à une checklist interactive ultra-complète qui vous guide avant, pendant et après votre PVT.
          </p>

          <Link
            to="/"
            className="inline-flex items-center gap-2 px-6 py-3 sm:px-8 sm:py-4 bg-primary-600 hover:bg-primary-700 text-white text-base sm:text-lg font-medium rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
          >
            <span>Commencer maintenant</span>
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5" />
          </Link>
        </div>
      </div>

      {/* Message Center Promo */}
      <div className="py-12 sm:py-20 bg-gradient-to-b from-white to-gray-50 dark:from-gray-800 dark:to-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
            <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-primary-100/20 via-accent-100/20 to-primary-100/20 dark:from-primary-800/10 dark:via-accent-800/10 dark:to-primary-800/10 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl pointer-events-none" />
            
            <div className="grid lg:grid-cols-2 gap-8 lg:gap-0">
              {/* Content Section */}
              <div className="relative p-8 sm:p-12">
                <div className="flex items-center gap-2 text-primary-600 dark:text-primary-400 mb-6">
                  <MessageSquare className="w-5 h-5" />
                  <span className="text-sm font-medium">Centre de messages</span>
                </div>
                
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
                  Un espace d'échange 100% pvtistes
                </h2>
                
                <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
                  Posez vos questions, partagez votre expérience et trouvez des réponses utiles grâce à notre communauté grandissante de voyageurs.
                </p>

                <div className="grid sm:grid-cols-2 gap-6 mb-8">
                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-xl bg-primary-100 dark:bg-primary-900/30 flex items-center justify-center">
                      <Users className="w-5 h-5 text-primary-600 dark:text-primary-400" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white mb-1">
                        Entraide communautaire
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Bénéficiez de l'expérience des autres pvtistes
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-xl bg-accent-100 dark:bg-accent-900/30 flex items-center justify-center">
                      <MessageSquare className="w-5 h-5 text-accent-600 dark:text-accent-400" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white mb-1">
                        Discussions par pays
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Échangez avec des voyageurs de votre destination
                      </p>
                    </div>
                  </div>
                </div>

                <Link
                  to="/"
                  className="inline-flex items-center gap-2 px-6 py-3 bg-primary-600 hover:bg-primary-700 text-white text-base font-medium rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
                >
                  <span>S'inscrire</span>
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </div>

              {/* Image Section */}
              <div className="relative lg:h-full">
                <img
                  src="https://images.unsplash.com/photo-1444210971048-6130cf0c46cf?q=80&w=2673&auto=format&fit=crop"
                  alt="Groupe de voyageurs"
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-black/60 via-black/30 to-transparent lg:from-transparent" />
                
                <div className="absolute inset-0 flex items-center justify-center p-8">
                  <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-2xl p-6 text-center">
                    <div className="text-3xl font-bold text-white mb-2">500k+</div>
                    <div className="text-white/90">membres actifs</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 sm:py-20 bg-white dark:bg-gray-800/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-xl sm:text-2xl font-bold text-center text-gray-900 dark:text-white mb-8 sm:mb-12">
            Une application web accessible partout
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8">
            <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl p-6 sm:p-8 border border-primary-100/50 dark:border-primary-800/30">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-lg bg-white dark:bg-gray-800 flex items-center justify-center flex-shrink-0">
                    <span className="text-xl">✅</span>
                  </div>
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Installation facile
                    </h3>
                    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                      Installez-la directement sur votre téléphone et accédez-y en un clic, même hors connexion.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-lg bg-white dark:bg-gray-800 flex items-center justify-center flex-shrink-0">
                    <span className="text-xl">✅</span>
                  </div>
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Toujours à jour
                    </h3>
                    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                      Recevez des mises à jour régulières pour suivre les dernières évolutions des visas, réglementations et démarches.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-8 h-8 rounded-lg bg-white dark:bg-gray-800 flex items-center justify-center flex-shrink-0">
                    <span className="text-xl">✅</span>
                  </div>
                  <div>
                    <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Expertise PVT
                    </h3>
                    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                      Profitez d'une checklist toujours à jour, entretenue par notre équipe d'experts en PVT.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative aspect-[4/3] sm:aspect-auto rounded-2xl overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?q=80&w=2421&auto=format&fit=crop"
                alt="Travel Planning"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 p-4 sm:p-8">
                <p className="text-lg sm:text-2xl font-bold text-white mb-4">
                  🚀 Inscrivez-vous dès maintenant et préparez votre PVT sans stress !
                </p>
                <Link
                  to="/"
                  className="inline-flex items-center gap-2 px-4 py-2 sm:px-6 sm:py-3 bg-white/20 hover:bg-white/30 backdrop-blur-sm text-white rounded-xl transition-all duration-300 group text-sm sm:text-base"
                >
                  <span>Commencer l'aventure</span>
                  <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}