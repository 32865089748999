import React from 'react';
import { Lock } from 'lucide-react';

interface LandingNavProps {
  onLogin: () => void;
  theme: 'light' | 'dark';
  onToggleTheme: () => void;
}

export function LandingNav({ onLogin, theme, onToggleTheme }: LandingNavProps) {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white/95 dark:bg-gray-900/95 backdrop-blur-lg border-b border-gray-200/50 dark:border-gray-800/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <a href="/"><img 
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png" 
            alt="PVTistes Logo" 
            className="h-6 sm:h-8 w-auto dark:hidden"
          />
          <img 
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png" 
            alt="PVTistes Logo" 
            className="h-6 sm:h-8 w-auto hidden dark:block"
          /></a>
          <div className="flex items-center gap-2 sm:gap-4">
            <button
              onClick={onToggleTheme}
              className="p-2 text-gray-600 dark:text-gray-300 hover:text-accent-600 dark:hover:text-accent-400 transition-colors"
            >
              {theme === 'dark' ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="5"/>
                  <line x1="12" y1="1" x2="12" y2="3"/>
                  <line x1="12" y1="21" x2="12" y2="23"/>
                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"/>
                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"/>
                  <line x1="1" y1="12" x2="3" y2="12"/>
                  <line x1="21" y1="12" x2="23" y2="12"/>
                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"/>
                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"/>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"/>
                </svg>
              )}
            </button>
            <button
              onClick={onLogin}
              className="flex items-center space-x-2 px-3 sm:px-4 py-2 rounded-xl bg-accent-500 hover:bg-accent-600 text-white text-sm font-medium shadow-sm transition-all duration-300"
            >
              <Lock className="w-4 h-4" />
              <span>Connexion</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}