import React from 'react';
import { ArrowRight, ExternalLink, FileText } from 'lucide-react';

export function CtaSection() {
  return (
    <div className="py-8 sm:py-24 bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20">
      <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-lg sm:text-2xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-6">
            Prêt à partir l'esprit tranquille ?
          </h2>
          <p className="text-sm sm:text-lg text-gray-600 dark:text-gray-400 mb-6 sm:mb-8 max-w-2xl mx-auto">
            Souscrivez dès maintenant à Globe PVT et profitez d'une couverture complète adaptée à votre PVT.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4">
            <a
              href="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white text-[15px] sm:text-base font-medium rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 w-full sm:w-auto"
            >
              <span>Souscrire à Globe PVT</span>
              <ArrowRight className="w-5 h-5" />
            </a>
            <a
              href="https://clems.acs-ami.com/fr/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center gap-2 px-6 py-3 bg-white dark:bg-gray-800 text-primary-600 dark:text-primary-400 text-[15px] sm:text-base font-medium rounded-xl transition-colors border border-primary-200 dark:border-primary-700 w-full sm:w-auto"
            >
              <span>Faire une demande de remboursement</span>
              <ExternalLink className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* Documents Section */}
        <div className="mt-8 sm:mt-16 pt-8 sm:pt-16 border-t border-primary-200/50 dark:border-primary-800/30">
          <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-6 sm:mb-8 text-center">
            Documents d'information
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6">
            {/* Canada */}
            <div className="bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
              <div className="relative aspect-[4/3] mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://pvtistes.net/wp-content/themes/pvtistest-2/static/ui/insurance-doc-02.png"
                  alt="Document Globe PVT Canada"
                  className="w-full h-full object-contain"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-3 left-3">
                  <FileText className="w-6 h-6 text-white" />
                </div>
              </div>
              <h4 className="text-base font-medium text-gray-900 dark:text-white mb-2">
                Notice Globe PVT Canada
              </h4>
              <a
                href="https://www.acs-ami.com/files/pdf/Globe-PVT-EIC-Canada-IB-fr.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 hover:underline"
              >
                <span>Télécharger le PDF</span>
                <ArrowRight className="w-4 h-4" />
              </a>
            </div>

            {/* Autres destinations */}
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
              <div className="relative aspect-[4/3] mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://pvtistes.net/wp-content/themes/pvtistest-2/static/ui/insurance-doc-02.png"
                  alt="Document Globe PVT autres destinations"
                  className="w-full h-full object-contain"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-3 left-3">
                  <FileText className="w-6 h-6 text-white" />
                </div>
              </div>
              <h4 className="text-base font-medium text-gray-900 dark:text-white mb-2">
                Notice Globe PVT autres destinations
              </h4>
              <a
                href="https://www.acs-ami.com/files/pdf/Globe-PVT-WHV-IB-fr.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 hover:underline"
              >
                <span>Télécharger le PDF</span>
                <ArrowRight className="w-4 h-4" />
              </a>
            </div>

            {/* Tableau de garanties */}
            <div className="bg-white dark:bg-gray-800 rounded-xl p-6 border border-gray-200/50 dark:border-gray-700/50">
              <div className="relative aspect-[4/3] mb-4 overflow-hidden rounded-lg">
                <img
                  src="https://pvtistes.net/wp-content/themes/pvtistest-2/static/ui/insurance-doc-02.png"
                  alt="Tableau de garanties Globe PVT"
                  className="w-full h-full object-contain"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-3 left-3">
                  <FileText className="w-6 h-6 text-white" />
                </div>
              </div>
              <h4 className="text-base font-medium text-gray-900 dark:text-white mb-2">
                Tableau de garanties Globe PVT
              </h4>
              <a
                href="https://www.acs-ami.com/files/pdf/Globe-PVT-TG-fr.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 hover:underline"
              >
                <span>Télécharger le PDF</span>
                <ArrowRight className="w-4 h-4" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}