import React from 'react';
import { Info, Fuel, Wrench, Tent, MapPin } from 'lucide-react';

interface NomadExpensesBreakdownProps {
  housingType: string;
}

export function NomadExpensesBreakdown({ housingType }: NomadExpensesBreakdownProps) {
  const isVanlife = housingType === 'vanlife';

  return (
    <div className="mt-4 bg-gray-50 dark:bg-gray-700/30 rounded-xl p-4 sm:p-6">
      <div className="flex items-start sm:items-center gap-2 mb-4">
        <Info className="hidden sm:block w-4 h-4 text-gray-500 dark:text-gray-400" />
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {isVanlife 
            ? "Coûts mensuels estimés pour la vie en van"
            : "Coûts mensuels estimés pour le mode nomade"}
        </p>
      </div>

      <div className="space-y-4">
        {isVanlife ? (
          <>
            {/* Van Life Specific Costs */}
            <div className="space-y-3">
              <div className="flex items-center justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-center gap-2">
                  <Fuel className="w-4 h-4 text-amber-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Carburant</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~250-350 €/mois
                </div>
              </div>

              <div className="flex items-center justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-center gap-2">
                  <Tent className="w-4 h-4 text-emerald-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Camping</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~50-100 €/mois
                </div>
              </div>

              <div className="flex items-center justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-center gap-2">
                  <Wrench className="w-4 h-4 text-blue-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Entretien</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~100-200 €/mois
                </div>
              </div>

              <div className="flex items-center justify-between py-2">
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-red-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Stationnement</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~30-50 €/mois
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className="mt-4 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-lg border border-amber-100 dark:border-amber-800/30">
              <div className="flex items-start gap-2">
                <Info className="w-4 h-4 text-amber-500 mt-0.5" />
                <div className="space-y-2">
                  <p className="text-sm text-amber-800 dark:text-amber-300">
                    <strong>Coûts d'acquisition :</strong>
                  </p>
                  <ul className="text-xs text-amber-700 dark:text-amber-400 list-disc list-inside space-y-1">
                    <li>Achat van aménagé : 15 000 - 35 000 €</li>
                    <li>Location van : 80-120 €/jour</li>
                    <li>Assurance van : 50-100 €/mois</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Other Nomad Types */}
            <div className="space-y-3">
              <div className="flex items-center justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-center gap-2">
                  <MapPin className="w-4 h-4 text-red-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Transport entre villes</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~100-200 €/mois
                </div>
              </div>

              <div className="flex items-center justify-between py-2">
                <div className="flex items-center gap-2">
                  <Tent className="w-4 h-4 text-emerald-500" />
                  <span className="text-sm text-gray-600 dark:text-gray-400">Frais supplémentaires</span>
                </div>
                <div className="text-sm font-medium text-gray-900 dark:text-white">
                  ~50-100 €/mois
                </div>
              </div>
            </div>

            {/* Notes */}
            <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg border border-blue-100 dark:border-blue-800/30">
              <div className="flex items-start gap-2">
                <Info className="w-4 h-4 text-blue-500 mt-0.5" />
                <div className="space-y-1">
                  <p className="text-sm text-blue-800 dark:text-blue-300">
                    Ces coûts s'ajoutent au prix de l'hébergement sélectionné.
                  </p>
                  <p className="text-xs text-blue-700 dark:text-blue-400">
                    Pensez à prévoir un budget pour les déplacements entre les différentes villes.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}