import React from 'react';
import { MapPin } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { budgetCountries } from '../../../data/budget-countries';
import { MEDIA_TAGS } from '../../../data/media-tags';

interface PostMetadataProps {
  username: string;
  caption: string;
  city?: string;
  country?: string;
  tags: string[];
  createdAt: Date;
}

export function PostMetadata({
  username,
  caption,
  city,
  country,
  tags,
  createdAt
}: PostMetadataProps) {
  return (
    <>
      <div className="mb-2">
        <span className="font-medium text-gray-900 dark:text-white text-sm sm:text-base">
          {username}
        </span>
        <span className="ml-2 text-gray-600 dark:text-gray-300 text-sm sm:text-base">
          {caption}
        </span>
      </div>

      {(city || country) && (
        <div className="flex items-center gap-1.5 mb-2 text-xs sm:text-sm text-gray-600 dark:text-gray-400">
          <MapPin className="w-4 h-4" />
          <span>
            {city}
            {city && country && ', '}
            {budgetCountries.find(c => c.code === country)?.name || country}
          </span>
        </div>
      )}

      {tags.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-2 mt-3">
          {tags.map(tag => (
            <Link
              to={`/media?tag=${tag}`}
              key={tag}
              className="inline-flex items-center gap-1.5 px-2 py-1 bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 rounded-lg text-xs sm:text-sm border border-primary-100/50 dark:border-primary-800/30 hover:bg-primary-100 dark:hover:bg-primary-900/30 transition-colors"
              onClick={(e) => e.stopPropagation()}
            >
              <span>{MEDIA_TAGS[tag]?.icon || '#'}</span>
              <span>{MEDIA_TAGS[tag]?.label || tag}</span>
            </Link>
          ))}
        </div>
      )}

      <div className="text-xs text-gray-500 dark:text-gray-400 mt-2">
        {formatDistanceToNow(createdAt, { addSuffix: true, locale: fr })}
      </div>
    </>
  );
}