import React from 'react';
import { PlayCircle } from 'lucide-react';

export function VideoSection() {
  return (
    <div className="py-4 sm:py-16 bg-gradient-to-b from-white via-gray-50/50 to-white dark:from-gray-800 dark:via-gray-900/50 dark:to-gray-800">
      <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-primary-100/20 via-accent-100/20 to-primary-100/20 dark:from-primary-800/10 dark:via-accent-800/10 dark:to-primary-800/10 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl pointer-events-none" />
          
          <div className="relative">
            <div className="flex items-center gap-2 text-primary-600 dark:text-primary-400 p-4 sm:p-8 pb-0">
              <PlayCircle className="w-5 h-5" />
              <span className="text-sm font-medium">Vidéo</span>
            </div>
            
            <div className="p-4 sm:p-8">
              <h2 className="text-lg sm:text-3xl font-bold text-gray-900 dark:text-white mb-3 sm:mb-4">
                Découvrez Globe PVT en vidéo
              </h2>
              <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 mb-4 sm:mb-6">
                Pvtistes.net recommande l'assurance Globe PVT depuis 2005. Dans cette vidéo, nous vous expliquons l'importance d'avoir une bonne assurance lorsque vous partez en PVT.
              </p>

              <div className="aspect-video w-full max-w-4xl mx-auto rounded-xl overflow-hidden shadow-lg">
                <iframe
                  src="https://www.youtube.com/embed/dEv0Bs2jviY"
                  title="Présentation Globe PVT"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}