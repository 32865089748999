import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Task, Country } from '../types/todo';

interface ChecklistEmailData {
  tasks: Task[];
  country: Country;
  departureDate?: string;
  returnDate?: string;
}

export function formatChecklistForEmail(data: ChecklistEmailData): string {
  const formatDate = () => {
    return format(new Date(), 'dd MMMM yyyy', { locale: fr });
  };

  const getTaskStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return '✅';
      case 'in-progress':
        return '⏳';
      default:
        return '⭕';
    }
  };

  const getCategoryTitle = (category: string) => {
    switch (category) {
      case 'before-departure':
        return 'Préparer votre départ';
      case 'after-arrival':
        return 'Le jour du départ';
      case 'return':
        return 'Les démarches à l\'arrivée';
      default:
        return 'Autres tâches';
    }
  };

  // Group tasks by category
  const tasksByCategory = data.tasks.reduce((acc, task) => {
    if (!acc[task.category]) {
      acc[task.category] = [];
    }
    acc[task.category].push(task);
    return acc;
  }, {} as Record<string, Task[]>);

  return `
    <div style="max-width: 600px; margin: 0 auto; font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;">
      <div style="text-align: center; margin-bottom: 30px; background: linear-gradient(to right, #EF4136, #0DB6DB); color: white; padding: 20px; border-radius: 16px; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
        <h1 style="color: white; font-size: 20px; line-height: 1.4; margin: 0 0 8px 0; font-weight: 600;">Checklist PVT ${data.country.name}</h1>
        <p style="color: rgba(255, 255, 255, 0.9); font-size: 13px; margin: 0;">Créée le ${formatDate()}</p>
      </div>

      ${data.departureDate || data.returnDate ? `
        <div style="background: white; border-radius: 16px; padding: 16px; margin: 20px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
          <h2 style="color: #1f2937; font-size: 16px; margin: 0 0 12px; font-weight: 600;">Dates du voyage</h2>
          ${data.departureDate ? `
            <div style="display: flex; justify-content: space-between; align-items: center; padding: 8px 0; border-bottom: 1px solid #f3f4f6;">
              <span style="color: #4b5563; font-size: 14px;">Date de départ :</span>
              <span style="font-weight: 500; color: #1f2937; font-size: 14px;">${format(new Date(data.departureDate), 'dd MMMM yyyy', { locale: fr })}</span>
            </div>
          ` : ''}
          ${data.returnDate ? `
            <div style="display: flex; justify-content: space-between; align-items: center; padding: 8px 0;">
              <span style="color: #4b5563; font-size: 14px;">Date de retour :</span>
              <span style="font-weight: 500; color: #1f2937; font-size: 14px;">${format(new Date(data.returnDate), 'dd MMMM yyyy', { locale: fr })}</span>
            </div>
          ` : ''}
        </div>
      ` : ''}

      ${Object.entries(tasksByCategory).map(([category, tasks]) => `
        <div style="background: white; border-radius: 16px; padding: 16px; margin: 20px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
          <h2 style="color: #1f2937; font-size: 16px; margin: 0 0 12px; font-weight: 600;">${getCategoryTitle(category)}</h2>
          ${tasks.map(task => `
            <div style="margin-bottom: 12px; padding: 12px; background: #f8fafc; border-radius: 12px;">
              <div style="display: flex; align-items: flex-start; gap: 8px;">
                <span style="font-size: 14px; line-height: 1.4;">${getTaskStatusIcon(task.status)}</span>
                <div style="flex: 1;">
                  <h3 style="color: #1f2937; font-size: 14px; margin: 0 0 6px; font-weight: 500; line-height: 1.4;">${task.title}</h3>
                  ${task.description ? `
                    <p style="color: #4b5563; font-size: 13px; margin: 0 0 6px; line-height: 1.4;">${task.description}</p>
                  ` : ''}
                  ${task.subtasks.length > 0 ? `
                    <div style="margin-top: 8px;">
                      <p style="color: #6b7280; font-size: 12px; margin: 0 0 6px;">Sous-tâches :</p>
                      ${task.subtasks.map(subtask => `
                        <div style="display: flex; align-items: center; gap: 6px; margin-bottom: 4px;">
                          <span style="font-size: 12px;">${subtask.completed ? '✅' : '⭕'}</span>
                          <span style="color: #4b5563; font-size: 12px; line-height: 1.4;">${subtask.title}</span>
                        </div>
                      `).join('')}
                    </div>
                  ` : ''}
                </div>
              </div>
            </div>
          `).join('')}
        </div>
      `).join('')}

      <div style="background: #f8fafc; border-radius: 16px; padding: 16px; margin: 20px 0; text-align: center;">
        <p style="color: #4b5563; font-size: 13px; margin: 0; line-height: 1.4;">
          Retrouvez votre checklist complète sur <a href="https://app.pvtistes.net" style="color: #EF4136; text-decoration: none;">app.pvtistes.net</a>
        </p>
      </div>
    </div>
  `;
}