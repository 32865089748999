import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { sanitizeDocumentId } from '../utils/firestore';

/**
 * Get cost of living data for a city from Firebase cache
 */
export async function getCityLivingCosts(city: string, countryCode: string): Promise<any> {
  if (!city || !countryCode) return null;
  
  try {
    // Normaliser la ville et le pays
    const normalizedCity = city.trim();
    const normalizedCountry = countryCode.trim().toLowerCase();
    
    console.log('🔍 Recherche des coûts pour:', normalizedCity);
    
    // Create document ID
    const docId = sanitizeDocumentId(`${normalizedCountry}-${normalizedCity}`);
    
    console.log('📄 Document ID généré:', docId, {
      originalCity: city,
      normalizedCity,
      originalCountry: countryCode,
      normalizedCountry,
      docId
    });
    
    const docRef = doc(db, 'city_costs', docId);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
      console.log('❌ Aucune donnée trouvée pour:', normalizedCity, '(ID:', docId, ')');
      return null;
    }

    const data = docSnap.data();
    console.log('✅ Données trouvées pour:', normalizedCity);

    return {
      priceItems: data.priceItems || [],
      eurPrices: data.eurPrices || [],
      currency: data.currency || 'EUR',
      housingTypes: data.housingTypes || [],
      contributors12months: Number(data.contributors12months) || 0,
      monthLastUpdate: data.monthLastUpdate || 0,
      contributors: Number(data.contributors) || 0,
      yearLastUpdate: data.yearLastUpdate || new Date().getFullYear(),
      name: data.name || normalizedCity
    };
  } catch (error) {
    console.error('Error fetching city living costs:', {
      city,
      countryCode,
      error: error instanceof Error ? error.message : 'Unknown error'
    });
    return null;
  }
}