import React from 'react';
import { Plus, X } from 'lucide-react';

interface PreviewOverlayProps {
  onAddList: () => void;
  onBack: () => void;
  isCreating?: boolean;
}

export function PreviewOverlay({ onAddList, onBack, isCreating }: PreviewOverlayProps) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-40 bg-black/50">
      <div className="w-[90%] sm:w-auto max-w-lg bg-white dark:bg-gray-800 rounded-xl shadow-2xl border border-primary-100 dark:border-primary-800/20 relative">
        {isCreating && (
          <div className="absolute inset-0 bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm flex items-center justify-center z-10 rounded-xl">
            <div className="text-center">
              <div className="w-12 h-12 border-4 border-primary-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
              <p className="text-gray-900 dark:text-white font-medium">Création de votre checklist...</p>
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2">Cette opération peut prendre quelques instants</p>
            </div>
          </div>
        )}

        <div className="p-4 sm:p-6">
          <div className="text-center space-y-3 sm:space-y-4">
            <div className="w-12 h-12 bg-primary-50 dark:bg-primary-900/20 rounded-full flex items-center justify-center mx-auto mb-2">
              <Plus className="w-6 h-6 text-primary-500 dark:text-primary-400" />
            </div>
            
            <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
              Prévisualisation de la checklist
            </h3>
            
            <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 max-w-md mx-auto">
              Pour personnaliser cette checklist et suivre votre progression, cliquez sur le bouton ci-dessous.
            </p>
            
            <div className="flex flex-col sm:flex-row justify-center gap-3 pt-2">
              <button
                onClick={onBack}
                className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-2.5 sm:py-3 text-sm sm:text-base font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-xl transition-colors"
              >
                <X className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                <span>Annuler</span>
              </button>
              
              <button
                onClick={onAddList}
                disabled={isCreating}
                className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-2.5 sm:py-3 text-sm sm:text-base font-medium text-white bg-primary-500 hover:bg-primary-600 rounded-xl shadow-md transition-colors"
              >
                {isCreating ? (
                  <>
                    <div className="w-4 h-4 sm:w-5 sm:h-5 border-2 border-white/30 border-t-white rounded-full animate-spin mr-2" />
                    <span>Création en cours...</span>
                  </>
                ) : (
                  <>
                    <Plus className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                    <span>Ajouter cette checklist</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}