import React from 'react';
import { Users } from 'lucide-react';

export function InterviewsHero() {
  return (
    <div className="relative min-h-[400px] sm:min-h-[500px] flex items-center">
      <div className="absolute inset-0">
        <img 
          src="https://images.unsplash.com/photo-1517457373958-b7bdd4587205?q=80&w=2669&auto=format&fit=crop"
          alt=""
          className="w-full h-full object-cover opacity-90 dark:opacity-90"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-gray-900/70 to-gray-900/95"></div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="max-w-3xl">
          <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-4 sm:mb-6">
            <Users className="w-4 h-4 text-primary-400" />
            <span className="text-xs sm:text-sm font-medium text-white">Récits de voyageurs PVT</span>
          </div>

          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 sm:mb-6">
            Ils racontent leur expérience PVT
          </h1>

          <p className="text-base sm:text-lg text-white/90 leading-relaxed">
            Découvrez les témoignages de pvtistes qui partagent leur expérience, leurs conseils et leur parcours à l'étranger.
          </p>
        </div>
      </div>
    </div>
  );
}