import React from 'react';

export function SplashScreen() {
  return (
    <div className="fixed inset-0 z-[100] bg-gradient-to-br from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-1/4 -left-1/4 w-96 h-96 bg-gradient-to-br from-primary-200/30 to-accent-200/30 dark:from-primary-900/20 dark:to-accent-900/20 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-1/4 -right-1/4 w-96 h-96 bg-gradient-to-br from-accent-200/30 to-primary-200/30 dark:from-accent-900/20 dark:to-primary-900/20 rounded-full blur-3xl animate-pulse delay-700" />
      </div>

      {/* Content */}
      <div className="relative h-full flex flex-col items-center justify-center">
        {/* Logo Container with Animation */}
        <div className="relative transform transition-all duration-700 animate-float">
          {/* Logo glow effect */}
          <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 to-accent-500/10 dark:from-primary-400/10 dark:to-accent-400/10 blur-2xl rounded-full scale-150" />
          
          {/* Logo */}
          <img
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png"
            alt="PVTistes.net"
            className="h-16 sm:h-20 w-auto relative dark:hidden"
          />
          <img
            src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png"
            alt="PVTistes.net"
            className="h-16 sm:h-20 w-auto relative hidden dark:block"
          />
        </div>

        {/* Tagline */}
        <p className="mt-6 text-base sm:text-lg text-gray-600 dark:text-gray-400 italic animate-fade-in opacity-0" style={{ animationDelay: '500ms' }}>
          Parce qu'ici on a déjà un pied là-bas.
        </p>

        {/* Loading Indicator */}
        <div className="mt-12 flex justify-center items-center space-x-2">
          <div className="w-2 h-2 bg-primary-500/80 dark:bg-primary-400/80 rounded-full animate-[bounce_1s_infinite_0ms]"></div>
          <div className="w-2 h-2 bg-primary-500/80 dark:bg-primary-400/80 rounded-full animate-[bounce_1s_infinite_200ms]"></div>
          <div className="w-2 h-2 bg-primary-500/80 dark:bg-primary-400/80 rounded-full animate-[bounce_1s_infinite_400ms]"></div>
        </div>
      </div>
    </div>
  );
}