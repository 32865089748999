import React from 'react';
import { Info, ChevronDown, ChevronUp } from 'lucide-react';

interface FaqSectionProps {
  title: string;
  content: string;
  sections: Array<{
    title: string;
    questions: Array<{
      question: string;
      answer: string;
    }>;
  }>;
  selectedCategory: 'all' | 'general' | 'canada' | 'other' | 'card';
  onCategoryChange: (category: 'all' | 'general' | 'canada' | 'other' | 'card') => void;
  expandedFaqSection: string | null;
  setExpandedFaqSection: (section: string | null) => void;
  expandedQuestion: string | null;
  setExpandedQuestion: (question: string | null) => void;
}

export function FaqSection({
  title,
  content,
  sections,
  selectedCategory,
  onCategoryChange,
  expandedFaqSection,
  setExpandedFaqSection,
  expandedQuestion,
  setExpandedQuestion
}: FaqSectionProps) {
  return (
    <div className="py-8 sm:py-16 bg-gradient-to-b from-white via-gray-50/50 to-white dark:from-gray-800 dark:via-gray-900/50 dark:to-gray-800">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* FAQ Category Filters */}
        <div className="flex flex-wrap items-center justify-start sm:justify-center gap-2 mb-6 sm:mb-8 overflow-x-auto pb-2 -mx-4 px-4 sm:mx-0 sm:px-0 no-scrollbar">
          <button
            onClick={() => onCategoryChange('all')}
            className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
              selectedCategory === 'all'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 dark:ring-primary-400/20'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700/50'
            }`}
          >
            Toutes les questions
          </button>
          <button
            onClick={() => onCategoryChange('general')}
            className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
              selectedCategory === 'general'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 dark:ring-primary-400/20'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700/50'
            }`}
          >
            Questions générales
          </button>
          <button
            onClick={() => onCategoryChange('canada')}
            className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
              selectedCategory === 'canada'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 dark:ring-primary-400/20'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700/50'
            }`}
          >
            Spécial Canada
          </button>
          <button
            onClick={() => onCategoryChange('other')}
            className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
              selectedCategory === 'other'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 dark:ring-primary-400/20'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700/50'
            }`}
          >
            Autres destinations
          </button>
          <button
            onClick={() => onCategoryChange('card')}
            className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm font-medium transition-colors whitespace-nowrap ${
              selectedCategory === 'card'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 dark:ring-primary-400/20'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700/50'
            }`}
          >
            Carte d'assuré
          </button>
        </div>

        <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl sm:rounded-2xl p-4 sm:p-6 border border-primary-100/50 dark:border-primary-800/30 mb-8 sm:mb-12">
          <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
          
          <div className="relative">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-1.5 sm:p-2 bg-white dark:bg-gray-800 rounded-lg">
                <Info className="w-4 h-4 sm:w-5 sm:h-5 text-primary-500 dark:text-primary-400" />
              </div>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                {title}
              </h2>
            </div>
            <div 
              className="text-sm sm:text-base text-gray-600 dark:text-gray-400 prose dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>

        <div className="space-y-4 sm:space-y-6">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 border border-gray-200/50 dark:border-gray-700/50">
              <button
                onClick={() => setExpandedFaqSection(
                  expandedFaqSection === section.title ? null : section.title
                )}
                className="w-full flex items-center justify-between p-4 sm:p-6 text-left hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
              >
                <h3 className="text-[13px] sm:text-lg font-semibold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300 bg-clip-text text-transparent">
                  {section.title}
                </h3>
                {expandedFaqSection === section.title ? (
                  <ChevronUp className="w-5 h-5 text-primary-500 dark:text-primary-400" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-primary-500 dark:text-primary-400" />
                )}
              </button>

              {expandedFaqSection === section.title && (
                <div className="px-6 pb-6 animate-fade-in">
                  <div className="space-y-4">
                    {section.questions.map((item, questionIndex) => (
                      <div 
                        key={questionIndex} 
                        className={`bg-gray-50 dark:bg-gray-700/50 rounded-lg overflow-hidden transition-all duration-300 ${
                          expandedQuestion === `${sectionIndex}-${questionIndex}` 
                            ? 'ring-1 ring-primary-500/20 dark:ring-primary-400/20' 
                            : ''
                        }`}
                      >
                        <button
                          onClick={() => setExpandedQuestion(
                            expandedQuestion === `${sectionIndex}-${questionIndex}` 
                              ? null 
                              : `${sectionIndex}-${questionIndex}`
                          )}
                          className="w-full flex items-center justify-between p-4 text-left hover:bg-white dark:hover:bg-gray-700 transition-colors"
                        >
                          <span className="text-sm font-medium text-gray-900 dark:text-white">
                            {item.question}
                          </span>
                          {expandedQuestion === `${sectionIndex}-${questionIndex}` ? (
                            <ChevronUp className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                          ) : (
                            <ChevronDown className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                          )}
                        </button>

                        {expandedQuestion === `${sectionIndex}-${questionIndex}` && (
                          <div className="px-4 pb-4 animate-fade-in">
                            <div 
                              className="text-[13px] leading-relaxed text-gray-600 dark:text-gray-400 [&_p]:mb-4 [&_p:last-child]:mb-0 [&_a]:text-primary-600 dark:[&_a]:text-primary-400 [&_a:hover]:text-primary-700 dark:[&_a:hover]:text-primary-300"
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}