import React from 'react';
import { BudgetFormData } from '../types/budget';

// Types pour les résultats des calculs
interface ExpenseCalculations {
  monthlyExpenses: number;
  preDepartureCosts: number; 
  setupCosts: number;
  totalBudget: number;
  housingCost: number;
  monthlyBreakdown: {
    category: string;
    amount: number;
  }[];
  preDepartureBreakdown: {
    category: string;
    amount: number;
  }[];
  setupBreakdown: {
    category: string;
    amount: number;
  }[];
}

export function useExpenseCalculations(formData: BudgetFormData): ExpenseCalculations {
  return React.useMemo(() => {
    // Récupérer le coût du logement
    let housingCost = 0;
    if (formData.isNomadMode && formData.customHousingCost) {
      housingCost = Number(formData.customHousingCost);
    } else if (formData.housingType && formData.housingTypes) {
      housingCost = formData.housingTypes.find(h => h.id === formData.housingType)?.costEUR || 
                    formData.housingTypes.find(h => h.id === formData.housingType)?.cost || 
                    0;
    }

    // Calculer les dépenses mensuelles avec breakdown
    const monthlyBreakdown = [
      { category: 'Logement', amount: housingCost },
      { category: 'Courses', amount: Number(formData.groceriesCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Restaurants', amount: Number(formData.restaurantsCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Transport', amount: Number(formData.transportCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Charges', amount: Number(formData.utilitiesCost) || 0 },
      { category: 'Forfait mobile', amount: Number(formData.phoneCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Loisirs', amount: Number(formData.leisureCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Épargne', amount: Number(formData.epargne) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Frais bancaires', amount: Number(formData.bankCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Streaming', amount: Number(formData.streamingCost) || 0 },
      // Ajouter les dépenses personnalisées
      ...(formData.expenseFields || []).map(expense => ({
        category: expense.name,
        amount: Number(expense.amount) * (formData.isCouple ? 2 : 1) || 0
      }))
    ];

    // Calculer les coûts avant départ avec breakdown
    const preDepartureBreakdown = [
      { category: 'Billet d\'avion', amount: Number(formData.flightCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Visa PVT', amount: Number(formData.pvtCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Assurance PVT', amount: (Number(formData.insuranceCost) * Number(formData.pvtDuration)) * (formData.isCouple ? 2 : 1) || 0 },
      // Ajouter les dépenses personnalisées avant départ
      ...(formData.preDepartureExpenses || []).map(expense => ({
        category: expense.name,
        amount: Number(expense.amount) * (formData.isCouple ? 2 : 1) || 0
      }))
    ];

    // Calculer les coûts d'installation avec breakdown
    const setupBreakdown = [
      { category: 'Caution et avance', amount: Number(formData.firstLastMonthRent) || 0 },
      { category: 'Mobilier', amount: Number(formData.furnitureCost) || 0 },
      { category: 'Vêtements', amount: Number(formData.clothingCost) * (formData.isCouple ? 2 : 1) || 0 },
      { category: 'Courses initiales', amount: Number(formData.groceryCost) * (formData.isCouple ? 1.5 : 1) || 0 },
      // Ajouter les dépenses personnalisées d'installation
      ...(formData.setupExpenses || []).map(expense => ({
        category: expense.name,
        amount: Number(expense.amount) * (formData.isCouple ? 2 : 1) || 0
      }))
    ];

    // Calculer les totaux
    const monthlyExpenses = monthlyBreakdown.reduce((sum, item) => sum + item.amount, 0);
    const preDepartureCosts = preDepartureBreakdown.reduce((sum, item) => sum + item.amount, 0);
    const setupCosts = setupBreakdown.reduce((sum, item) => sum + item.amount, 0);
    
    // Budget total sur toute la durée
    const totalBudget = (monthlyExpenses * Number(formData.pvtDuration)) + setupCosts + preDepartureCosts;

    return {
      monthlyExpenses,
      preDepartureCosts,
      setupCosts,
      totalBudget,
      housingCost,
      monthlyBreakdown,
      preDepartureBreakdown,
      setupBreakdown
    };
  }, [formData]);
}