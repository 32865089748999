import React from 'react';
import { StepOne } from './steps/StepOne';
import { StepTwo } from './steps/StepTwo';
import { StepThree } from './steps/StepThree';
import { StepFour } from './steps/StepFour';
import { BudgetSummaryBox } from './BudgetSummaryBox';
import { Calculator, PiggyBank, ArrowRight } from 'lucide-react';
import { Country } from '../../types/todo';

interface BudgetFormProps {
  countries: Country[];
  onSubmit: (data: any) => void;
  step: number;
  onBack: () => void;
  onFormDataChange: (data: any) => void;
  formData: any;
  housingTypes: any[];
  currency: string;
  city?: string;
  countryName?: string;
}

export function BudgetForm({ 
  countries, 
  onSubmit, 
  step, 
  onBack,
  onFormDataChange,
  formData,
  housingTypes,
  currency,
  city,
  countryName
}: BudgetFormProps) {
  const [includePreDeparture, setIncludePreDeparture] = React.useState(() => {
    const saved = localStorage.getItem('includePreDeparture');
    return saved === null ? true : saved === 'true';
  });

  // Validation pour l'étape 1
  const isStepOneValid = React.useMemo(() => {
    return (
      formData.country &&
      formData.city &&
      formData.housingType && // Ajout de la validation du type de logement
      formData.initialSavings &&
      formData.pvtDuration
    );
  }, [formData]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Empêcher la soumission si l'étape 1 n'est pas valide
    if (step === 1 && !isStepOneValid) {
      return;
    }
    
    // Faire défiler vers le haut de la page
    window.scrollTo({ top: 0, behavior: 'smooth' });
    
    // Attendre que le défilement soit terminé avant de passer à l'étape suivante
    setTimeout(() => {
      onSubmit(formData);
    }, 500); // Délai pour laisser le temps au défilement de s'effectuer
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-5 gap-4 sm:gap-8">
      {/* Main Form Section */}
      <div className="xl:col-span-3 relative space-y-6">
        {step === 1 && (
          <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl border border-primary-100/50 dark:border-primary-800/30 p-6 sm:p-8">
            <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
            
            <div className="relative">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                  <Calculator className="w-6 h-6 text-primary-500 dark:text-primary-400" />
                </div>
                <div>
                  <h2 className="text-lg sm:text-2xl font-bold text-gray-900 dark:text-white">
                    Simulateur de budget PVT
                  </h2>
                  <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                    Planifiez votre budget avec précision
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
                <div className="bg-white/80 dark:bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-emerald-50 dark:bg-emerald-900/20 rounded-lg">
                      <PiggyBank className="w-5 h-5 text-emerald-500" />
                    </div>
                    <div>
                      <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">Données fiables</p>
                      <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                        Prix à jour et vérifiés
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/80 dark:bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                      <ArrowRight className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">Estimation précise</p>
                      <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                        En 4 étapes simples
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
          {step === 1 && (
            <StepOne 
              formData={formData} 
              countries={countries} 
              onFormDataChange={onFormDataChange}
            />
          )}
          {step === 2 && (
            <StepTwo 
              formData={formData} 
              onFormDataChange={onFormDataChange} 
            />
          )}
          {step === 3 && (
            <StepThree 
              formData={formData} 
              onFormDataChange={onFormDataChange}
            />
          )}
          {step === 4 && (
            <StepFour 
              formData={formData} 
              onFormDataChange={onFormDataChange} 
            />
          )}
          
          {/* Navigation Buttons */}
          <div className="flex flex-col sm:flex-row justify-between gap-3 sm:gap-4 pt-6 border-t border-gray-200 dark:border-gray-700">
            {step > 1 && (
              <button
                type="button"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setTimeout(() => {
                    onBack();
                  }, 500);
                }}
                className="order-2 sm:order-1 w-full sm:w-auto flex items-center justify-center gap-2 px-6 py-3 text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-xl transition-all duration-200 border border-gray-200 dark:border-gray-700 shadow-sm hover:shadow-md"
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
                <span className="font-medium">Retour</span>
              </button>
            )}
            <button
              type="submit"
              disabled={step === 1 && !isStepOneValid}
              className={`order-1 sm:order-2 w-full sm:w-auto flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 text-white font-medium rounded-xl transition-all duration-200 transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 shadow-sm hover:shadow-md ${
                step === 1 ? 'sm:ml-auto' : ''
              }`}
            >
              <span className="font-medium">{step === 4 ? 'Calculer mon budget' : 'Continuer'}</span>
              {step !== 4 && (
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Summary Box - Hidden on mobile, shown on large screens */}
      <div className="hidden xl:block xl:col-span-2 relative">
        <div className="sticky top-[8.5rem] pt-8">
          <BudgetSummaryBox 
            formData={formData}
            housingTypes={housingTypes}
            currency={currency}
            city={city}
            countryName={countryName}
            includePreDeparture={includePreDeparture}
            onTogglePreDeparture={() => setIncludePreDeparture(!includePreDeparture)}
            currentStep={step}
          />
        </div>
      </div>
    </div>
  );
}