import React from 'react';
import { LogOut, User, Menu, X, Moon, Sun, Settings, Home, MessageSquare, Calculator, ListChecks, Book, Newspaper, Users, UserPlus, Camera } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../lib/auth-context';
import { useTheme } from '../lib/theme-context';
import { Menu as MenuComponent } from './ui/Menu';
import { FeedbackWidget } from './FeedbackWidget';
import { MobileNavBar } from './MobileNavBar';
import { MessageCenter } from './MessageCenter/MessageCenter';

interface NavbarProps {
  onLogin: () => void;
  isBudgetPage?: boolean;
  countryCode?: string;
}

export function Navbar({ onLogin, isBudgetPage, countryCode }: NavbarProps) {
  const { user, userInfo, isAdmin, logout } = useAuth();
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [showMessageCenter, setShowMessageCenter] = React.useState(false);
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [hasSeenMenuHint, setHasSeenMenuHint] = React.useState(() => {
    return localStorage.getItem('hasSeenMenuHint') === 'true';
  });

  // Handle menu hint
  React.useEffect(() => {
    if (!hasSeenMenuHint) {
      const timer = setTimeout(() => {
        localStorage.setItem('hasSeenMenuHint', 'true');
        setHasSeenMenuHint(true);
      }, 10000); // Hide after 10 seconds

      return () => clearTimeout(timer);
    }
  }, [hasSeenMenuHint]);

  // Handle menu toggle with scroll
  const handleMenuToggle = () => {
    if (!isMenuOpen) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (!hasSeenMenuHint) {
        localStorage.setItem('hasSeenMenuHint', 'true');
        setHasSeenMenuHint(true);
      }
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSimulatorClick = () => {
    window.scrollTo(0, 0);
    navigate(isBudgetPage ? '/' : '/simulateur-budget', { replace: true });
  };

  // Close user menu when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.user-menu') && !target.closest('.user-avatar')) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const displayName = userInfo?.user?.username || user?.displayName || '';

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white dark:bg-gray-900 border-b border-gray-200/50 dark:border-gray-800/50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
            {/* Logo */}
            <div className="flex-shrink-0">
              <a href="/"><img 
                src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_positive_fullcolor.png" 
                alt="PVTistes Logo" 
                className="h-8 w-auto dark:hidden"
              />
              <img 
                src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png" 
                alt="PVTistes Logo" 
                className="h-8 w-auto hidden dark:block"
              /></a>              
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex md:items-center md:space-x-4">
              {user ? (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <ListChecks className="w-4 h-4" />
                    <span className="text-sm">Checklist</span>
                  </Link>
                  <Link
                    to="/matching"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <UserPlus className="w-4 h-4" />
                    <span className="text-sm">Compagnons</span>
                  </Link>
                  <Link
                    to="/media"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <Camera className="w-4 h-4" />
                    <span className="text-sm">Photos & Vidéos</span>
                  </Link>
                  <Link
                    to="/simulateur-budget"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <Calculator className="w-4 h-4" />
                    <span className="text-sm">Budget</span>
                  </Link>
                  <button
                    onClick={() => setShowMessageCenter(true)}
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200 group"
                  >
                    <MessageSquare className="w-4 h-4 group-hover:scale-110 transition-transform" />
                    <span className="text-sm">Messages</span>
                  </button>
                  <button
                    onClick={() => setIsMenuOpen(true)}
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <Menu className="w-4 h-4" />
                    <span className="text-sm">Menu</span>
                  </button>
                  <button
                    onClick={toggleTheme}
                    className="p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                    title={theme === 'dark' ? 'Passer en mode clair' : 'Passer en mode sombre'}
                  >
                    {theme === 'dark' ? (
                      <Sun className="w-5 h-5" />
                    ) : (
                      <Moon className="w-5 h-5" />
                    )}
                  </button>

                  {/* User Avatar & Menu */}
                  <div className="relative">
                    <button
                      onClick={() => setShowUserMenu(!showUserMenu)}
                      className="user-avatar w-8 h-8 rounded-full bg-gradient-to-br from-primary-500 to-accent-500 flex items-center justify-center text-white text-sm font-medium hover:shadow-lg transition-shadow duration-200"
                    >
                      {displayName?.[0]?.toUpperCase()}
                    </button>

                    {/* Dropdown Menu */}
                    {showUserMenu && (
                      <div className="user-menu absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-xl shadow-premium border border-gray-200 dark:border-gray-700 py-1 z-50">
                        <div className="px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                          <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                            {displayName}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            logout();
                            setShowUserMenu(false);
                          }}
                          className="w-full flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                        >
                          <LogOut className="w-4 h-4" />
                          <span>Déconnexion</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <ListChecks className="w-4 h-4" />
                    <span className="text-sm">Checklist</span>
                  </Link>
                  <Link
                    to="/simulateur-budget"
                    className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                  >
                    <Calculator className="w-4 h-4" />
                    <span className="text-sm">Simulateur Budget</span>
                  </Link>
                  <button
                    onClick={onLogin}
                    className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 text-white text-sm font-medium transition-all duration-300 transform hover:scale-[1.02] focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-900"
                  >
                    <User className="w-4 h-4" />
                    <span>Connexion</span>
                  </button>
                  <button
                    onClick={toggleTheme}
                    className="p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors duration-200"
                    title={theme === 'dark' ? 'Passer en mode clair' : 'Passer en mode sombre'}
                  >
                    {theme === 'dark' ? (
                      <Sun className="w-5 h-5" />
                    ) : (
                      <Moon className="w-5 h-5" />
                    )}
                  </button>
                </div>
              )}
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden flex items-center gap-3">
              {user && (
                <button
                  onClick={() => setShowMessageCenter(true)}
                  className="p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
                >
                  <MessageSquare className="w-5 h-5" />
                </button>
              )}
              <button
                onClick={toggleTheme}
                className="p-2 text-gray-600 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
                title={theme === 'dark' ? 'Passer en mode clair' : 'Passer en mode sombre'}
              >
                {theme === 'dark' ? (
                  <Sun className="w-5 h-5" />
                ) : (
                  <Moon className="w-5 h-5" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          <MenuComponent
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
        </div>
      </nav>

      {/* Mobile Navigation Bar */}
      {user && (
        <MobileNavBar
          onOpenMenu={handleMenuToggle}
          isMenuOpen={isMenuOpen}
        />
      )}

      {/* Feedback Modal */}
      {showFeedback && <FeedbackWidget onClose={() => setShowFeedback(false)} />}

      {/* Message Center */}
      {showMessageCenter && (
        <MessageCenter 
          isOpen={true} 
          onClose={() => setShowMessageCenter(false)}
          countryCode={countryCode}
        />
      )}
    </>
  );
}