// Tag categories with icons and translations
export const MEDIA_TAGS = {
  // PVT Status & Milestones
  pvtlife: { icon: '🌍', label: 'VieEnPVT', category: 'status' },
  preparation: { icon: '✈️', label: 'Préparation', category: 'status' },
  installation: { icon: '🏠', label: 'Installation', category: 'status' },
  firstjob: { icon: '💼', label: 'PremierJob', category: 'status' },
  returnhome: { icon: '🔄', label: 'Retour', category: 'status' },

  // Accommodation
  colocation: { icon: '🏢', label: 'Colocation', category: 'logement' },
  vanlife: { icon: '🚐', label: 'VanLife', category: 'logement' },
  hostel: { icon: '🛏️', label: 'Auberge', category: 'logement' },
  houseshare: { icon: '🏡', label: 'MaisonPartagée', category: 'logement' },

  // Work Experience
  farmwork: { icon: '🌾', label: 'Ferme', category: 'travail' },
  hospitality: { icon: '🍽️', label: 'Restauration', category: 'travail' },
  office: { icon: '💻', label: 'Bureau', category: 'travail' },
  construction: { icon: '🏗️', label: 'Construction', category: 'travail' },
  seasonal: { icon: '🎪', label: 'Saisonnier', category: 'travail' },

  // Lifestyle & Activities
  roadtrip: { icon: '🚗', label: 'RoadTrip', category: 'lifestyle' },
  hiking: { icon: '🥾', label: 'Randonnée', category: 'lifestyle' },
  citylife: { icon: '🌆', label: 'VieUrbaine', category: 'lifestyle' },
  beach: { icon: '🏖️', label: 'Plage', category: 'lifestyle' },
  nature: { icon: '🌿', label: 'Nature', category: 'lifestyle' },
  culture: { icon: '🎭', label: 'Culture', category: 'lifestyle' },
  food: { icon: '🍳', label: 'Gastronomie', category: 'lifestyle' },
  sport: { icon: '⚽', label: 'Sport', category: 'lifestyle' }
} as const;