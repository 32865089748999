import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { CountryFilter } from '../components/dossiers/CountryFilter';
import { SearchAndSort } from '../components/dossiers/SearchAndSort';
import { ContentCard } from '../components/content/ContentCard';
import { budgetCountries } from '../data/budget-countries';
import { UnifiedContent } from '../types/content';
import { Book, Newspaper } from 'lucide-react';
import { fetchNews, fetchDossiers } from '../lib/api';

const ITEMS_PER_PAGE = 12;

export function UnifiedContentPage() {
  const { user, userInfo } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [content, setContent] = React.useState<UnifiedContent[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortBy, setSortBy] = React.useState<'date' | 'popularity'>('date');
  const [contentType, setContentType] = React.useState<'all' | 'news' | 'dossiers'>('all');
  const observerTarget = React.useRef<HTMLDivElement>(null);

  const selectedCountry = searchParams.get('country');

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const fetchContent = React.useCallback(async (page: number) => {
    if (!userInfo) return;

    try {
      const newsPromise = contentType !== 'dossiers' ? 
        fetchNews(page, ITEMS_PER_PAGE, selectedCountry, sortBy, userInfo) :
        Promise.resolve([]);
        
      const dossiersPromise = contentType !== 'news' ?
        fetchDossiers(page, ITEMS_PER_PAGE, selectedCountry, sortBy, userInfo) :
        Promise.resolve([]);

      const [newsData, dossiersData] = await Promise.all([
        newsPromise,
        dossiersPromise
      ]);

      const newsItems: UnifiedContent[] = (Array.isArray(newsData) ? newsData : [newsData])
        .filter(item => item && !item.error)
        .map(item => ({
          ...item,
          type: 'news' as const,
          description: item.excerpt || item.shortDescription
        }));

      const dossierItems: UnifiedContent[] = (Array.isArray(dossiersData) ? dossiersData : [dossiersData])
        .filter(item => item && !item.error)
        .map(item => ({
          ...item,
          type: 'dossier' as const,
          description: item.shortDescription,
          hasChapters: !!item.chapters?.length
        }));

      const combinedItems = [...newsItems, ...dossierItems]
        .sort((a, b) => {
          if (sortBy === 'date') {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
          }
          return b.totalComments - a.totalComments;
        });

      if (combinedItems.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }

      setContent(prev => page === 1 ? combinedItems : [...prev, ...combinedItems]);
    } catch (error) {
      console.error('Error fetching content:', error);
      setError('Impossible de charger le contenu pour le moment');
    } finally {
      setIsLoading(false);
    }
  }, [selectedCountry, contentType, sortBy, userInfo]);

  // Initial load
  React.useEffect(() => {
    if (user && userInfo) {
      setIsLoading(true);
      setCurrentPage(1);
      setHasMore(true);
      fetchContent(1);
    }
  }, [fetchContent, user, userInfo, selectedCountry, contentType, sortBy]);

  // Infinite scroll
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          setCurrentPage(prev => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [hasMore, isLoading]);

  // Load more when page changes
  React.useEffect(() => {
    if (currentPage > 1) {
      fetchContent(currentPage);
    }
  }, [currentPage, fetchContent]);

  const handleCountrySelect = (countryCode: string | null) => {
    if (countryCode) {
      const apiCountryCodes: { [key: string]: string } = {
        'aus': 'australie',
        'nz': 'nouvelle-zelande',
        'ca': 'canada',
        'jpn': 'japon',
        'kor': 'coree-du-sud',
        'twn': 'taiwan',
        'arg': 'argentine',
        'chl': 'chilie',
        'col': 'colombie',
        'mex': 'mexique',
        'per': 'perou',
        'uru': 'uruguay',
        'bra': 'bresil',
        'hkg': 'hong-kong',
        'fra': 'france',
        'ecu': 'equateur'
      };

      const apiCountryCode = apiCountryCodes[countryCode.toLowerCase()] || countryCode.toLowerCase();
      setSearchParams({ country: apiCountryCode });
      setCurrentPage(1);
      setContent([]);
      setHasMore(true);
    } else {
      setSearchParams({});
      setCurrentPage(1);
      setContent([]);
      setHasMore(true);
    }
  };

  // Filter content based on search query and type
  const filteredContent = React.useMemo(() => {
    if (!searchQuery) return content;
    
    const searchTerms = searchQuery.toLowerCase().split(' ');
    return content.filter(item => 
      searchTerms.every(term => 
        item.title.toLowerCase().includes(term) ||
        item.description.toLowerCase().includes(term)
      )
    );
  }, [content, searchQuery]);

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      {/* Hero Section */}
      <div className="relative min-h-[400px] sm:min-h-[500px] flex items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1547314283-befb6cc5cf29?q=80&w=2664&auto=format&fit=crop"
            alt=""
            className="w-full h-full object-cover opacity-90 dark:opacity-90"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-gray-900/70 to-gray-900/95"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
          <div className="max-w-3xl">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-4 sm:mb-6">
              <Newspaper className="w-4 h-4 text-primary-400" />
              <span className="text-xs sm:text-sm font-medium text-white">Actualités & Dossiers</span>
            </div>

            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 sm:mb-6">
              Toute l'actualité PVT
            </h1>

            <p className="text-base sm:text-lg text-white/90 leading-relaxed">
              Restez informé des dernières actualités et découvrez nos dossiers thématiques sur les PVT.
            </p>
          </div>
        </div>
      </div>

      <CountryFilter
        countries={budgetCountries}
        selectedCountry={selectedCountry}
        onCountrySelect={handleCountrySelect}
      />

      <main className="flex-1 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Content Type Filter */}
          <div className="flex items-center gap-4 mb-8">
            <button
              onClick={() => setContentType('all')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                contentType === 'all'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <span className="text-sm font-medium">Tout</span>
            </button>
            <button
              onClick={() => setContentType('news')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                contentType === 'news'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <Newspaper className="w-4 h-4" />
              <span className="text-sm font-medium">Actualités</span>
            </button>
            <button
              onClick={() => setContentType('dossiers')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                contentType === 'dossiers'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <Book className="w-4 h-4" />
              <span className="text-sm font-medium">Dossiers</span>
            </button>
          </div>

          <SearchAndSort
            searchQuery={searchQuery}
            onSearchChange={(query) => {
              setSearchQuery(query);
              setCurrentPage(1);
            }}
            sortBy={sortBy}
            onSortChange={(sort) => {
              setSortBy(sort);
              setCurrentPage(1);
              setContent([]);
              setHasMore(true);
            }}
          />

          {isLoading && content.length === 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 animate-fade-in">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="animate-pulse">
                  <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden border border-gray-200/50 dark:border-gray-700/50 shadow-sm">
                    <div className="aspect-[16/9] bg-gray-200 dark:bg-gray-700 relative overflow-hidden">
                      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" style={{ transform: 'translateX(-100%)', animation: 'shimmer 2s infinite' }} />
                    </div>
                    <div className="p-4 space-y-3">
                      <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4 relative overflow-hidden">
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" />
                      </div>
                      <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-lg w-1/2 relative overflow-hidden">
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" />
                      </div>
                      <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-lg w-full relative overflow-hidden">
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" />
                      </div>
                      <div className="flex items-center gap-2 pt-2">
                        <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full relative overflow-hidden">
                          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" />
                        </div>
                        <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-lg w-24 relative overflow-hidden">
                          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 dark:via-gray-700/20 to-transparent animate-shimmer" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : error ? (
            <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
              <p className="text-red-600 dark:text-red-400">{error}</p>
            </div>
          ) : filteredContent.length === 0 ? (
            <div className="text-center py-12">
              <Newspaper className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                {searchQuery
                  ? 'Aucun résultat trouvé'
                  : selectedCountry 
                    ? 'Aucun contenu disponible pour ce pays'
                    : 'Aucun contenu disponible'}
              </h3>
              <p className="text-gray-500 dark:text-gray-400">
                {searchQuery
                  ? 'Essayez de modifier vos critères de recherche'
                  : selectedCountry
                    ? 'Essayez de sélectionner un autre pays ou revenez plus tard'
                    : 'De nouveaux contenus seront bientôt disponibles'}
              </p>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {filteredContent.map((item) => (
                  <ContentCard key={`${item.type}-${item.id}`} content={item} />
                ))}
              </div>

              {/* Loading indicator and observer target */}
              {hasMore && !searchQuery && (
                <div 
                  ref={observerTarget}
                  className="flex justify-center py-8"
                >
                  {isLoading && (
                    <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-500 border-t-transparent"></div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
}