import React from 'react';
import { MessageSquare, Users, ArrowRight } from 'lucide-react';

export function MessageCenterPromo() {
  const handleSignUp = () => {
    // Scroll to top where the sign up button is
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className="py-12 sm:py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-primary-100/20 via-accent-100/20 to-primary-100/20 dark:from-primary-800/10 dark:via-accent-800/10 dark:to-primary-800/10 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl pointer-events-none" />
          
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-0">
            {/* Content Section */}
            <div className="relative p-8 sm:p-12">
              <div className="flex items-center gap-2 text-primary-600 dark:text-primary-400 mb-6">
                <MessageSquare className="w-5 h-5" />
                <span className="text-sm font-medium">Centre de messages</span>
              </div>
              
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
                Un espace d'échange 100% pvtistes
              </h2>
              
              <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
                Posez vos questions, partagez votre expérience et trouvez des réponses utiles grâce à notre communauté grandissante de voyageurs.
              </p>

              <div className="grid sm:grid-cols-2 gap-6 mb-8">
                <div className="flex items-start gap-4">
                  <div className="w-10 h-10 rounded-xl bg-primary-100 dark:bg-primary-900/30 flex items-center justify-center">
                    <Users className="w-5 h-5 text-primary-600 dark:text-primary-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white mb-1">
                      Entraide communautaire
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Bénéficiez de l'expérience des autres pvtistes
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-10 h-10 rounded-xl bg-accent-100 dark:bg-accent-900/30 flex items-center justify-center">
                    <MessageSquare className="w-5 h-5 text-accent-600 dark:text-accent-400" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white mb-1">
                      Discussions par pays
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Échangez avec des voyageurs de votre destination
                    </p>
                  </div>
                </div>
              </div>

             
            </div>

            {/* Image Section */}
            <div className="relative lg:h-full">
              <img
                src="https://images.unsplash.com/photo-1444210971048-6130cf0c46cf?q=80&w=2673&auto=format&fit=crop"
                alt="Groupe de voyageurs"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-black/60 via-black/30 to-transparent lg:from-transparent" />
              
              <div className="absolute inset-0 flex items-center justify-center p-8">
                <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-2xl p-6 text-center">
                  <div className="text-3xl font-bold text-white mb-2">500k+</div>
                  <div className="text-white/90">membres actifs</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}