import React from 'react';
import { Shield, PlayCircle, ArrowRight, CheckCircle2 } from 'lucide-react';

export function GlobePvtPromo() {
  return (
    <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl border border-primary-100/50 dark:border-primary-800/30 overflow-hidden">
      <div className="grid md:grid-cols-2 gap-6">
        {/* Content Section */}
        <div className="p-6">
          <div className="flex items-center gap-2 text-primary-600 dark:text-primary-400 mb-4">
            <Shield className="w-5 h-5" />
            <span className="text-sm font-medium">Recommandé par pvtistes.net</span>
          </div>
          
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Pourquoi prendre l'assurance Globe PVT ?
          </h3>
          
          <div className="space-y-3 mb-6">
            <div className="flex items-start gap-2">
              <CheckCircle2 className="w-5 h-5 text-emerald-500 mt-0.5" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Recommandée par pvtistes.net depuis 20 ans !
              </span>
            </div>
            <div className="flex items-start gap-2">
              <CheckCircle2 className="w-5 h-5 text-emerald-500 mt-0.5" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Remboursement des mois non utilisés
              </span>
            </div>
            <div className="flex items-start gap-2">
              <CheckCircle2 className="w-5 h-5 text-emerald-500 mt-0.5" />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Couverture complète adaptée au PVT
              </span>
            </div>
          </div>

          <a
            href="ttps://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-4 py-2 bg-white/80 dark:bg-gray-800/80 hover:bg-white dark:hover:bg-gray-800 text-primary-600 dark:text-primary-400 rounded-lg transition-colors text-sm font-medium"
          >
            <span>Souscrire maintenant</span>
            <ArrowRight className="w-4 h-4" />
          </a>
        </div>

        {/* Video Section */}
        <div className="relative aspect-video md:aspect-auto md:h-full">
          <img
            src="https://images.unsplash.com/photo-1530230400789-cfc669762b1c?q=80&w=2670&auto=format&fit=crop"
            alt="Globe PVT"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          
          <div className="absolute inset-0 flex flex-col items-center justify-center p-6">
            <a
              href="https://www.youtube.com/watch?v=dEv0Bs2jviY"
              target="_blank"
              rel="noopener noreferrer"
              className="group"
            >
              <div className="relative">
                <div className="w-16 h-16 rounded-full bg-white/20 absolute -inset-4 animate-ping" />
                <div className="w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center relative">
                  <PlayCircle className="w-8 h-8 text-white transition-transform group-hover:scale-110" />
                </div>
              </div>
              <p className="text-white text-center mt-4 text-sm">
                Regarder la vidéo
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}