import React from 'react';
import { Info, ListChecks, Shield } from 'lucide-react';

interface TaskInfoProps {
  createdAt?: Date;
}

export function TaskInfo({ createdAt }: TaskInfoProps) {
  const formatDate = (date?: Date) => {
    if (!date) return null;
    return new Date(date).toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <div className="hidden lg:block relative overflow-hidden bg-gradient-to-br from-primary-50 via-accent-50/50 to-primary-50 dark:from-primary-900/20 dark:via-accent-900/10 dark:to-primary-900/20 rounded-xl border border-primary-100/50 dark:border-primary-800/30 p-6 mb-6">
      <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-primary-100/20 via-accent-100/20 to-primary-100/20 dark:from-primary-800/10 dark:via-accent-800/10 dark:to-primary-800/10 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl pointer-events-none" />
      
      <div className="relative">
        <h3 className="text-xl font-semibold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent mb-3">
          Personnalisez votre checklist
        </h3>

        <p className="text-base text-gray-700 dark:text-gray-200 leading-relaxed">
          Les tâches ont été générées automatiquement en fonction de votre destination. Vous pouvez ajouter des éléments (tâches, mémos ou des notes) selon vos besoins pour qu'elles correspondent parfaitement à votre projet.
        </p>

        <div className="mt-5 flex flex-col sm:flex-row gap-3">
          {createdAt && (
            <div className="flex items-center gap-2 px-4 py-2.5 bg-white/50 dark:bg-gray-800/30 rounded-xl border border-gray-200/50 dark:border-gray-700/30">
              <ListChecks className="w-5 h-5 text-accent-600 dark:text-accent-400" />
              <span className="text-base text-gray-700 dark:text-gray-200">
                Créée le {formatDate(createdAt)}
              </span>
            </div>
          )}

          <div className="flex items-center gap-2 px-4 py-2.5 bg-white/50 dark:bg-gray-800/30 rounded-xl border border-gray-200/50 dark:border-gray-700/30">
            <Shield className="w-5 h-5 text-accent-600 dark:text-accent-400" />
            <span className="text-base text-gray-700 dark:text-gray-200">
              Informations fiables et actualisées par l'équipe pvtistes.net
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}