import React from 'react';
import { Star, Quote, ChevronLeft, ChevronRight } from 'lucide-react';
import { testimonials } from '../../data/testimonials';

export function TestimonialsSection() {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    
    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrevious();
    }
  };

  return (
    <div className="relative py-12 sm:py-16 overflow-hidden">
      <div className="absolute inset-0">
        <img 
          src="https://plus.unsplash.com/premium_photo-1700486007419-ef089e06f403?q=80&w=2664&auto=format&fit=crop"
          alt=""
          className="w-full h-full object-cover opacity-75 dark:opacity-50"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-white/60 via-white/80 to-white dark:from-gray-900/60 dark:via-gray-900/80 dark:to-gray-900" />
      </div>

      <div className="relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Ils nous font confiance
          </h2>
          <p className="text-base sm:text-lg text-gray-600 dark:text-gray-400">
            Découvrez les retours d'expérience de nos assurés
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          {/* Navigation Buttons */}
          <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 flex justify-between pointer-events-none z-10">
            <button
              onClick={handlePrevious}
              className="pointer-events-auto p-1.5 rounded-full bg-white/60 dark:bg-gray-800/60 backdrop-blur-[2px] text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-400 hover:bg-white/80 dark:hover:bg-gray-800/80 transition-all duration-200 -translate-x-1/2 sm:-translate-x-6 opacity-60 hover:opacity-100"
            >
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button
              onClick={handleNext}
              className="pointer-events-auto p-1.5 rounded-full bg-white/60 dark:bg-gray-800/60 backdrop-blur-[2px] text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-400 hover:bg-white/80 dark:hover:bg-gray-800/80 transition-all duration-200 translate-x-1/2 sm:translate-x-6 opacity-60 hover:opacity-100"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>

          {/* Testimonials Slider */}
          <div 
            className="overflow-hidden touch-pan-x"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div 
              className="flex transition-transform duration-500 ease-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={testimonial.id}
                  className="w-full flex-shrink-0 px-4"
                >
                  <div className="relative bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-8 shadow-premium border border-gray-200/50 dark:border-gray-700/50">
                    {/* Quote Icon */}
                    <div className="absolute top-4 sm:top-6 right-4 sm:right-6">
                      <Quote className="w-6 h-6 sm:w-8 sm:h-8 text-gray-200 dark:text-gray-700" />
                    </div>

                    {/* Rating */}
                    <div className="flex items-center gap-1 mb-4 sm:mb-6">
                      {[...Array(5)].map((_, i) => (
                        <Star
                          key={i}
                          className={`w-4 h-4 sm:w-5 sm:h-5 ${
                            i < (testimonial.rating || 5)
                              ? 'text-amber-400'
                              : 'text-gray-300 dark:text-gray-600'
                          }`}
                          fill={i < (testimonial.rating || 5) ? 'currentColor' : 'none'}
                        />
                      ))}
                    </div>

                    {/* Content */}
                    <blockquote className="text-[15px] sm:text-lg text-gray-700 dark:text-gray-300 mb-4 sm:mb-6 leading-relaxed">
                      "{testimonial.content}"
                    </blockquote>

                    {/* Author */}
                    <div className="flex items-center gap-4">
                      <div>
                        <div className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                          {testimonial.author}
                        </div>
                        <div className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                          Assuré·e Globe PVT
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination Dots */}
          <div className="flex justify-center gap-2 mt-6 sm:mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full transition-all duration-300 ${
                  index === currentIndex
                    ? 'bg-primary-500 scale-125'
                    : 'bg-gray-300 dark:bg-gray-600 hover:bg-primary-400 dark:hover:bg-primary-600'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}