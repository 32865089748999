import React from 'react';
import { Country } from '../../types/todo';

interface CountriesGridProps {
  countries: Country[];
  isLoading: boolean;
}

export function CountriesGrid({ countries, isLoading }: CountriesGridProps) {
  const getOptimizedImageUrl = (url: string): string => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname.includes('unsplash.com')) {
        const baseUrl = url.split('?')[0];
        // Optimiser pour les différentes tailles d'écran
        return `${baseUrl}?q=80&w=800&h=600&fit=crop&crop=entropy&auto=format`;
      }
      return url;
    } catch (e) {
      console.error('Invalid image URL:', e);
      return url;
    }
  };

  return (
    <section className="py-8 sm:py-12 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white text-center mb-6 sm:mb-8">
          Destinations disponibles
        </h2>
        {isLoading ? (
          <div className="flex items-center justify-center min-h-[200px]">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 sm:gap-6">
            {countries.map((country) => (
              <div
                key={country.code}
                className="group relative aspect-[4/3] rounded-xl shadow-sm sm:shadow-premium border border-gray-200 dark:border-gray-700 overflow-hidden hover:shadow-premium-hover transition-all duration-300"
              >
                <img
                  src={getOptimizedImageUrl(country.image)}
                  alt={country.name}
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  loading="lazy"
                  decoding="async"
                  fetchpriority="low"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute inset-x-0 bottom-0 p-2 sm:p-4">
                  <div className="flex items-center space-x-2">
                    <span className="text-lg sm:text-2xl">{country.flag}</span>
                    <h3 className="text-sm sm:text-lg font-semibold text-white">
                      {country.name}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}