import React from 'react';
import { Calendar, Plane, ArrowRight, Home, ChevronDown } from 'lucide-react';

interface TravelDatesProps {
  departureDate: string;
  returnDate: string;
  onDepartureDateChange: (date: string) => void;
  onReturnDateChange: (date: string) => void;
}

export function TravelDates({
  departureDate,
  returnDate,
  onDepartureDateChange,
  onReturnDateChange
}: TravelDatesProps) {
  const [isExpanded, setIsExpanded] = React.useState(!departureDate && !returnDate);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const getDuration = () => {
    if (!departureDate || !returnDate) return null;
    const start = new Date(departureDate);
    const end = new Date(returnDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const duration = getDuration();

  return (
    <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-950/50 dark:to-accent-950/50 rounded-xl border border-primary-100/50 dark:border-primary-800/30 overflow-hidden relative">
      <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
      
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative w-full flex items-center justify-between p-4 text-left hover:bg-white/5 dark:hover:bg-black/5 transition-colors"
      >
        <div className="flex items-center space-x-2">
          <Calendar className="w-5 h-5 text-primary-500 dark:text-primary-400" />
          <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
            Dates du voyage
          </h3>
        </div>
        <div className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}>
          <ChevronDown className="w-5 h-5 text-gray-500 dark:text-gray-400" />
        </div>
      </button>

      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{
          maxHeight: isExpanded ? contentRef.current?.scrollHeight : 0,
          opacity: isExpanded ? 1 : 0
        }}
      >
        <div className="p-4 sm:p-6 pt-0">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
            {/* Sélecteurs de dates */}
            <div className="flex flex-col gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Date de départ
                </label>
                <input
                  type="date"
                  value={departureDate}
                  onChange={(e) => onDepartureDateChange(e.target.value)}
                  className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-sm"
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Date de retour
                </label>
                <input
                  type="date"
                  value={returnDate}
                  min={departureDate}
                  onChange={(e) => onReturnDateChange(e.target.value)}
                  className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-sm"
                />
              </div>
            </div>

            {/* Visualisation des dates */}
            {(departureDate || returnDate) && (
              <div className="bg-white dark:bg-gray-800/50 rounded-xl p-4 border border-gray-200 dark:border-gray-700/50 space-y-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="w-8 sm:w-10 h-8 sm:h-10 rounded-full bg-primary-100 dark:bg-primary-900/50 flex items-center justify-center">
                      <Plane className="w-4 sm:w-5 h-4 sm:h-5 text-primary-600 dark:text-primary-400" />
                    </div>
                    <div>
                      <div className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">Départ</div>
                      <div className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                        {formatDate(departureDate) || 'Non défini'}
                      </div>
                    </div>
                  </div>
                  <ArrowRight className="w-4 sm:w-5 h-4 sm:h-5 text-gray-400 dark:text-gray-500 mx-2" />
                  <div className="flex items-center space-x-3">
                    <div className="w-8 sm:w-10 h-8 sm:h-10 rounded-full bg-accent-100 dark:bg-accent-900/50 flex items-center justify-center">
                      <Home className="w-4 sm:w-5 h-4 sm:h-5 text-accent-600 dark:text-accent-400" />
                    </div>
                    <div>
                      <div className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">Retour</div>
                      <div className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                        {formatDate(returnDate) || 'Non défini'}
                      </div>
                    </div>
                  </div>
                </div>

                {duration && (
                  <div className="pt-4 border-t border-gray-200 dark:border-gray-700/50">
                    <div className="text-center">
                      <div className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">Durée du séjour</div>
                      <div className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-primary-600 to-accent-600 bg-clip-text text-transparent">
                        {duration} jours
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}