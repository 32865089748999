import React from 'react';
import { Shield, Check, ArrowRight } from 'lucide-react';

interface WhyChooseSectionProps {
  title: string;
  content: string;
  sections: Array<{
    icon: string;
    title: string;
    description: string;
    list: Array<{
      icon: string;
      content: string;
    }>;
  }>;
}

export function WhyChooseSection({ title, content, sections }: WhyChooseSectionProps) {
  return (
    <div className="relative py-20 sm:py-32 overflow-hidden">
      <div className="absolute inset-0">
        <img 
          src="https://images.unsplash.com/photo-1517760444937-f6397edcbbcd?q=80&w=2670&auto=format&fit=crop"
          alt=""
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/40" />
      </div>
      
      <div className="relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="inline-flex items-center justify-center gap-2 px-4 py-2 rounded-full bg-white/10 dark:bg-gray-800/10 backdrop-blur-sm border border-white/20 dark:border-gray-700/20 mb-6">
              <Shield className="w-5 h-5 text-primary-500" />
              <span className="text-sm font-medium text-white">Une assurance de confiance</span>
            </div>
            <h2 className="text-2xl sm:text-4xl font-bold text-white mb-4">
              Les principaux avantages de Globe PVT !
            </h2>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {/* Avantages principaux */}
            <div className="relative overflow-hidden bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50 hover:shadow-lg transition-shadow duration-300">
              <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-primary-200/20 dark:from-primary-800/10 dark:to-primary-900/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl" />
              
              <div className="relative space-y-6">
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Epidémies & Covid-19 : Frais médicaux, hospitalisation et rapatriement sanitaire couverts.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      L'assurance préférée des pvtistes avec d'excellents témoignages.{' '}
                      <a 
                        href="https://pvtistes.net/assurance-pvt/temoignages/" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Lire les témoignages
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Des tarifs ultra-compétitifs !
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Un contact facile et rapide avec les conseillers de Globe PVT via notre messagerie privée.{' '}
                      <a 
                        href="https://pvtistes.net/mon-profil/messages/?to=44868&m=2" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Envoyer un message privé
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Des indications claires et précises pour utiliser son assurance.{' '}
                      <a 
                        href="https://pvtistes.net/share/assurance/infographie-comment-bien-utiliser-mon-assurance-globe-pvt.pdf" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Télécharger le document
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Avantages secondaires */}
            <div className="relative overflow-hidden bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm rounded-2xl p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50 hover:shadow-lg transition-shadow duration-300">
              <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-accent-100/20 to-accent-200/20 dark:from-accent-800/10 dark:to-accent-900/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl" />
              
              <div className="relative space-y-6">
                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Vos demandes de remboursements de frais médicaux en quelques clics grâce à une interface web dédiée.{' '}
                      <a 
                        href="https://pvtistes.net/se-faire-rembourser-globe-pvt/" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Consulter le tutoriel
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      La couverture de nombreux sports : plongée sous-marine, ski sur piste, surf, randonnée, etc.{' '}
                      <a 
                        href="https://pvtistes.net/assurance-pvt/#assurance-docs" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Lire les conditions
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Possibilité de payer votre assurance Globe PVT en 2, 3 ou 4 fois !{' '}
                      <a 
                        href="https://pvtistes.net/payez-votre-assurance-globe-pvt-en-plusieurs-fois/" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        En savoir plus
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Le remboursement des mois d'assurance non-utilisés en cas de retour définitif, que vous ayez ou non demandé un remboursement de soins pendant votre PVT !{' '}
                      <a 
                        href="https://pvtistes.net/dossiers/assurance-pvt-whv-infos/6/" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        En savoir plus
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Un mois d'assurance Globe PVT/EIC offert si vous ouvrez votre compte en banque canadien chez Desjardins.{' '}
                      <a 
                        href="https://pvtistes.net/bonsplans/banque-quebec-desjardins/" 
                        className="inline-flex items-center gap-1 text-primary-600 hover:text-primary-700 dark:text-primary-400 group"
                      >
                        Plus d'infos
                        <ArrowRight className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-0.5" />
                      </a>
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <Check className="w-4 h-4 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-[15px] text-gray-700 dark:text-gray-300">
                      Avec Globe PVT/EIC Canada : bénéficiez de la téléconsultation médicale 100 % prise en charge par ACS - Assurances voyage et expatriation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}