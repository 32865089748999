import React from 'react';
import { MediaPost as MediaPostType } from '../../types/media';
import { useAuth } from '../../lib/auth-context';
import { likePost, unlikePost, addComment } from '../../lib/media-service';
import { PostHeader } from './post/PostHeader';
import { PostActions } from './post/PostActions';
import { PostMedia } from './post/PostMedia';
import { PostMetadata } from './post/PostMetadata';
import { PostComments } from './post/PostComments';

interface MediaPostProps {
  post: MediaPostType;
  currentUserId: string;
  onUpdate?: () => void;
  onDelete?: (postId: string) => void;
}

export function MediaPost({ post, currentUserId, onUpdate, onDelete }: MediaPostProps) {
  const { userInfo } = useAuth();
  const username = userInfo?.user?.username || '';
  const [localLikes, setLocalLikes] = React.useState(post.likes);
  const [comments, setComments] = React.useState(post.comments);
  const [showComments, setShowComments] = React.useState(false);
  const [showLikes, setShowLikes] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const [newComment, setNewComment] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const isLiked = localLikes.includes(username);
  const likesCount = localLikes.length;

  // Update local state when post data changes
  React.useEffect(() => {
    setComments(post.comments);
    setLocalLikes(post.likes);
  }, [post.comments, post.likes]);

  const handleLike = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      // Update UI immediately
      const newLikes = isLiked
        ? localLikes.filter(like => like !== username)
        : [...localLikes, username];
      setLocalLikes(newLikes);
      
      // Animate heart icon
      const heartIcon = document.querySelector(`#heart-${post.id}`);
      if (heartIcon) {
        heartIcon.classList.add('scale-125');
        setTimeout(() => heartIcon.classList.remove('scale-125'), 200);
      }
      
      // Make API call
      await (isLiked ? unlikePost : likePost)(post.id, username);
    } catch (error) {
      // Revert UI on error
      setLocalLikes(post.likes);
      
      // Show error toast
      const toast = document.createElement('div');
      toast.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-3 rounded-xl shadow-lg border border-red-100 dark:border-red-800/30 z-50 flex items-center gap-3 animate-fade-in';
      toast.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <span>Une erreur est survenue</span>
      `;
      document.body.appendChild(toast);
      setTimeout(() => toast.remove(), 3000);
    }
  };

  const handleComment = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim() || isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      const commentId = await addComment(post.id, {
        userId: currentUserId,
        username: username,
        content: newComment.trim(),
        likes: []
      });
      
      const newCommentObj = {
        id: commentId,
        userId: currentUserId,
        username: username,
        content: newComment.trim(),
        createdAt: new Date(),
        likes: []
      };
      
      setComments(prev => [...prev, newCommentObj]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
      <PostHeader
        username={post.username}
        avatar={post.avatar}
        userId={post.userId}
        currentUserId={currentUserId}
        onDelete={onDelete ? () => onDelete(post.id) : undefined}
      />

      <PostMedia
        type={post.mediaType}
        mediaUrl={post.mediaUrl}
        previewUrl={post.previewUrl}
        thumbnail80Url={post.thumbnail80Url}
        caption={post.caption}
      />

      <div className="p-4">
        <PostActions
          postId={post.id}
          isLiked={isLiked}
          likesCount={likesCount}
          commentsCount={comments.length}
          onLike={handleLike}
          onToggleLikes={() => setShowLikes(!showLikes)}
          onToggleComments={() => setShowComments(!showComments)}
          onReport={() => setShowReportModal(true)}
          showLikes={showLikes}
        />

        {/* Likes List */}
        {showLikes && localLikes.length > 0 && (
          <div className="mb-4 p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
            <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
              Aimé par
            </h4>
            <div className="space-y-2">
              {localLikes.map((username) => (
                <div key={username} className="flex items-center gap-2">
                  <img
                    src={`https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&size=24`}
                    alt=""
                    className="w-6 h-6 rounded-full"
                  />
                  <span className="text-sm text-gray-600 dark:text-gray-300">
                    {username}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        <PostMetadata
          username={post.username}
          caption={post.caption}
          city={post.city}
          country={post.country}
          tags={post.tags}
          createdAt={post.createdAt.toDate()}
        />

        {showComments && (
          <PostComments
            comments={comments}
            onSubmit={handleComment}
            newComment={newComment}
            onCommentChange={setNewComment}
            isSubmitting={isSubmitting}
          />
        )}
      </div>

      {/* Report Modal */}
      {showReportModal && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Signaler ce contenu
              </h3>
            </div>
            <div className="p-4 space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Êtes-vous sûr de vouloir signaler ce contenu ? Notre équipe de modération sera notifiée et examinera ce post.
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowReportModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={() => setShowReportModal(false)}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                >
                  Signaler
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}