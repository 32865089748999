import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, Share2, X, MapPin, GraduationCap, Briefcase } from 'lucide-react';
import { Interview } from '../types/interviews';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { ReadingProgress } from '../components/ReadingProgress';
import { fetchInterviewById } from '../lib/api';

const cleanContent = (content: string): string => {
  if (!content) return '';

  // Clean up styles and formatting
  return content
    .replace(/style="[^"]*color:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*background-color:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*font-weight:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*text-decoration:\s*underline[^";]*;?[^"]*"/g, 'class="custom-underline"')
    .replace(/<\/?(?:b|strong)>/g, '')
    .replace(/<\/?em>/g, '')
    .replace(/<\/?u>/g, '')
    .replace(/<a[^>]*>(<img[^>]*>)<\/a>/g, '$1');
};

const formatSection = (section: { type: string; content: string }, interview: Interview | null) => {
  if (!section || !section.content) return '';

  const cleanedContent = cleanContent(section.content);

  switch (section.type) { 
    case 'question':
      return `<div class="mb-8 relative px-3 sm:px-0">
          <div class="relative bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 p-4 rounded-xl border border-primary-100/50 dark:border-primary-800/30 hover:shadow-sm transition-shadow duration-200 overflow-hidden w-full">
            <div class="absolute top-1/2 right-4 -translate-y-1/2 w-24 h-24 opacity-5">
              <img src="https://pvtistes.net/wp-content/uploads/2024/12/sign_fullcolor.png" alt="" class="w-full h-full object-contain dark:hidden" />
              <img src="https://pvtistes.net/wp-content/uploads/2025/02/sign_white.png" alt="" class="w-full h-full object-contain hidden dark:block" />
            </div>
            <div class="relative">
              <p class="text-base font-medium text-gray-900 dark:text-white leading-relaxed tracking-normal">
                ${cleanedContent}
              </p>
            </div>
          </div>
        </div>`;
    case 'answer':
      if (!interview?.interviewee) return '';
      const avatar = interview?.interviewee?.avatar || `https://ui-avatars.com/api/?name=${interview?.interviewee?.firstname}&background=random`;
      return `<div class="mb-8 px-3 sm:px-0">
        <div class="flex items-start gap-2 sm:gap-3 pl-0 sm:pl-[3.75rem]">
          <div class="hidden sm:block flex-shrink-0 mt-1">
            <img src="${avatar}" alt="${interview?.interviewee?.firstname}" class="w-12 h-12 rounded-full ring-2 ring-gray-200/50 dark:ring-gray-700/50 shadow-sm" />
          </div>
          <div class="relative flex-1 prose prose-sm sm:prose-base dark:prose-invert bg-white dark:bg-gray-800/50 p-3 sm:p-4 rounded-xl border border-gray-200/50 dark:border-gray-700/50 shadow-sm hover:shadow-md transition-shadow duration-200">
            ${cleanedContent}
          </div>
        </div>
      </div>`;
    default:
      return `<div class="mb-8 px-3 sm:px-0">
        <div class="prose prose-base dark:prose-invert bg-gray-50 dark:bg-gray-800/50 p-4 rounded-xl border border-gray-200/50 dark:border-gray-700/50 shadow-sm">
          ${cleanedContent}
        </div>
      </div>`;
  }
};

export function InterviewDetailPage() {
  const { user, userInfo } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [interview, setInterview] = React.useState<Interview | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  React.useEffect(() => {
    const fetchInterview = async () => {
      if (!id || !userInfo) return;
      
      setIsLoading(true);
      setError(null);

      try {
        const data = await fetchInterviewById(id, userInfo);
        setInterview(data);
      } catch (error) {
        console.error('Error fetching interview:', error);
        setError('Impossible de charger l\'interview pour le moment');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchInterview();
    }
    window.scrollTo(0, 0);
  }, [id, user, userInfo]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: interview?.title,
          text: interview?.shortDescription,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Lien copié dans le presse-papier !');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />
      
      <ReadingProgress color="primary" />

      <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-14">
            <button
              onClick={() => navigate('/interviews')}
              className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span className="font-medium">Retour aux récits</span>
            </button>
            {interview && (
              <div className="flex items-center gap-4">
                <button
                  onClick={handleShare}
                  className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  <Share2 className="w-5 h-5" />
                  <span className="hidden sm:inline font-medium">Partager</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <main className="flex-1 pt-32">
        {isLoading ? (
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50">
              <div className="p-4 sm:p-6 md:p-8 lg:p-12">
                <div className="animate-pulse space-y-8">
                  {/* Title skeleton */}
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4" />
                  
                  {/* Content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                  
                  {/* Image skeleton */}
                  <div className="aspect-video bg-gray-200 dark:bg-gray-700 rounded-xl" />
                  
                  {/* More content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : error || !interview ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
              <p className="text-red-600 dark:text-red-400">{error || 'Interview non trouvée'}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="relative h-[300px] sm:h-[400px]">
              <img
                src={interview.cover}
                alt={interview.title}
                className="absolute inset-0 w-full h-full object-cover"
                loading="eager"
                fetchpriority="high"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  if (!target.src.includes('fallback')) {
                    target.src = 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2670&auto=format&fit=crop';
                  }
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/70 to-black/80" />
              
              <div className="absolute inset-x-0 bottom-0 p-6 sm:p-8">
                <div className="max-w-3xl mx-auto">
                  <div className="flex flex-wrap items-center gap-4 text-white/80 mb-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      <span className="text-sm">
                        {format(parseISO(interview.created), 'dd MMMM yyyy', { locale: fr })}
                      </span>
                    </div>
                  </div>

                  <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 leading-tight">
                    {interview.title}
                  </h1>

                  <div className="flex items-center gap-4">
                    <img
                      src={interview.interviewee.avatar || `https://ui-avatars.com/api/?name=${interview.interviewee.firstname}&background=random`}
                      alt={interview.interviewee.firstname}
                      className="w-12 h-12 rounded-full ring-2 ring-white/20"
                    />
                    <div>
                      <span className="block text-white font-medium text-base">
                        {interview.interviewee.firstname} {interview.interviewee.lastname}
                      </span>
                      <div className="flex flex-wrap items-center gap-x-3 gap-y-1.5 mt-1">
                        {interview.interviewee.location && (
                          <div className="flex items-center gap-1.5">
                            <MapPin className="w-3.5 h-3.5 text-white/70" />
                            <span className="text-xs sm:text-sm text-white/70">
                              {interview.interviewee.location}
                            </span>
                          </div>
                        )}
                        {interview.interviewee.occupation && (
                          <div className="flex items-center gap-1.5">
                            <Briefcase className="w-3.5 h-3.5 text-white/70" />
                            <span className="text-xs sm:text-sm text-white/70">
                              {interview.interviewee.occupation}
                            </span>
                          </div>
                        )}
                        {interview.interviewee.diploma && (
                          <div className="flex items-center gap-1.5">
                            <GraduationCap className="w-3.5 h-3.5 text-white/70" />
                            <span className="text-xs sm:text-sm text-white/70">
                              {interview.interviewee.diploma}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-4xl mx-auto px-2 sm:px-6 lg:px-8 py-4 sm:py-12">
              <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50">
                <div className="p-3 sm:p-6 md:p-8 lg:p-12">
                  <div 
                    ref={contentRef}
                    className="prose prose-base sm:prose-lg dark:prose-invert mx-auto space-y-4 sm:space-y-6 max-w-none"
                    dangerouslySetInnerHTML={{ 
                      __html: interview.sections?.filter(section => section && section.content)
                        .map(section => formatSection(section, interview))
                        .join('') || ''
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </main>

      <Footer />
    </div>
  );
}