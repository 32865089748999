import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Send, Flag, MoreVertical } from 'lucide-react';
import { Comment } from '../../../types/media';

interface PostCommentsProps {
  comments: Comment[];
  onSubmit: (e: React.FormEvent) => void;
  newComment: string;
  onCommentChange: (value: string) => void;
  isSubmitting: boolean;
}

const COMMENTS_PER_PAGE = 5;

export function PostComments({
  comments,
  onSubmit,
  newComment,
  onCommentChange,
  isSubmitting
}: PostCommentsProps) {
  const commentsContainerRef = React.useRef<HTMLDivElement>(null);
  const [commentToReport, setCommentToReport] = React.useState<Comment | null>(null);
  const [showOptionsForComment, setShowOptionsForComment] = React.useState<string | null>(null);
  const [displayedComments, setDisplayedComments] = React.useState<Comment[]>([]);
  const [page, setPage] = React.useState(1);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);

  // Initialize with first page of comments
  React.useEffect(() => {
    setDisplayedComments(comments.slice(0, COMMENTS_PER_PAGE));
  }, [comments]);

  // Handle scroll to load more
  React.useEffect(() => {
    const container = commentsContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      if (isLoadingMore) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      // Load more when user scrolls to bottom (with 20px threshold)
      if (scrollHeight - scrollTop - clientHeight < 20) {
        loadMoreComments();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, [isLoadingMore, page, comments]);

  const loadMoreComments = async () => {
    if (displayedComments.length >= comments.length) return;

    setIsLoadingMore(true);
    // Simulate loading delay
    await new Promise(resolve => setTimeout(resolve, 500));

    const nextPage = page + 1;
    const newComments = comments.slice(0, nextPage * COMMENTS_PER_PAGE);
    setDisplayedComments(newComments);
    setPage(nextPage);
    setIsLoadingMore(false);
  };

  // Update displayed comments when new comment is added
  React.useEffect(() => {
    if (comments.length > displayedComments.length) {
      setDisplayedComments(comments.slice(0, page * COMMENTS_PER_PAGE));
    }
  }, [comments.length]);

  const handleReport = async (comment: Comment) => {
    try {
      setCommentToReport(comment);
    } catch (error) {
      console.error('Error reporting comment:', error);
    }
  };

  return (
    <div className="mt-4 space-y-4">
      <div 
        ref={commentsContainerRef}
        className="max-h-48 overflow-y-auto space-y-3 custom-scrollbar"
      >
        {displayedComments.map(comment => (
          <div key={comment.id} className="flex items-start gap-2 group">
            <img
              src={comment.avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(comment.username)}&background=random&size=24`}
              alt={comment.username}
              className="w-6 h-6 rounded-full ring-2 ring-gray-200/50 dark:ring-gray-700/50"
              loading="lazy"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(comment.username)}&background=random&size=24`;
              }}
            />
            <div className="flex-1">
              <div className="flex items-start justify-between gap-2">
                <div>
                  <span className="font-medium text-gray-900 dark:text-white">
                    {comment.username}
                  </span>
                  <span className="ml-2 text-gray-600 dark:text-gray-300">
                    {comment.content}
                  </span>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {comment.createdAt && formatDistanceToNow(
                      comment.createdAt instanceof Date 
                        ? comment.createdAt 
                        : comment.createdAt.toDate?.() || new Date(comment.createdAt),
                      { addSuffix: true, locale: fr }
                    )}
                  </p>
                </div>
                <div className="relative">
                  <button
                    onClick={() => setShowOptionsForComment(showOptionsForComment === comment.id ? null : comment.id)}
                    className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 rounded-lg transition-colors opacity-0 group-hover:opacity-100"
                  >
                    <MoreVertical className="w-4 h-4" />
                  </button>
                  {showOptionsForComment === comment.id && (
                    <div className="absolute right-0 mt-1 w-48 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-10">
                      <button
                        onClick={() => {
                          handleReport(comment);
                          setShowOptionsForComment(null);
                        }}
                        className="w-full flex items-center gap-2 px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50 dark:hover:bg-red-900/20"
                      >
                        <Flag className="w-4 h-4" />
                        <span>Signaler</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {isLoadingMore && (
          <div className="flex justify-center py-2">
            <div className="w-6 h-6 border-2 border-primary-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>

      <form onSubmit={onSubmit} className="relative">
        <input
          type="text"
          value={newComment}
          onChange={(e) => onCommentChange(e.target.value)}
          placeholder="Ajouter un commentaire..."
          className="w-full pr-12 pl-4 py-2 bg-gray-50 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white text-sm"
        />
        <button
          type="submit"
          disabled={!newComment.trim() || isSubmitting}
          className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 text-primary-500 hover:text-primary-600 dark:text-primary-400 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
        >
          <Send className="w-4 h-4" />
        </button>
      </form>

      {/* Report Comment Modal */}
      {commentToReport && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Signaler ce commentaire
              </h3>
            </div>
            <div className="p-4 space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Êtes-vous sûr de vouloir signaler ce commentaire ? Notre équipe de modération sera notifiée et l'examinera.
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setCommentToReport(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={() => {
                    // TODO: Implement report logic
                    setCommentToReport(null);
                    // Show success message
                    const toast = document.createElement('div');
                    toast.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 px-6 py-3 rounded-xl shadow-lg border border-emerald-100 dark:border-emerald-800/30 z-50 flex items-center gap-3 animate-fade-in';
                    toast.innerHTML = `
                      <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <span>Commentaire signalé</span>
                    `;
                    document.body.appendChild(toast);
                    setTimeout(() => toast.remove(), 3000);
                  }}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                >
                  Signaler
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}