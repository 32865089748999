import React from 'react';
import { X } from 'lucide-react';

interface PrivacyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function PrivacyModal({ isOpen, onClose }: PrivacyModalProps) {
  if (!isOpen) return null;

  const currentDate = new Date().toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium w-full max-w-4xl h-[90vh] flex flex-col">
        {/* Header - Fixed */}
        <div className="flex-shrink-0 flex items-center justify-between p-4 border-b dark:border-gray-700">
          <h2 className="text-lg font-bold text-gray-900 dark:text-white">
            Politique de Confidentialité
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Content - Scrollable */}
        <div className="flex-1 overflow-y-auto custom-scrollbar">
          <div className="p-4">
            <div className="prose prose-sm sm:prose dark:prose-invert max-w-none">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Dernière mise à jour : {currentDate}
              </p>

              <p className="text-sm sm:text-base">
                La protection de vos données personnelles est une priorité pour nous. Cette politique de confidentialité 
                explique comment nous collectons, utilisons et protégeons vos informations lorsque vous utilisez app.pvtistes.net.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">1. Qui sommes-nous ?</h3>
              <p className="text-sm sm:text-base">
                L'interface app.pvtistes.net est exploitée par Yoodac Web Agency, une société immatriculée en Ontario, Canada. 
                Nous faisons partie de la même équipe derrière pvtistes.net et whvaustralie.com.
              </p>
              <p className="text-sm sm:text-base">
                Pour toute question relative à cette politique, vous pouvez nous contacter via le{' '}
                <a 
                  href="https://pvtistes.net/forum/sendmessage.php" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-primary-600 hover:text-primary-700 dark:text-primary-400"
                >
                  formulaire de contact
                </a>.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">2. Quelles données collectons-nous ?</h3>
              <p className="text-sm sm:text-base">
                Nous collectons uniquement les informations nécessaires au bon fonctionnement de l'interface :
              </p>
              <ul className="text-sm sm:text-base">
                <li>Adresse e-mail : utilisée pour sauvegarder et gérer vos checklists.</li>
                <li>Checklists enregistrées : associées à votre compte pour vous permettre de les retrouver.</li>
                <li>Données techniques : cookies et logs de connexion pour améliorer l'expérience utilisateur et assurer la sécurité du site.</li>
              </ul>

              <h3 className="text-base sm:text-lg font-semibold">3. Pourquoi collectons-nous ces données ?</h3>
              <p className="text-sm sm:text-base">
                Vos données sont collectées dans les objectifs suivants :
              </p>
              <ul className="text-sm sm:text-base">
                <li>Permettre la création et la gestion de vos checklists.</li>
                <li>Garantir le bon fonctionnement du site.</li>
                <li>Améliorer l'expérience utilisateur grâce à des statistiques anonymes.</li>
              </ul>
              <p className="text-sm sm:text-base">
                Nous ne partageons aucune donnée personnelle avec des tiers sans votre consentement.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">4. Où sont stockées vos données ?</h3>
              <p className="text-sm sm:text-base">
                Vos données sont stockées sur des serveurs sécurisés. Nous appliquons des mesures de protection 
                pour garantir leur confidentialité et leur intégrité.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">5. Cookies et technologies de suivi</h3>
              <p className="text-sm sm:text-base">
                Nous utilisons des cookies pour améliorer votre expérience de navigation. Vous pouvez gérer vos 
                préférences en matière de cookies via les paramètres de votre navigateur.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">6. Vos droits</h3>
              <p className="text-sm sm:text-base">Vous avez le droit de :</p>
              <ul className="text-sm sm:text-base">
                <li>Accéder à vos données et en obtenir une copie.</li>
                <li>Demander la modification ou la suppression de vos données.</li>
                <li>Vous opposer à l'utilisation de vos données.</li>
              </ul>
              <p className="text-sm sm:text-base">
                Pour exercer ces droits, vous pouvez nous contacter via le{' '}
                <a 
                  href="https://pvtistes.net/forum/sendmessage.php" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-primary-600 hover:text-primary-700 dark:text-primary-400"
                >
                  formulaire de contact
                </a>.
              </p>

              <h3 className="text-base sm:text-lg font-semibold">7. Modifications de la politique de confidentialité</h3>
              <p className="text-sm sm:text-base">
                Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. 
                Toute mise à jour sera publiée sur cette page.
              </p>
            </div>
          </div>
        </div>

        {/* Footer - Fixed */}
        <div className="flex-shrink-0 p-4 border-t dark:border-gray-700 bg-gray-50 dark:bg-gray-800/50">
          <button
            onClick={onClose}
            className="w-full sm:w-auto px-6 py-2.5 bg-primary-600 hover:bg-primary-700 text-white text-sm font-medium rounded-xl transition-colors"
          >
            J'ai compris
          </button>
        </div>
      </div>
    </div>
  );
}