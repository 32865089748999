import React from 'react';
import { MessageCenter } from './MessageCenter/MessageCenter';
import { useAuth } from '../lib/auth-context';

interface FloatingDiscussionProps {
  countryCode?: string;
}

export function FloatingDiscussion({ countryCode }: FloatingDiscussionProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { user } = useAuth();

  // Listen for custom event to open message center
  React.useEffect(() => {
    const handleOpenMessageCenter = () => {
      setIsOpen(true);
    };

    window.addEventListener('openMessageCenter', handleOpenMessageCenter);
    return () => window.removeEventListener('openMessageCenter', handleOpenMessageCenter);
  }, []);

  // If user is not authenticated, don't render anything
  if (!user) return null;

  return (
    <MessageCenter 
      isOpen={isOpen} 
      onClose={() => setIsOpen(false)}
      countryCode={countryCode}
    />
  );
}