import React from 'react';
import { X, CheckCircle2, Clock, XCircle, Paperclip, StickyNote, MousePointerClick } from 'lucide-react';

interface GuideModalProps {
  isOpen: boolean;
  onClose: () => void;
  isIntroGuide?: boolean;
}

export function GuideModal({ isOpen, onClose, isIntroGuide = false }: GuideModalProps) {
  const [countdown, setCountdown] = React.useState(8);
  const [canClose, setCanClose] = React.useState(!isIntroGuide);

  React.useEffect(() => {
    if (isIntroGuide && isOpen) {
      setCountdown(8);
      setCanClose(false);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setCanClose(true);
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isIntroGuide, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
          <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
            {isIntroGuide ? 'Bienvenue dans votre checklist !' : 'Guide d\'utilisation'}
          </h2>
          {canClose && (
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          )}
        </div>
        
        <div className="p-4 space-y-6">
          {/* États des tâches */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
              États des tâches
            </h3>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center">
                  <CheckCircle2 className="w-4 h-4 text-emerald-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">Terminé</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    La tâche est complètement achevée
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-amber-50 dark:bg-amber-900/20 flex items-center justify-center">
                  <Clock className="w-4 h-4 text-amber-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">En cours</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Vous avez commencé cette tâche
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-red-50 dark:bg-red-900/20 flex items-center justify-center">
                  <XCircle className="w-4 h-4 text-red-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">À faire</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    La tâche est en attente
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Gestion des tâches */}
          <div>
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
              Personnalisation
            </h3>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-blue-50 dark:bg-blue-900/20 flex items-center justify-center">
                  <MousePointerClick className="w-4 h-4 text-blue-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">Changer l'état</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Cliquez sur l'icône pour changer le statut
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-purple-50 dark:bg-purple-900/20 flex items-center justify-center">
                  <Paperclip className="w-4 h-4 text-purple-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">Sous-tâche</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                   Ajoutez d'autres points à votre liste personnalisée
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="w-8 h-8 rounded-lg bg-pink-50 dark:bg-pink-900/20 flex items-center justify-center">
                  <StickyNote className="w-4 h-4 text-pink-500" />
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">Notes</span>
                  <p className="text-xs text-gray-600 dark:text-gray-400">
                    Ajoutez des notes détaillées
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 border-t dark:border-gray-700">
            <button
              onClick={onClose}
              disabled={!canClose}
              className={`relative w-full px-4 py-3 bg-primary-600 text-white rounded-lg transition-all duration-300 ${
                canClose 
                  ? 'hover:bg-primary-700 cursor-pointer' 
                  : 'opacity-80 cursor-not-allowed'
              }`}
            >
              <span className="relative z-10">
                {isIntroGuide 
                  ? canClose 
                    ? "C'est parti ! 🚀" 
                    : `Veuillez patienter (${countdown}s)`
                  : "J'ai compris !"}
              </span>
              {isIntroGuide && !canClose && (
                <div 
                  className="absolute inset-0 bg-primary-500 rounded-lg transition-all duration-1000 ease-out"
                  style={{ 
                    width: `${(countdown / 8) * 100}%`,
                    opacity: 0.5
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}