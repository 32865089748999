import React from 'react';
import { HelpCircle, ChevronDown, ChevronUp, CheckCircle2, Calculator, ListChecks, MessageSquare } from 'lucide-react';

interface FaqItem {
  question: string;
  answer: string;
  category: 'checklist' | 'budget';
}

const faqs: FaqItem[] = [
  {
    category: 'checklist',
    question: "Qu'est-ce que la checklist PVT ?",
    answer: "La checklist PVT est un outil interactif conçu pour vous aider à organiser toutes les étapes de votre départ et installation à l'étranger en PVT. Elle vous permet de suivre vos démarches avant le départ, à l'arrivée et au retour."
  },
  {
    category: 'checklist',
    question: "Comment utiliser la checklist ?",
    answer: "C'est simple ! Sélectionnez votre destination, cochez les tâches complétées, ajoutez vos propres sous-tâches et suivez votre progression en temps réel."
  },
  {
    category: 'checklist',
    question: "Puis-je personnaliser ma checklist ?",
    answer: "Oui ! Vous pouvez ajouter des sous-tâches, modifier leur priorité et mettre des notes pour vous organiser au mieux."
  },
  {
    category: 'checklist',
    question: "La checklist est-elle accessible hors ligne ?",
    answer: "Non, la checklist nécessite une connexion internet pour être accessible. Cependant, vous pouvez ajouter la web app à l'écran d'accueil de votre téléphone pour un accès rapide lorsque vous êtes en ligne."
  },
  {
    category: 'checklist',
    question: "Puis-je partager ma checklist avec quelqu'un ?",
    answer: "Pas encore, mais nous travaillons sur une fonctionnalité de partage."
  },
  {
    category: 'budget',
    question: "Comment fonctionne le simulateur de budget ?",
    answer: "Notre simulateur vous permet d'estimer vos dépenses avant et pendant votre PVT. Il prend en compte votre ville d'arrivée, le type de logement, les dépenses mensuelles et bien plus encore."
  },
  {
    category: 'budget',
    question: "D'où viennent les données utilisées dans le simulateur ?",
    answer: "Les montants affichés sont des estimations basées sur l'API Numbeo, reconnue pour sa fiabilité. Les coûts réels peuvent varier en fonction de votre situation, de la période et de la zone géographique."
  },
  {
    category: 'budget',
    question: "Comment sont gérées les conversions de devises ?",
    answer: "Les conversions sont effectuées en temps réel en utilisant le taux de change actuel pour vous donner une estimation au plus proche de la réalité."
  },
  {
    category: 'budget',
    question: "Puis-je enregistrer mon budget et y revenir plus tard ?",
    answer: "Oui, vous pouvez tout à fait enregistrer jusqu'à 4 budgets différents, ce qui vous permet de les comparer à tout moment."
  },
  {
    category: 'budget',
    question: "Comment puis-je ajuster mon budget en fonction de mes dépenses réelles ?",
    answer: "Vous pouvez modifier les montants suggérés et ajouter des dépenses supplémentaires pour personnaliser votre budget selon vos besoins."
  }
];

export function FaqSection() {
  const [activeCategory, setActiveCategory] = React.useState<'checklist' | 'budget'>('checklist');
  const [openQuestions, setOpenQuestions] = React.useState<Set<string>>(new Set());

  const toggleQuestion = (question: string) => {
    setOpenQuestions(prev => {
      const next = new Set(prev);
      if (next.has(question)) {
        next.delete(question);
      } else {
        next.add(question);
      }
      return next;
    });
  };

  const filteredFaqs = faqs.filter(faq => faq.category === activeCategory);

  return (
    <section className="relative py-12 sm:py-16 overflow-hidden">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 hidden sm:block">
        <img 
          src="https://plus.unsplash.com/premium_photo-1677002240252-af3f88114efc?q=80&w=2625&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="w-full h-full object-cover opacity-75 dark:opacity-40"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-white/40 via-white/60 to-white dark:from-gray-900/40 dark:via-gray-900/60 dark:to-gray-900" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-12">
          <div className="inline-flex items-center justify-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-primary-50 dark:bg-primary-900/20 mb-4 sm:mb-6">
            <HelpCircle className="w-5 h-5 text-primary-500" />
            <span className="text-sm font-medium text-primary-600 dark:text-primary-400">FAQ</span>
          </div>
          <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 dark:text-white mb-3 sm:mb-4">
            Questions fréquentes
          </h2>
          <p className="text-base sm:text-lg text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Tout ce que vous devez savoir sur nos outils pour préparer votre PVT sereinement
          </p>
        </div>

        {/* Category Selector */}
        <div className="flex justify-center mb-6 sm:mb-8">
          <div className="flex flex-col sm:flex-row p-1 bg-white dark:bg-gray-800 rounded-xl shadow-sm w-full sm:w-auto">
            <button
              onClick={() => setActiveCategory('checklist')}
              className={`flex items-center justify-center gap-2 px-3 sm:px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 w-full sm:w-auto mb-1 sm:mb-0 ${
                activeCategory === 'checklist'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <ListChecks className="w-4 h-4" />
              <span>Checklist PVT</span>
            </button>
            <button
              onClick={() => setActiveCategory('budget')}
              className={`flex items-center justify-center gap-2 px-3 sm:px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 w-full sm:w-auto ${
                activeCategory === 'budget'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <Calculator className="w-4 h-4" />
              <span>Simulateur de Budget</span>
            </button>
          </div>
        </div>

        {/* FAQ Grid */}
        <div className="grid gap-3 sm:gap-6 max-w-3xl mx-auto">
          {filteredFaqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200 dark:border-gray-700 overflow-hidden group"
            >
              <button
                onClick={() => toggleQuestion(faq.question)}
                className="w-full text-left px-4 sm:px-6 py-3 sm:py-4 flex items-center justify-between gap-3 sm:gap-4"
              >
                <span className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                  {faq.question}
                </span>
                <div className={`p-1 rounded-lg transition-colors ${
                  openQuestions.has(faq.question)
                    ? 'bg-primary-50 dark:bg-primary-900/20'
                    : 'bg-gray-100 dark:bg-gray-700'
                }`}>
                  {openQuestions.has(faq.question) ? (
                    <ChevronUp className={`w-4 h-4 ${
                      openQuestions.has(faq.question)
                        ? 'text-primary-500'
                        : 'text-gray-400'
                    }`} />
                  ) : (
                    <ChevronDown className={`w-4 h-4 ${
                      openQuestions.has(faq.question)
                        ? 'text-primary-500'
                        : 'text-gray-400'
                    }`} />
                  )}
                </div>
              </button>
              
              <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openQuestions.has(faq.question) ? 'max-h-96' : 'max-h-0'
                }`}
              >
                <div className="px-4 sm:px-6 pb-4">
                  <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                    {faq.answer}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}