import React, { memo } from 'react';
import { Calculator, ArrowRight, Globe2, Database } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useTheme } from '../../lib/theme-context';

// Memoize phone mockup images to prevent unnecessary re-renders
const PhoneMockup = memo(({ theme, position }: { theme: 'light' | 'dark'; position: 'first' | 'second' }) => {
  const imageUrl = position === 'first'
    ? theme === 'dark'
      ? "https://pvtistes.net/wp-content/uploads/2025/02/budget1-sombre.jpg"
      : "https://pvtistes.net/wp-content/uploads/2025/02/budget1.jpg"
    : theme === 'dark'
      ? "https://pvtistes.net/wp-content/uploads/2025/02/budget2-sombre.jpg"
      : "https://pvtistes.net/wp-content/uploads/2025/02/budget1.jpg";

  return (
    <div className={`relative w-[240px] sm:w-[280px] aspect-[9/19] bg-black rounded-[3rem] p-3 shadow-2xl ${
      position === 'first' ? '-rotate-6 translate-x-4' : 'rotate-6 -translate-x-4'
    }`}>
      <div className="absolute inset-0 rounded-[3rem] border-[14px] border-gray-900">
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-1/2 h-6 bg-black rounded-b-3xl" />
      </div>
      
      <div className="relative w-full h-full rounded-[2rem] overflow-hidden bg-gray-50">
        <img 
          src={imageUrl}
          alt="Budget app screenshot"
          className="absolute inset-0 w-full h-full object-cover"
          loading="lazy"
          decoding="async"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
        
        {position === 'first' ? (
          <div className="absolute inset-x-0 bottom-0 p-4">
            <div className="space-y-4">
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs font-medium text-white">Budget mensuel</span>
                  <span className="text-base font-bold text-white">1,850 €</span>
                </div>
                <div className="h-1.5 bg-white/20 rounded-full overflow-hidden">
                  <div className="w-3/4 h-full bg-primary-500" />
                </div>
              </div>

              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs font-medium text-white">Économies</span>
                  <span className="text-base font-bold text-white">5,000 €</span>
                </div>
                <div className="h-1.5 bg-white/20 rounded-full overflow-hidden">
                  <div className="w-1/2 h-full bg-accent-500" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="absolute inset-x-0 bottom-0 p-4">
            <div className="space-y-4">
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs font-medium text-white">Mode nomade</span>
                  <span className="text-base font-bold text-white">🏕️</span>
                </div>
                <div className="flex items-center gap-2 text-xs text-white/80">
                  <span>Van</span>
                  <span>•</span>
                  <span>Auberges</span>
                  <span>•</span>
                  <span>Volontariat</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export function BudgetSimulatorPromo() {
  const { theme } = useTheme();

  return (
    <section className="py-12 sm:py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 dark:from-gray-800 dark:to-gray-900 rounded-2xl shadow-premium">
          {/* Logo Overlay - New */}
          <div className="absolute top-0 right-0 w-32 sm:w-40 h-auto p-4 z-10">
            <div className="relative">
              <div className="absolute inset-0 bg-white/10 backdrop-blur-sm rounded-lg"></div>
              <img 
                src={theme === 'dark' 
                  ? "https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png"
                  : "https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png"
                }
                alt="PVTistes Logo"
                className="relative w-full h-auto"
              />
            </div>
          </div>

          <div className="absolute inset-0">
            <img 
              src="https://images.unsplash.com/photo-1543183501-7df0144a6753?q=80&w=2670&auto=format&fit=crop"
              alt="Background pattern"
              className="w-full h-full object-cover opacity-10"
            />
          </div>

          <div className="relative grid lg:grid-cols-2 gap-8 lg:gap-0">
            {/* Content Section */}
            <div className="p-8 sm:p-12">
              <div className="flex items-center gap-2 text-primary-400 mb-6">
                <Calculator className="w-5 h-5" />
                <span className="text-sm font-medium">Nouveau</span>
              </div>
              
              <h2 className="text-2xl sm:text-3xl font-bold text-white mb-4">
                Simulateur de budget PVT
              </h2>
              
              <p className="text-base sm:text-lg text-gray-300 mb-8">
                Planifiez votre budget PVT avec précision grâce à notre simulateur alimenté par des données réelles et actualisées. Mode nomade disponible avec options d'hébergement flexibles : auberges, van, volontariat !
              </p>

              {/* Mobile Features */}
              <div className="grid grid-cols-2 gap-4 mb-8 lg:hidden">
                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                  <div className="flex items-center gap-2 mb-2">
                    <Database className="w-4 h-4 text-primary-400" />
                    <span className="text-sm font-medium text-white">Données réelles</span>
                  </div>
                  <p className="text-xs text-gray-400">Prix à jour pour tous les styles de voyage</p>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                  <div className="flex items-center gap-2 mb-2">
                    <span className="text-xl">🏕️</span>
                    <span className="text-sm font-medium text-white">Mode nomade</span>
                  </div>
                  <p className="text-xs text-gray-400">Auberges, van, volontariat</p>
                </div>
              </div>

              {/* Desktop Features */}
              <div className="hidden lg:grid sm:grid-cols-2 gap-6 mb-8">
                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                  <div className="flex items-start gap-4">
                    <Database className="w-5 h-5 text-primary-400" />
                    <div>
                      <h3 className="font-medium text-white mb-1">Données réelles</h3>
                      <p className="text-sm text-gray-400">Prix à jour pour tous les styles de voyage</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                  <div className="flex items-start gap-4">
                    <span className="text-xl">🏕️</span>
                    <div>
                      <h3 className="font-medium text-white mb-1">Mode nomade</h3>
                      <p className="text-sm text-gray-400">Auberges, van, volontariat</p>
                    </div>
                  </div>
                </div>

                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                  <div className="flex items-start gap-4">
                    <Globe2 className="w-5 h-5 text-primary-400" />
                    <div>
                      <h3 className="font-medium text-white mb-1">Tous les pays PVT</h3>
                      <p className="text-sm text-gray-400">Compatible avec toutes les destinations</p>
                    </div>
                  </div>
                </div>
              </div>

             
            </div>

            {/* Phone Mockup Section - Hidden on mobile */}
            <div className="relative p-8 hidden lg:flex items-center justify-center gap-4">
              <PhoneMockup theme={theme} position="first" />
              <PhoneMockup theme={theme} position="second" />

              {/* Decorative Elements */}
              <div className="absolute inset-0 pointer-events-none">
                <div className="absolute top-1/4 right-1/4 w-24 h-24 bg-primary-500/20 rounded-full blur-2xl" />
                <div className="absolute bottom-1/4 left-1/4 w-32 h-32 bg-accent-500/20 rounded-full blur-2xl" />
              </div>
            </div>

            {/* Mobile Screenshot - Only visible on mobile */}
            <div className="lg:hidden relative h-64 overflow-hidden rounded-b-2xl">
              <img
                src={theme === 'dark' 
                  ? "https://pvtistes.net/wp-content/uploads/2025/02/budget1-sombre.jpg"
                  : "https://pvtistes.net/wp-content/uploads/2025/02/budget1.jpg"}
                alt="Budget simulator preview"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/30 to-transparent" />
              <div className="absolute inset-x-0 bottom-0 p-4">
                <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-xs font-medium text-white">Budget mensuel</span>
                    <span className="text-base font-bold text-white">1,850 €</span>
                  </div>
                  <div className="h-1.5 bg-white/20 rounded-full overflow-hidden">
                    <div className="w-3/4 h-full bg-primary-500" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
