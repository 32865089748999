import React from 'react';
import { Globe2, ChevronDown } from 'lucide-react';
import { Country } from '../../types/todo';

interface CountryFilterProps {
  countries: Country[];
  selectedCountry: string | null;
  onCountrySelect: (countryCode: string | null) => void;
}

const getCountryFlagUrl = (countryCode: string): string => {
  const countryCodeMap: { [key: string]: string } = {
    'aus': 'au',
    'nz': 'nz',
    'ca': 'ca',
    'jpn': 'jp',
    'kor': 'kr',
    'twn': 'tw',
    'arg': 'ar',
    'chl': 'cl',
    'col': 'co',
    'mex': 'mx',
    'per': 'pe',
    'uru': 'uy',
    'bra': 'br',
    'hkg': 'hk',
    'fra': 'fr',
    'ecu': 'ec'
  };
  
  const isoCode = countryCodeMap[countryCode.toLowerCase()] || countryCode.toLowerCase();
  return `https://flagcdn.com/w20/${isoCode}.png`;
};

export function CountryFilter({ countries, selectedCountry, onCountrySelect }: CountryFilterProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedCountryData = React.useMemo(() => {
    if (!selectedCountry) return null;
    
    const apiToInternalCode: { [key: string]: string } = {
      'australie': 'aus',
      'nouvelle-zelande': 'nz',
      'canada': 'ca',
      'japon': 'jpn',
      'coree-du-sud': 'kor',
      'taiwan': 'twn',
      'argentine': 'arg',
      'chilie': 'chl',
      'colombie': 'col',
      'mexique': 'mex',
      'perou': 'per',
      'uruguay': 'uru',
      'bresil': 'bra',
      'hong-kong': 'hkg',
      'france': 'fra',
      'equateur': 'ecu'
    };

    const internalCode = apiToInternalCode[selectedCountry] || selectedCountry;
    return countries.find(c => c.code.toLowerCase() === internalCode.toLowerCase());
  }, [selectedCountry, countries]);

  return (
    <div className="relative py-6 sm:py-8 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center gap-3 mb-4">
          <Globe2 className="w-6 h-6 text-primary-500" />
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Filtrer par pays
          </h2>
        </div>

        <div className="relative" ref={dropdownRef}>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full sm:w-auto flex items-center justify-between gap-3 p-4 rounded-xl border bg-gray-50 dark:bg-gray-700 border-gray-200 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-600 transition-all"
          >
            <div className="flex items-center gap-3">
              {selectedCountryData ? (
                <>
                  <img
                    src={getCountryFlagUrl(selectedCountryData.code)}
                    alt={selectedCountryData.name}
                    className="w-5 h-auto rounded-sm shadow-sm"
                  />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    {selectedCountryData.name}
                  </span>
                </>
              ) : (
                <>
                  <Globe2 className="w-5 h-5 text-gray-400 dark:text-gray-500" />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Tous les pays
                  </span>
                </>
              )}
            </div>
            <ChevronDown className={`w-5 h-5 text-gray-500 dark:text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
          </button>

          {isOpen && (
            <div className="absolute z-50 w-full sm:w-72 mt-2 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700">
              <div className="max-h-96 overflow-y-auto custom-scrollbar">
                <button
                  onClick={() => {
                    onCountrySelect(null);
                    setIsOpen(false);
                  }}
                  className={`w-full flex items-center gap-3 px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors ${
                    !selectedCountry ? 'bg-primary-50 dark:bg-primary-900/20' : ''
                  }`}
                >
                  <Globe2 className={`w-5 h-5 ${
                    !selectedCountry 
                      ? 'text-primary-500 dark:text-primary-400'
                      : 'text-gray-400 dark:text-gray-500'
                  }`} />
                  <span className={`text-sm font-medium ${
                    !selectedCountry
                      ? 'text-primary-700 dark:text-primary-300'
                      : 'text-gray-700 dark:text-gray-300'
                  }`}>
                    Tous les pays
                  </span>
                </button>

                <div className="h-px bg-gray-200 dark:bg-gray-700 mx-4 my-2" />

                {countries.map((country) => (
                  <button
                    key={country.code}
                    onClick={() => {
                      onCountrySelect(country.code);
                      setIsOpen(false);
                    }}
                    className={`w-full flex items-center gap-3 px-4 py-3 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors ${
                      selectedCountryData?.code === country.code
                        ? 'bg-primary-50 dark:bg-primary-900/20'
                        : ''
                    }`}
                  >
                    <img
                      src={getCountryFlagUrl(country.code)}
                      alt={country.name}
                      className="w-5 h-auto rounded-sm shadow-sm"
                      loading="lazy"
                    />
                    <span className={`text-sm font-medium ${
                      selectedCountryData?.code === country.code
                        ? 'text-primary-700 dark:text-primary-300'
                        : 'text-gray-700 dark:text-gray-300'
                    }`}>
                      {country.name}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}