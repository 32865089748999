import React from 'react';
import { TaskPriority as TaskPriorityType } from '../types/todo';
import { Flag } from 'lucide-react';

interface TaskPriorityProps {
  priority?: TaskPriorityType;
  isPreview: boolean;
  onClick: () => void;
}

export function TaskPriority({ priority = 'low', isPreview, onClick }: TaskPriorityProps) {
  const getPriorityLabel = (priority: TaskPriorityType) => {
    switch (priority) {
      case 'high':
        return 'Haute';
      case 'medium':
        return 'Moyenne';
      case 'low':
        return 'Basse';
    }
  };

  const getPriorityStyles = (priority: TaskPriorityType) => {
    switch (priority) {
      case 'high':
        return 'bg-red-100 dark:bg-red-900/20 text-red-600 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-900/30';
      case 'medium':
        return 'bg-amber-100 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 hover:bg-amber-200 dark:hover:bg-amber-900/30';
      case 'low':
        return 'bg-emerald-100 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 hover:bg-emerald-200 dark:hover:bg-emerald-900/30';
    }
  };

  if (isPreview) {
    return (
      <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${getPriorityStyles(priority)}`}>
        <Flag className="w-3 h-3 mr-1" />
        {getPriorityLabel(priority)}
      </span>
    );
  }

  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium transition-colors ${getPriorityStyles(priority)}`}
    >
      <Flag className="w-3 h-3 mr-1" />
      {getPriorityLabel(priority)}
    </button>
  );
}