import React from 'react';
import { MessageSquare, Users, Clock, ArrowRight } from 'lucide-react';
import { collection, query, orderBy, limit, getDocs, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

export function MessageCenterPromo() {
  const [stats, setStats] = React.useState({
    totalMessages: 0,
    lastMessageTime: null as Date | null,
    activeUsers: 0
  });
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchStats = async () => {
      try {
        const messagesRef = collection(db, 'messages');
        
        // Query using the existing index
        const latestMessageQuery = query(
          messagesRef,
          where('thread_code', '==', 'general_discussion'),
          where('status', '==', 'active'),
          orderBy('created_at', 'desc'),
          limit(1)
        );
        
        // Get total messages and unique users
        const activeMessagesQuery = query(
          messagesRef,
          where('status', '==', 'active')
        );

        // Execute queries
        const [latestMessageSnapshot, totalMessagesSnapshot] = await Promise.all([
          getDocs(latestMessageQuery),
          getDocs(activeMessagesQuery)
        ]);

        const lastMessage = latestMessageSnapshot.docs[0];
        const uniqueUsers = new Set(
          totalMessagesSnapshot.docs.map(doc => doc.data().user_id)
        );

        setStats({
          totalMessages: totalMessagesSnapshot.size,
          lastMessageTime: lastMessage ? lastMessage.data().created_at.toDate() : null,
          activeUsers: uniqueUsers.size
        });
      } catch (error) {
        console.error('Error fetching message stats:', error);
        setStats({
          totalMessages: 0,
          lastMessageTime: null,
          activeUsers: 0
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  const formatLastMessageTime = (date: Date) => {
    return formatDistanceToNow(date, { addSuffix: true, locale: fr });
  };

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 p-4 animate-pulse">
        <div className="h-32 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
      </div>
    );
  }

  return (
    <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-900 to-gray-800 dark:from-gray-800 dark:to-gray-900">
      {/* Background image with overlay */}
      <div className="absolute inset-0">
        <img 
          src="https://images.unsplash.com/photo-1539635278303-d4002c07eae3?q=80&w=2670&auto=format&fit=crop"
          alt="Community background"
          className="w-full h-full object-cover"
          loading="lazy"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent" />
      </div>

      <div className="relative p-4 sm:p-6 md:p-8">
        <div className="max-w-2xl">
          {/* Header - Responsive text sizes */}
          <div className="flex flex-col sm:flex-row sm:items-center gap-3 mb-6 sm:mb-8">
            <div className="p-2 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20 w-fit">
              <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
            </div>
            <div>
              <h3 className="text-xl sm:text-2xl font-bold text-white">
                Centre de messages
              </h3>
              <p className="text-sm sm:text-base text-white/80">
                Échangez avec la communauté pvtistes
              </p>
            </div>
          </div>

          {/* Stats - Responsive grid */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 sm:gap-4 mb-6 sm:mb-8">
            {/* Messages stat */}
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 sm:p-4 border border-white/20">
              <div className="flex sm:flex-col items-center sm:text-center gap-3 sm:gap-2">
                <MessageSquare className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
                <div>
                  <p className="text-lg sm:text-2xl font-bold text-white">
                    {stats.totalMessages}
                  </p>
                  <p className="text-xs sm:text-sm text-white/80">
                    Messages
                  </p>
                </div>
              </div>
            </div>

            {/* Users stat */}
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 sm:p-4 border border-white/20">
              <div className="flex sm:flex-col items-center sm:text-center gap-3 sm:gap-2">
                <Users className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
                <div>
                  <p className="text-lg sm:text-2xl font-bold text-white">
                    {stats.activeUsers}
                  </p>
                  <p className="text-xs sm:text-sm text-white/80">
                    Participants
                  </p>
                </div>
              </div>
            </div>

            {/* Last message stat */}
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 sm:p-4 border border-white/20">
              <div className="flex sm:flex-col items-center sm:text-center gap-3 sm:gap-2">
                <Clock className="w-5 h-5 sm:w-6 sm:h-6 text-white" />
                <div>
                  <p className="text-xs sm:text-sm text-white/80">
                    Dernier message
                  </p>
                  <p className="text-xs sm:text-sm font-medium text-white">
                    {stats.lastMessageTime 
                      ? formatLastMessageTime(stats.lastMessageTime)
                      : 'Aucun message'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Description and CTA - Responsive text and spacing */}
          <div className="space-y-4 sm:space-y-6">
            <p className="text-base sm:text-lg text-white/90 leading-relaxed">
              Posez vos questions, partagez votre expérience et trouvez des réponses utiles grâce à notre communauté grandissante de voyageurs.
            </p>

            <button
              onClick={() => window.dispatchEvent(new CustomEvent('openMessageCenter'))}
              className="w-full sm:w-auto inline-flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 bg-white/20 hover:bg-white/30 text-white text-sm sm:text-base rounded-xl backdrop-blur-sm transition-all duration-300 group border border-white/20"
            >
              <span>Rejoindre la discussion</span>
              <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 transform transition-transform group-hover:translate-x-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}