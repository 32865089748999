// Taux horaires minimums par pays (en monnaie locale)
export const MINIMUM_WAGES: { [key: string]: { rate: number; currency: string; } } = {
  'aus': { rate: 21.38, currency: 'AUD' }, // Australie
  'nz': { rate: 23.15, currency: 'NZD' },  // Nouvelle-Zélande (depuis avril 2024)
  'ca': { rate: 15.55, currency: 'CAD' },  // Canada (Ontario)
  'jpn': { rate: 1161, currency: 'JPY' },  // Japon (Tokyo, depuis octobre 2023)
  'kor': { rate: 9620, currency: 'KRW' },  // Corée du Sud
  'twn': { rate: 182, currency: 'TWD' },   // Taiwan (depuis janvier 2024)
  'arg': { rate: 1147.58, currency: 'ARS' }, // Argentine
  'chl': { rate: 3800, currency: 'CLP' },  // Chili
  'col': { rate: 4687, currency: 'COP' },  // Colombie
  'mex': { rate: 207.44, currency: 'MXN' }, // Mexique
  'per': { rate: 5.58, currency: 'PEN' },  // Pérou
  'uru': { rate: 140, currency: 'UYU' },   // Uruguay
  'bra': { rate: 6.48, currency: 'BRL' },  // Brésil
  'hkg': { rate: 40, currency: 'HKD' },    // Hong Kong
  'fra': { rate: 11.65, currency: 'EUR' }, // France (depuis janvier 2024)
  'ecu': { rate: 2.50, currency: 'USD' }   // Équateur (en USD)
};