import React from 'react';
import { Users } from 'lucide-react';
import { MediaPost } from './MediaPost';
import { MediaPost as MediaPostType } from '../../types/media';
import { getPosts, deletePost } from '../../lib/media-service';

interface MediaFeedProps {
  userId?: string;
  currentUserId: string;
  refreshKey?: number;
  filters?: {
    query: string;
    country?: string;
    city?: string;
    tags: string[];
    mediaType?: 'image' | 'video';
    sortBy: 'date' | 'popularity';
  };
}

const POSTS_PER_PAGE = 6;

export function MediaFeed({ userId, currentUserId, refreshKey, filters }: MediaFeedProps) {
  const [posts, setPosts] = React.useState<MediaPostType[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [localRefreshKey, setLocalRefreshKey] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const observerTarget = React.useRef<HTMLDivElement>(null);

  // Filter posts based on search criteria
  const filteredPosts = React.useMemo(() => {
    if (!filters) return posts;
    
    return posts.filter(post => {
      // Filter by country
      if (filters.country && post.country?.toLowerCase() !== filters.country.toLowerCase()) {
        return false;
      }
      
      // Filter by media type
      if (filters.mediaType && post.mediaType !== filters.mediaType) {
        return false;
      }

      // Filter by tags
      if (filters.tags.length > 0) {
        const hasMatchingTag = filters.tags.some(tag => post.tags.includes(tag));
        if (!hasMatchingTag) return false;
      }
      
      return true;
    });
  }, [posts, filters]);

  // Sort posts
  const sortedPosts = React.useMemo(() => {
    if (!filters || filters.sortBy === 'date') {
      return filteredPosts;
    }

    return [...filteredPosts].sort((a, b) => {
      // Calculate engagement score: likes count more than comments
      const aScore = (a.likes?.length || 0) * 2 + (a.comments?.length || 0);
      const bScore = (b.likes?.length || 0) * 2 + (b.comments?.length || 0);
      return bScore - aScore;
    });
  }, [filteredPosts, filters?.sortBy]);

  // Fetch posts with pagination
  const fetchPosts = React.useCallback(async (pageNumber: number) => {
    try {
      setIsLoadingMore(true);
      const fetchedPosts = await getPosts(userId);
      
      // Apply pagination
      const start = 0;
      const end = pageNumber * POSTS_PER_PAGE;
      const paginatedPosts = fetchedPosts.slice(start, end);
      
      // Check if there are more posts to load
      setHasMore(end < fetchedPosts.length);
      
      // Update posts state
      setPosts(paginatedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setError('Impossible de charger les posts');
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  }, [userId]);

  // Initial load
  React.useEffect(() => {
    setIsLoading(true);
    setPage(1);
    fetchPosts(1);
  }, [fetchPosts, refreshKey, localRefreshKey]);

  // Infinite scroll observer
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoadingMore) {
          setPage(prev => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [hasMore, isLoadingMore]);

  // Load more posts when page changes
  React.useEffect(() => {
    if (page > 1) {
      fetchPosts(page);
    }
  }, [page, fetchPosts]);

  const handlePostUpdate = () => {
    setLocalRefreshKey(prev => prev + 1);
  };

  const handleDeletePost = async (postId: string) => {
    try {
      // Find post to delete
      const postToDelete = posts.find(p => p.id === postId);
      if (!postToDelete) {
        throw new Error('Post not found');
      }
      
      // Check if current user is the post owner
      if (postToDelete.userId !== currentUserId) {
        throw new Error('Vous n\'êtes pas autorisé à supprimer ce post');
      }
      
      // Delete post and wait for completion
      await deletePost(postId);
      
      // Update local state after successful deletion
      const updatedPosts = posts.filter(post => post.id !== postId);
      setPosts(updatedPosts);
      
      // Show success message
      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 px-6 py-3 rounded-xl shadow-lg border border-emerald-100 dark:border-emerald-800/30 z-50 flex items-center gap-3 animate-fade-in';
      alert.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Post supprimé avec succès</span>
      `;
      document.body.appendChild(alert);
      setTimeout(() => alert.remove(), 3000);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Erreur lors de la suppression du post'; 
      console.error('Error deleting post:', errorMessage);
      
      // Show error message
      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-3 rounded-xl shadow-lg border border-red-100 dark:border-red-800/30 z-50 flex items-center gap-3 animate-fade-in';
      alert.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
        <span>${errorMessage}</span>
      `;
      document.body.appendChild(alert);
      setTimeout(() => alert.remove(), 5000);
    }
  };

  if (isLoading && posts.length === 0) {
    return (
      <div className="space-y-6">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <div className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden border border-gray-200/50 dark:border-gray-700/50 shadow-sm">
              <div className="p-4 flex items-center gap-3">
                <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full" />
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-24" />
              </div>
              <div className="aspect-square bg-gray-200 dark:bg-gray-700" />
              <div className="p-4 space-y-3">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  if (sortedPosts.length === 0) {
    return (
      <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-8 text-center">
        <div className="flex flex-col items-center gap-4">
          <Users className="w-12 h-12 text-gray-400" />
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-1">
              {filters?.tags.length 
                ? 'Aucun post trouvé avec ces tags'
                : filters?.country
                ? 'Aucun post pour ce pays'
                : 'Aucun post pour le moment'}
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              {filters?.tags.length 
                ? 'Essayez de sélectionner d\'autres tags'
                : filters?.country
                ? 'Soyez le premier à partager un moment dans ce pays'
                : 'Soyez le premier à partager un moment'}
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Posts */}
      <div className="space-y-6">
        {sortedPosts.map(post => (
          <div 
            key={post.id}
            className="opacity-0 animate-fade-in"
            style={{
              animationDelay: '100ms',
              animationFillMode: 'forwards'
            }}
          >
            <MediaPost
              key={post.id}
              post={post}
              currentUserId={currentUserId}
              onUpdate={handlePostUpdate}
              onDelete={handleDeletePost}
            />
          </div>
        ))}
      </div>

      {/* Loading indicator and observer target */}
      {hasMore && (
        <div 
          ref={observerTarget}
          className="flex justify-center py-8"
        >
          {isLoadingMore && (
            <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-500 border-t-transparent"></div>
          )}
        </div>
      )}
    </div>
  );
}