import React from 'react';
import { MessageSquare, Bug, Globe, MapPin } from 'lucide-react';
import { MessageThread } from './types';
import { getCountriesFromFirebase } from '../../lib/firebase-service';

interface ThreadSelectorProps {
  activeThread: MessageThread;
  onThreadChange: (thread: MessageThread) => void;
  countryCode?: string;
}

export function ThreadSelector({ activeThread, onThreadChange, countryCode }: ThreadSelectorProps) {
  const [countries, setCountries] = React.useState<{code: string; name: string}[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const loadCountries = async () => {
      try {
        const countriesData = await getCountriesFromFirebase();
        const sortedCountries = countriesData
          .map(c => ({ code: c.code, name: c.name }))
          .sort((a, b) => a.name.localeCompare(b.name, 'fr'));
        setCountries(sortedCountries);
      } catch (error) {
        console.error('Error loading countries:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadCountries();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-32">
        <div className="animate-spin rounded-full h-6 w-6 border-2 border-primary-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* General Threads */}
      <div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
          Général
        </h3>
        <div className="space-y-1">
          <button
            onClick={() => onThreadChange({ code: 'general_discussion', type: 'general' })}
            className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-200 ${
              activeThread.code === 'general_discussion'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <Globe className="w-4 h-4" />
            <span className="text-sm font-medium">Discussion générale</span>
          </button>

          <button
            onClick={() => onThreadChange({ code: 'bug_report', type: 'bug' })}
            className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-200 ${
              activeThread.code === 'bug_report'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
            }`}
          >
            <Bug className="w-4 h-4" />
            <span className="text-sm font-medium">Signaler un bug</span>
          </button>
        </div>
      </div>

      {/* Country Threads */}
      <div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-3">
          Par pays
        </h3>
        <div className="space-y-1">
          {countries.map((country) => (
            <button
              key={country.code}
              onClick={() => onThreadChange({ code: country.code, type: 'country' })}
              className={`w-full flex items-center gap-3 px-4 py-3 rounded-xl transition-all duration-200 ${
                activeThread.code === country.code
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <MapPin className="w-4 h-4" />
              <span className="text-sm font-medium">{country.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}