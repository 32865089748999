import React from 'react';
import { TravelProfile } from '../../types/matching';
import { MatchingCard } from './MatchingCard';
import { Users } from 'lucide-react';

interface MatchingListProps {
  profiles: TravelProfile[];
  onLike: (profile: TravelProfile) => void;
  onPass: (profile: TravelProfile) => void;
  onMessage?: (profile: TravelProfile) => void;
  isLoading?: boolean;
  error?: string | null;
}

export function MatchingList({ 
  profiles, 
  onLike, 
  onPass, 
  onMessage,
  isLoading,
  error 
}: MatchingListProps) {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 overflow-hidden animate-pulse">
            <div className="relative aspect-[4/5] bg-gray-200 dark:bg-gray-700">
              <div className="absolute inset-0 bg-gradient-to-br from-gray-300 to-gray-200 dark:from-gray-600 dark:to-gray-700 animate-shimmer" />
            </div>
            <div className="p-4 space-y-4">
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-full w-3/4" />
              <div className="space-y-2">
                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-full w-1/2" />
                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded-full w-3/4" />
              </div>
              <div className="flex gap-2">
                {[...Array(3)].map((_, j) => (
                  <div key={j} className="h-6 w-16 bg-gray-200 dark:bg-gray-700 rounded-full" />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 sm:p-8 text-center">
        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 rounded-full bg-red-100 dark:bg-red-900/30 flex items-center justify-center">
            <Users className="w-8 h-8 text-red-500 dark:text-red-400" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-red-900 dark:text-red-300 mb-2">
              Une erreur est survenue
            </h3>
            <p className="text-red-600 dark:text-red-400 max-w-md mx-auto">
              {error}
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (profiles.length === 0) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 p-8 sm:p-12 text-center">
        <div className="flex flex-col items-center gap-6">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 to-accent-500/10 dark:from-primary-500/5 dark:to-accent-500/5 blur-2xl" />
            <div className="relative w-16 h-16 mb-4 bg-gradient-to-br from-primary-100 to-accent-100 dark:from-primary-900/30 dark:to-accent-900/30 rounded-2xl flex items-center justify-center transform transition-transform hover:scale-105">
              <Users className="w-8 h-8 text-primary-500 dark:text-primary-400" />
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-3">
              Aucun profil pour le moment
            </h3>
            <p className="text-base text-gray-600 dark:text-gray-400 max-w-sm mx-auto">
              Modifiez vos critères de recherche ou revenez plus tard pour découvrir de nouveaux profils.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 animate-fade-in">
      {/* Afficher d'abord le profil de l'utilisateur */}
      {profiles
        .filter(profile => profile.isOwnProfile)
        .map((profile) => (
          <MatchingCard
            key={profile.id}
            profile={profile}
            onLike={() => onLike(profile)}
            onPass={() => onPass(profile)}
            onMessage={onMessage ? () => onMessage(profile) : undefined}
            similarity={75}
            isOwnProfile={true}
          />
        ))}

      {/* Afficher ensuite les autres profils */}
      {profiles.map((profile) => (
        !profile.isOwnProfile && (
        <MatchingCard
          key={profile.id}
          profile={profile}
          onLike={() => onLike(profile)}
          onPass={() => onPass(profile)}
          onMessage={onMessage ? () => onMessage(profile) : undefined}
          similarity={75} // TODO: Calculate actual similarity
          isOwnProfile={profile.isOwnProfile}
        />
        )
      ))}
    </div>
  );
}