import React from 'react';
import { Search, ArrowUpDown, ChevronDown } from 'lucide-react';
import { SortOption } from '../../types/dossiers';

interface SearchAndSortProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  sortBy: SortOption;
  onSortChange: (sort: SortOption) => void;
}

export function SearchAndSort({ searchQuery, onSearchChange, sortBy, onSortChange }: SearchAndSortProps) {
  const [showSortMenu, setShowSortMenu] = React.useState(false);

  return (
    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
      <div className="relative flex-1 max-w-lg">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Rechercher un dossier..."
          className="w-full pl-10 pr-4 py-2.5 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
        />
      </div>

      <div className="relative">
        <button
          onClick={() => setShowSortMenu(!showSortMenu)}
          className="flex items-center gap-2 px-4 py-2.5 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
        >
          <ArrowUpDown className="w-4 h-4 text-gray-500" />
          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
            Trier par {sortBy === 'date' ? 'date' : 'popularité'}
          </span>
          <ChevronDown className="w-4 h-4 text-gray-400" />
        </button>

        {showSortMenu && (
          <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 py-1 z-10">
            <button
              onClick={() => {
                onSortChange('date');
                setShowSortMenu(false);
              }}
              className="w-full px-4 py-2.5 text-left text-sm hover:bg-gray-50 dark:hover:bg-gray-700/50 text-gray-700 dark:text-gray-300"
            >
              Date de publication
            </button>
            <button
              onClick={() => {
                onSortChange('popularity');
                setShowSortMenu(false);
              }}
              className="w-full px-4 py-2.5 text-left text-sm hover:bg-gray-50 dark:hover:bg-gray-700/50 text-gray-700 dark:text-gray-300"
            >
              Popularité
            </button>
          </div>
        )}
      </div>
    </div>
  );
}