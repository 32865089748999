import React from 'react';
import { Phone, Info, CreditCard, Users, Ambulance, Mail, ChevronDown, ChevronUp } from 'lucide-react';

export function EmergencyInstructions() {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="py-8 sm:py-16 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-white dark:bg-gray-800 rounded-xl sm:rounded-2xl p-4 sm:p-6 shadow-premium border border-gray-200/50 dark:border-gray-700/50">
          <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/10 to-accent-100/10 dark:from-primary-800/5 dark:to-accent-800/5 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
          
          <div className="relative">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="w-full flex items-center justify-between gap-3 group"
            >
              <div className="flex items-center gap-3">
                <div className="p-2 sm:p-3 bg-primary-50 dark:bg-primary-900/20 rounded-lg sm:rounded-xl">
                  <Ambulance className="w-5 h-5 sm:w-6 sm:h-6 text-primary-500 dark:text-primary-400" />
                </div>
                <div>
                  <h2 className="text-lg sm:text-2xl font-bold text-gray-900 dark:text-white text-left">
                    En cas d'urgence médicale
                  </h2>
                  <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 mt-1">
                    Cliquez pour {isExpanded ? 'masquer' : 'afficher'} les instructions
                  </p>
                </div>
              </div>
              <div className="p-1.5 sm:p-2 bg-gray-100 dark:bg-gray-700 rounded-full transition-colors group-hover:bg-gray-200 dark:group-hover:bg-gray-600">
                {isExpanded ? (
                  <ChevronUp className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                )}
              </div>
            </button>

            <div className={`space-y-6 transition-all duration-300 ease-in-out overflow-hidden ${
              isExpanded ? 'mt-8 max-h-[2000px] opacity-100' : 'max-h-0 opacity-0'
            }`}>
              {/* Étape 1 */}
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-lg sm:rounded-xl p-3 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                    <span className="text-sm font-bold text-primary-600 dark:text-primary-400">1</span>
                  </div>
                  <div>
                    <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-white">
                      J'appelle le numéro des urgences du pays où je me trouve et je suis leurs instructions.
                    </p>
                  </div>
                </div>
              </div>

              {/* Étape 2 */}
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                    <span className="text-sm font-bold text-primary-600 dark:text-primary-400">2</span>
                  </div>
                  <div>
                    <p className="text-base font-medium text-gray-900 dark:text-white mb-4">
                      Je contacte la plateforme d'assistance Mutuaide (24h/24 et 7j/7)
                    </p>
                    <div className="space-y-4">
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                          <Phone className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-700 dark:text-gray-300">Globe PVT/EIC Canada</p>
                          <p className="text-base font-bold text-primary-600 dark:text-primary-400">+33 (0)1 55 98 71 73</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                          <Phone className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-700 dark:text-gray-300">Globe PVT / WHV reste du monde</p>
                          <p className="text-base font-bold text-primary-600 dark:text-primary-400">+33 (0)1 55 98 71 30</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                          <Mail className="w-4 h-4 text-primary-500 dark:text-primary-400" />
                        </div>
                        <a 
                          href="mailto:medical@mutuaide.fr"
                          className="text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300"
                        >
                          medical@mutuaide.fr
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Carte d'assuré */}
              <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                    <CreditCard className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                  </div>
                  <div>
                    <p className="text-base font-medium text-gray-900 dark:text-white">
                      Je garde toujours sur moi la carte d'assuré Globe PVT que le personnel soignant peut utiliser pour contacter Mutuaide.
                    </p>
                  </div>
                </div>
              </div>

              {/* Hospitalisation */}
              <div className="space-y-4">
                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                      <Info className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 dark:text-white mb-3">
                        Si je reste hospitalisé·e plus de 24h
                      </h3>
                      <ul className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Contacter Mutuaide pour ouvrir un dossier</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Étude de la possibilité de prise en charge directe des soins</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Si la situation médicale est conforme aux conditions du contrat : aucun frais avancé et possibilité de rapatriement médical si nécessaire</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                      <Info className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 dark:text-white mb-3">
                        Si je reste hospitalisé·e moins de 24h
                      </h3>
                      <ul className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Je paie les frais demandés</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Demande de remboursement via la plateforme sécurisée de remboursement en ligne (pour les frais inférieurs à 500 €) ou par courrier (pour les frais supérieurs à 500 €) via le formulaire en ligne</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-primary-50 dark:bg-primary-900/30 flex items-center justify-center">
                      <Users className="w-4 h-4 text-primary-600 dark:text-primary-400" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 dark:text-white mb-3">
                        En cas d'hospitalisation prolongée (+7 jours) sans accompagnement familial
                      </h3>
                      <ul className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Demande possible pour la venue d'un proche</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <div className="w-1 h-1 rounded-full bg-primary-500/50 dark:bg-primary-400/50" />
                          <span>Prise en charge du billet aller-retour et de l'hébergement par Globe PVT</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}