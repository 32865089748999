import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface BudgetData {
  monthlyExpenses: number;
  preDepartureCosts: number;
  setupCosts: number;
  totalBudget: number;
  monthlyBreakdown: Array<{
    category: string;
    amount: number;
  }>;
  preDepartureBreakdown: Array<{
    category: string;
    amount: number;
  }>;
  setupBreakdown: Array<{
    category: string;
    amount: number;
  }>;
  data: {
    city: string;
    country: string;
    pvtDuration: string;
    initialSavings: string;
  };
}

export function formatBudgetForEmail(budget: BudgetData): string {
  const formatPrice = (amount: number) => {
    return amount.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  const formatDate = () => {
    return format(new Date(), 'dd MMMM yyyy', { locale: fr });
  };

  const getProgressBarColor = (amount: number, target: number) => {
    const percentage = (amount / target) * 100;
    if (percentage >= 100) return '#10b981'; // emerald-500
    if (percentage >= 75) return '#f59e0b'; // amber-500
    return '#ef4444'; // red-500
  };

  const progressBarColor = getProgressBarColor(
    Number(budget.data.initialSavings),
    budget.totalBudget
  );

  const getStatusColor = (amount: number, target: number) => {
    if (amount >= target) return '#10b981'; // emerald-500
    if (amount >= target * 0.75) return '#f59e0b'; // amber-500
    return '#ef4444'; // red-500
  };

  const savingsColor = getStatusColor(
    Number(budget.data.initialSavings),
    budget.totalBudget
  );

  return `<div style="text-align: center; margin-bottom: 30px; background: linear-gradient(to right, #EF4136, #0DB6DB); color: white; padding: 24px; border-radius: 16px; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
    <h1 style="color: white; font-size: 24px; margin-bottom: 12px; font-weight: 600;">Budget prévisionnel PVT - ${budget.data.city}</h1>
    <p style="color: rgba(255, 255, 255, 0.9); font-size: 14px;">Généré le ${formatDate()}</p>
  </div>

  <div style="background: white; border-radius: 16px; padding: 24px; margin: 24px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
    <h2 style="color: #1f2937; font-size: 18px; margin: 0 0 16px; font-weight: 600;">Résumé</h2>
    <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Durée prévue :</span>
      <span style="display: table-cell; text-align: right; font-weight: 500; color: #1f2937;">${budget.data.pvtDuration} mois</span>
    </div>
    <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Budget total requis :</span>
      <span style="display: table-cell; text-align: right; font-weight: 500; color: #EF4136;">${formatPrice(budget.totalBudget)}</span>
    </div>
    <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Économies actuelles :</span>
      <span style="display: table-cell; text-align: right; font-weight: 500; color: #1f2937;">${formatPrice(Number(budget.data.initialSavings))}</span>
    </div>
    <div style="text-align: center; margin-top: 16px;">
      <div style="display: inline-block; padding: 4px 12px; border-radius: 9999px; font-size: 14px; font-weight: 500; color: white; background: ${savingsColor}; margin-top: 16px;">
        ${Number(budget.data.initialSavings) >= budget.totalBudget 
          ? 'Budget atteint ✨' 
          : `Il manque ${formatPrice(budget.totalBudget - Number(budget.data.initialSavings))}`}
      </div>
      <div style="width: 100%; height: 8px; background: #f3f4f6; border-radius: 9999px; margin-top: 16px; overflow: hidden;">
        <div style="height: 100%; background: ${progressBarColor}; width: ${Math.min((Number(budget.data.initialSavings) / budget.totalBudget) * 100, 100)}%;"></div>
      </div>
    </div>
  </div>

  <div style="background: white; border-radius: 16px; padding: 24px; margin: 24px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
    <h2 style="color: #1f2937; font-size: 18px; margin: 0 0 16px; font-weight: 600;">Dépenses mensuelles</h2>
    ${budget.monthlyBreakdown
      .map(item => `
        <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
          <span style="display: table-cell; color: #4b5563; font-size: 15px;">${item.category} :</span>
          <span style="display: table-cell; text-align: right; font-weight: 500; color: #1f2937;">${formatPrice(item.amount)}</span>
        </div>
      `).join('')}
    <div style="display: table; width: 100%; padding: 12px 0; margin-top: 16px; border-top: 2px solid #f3f4f6; font-weight: 600;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Total mensuel :</span>
      <span style="display: table-cell; text-align: right; color: #EF4136;">${formatPrice(budget.monthlyExpenses)}</span>
    </div>
  </div>

  <div style="background: white; border-radius: 16px; padding: 24px; margin: 24px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
    <h2 style="color: #1f2937; font-size: 18px; margin: 0 0 16px; font-weight: 600;">Dépenses avant le départ</h2>
    <div style="background: #f8fafc; border-radius: 8px; padding: 12px; margin: 8px 0;">
      <div style="display: flex; align-items: center; gap: 8px;">
        <span style="color: #0DB6DB;">💡</span>
        <span style="font-size: 13px; color: #64748b;">
          Conseil : Protégez votre PVT avec Globe PVT, l'assurance recommandée par pvtistes.net depuis 2005.
          <a href="https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa" style="color: #EF4136; text-decoration: none;">En savoir plus</a>
        </span>
      </div>
    </div>
    ${budget.preDepartureBreakdown
      .map(item => `
        <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
          <span style="display: table-cell; color: #4b5563; font-size: 15px;">${item.category} :</span>
          <span style="display: table-cell; text-align: right; font-weight: 500; color: #1f2937;">${formatPrice(item.amount)}</span>
        </div>
      `).join('')}
    <div style="display: table; width: 100%; padding: 12px 0; margin-top: 16px; border-top: 2px solid #f3f4f6; font-weight: 600;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Total avant départ :</span>
      <span style="display: table-cell; text-align: right; color: #EF4136;">${formatPrice(budget.preDepartureCosts)}</span>
    </div>
  </div>

  <div style="background: white; border-radius: 16px; padding: 24px; margin: 24px 0; box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.1);">
    <h2 style="color: #1f2937; font-size: 18px; margin: 0 0 16px; font-weight: 600;">Dépenses d'installation</h2>
    ${budget.setupBreakdown
      .map(item => `
        <div style="display: table; width: 100%; padding: 12px 0; border-bottom: 1px solid #f3f4f6;">
          <span style="display: table-cell; color: #4b5563; font-size: 15px;">${item.category} :</span>
          <span style="display: table-cell; text-align: right; font-weight: 500; color: #1f2937;">${formatPrice(item.amount)}</span>
        </div>
      `).join('')}
    <div style="display: table; width: 100%; padding: 12px 0; margin-top: 16px; border-top: 2px solid #f3f4f6; font-weight: 600;">
      <span style="display: table-cell; color: #4b5563; font-size: 15px;">Total installation :</span>
      <span style="display: table-cell; text-align: right; color: #EF4136;">${formatPrice(budget.setupCosts)}</span>
    </div>
  </div>`;
}