export const insuranceData = {
  url: "https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes",
  subscriptionLink: "https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/?part=pvtistes&utm_source=pvtistes-pwa&utm_medium=aff-link&utm_campaign=gp-pvt-pwa",
  claimsLink: "https://www.acs-ami.com/fr/part/pvtistes/globe-pvt/remboursement/?part=pvtistes",
  header: {
    image: "https://plus.unsplash.com/premium_photo-1716719107643-7fca1e32f8d8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Globe PVT : l'assurance recommandée par pvtistes.net",
    content: "Une assurance complète et adaptée à votre PVT, avec un service client en français et un remboursement des mois non utilisés. Profitez d'une couverture optimale pour votre séjour à l'étranger !"
  },
  ascHeader: {
    image: "https://www.acs-ami.com/fr/images/logo.svg",
    title: "ACS : votre partenaire assurance depuis plus de 40 ans",
    titlePage: "Globe PVT : l'assurance recommandée par pvtistes.net",
    content: "Depuis plus de 40 ans, ACS assure les voyageurs du monde entier. Notre expertise dans l'assurance voyage et notre service client en français font de nous le partenaire idéal pour votre PVT."
  },
  intro: {
    image: "https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2670&auto=format&fit=crop",
    title: "Une assurance adaptée à vos besoins",
    content: "Globe PVT est une assurance spécialement conçue pour les voyageurs en PVT. Elle vous offre une couverture complète et adaptée à vos besoins, avec un service client disponible en français."
  },
  whyChoosePVT: {
    title: "Pourquoi choisir Globe PVT ?",
    content: "Découvrez les avantages qui font de Globe PVT l'assurance préférée des pvtistes",
    sections: [
      {
        icon: "shield",
        title: "Une couverture complète",
        description: "Globe PVT vous offre une protection optimale pendant toute la durée de votre séjour",
        list: [
          {
            icon: "check",
            content: "Frais médicaux jusqu'à 200 000 €"
          },
          {
            icon: "check",
            content: "Hospitalisation couverte à 100%"
          },
          {
            icon: "check",
            content: "Assistance rapatriement 24h/24"
          },
          {
            icon: "check",
            content: "Responsabilité civile incluse"
          }
        ]
      },
      {
        icon: "pencil",
        title: "Flexibilité maximale",
        description: "Une assurance qui s'adapte à vos besoins et à votre durée de séjour",
        list: [
          {
            icon: "check",
            content: "Remboursement des mois non utilisés"
          },
          {
            icon: "check",
            content: "Modification de dates possible"
          },
          {
            icon: "check",
            content: "Souscription avant ou pendant le séjour"
          },
          {
            icon: "check",
            content: "Paiement mensuel possible"
          }
        ]
      },
      {
        icon: "speech-bubble",
        title: "Service client premium",
        description: "Une équipe dédiée pour vous accompagner tout au long de votre séjour",
        list: [
          {
            icon: "check",
            content: "Service client en français"
          },
          {
            icon: "check",
            content: "Assistance 24h/24 et 7j/7"
          },
          {
            icon: "check",
            content: "Remboursements rapides"
          },
          {
            icon: "check",
            content: "Support par email et téléphone"
          }
        ]
      },
      {
        icon: "shield",
        title: "Tarifs avantageux",
        description: "Des prix compétitifs pour une couverture optimale",
        list: [
          {
            icon: "check",
            content: "À partir de 31,84 € par mois"
          },
          {
            icon: "check",
            content: "Pas de franchise"
          },
          {
            icon: "check",
            content: "Tiers payant possible"
          },
          {
            icon: "check",
            content: "Devis gratuit en ligne"
          }
        ]
      }
    ]
  },
  faq: {
    title: "Questions fréquentes",
    content: "Retrouvez les réponses aux questions les plus fréquentes sur l'assurance Globe PVT",
    sections: [
      {
        title: "Questions pour toutes les destinations",
        questions: [
          {
            question: "L'assurance Globe PVT est-elle valide pour toutes les destinations PVT ?",
            answer: "Oui, le contrat Globe PVT est valide auprès des autorités de tous les pays ayant des accords PVT. Il est donc valide pour vos demandes de PVT auprès des ambassades et consulats."
          },
          {
            question: "J'ai un emploi sur place qui m'attend et qui inclut une assurance complémentaire. Ai-je tout de même besoin de souscrire à Globe PVT ?",
            answer: "Dans la plupart des cas, l'assurance est obligatoire pour vos démarches administratives ou pour passer la frontière. Même si elle n'est pas obligatoire, elle est fortement recommandée pour ses garanties essentielles comme le rapatriement ou la responsabilité civile vie privée."
          },
          {
            question: "Votre assurance voyage Globe PVT est-elle réservée aux étudiants ?",
            answer: "Non, l'assurance Globe PVT est ouverte à tous les pvtistes, détenteurs d'un visa PVT/WHV ainsi qu'aux EICistes, peu importe leur nationalité."
          },
          {
            question: "Suis-je également couvert lors de courts séjours dans les pays alentours ?",
            answer: "Oui, tant que votre PVT est activé, vous êtes couvert lors de courts séjours touristiques dans les pays alentours sans limite de durée, avec un plafond de frais médicaux de 150 000 € par an."
          },
          {
            question: "Suis-je également couvert lors de retours dans mon pays d'origine ?",
            answer: "Le contrat couvre les retours temporaires dans votre pays d'origine pendant un maximum de 30 jours par an. Les urgences médicales sont prises en charge à hauteur de 15 000 €."
          },
          {
            question: "Je suis couvert durant les 3 premiers mois de mon séjour par ma carte bancaire, puis-je souscrire à votre contrat ensuite ?",
            answer: "Oui, mais il est nécessaire de souscrire Globe PVT avant votre départ. Attention, les incidents survenus pendant les 90 premiers jours ne seront pas couverts par Globe PVT par la suite."
          },
          {
            question: "Est-il possible de payer la cotisation mensuellement plutôt que de tout régler d'un seul coup ?",
            answer: "Non, le paiement mensuel n'est pas possible, mais un paiement en 2, 3 ou 4 fois est proposé sous certaines conditions."
          },
          {
            question: "Puis-je régler mon assurance en plusieurs fois ?",
            answer: "Oui, le paiement en 2, 3 ou 4 fois est possible pour les séjours de 6 mois minimum, à condition d'être résident en France ou DOM-TOM et d'utiliser une carte bancaire française avec 3D Secure."
          },
          {
            question: "Je pars en vacances à Cuba pendant mon PVT, mon assurance Globe PVT suffit-elle ?",
            answer: "Oui, mais une attestation spécifique est requise par les autorités cubaines. Vous pouvez l'obtenir gratuitement sous 3 jours ouvrés."
          },
          {
            question: "Quels sont les sports qui ne sont pas couverts par Globe PVT ?",
            answer: "Les sports extrêmes tels que l'alpinisme, les sports aériens, les sports de glisse hors-piste et les compétitions officielles ne sont pas couverts."
          },
          {
            question: "Où puis-je trouver une assurance pour les sports non pris en charge ?",
            answer: "Les clubs qui organisent ces sports proposent généralement des assurances adaptées."
          },
          {
            question: "Puis-je consulter n'importe quel médecin et sans autorisation au préalable ?",
            answer: "Oui, vous êtes libre de consulter le médecin de votre choix sans autorisation préalable."
          },
          {
            question: "Pour le remboursement des frais médicaux, à qui dois-je envoyer les factures de médecins et ordonnances ?",
            answer: "Les factures inférieures à 500 € peuvent être envoyées via le formulaire en ligne. Au-delà, les documents doivent être envoyés par courrier postal à ACS."
          },
          {
            question: "En cas de soins médicaux dois-je faire moi-même l'avance des frais ?",
            answer: "Oui, sauf en cas d'hospitalisation (au moins une nuit), où la prise en charge directe est possible via la plateforme d'assistance."
          },
          {
            question: "Que dois-je faire en cas d'hospitalisation?",
            answer: "Contactez la plateforme d'assistance Mutuaide disponible 24h/24 et 7j/7 pour une prise en charge directe des frais."
          },
          {
            question: "Est-il possible de prolonger mon contrat Globe PVT ?",
            answer: "Oui, vous pouvez demander une extension avant la fin de votre contrat, à condition qu'il n'y ait pas d'interruption entre les contrats."
          },
          {
            question: "Si je prolonge mon séjour après mon PVT, puis-je souscrire une nouvelle assurance si je ne suis pas assuré·e sur place ?",
            answer: "Oui, contactez le service client d'ACS avant la fin de votre contrat actuel pour étudier les options."
          },
          {
            question: "Est-ce que l'assurance voyage Globe PVT couvre les accidents du travail ?",
            answer: "Oui, les frais médicaux liés aux accidents du travail sont couverts, mais pas les indemnités journalières ou la prévoyance."
          },
          {
            question: "Je souhaite souscrire Globe PVT, mais je souffre d'une affection chronique. Comment dois-je procéder ?",
            answer: "Avant la souscription, contactez le médecin-conseil d'ACS pour déclarer votre situation."
          },
          {
            question: "Est-ce que l'assurance Globe PVT couvre mes frais médicaux liés à une maladie ou un accident antérieur à la date d'effet de mon assurance ?",
            answer: "Non, les affections antérieures à la souscription ne sont pas couvertes."
          },
          {
            question: "L'assurance Globe PVT couvre-t-elle le vol de mes bagages lors de mon séjour ?",
            answer: "Oui, uniquement lors des trajets sous la responsabilité de la compagnie de transport (vols aller et retour)."
          },
          {
            question: "L'assurance Globe PVT me couvre-t-elle si je conduis un véhicule à l'étranger ?",
            answer: "Les frais médicaux sont couverts en cas d'accident, mais la responsabilité civile automobile n'est pas incluse."
          },
          {
            question: "Si je suis malade de la Covid-19 est ce que mon assurance Globe PVT me couvre ?",
            answer: "Oui, la Covid-19 et d'autres maladies épidémiques sont couvertes sans franchise dans le pays de PVT."
          },
          {
            question: "Je ne suis pas français ou belge, puis-je tout de même souscrire à Globe PVT ?",
            answer: "Oui, si votre nationalité est éligible aux accords PVT. Contactez ACS si vous avez des doutes."
          },
          {
            question: "Je pars à l'étranger en tant que pvtiste. Quel document dois-je présenter à l'aéroport en cas de contrôle ?",
            answer: "Votre certificat d'assurance contenant vos informations personnelles et les détails du contrat."
          },
          {
            question: "Comment faire pour assurer un couple ou une famille ?",
            answer: "Chaque personne doit souscrire un contrat individuel, y compris les enfants."
          },
          {
            question: "En cas de retour anticipé, suis-je remboursé·e des mois non utilisés ?",
            answer: "Oui, si vous rentrez définitivement et qu'il vous reste au moins deux mois complets non utilisés."
          },
          {
            question: "Dans votre formulaire de souscription, le \"pays d'origine\" est le pays de départ ou le pays de nationalité ?",
            answer: "Le pays d'origine est celui dans lequel vous résidez avant votre départ."
          },
          {
            question: "Puis-je souscrire dès maintenant, alors que je ne pars que dans plusieurs mois ?",
            answer: "Oui, la souscription et la date de début de contrat peuvent être différentes."
          },
          {
            question: "J'ai d'autres questions, à qui puis-je les poser ?",
            answer: "Vous pouvez contacter les conseillers Globe PVT via la messagerie privée sur pvtistes.net."
          }
        ]
      },
      {
        title: "Questions spécifiques à la destination Canada",
        questions: [
          {
            question: "Je suis conjoint·e de fait d'un·e pvtiste, puis-je souscrire à Globe PVT/EIC Canada ?",
            answer: "Oui, si vous avez un permis de travail ouvert lié à celui de votre conjoint·e. Sinon, optez pour le contrat Globe Partner jusqu'à ce que vous obteniez ce permis."
          },
          {
            question: "Je pars en PVT au Canada avec mes enfants, dois-je souscrire une assurance pour eux ?",
            answer: "Oui, chaque membre de la famille doit souscrire son propre contrat Globe PVT."
          },
          {
            question: "Je souhaite aller valider mon PVT, puis repartir dans mon pays d'origine pour le moment, et revenir au Canada plus tard. Est-il possible de me faire rembourser les mois passés en France ?",
            answer: "Non, le remboursement n'est possible qu'en cas de retour définitif."
          },
          {
            question: "Puis-je bénéficier de la téléconsultation médicale ?",
            answer: "Oui, grâce au partenariat avec MédecinDirect, vous avez accès à des consultations 24h/24 et 7j/7."
          },
          {
            question: "J'ai l'impression que consulter un médecin peut être compliqué.",
            answer: "Le système canadien diffère du système français. L'application Doctr vous permet de localiser les cliniques ouvertes et les temps d'attente en temps réel."
          }
        ]
      },
      {
        title: "Question spécifique aux destinations hors Canada",
        questions: [
          {
            question: "J'ai un rendez-vous à l'ambassade pour déposer ma demande de PVT. On me demande une attestation d'assurance, comment faire ?",
            answer: "Souscrivez le contrat avec une date indicative et demandez la modification si votre demande de visa est acceptée. En cas de refus, le contrat peut être annulé et remboursé sous conditions."
          }
        ]
      },
      {
        title: "Carte d'assuré pour le contrat Globe PVT",
        questions: [
          {
            question: "Comment fonctionne cette carte ?",
            answer: "Elle contient les informations importantes sur votre contrat et les contacts d'urgence. Elle est envoyée après la souscription."
          },
          {
            question: "Suis-je obligé·e d'imprimer la carte d'assuré ?",
            answer: "Non, mais il est conseillé de l'imprimer pour l'avoir sur vous en cas d'urgence."
          },
          {
            question: "En présentant la carte, les soins sont-ils directement pris en charge ?",
            answer: "Non, la carte sert uniquement de rappel des informations. Elle ne permet pas une prise en charge directe."
          },
          {
            question: "Que dois-je faire en cas de soins ?",
            answer: "Pour les soins externes, avancez les frais et demandez un remboursement. En cas d'hospitalisation de plus de 24 heures, la plateforme d'assistance peut gérer la prise en charge directe."
          },
          {
            question: "Que faire si je perds ma carte ?",
            answer: "Vous pouvez la réimprimer à partir de votre certificat d'assurance ou en demander une nouvelle auprès du service client."
          }
        ]
      }
    ]
  }
};