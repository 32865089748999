import React from 'react';
import { Heart, MessageCircle, Flag, ChevronDown, ChevronUp } from 'lucide-react';

interface PostActionsProps {
  postId: string;
  isLiked: boolean;
  likesCount: number;
  commentsCount: number;
  onLike: (e: React.MouseEvent) => void;
  onToggleLikes: () => void;
  onToggleComments: () => void;
  onReport: () => void;
  showLikes: boolean;
}

export function PostActions({
  postId,
  isLiked,
  likesCount,
  commentsCount,
  onLike,
  onToggleLikes,
  onToggleComments,
  onReport,
  showLikes
}: PostActionsProps) {
  return (
    <div className="flex items-center gap-4 mb-4">
      <button
        onClick={onLike}
        className={`flex items-center gap-1.5 p-1.5 sm:p-2 rounded-full transition-all duration-200 text-sm sm:text-base ${
          isLiked
            ? 'text-red-500 hover:bg-red-50 dark:hover:bg-red-900/20'
            : 'text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
        }`}
      >
        <Heart 
          id={`heart-${postId}`}
          className={`w-5 h-5 sm:w-6 sm:h-6 transition-transform duration-200 ${isLiked ? 'fill-current animate-like' : ''}`} 
        />
        <span>{likesCount}</span>
      </button>
      {likesCount > 0 && (
        <button
          onClick={onToggleLikes}
          className="flex items-center gap-1 p-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
        >
          {showLikes ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <span className="sr-only">Afficher les likes</span>
        </button>
      )}
      <button
        onClick={onToggleComments}
        className="flex items-center gap-1.5 p-1.5 sm:p-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors text-sm sm:text-base"
      >
        <MessageCircle className="w-5 h-5 sm:w-6 sm:h-6" />
        <span>{commentsCount}</span>
      </button>
      <button
        onClick={onReport}
        className="p-1.5 sm:p-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors ml-auto"
      >
        <Flag className="w-4 h-4 sm:w-5 sm:h-5" />
      </button>
    </div>
  );
}