import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Building2, Users, Calendar, Globe2, Info, ArrowLeft, X } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MAJOR_CITIES } from '../data/budget-countries';
import { sanitizeDocumentId } from '../utils/firestore';

const ITEM_TRANSLATIONS: { [key: number]: string } = {
  // Restaurants
  1: 'Repas, Restaurant bon marché',
  2: 'Repas pour 2, Restaurant moyen, 3 plats',
  3: 'Menu McDonalds (ou équivalent)',
  4: 'Bière locale (0.5L pression)',
  5: 'Bière importée (33cl bouteille)',
  6: 'Coca/Pepsi (33cl bouteille)',
  7: 'Eau (33cl bouteille)',
  8: 'Lait (1 litre)',
  
  // Alimentation
  9: 'Pain blanc frais (500g)',
  11: 'Œufs (12)',
  12: 'Fromage local (1kg)',
  13: 'Eau (1.5L bouteille)',
  14: 'Bouteille de vin (gamme moyenne)',
  15: 'Bière locale (0.5L bouteille)',
  16: 'Bière importée (33cl bouteille)',
  17: 'Cigarettes 20 Pack (Marlboro)',
  18: 'Ticket transport (unité)',
  19: 'Filets de poulet (1kg)',
  20: 'Pass transport mensuel',
  24: 'Essence (1 litre)',
  25: 'Volkswagen Golf 1.4 90 KW Trendline (ou équivalent)',
  26: 'Appartement 1 chambre centre-ville',
  
  // Transport
  107: 'Taxi - prise en charge',
  108: 'Taxi - tarif au km',
  109: 'Taxi - tarif horaire d\'attente',
  110: 'Pommes (1kg)',
  111: 'Oranges (1kg)',
  112: 'Pommes de terre (1kg)',
  113: 'Salade (1 pièce)',
  114: 'Cappuccino',
  115: 'Riz blanc (1kg)',
  116: 'Tomates (1kg)',
  118: 'Bananes (1kg)',
  119: 'Oignons (1kg)',
  121: 'Bœuf (1kg)',
  
  // Logement
  27: 'Appartement 1 chambre hors centre-ville',
  28: 'Appartement 3 chambres centre-ville',
  29: 'Appartement 3 chambres hors centre-ville',
  
  // Services
  30: 'Charges (électricité, chauffage, eau) - 85m²',
  33: 'Internet (60 Mbps+, illimité)',
  34: 'Forfait mobile (appels + 10GB data)',
  
  // Loisirs
  40: 'Abonnement salle de sport',
  42: 'Location court de tennis (1h weekend)',
  44: 'Place de cinéma',
  
  // Mode
  60: 'Jean (Levi\'s 501 ou équivalent)',
  62: 'Robe d\'été (Zara, H&M)',
  64: 'Chaussures de sport Nike (gamme moyenne)',
  66: 'Chaussures de ville en cuir (homme)'
};

const PRICE_CATEGORIES = [
  {
    title: 'Restaurants',
    items: [1, 2, 3, 4, 5, 6, 7, 114],
    icon: '🍽️',
    description: 'Prix moyens dans les restaurants, cafés et bars'
  },
  {
    title: 'Alimentation',
    items: [8, 9, 11, 12, 19, 110, 111, 112, 113, 115, 116, 118, 119, 121],
    icon: '🛒',
    description: 'Prix des produits alimentaires en supermarché et marchés'
  },
  {
    title: 'Transport',
    items: [18, 20, 24, 25, 107, 108, 109],
    icon: '🚌',
    description: 'Prix des transports en commun, taxis et essence'
  },
  {
    title: 'Logement',
    items: [26, 27, 28, 29],
    icon: '🏠',
    description: 'Loyers mensuels selon le quartier et la taille'
  },
  {
    title: 'Services',
    items: [30, 33, 34],
    icon: '💡',
    description: 'Charges, internet et téléphonie mobile'
  },
  {
    title: 'Loisirs',
    items: [40, 42, 44],
    icon: '🎭',
    description: 'Sport, cinéma et activités culturelles'
  },
  {
    title: 'Mode',
    items: [60, 62, 64, 66],
    icon: '👕',
    description: 'Vêtements et chaussures de marques courantes'
  }
];

interface CityDataPageProps {
  isOpen?: boolean;
  onClose?: () => void;
  modalCountryCode?: string;
  modalCity?: string;
}

export function CityDataPage({ isOpen = true, onClose, modalCountryCode, modalCity }: CityDataPageProps) {
  const { countryCode: urlCountryCode, city: urlCity } = useParams<{ countryCode: string; city: string }>();
  const [cityData, setCityData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [language, setLanguage] = React.useState<'fr' | 'en'>('fr');
  const navigate = useNavigate();

  const formatPrice = (price: number): string => {
    return language === 'fr'
      ? price.toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
      : price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
  };

  // Use either modal props or URL params
  const effectiveCountryCode = modalCountryCode || urlCountryCode;
  const effectiveCity = modalCity || urlCity;

  // Validate inputs early
  const validateInputs = () => {
    if (!effectiveCountryCode || !effectiveCity) {
      return 'Paramètres manquants';
    }
    if (!MAJOR_CITIES[effectiveCountryCode]?.includes(effectiveCity)) {
      return 'Ville non valide pour ce pays';
    }
    return null;
  };

  React.useEffect(() => {
    const fetchCityData = async () => {
      const validationError = validateInputs();
      if (validationError) {
        setError(validationError);
        setIsLoading(false);
        return;
      }

      try {
        const docId = sanitizeDocumentId(`${effectiveCountryCode}-${effectiveCity}`);
        console.log('Fetching city data:', {
          city: effectiveCity,
          countryCode: effectiveCountryCode,
          docId
        });
        
        const docRef = doc(db, 'city_costs', docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (!data.priceItems?.length && !data.housingTypes?.length) {
            setError('Données incomplètes pour cette ville');
            return;
          }
          setCityData(data);
        } else {
          setError('Aucune donnée trouvée pour cette ville');
        }
      } catch (error) {
        console.error('Error fetching city data:', {
          city: effectiveCity,
          countryCode: effectiveCountryCode,
          error: error instanceof Error ? error.message : 'Unknown error'
        });
        setError('Erreur lors de la récupération des données');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCityData();
  }, [effectiveCountryCode, effectiveCity]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  const formatDate = (date: any) => {
    if (!date) return 'Non disponible';
    const dateObj = date.toDate();
    return format(dateObj, 'dd MMMM yyyy', { locale: fr });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50 overflow-hidden">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium w-full max-w-6xl h-[90vh] flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
          <div className="flex items-center gap-4">
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
            <div>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                {cityData?.name || effectiveCity}
              </h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Coût de la vie et statistiques
              </p>
            </div>
          </div>
          <button
            onClick={handleClose}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
          >
            <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        {/* Content - Scrollable */}
        <div className="flex-1 overflow-y-auto custom-scrollbar">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
            </div>
          ) : error ? (
            <div className="p-4">
              <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
                <h2 className="text-xl font-semibold text-red-900 dark:text-red-300 mb-4">
                  {error}
                </h2>
              </div>
            </div>
          ) : (
            <div className="p-4">
              <div className="flex items-end justify-end mb-6">
                <div className="inline-flex rounded-lg border border-gray-200 dark:border-gray-700">
                  <button
                    onClick={() => setLanguage('fr')}
                    className={`px-3 py-1.5 text-sm font-medium rounded-l-lg transition-colors ${
                      language === 'fr'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }`}
                  >
                    Français
                  </button>
                  <button
                    onClick={() => setLanguage('en')}
                    className={`px-3 py-1.5 text-sm font-medium rounded-r-lg border-l border-gray-200 dark:border-gray-700 transition-colors ${
                      language === 'en'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
                    }`}
                  >
                    English
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-blue-100 dark:bg-blue-900/30 rounded-lg">
                      <Users className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">Contributeurs</p>
                      <p className="text-lg font-semibold text-gray-900 dark:text-white">
                        {cityData.contributors || 0}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-emerald-100 dark:bg-emerald-900/30 rounded-lg">
                      <Calendar className="w-5 h-5 text-emerald-600 dark:text-emerald-400" />
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">Dernière mise à jour</p>
                      <p className="text-lg font-semibold text-gray-900 dark:text-white">
                        {formatDate(cityData.lastUpdated)}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-purple-100 dark:bg-purple-900/30 rounded-lg">
                      <Globe2 className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">Devise</p>
                      <p className="text-lg font-semibold text-gray-900 dark:text-white">
                        {cityData.currency}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-4">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-amber-100 dark:bg-amber-900/30 rounded-lg">
                      <Building2 className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                    </div>
                    <div>
                      <p className="text-sm text-gray-500 dark:text-gray-400">Types de logement</p>
                      <p className="text-lg font-semibold text-gray-900 dark:text-white">
                        {(cityData.housingTypes || []).length}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl p-6 mb-8 border border-primary-100/50 dark:border-primary-800/30">
                <div className="flex items-start gap-3">
                  <Info className="w-5 h-5 text-primary-500 flex-shrink-0 mt-0.5" />
                  <div>
                    <h3 className="text-base font-medium text-gray-900 dark:text-white mb-1">
                      À propos des données
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Ces données sont basées sur les contributions des utilisateurs et sont mises à jour régulièrement. 
                      Les prix sont affichés en euros pour faciliter la comparaison, mais peuvent varier selon la période et l'emplacement exact.
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                {PRICE_CATEGORIES.map((category) => {
                  const items = cityData.priceItems.filter((item: any) => 
                    category.items.includes(item.item_id)
                  );

                  if (items.length === 0) return null;

                  return (
                    <div key={category.title} className="bg-white dark:bg-gray-800 rounded-xl shadow-sm p-6">
                      <div className="flex items-center gap-3 mb-6">
                        <div className="p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                          <span className="text-2xl">{category.icon}</span>
                        </div>
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            {category.title}
                          </h3>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            {category.description}
                          </p>
                        </div>
                      </div>

                      <div className="space-y-3">
                        {items.map((item: any) => {
                          const eurItem = cityData.eurPrices?.find((p: any) => p.item_id === item.item_id);
                          const itemName = language === 'fr' 
                            ? ITEM_TRANSLATIONS[item.item_id] || item.item_name 
                            : item.item_name;
                          return (
                            <div key={item.item_id} className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 border-b border-gray-100 dark:border-gray-700/50 last:border-0">
                              <div className="flex-1 min-w-0 mb-1 sm:mb-0">
                                <span className="text-sm text-gray-600 dark:text-gray-400 break-words">
                                  {itemName}
                                </span>
                              </div>
                              <div className="text-right">
                                <span className="text-sm font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                  {formatPrice(eurItem?.average_price || item.average_price)}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}