import React from 'react';
import { Calculator, Plane, Building2, Wallet, PiggyBank, X, Info } from 'lucide-react';
import { BudgetForm } from '../components/budget/BudgetForm';
import { BudgetResults } from '../components/budget/BudgetResults';
import { BudgetSteps } from '../components/budget/BudgetSteps';
import { SavedBudgetsList } from '../components/budget/SavedBudgetsList';
import { MobileBudgetSummary } from '../components/budget/MobileBudgetSummary';
import { useNavigate } from 'react-router-dom';
import { Country } from '../types/todo';
import { SavedBudget } from '../types/budget';
import { Footer } from '../components/Footer';
import { Navbar } from '../components/Navbar';
import { getCityLivingCosts } from '../lib/numbeo-service';
import { BackButton } from '../components/BackButton';
import { budgetCountries } from '../data/budget-countries';
import { useAuth } from '../lib/auth-context';
import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { deleteBudget } from '../lib/firebase-service';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { FLIGHT_RANGES } from '../constants/visa-costs';

export function BudgetSimulatorPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const [savedBudgets, setSavedBudgets] = React.useState<SavedBudget[]>([]);
  const [isLoadingBudgets, setIsLoadingBudgets] = React.useState(true);
  const [budgetToDelete, setBudgetToDelete] = React.useState<SavedBudget | null>(null);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [budgetData, setBudgetData] = React.useState<any>(null);
  const [selectedBudget, setSelectedBudget] = React.useState<SavedBudget | null>(null);
  const [formData, setFormData] = React.useState({
    country: '',
    city: '',
    housingType: '',
    isNomadMode: false,
    housingTypes: [],
    initialSavings: '',
    pvtDuration: '12',
    flightCost: '700',
    insuranceCost: '',
    pvtCost: '240',
    foodCost: '0',
    transportCost: '0',
    phoneCost: '0',
    internetCost: '20',
    utilitiesCost: '0',
    bankCost: '0',
    streamingCost: '0',
    leisureCost: '0',
    epargne: '0',
    firstLastMonthRent: '0',
    furnitureCost: '0',
    clothingCost: '0',
    groceryCost: '0'
  });
  const [housingTypes, setHousingTypes] = React.useState([]);
  const [currency, setCurrency] = React.useState('EUR');

  const onFormDataChange = (newData: any) => {
    setFormData(newData);
  };

  // Check if budget limit is reached
  const isBudgetLimitReached = savedBudgets.length >= 4;

  // Update housing data when city changes
  React.useEffect(() => {
    const updateHousingData = async () => {
      if (formData.city && formData.country) {
        try {
          const costData = await getCityLivingCosts(formData.city, formData.country);
          if (costData?.housingTypes) {
            setHousingTypes(costData.housingTypes);
            setCurrency(costData.currency);
          }
        } catch (error) {
          console.error('Error fetching housing data:', error);
        }
      }
    };

    updateHousingData();
  }, [formData.city, formData.country]);

  // Mettre à jour les valeurs par défaut quand le pays change
  React.useEffect(() => {
    if (formData.country) {
      // Définir le coût de l'assurance en fonction du pays
      const insuranceCost = formData.country === 'ca' ? '31.84' : '35.70';
      const flightRange = FLIGHT_RANGES[formData.country];
      onFormDataChange({
        ...formData,
        pvtDuration: formData.country === 'ca' ? '24' : '12',
        initialSavings: formData.initialSavings || '5000',
        insuranceCost,
        flightCost: flightRange ? flightRange.avg.toString() : '700'
      });
    }
  }, [formData.country]);

  const steps = [
    { 
      id: 1, 
      title: 'Configuration',
      description: 'Destination et préférences',
      icon: Calculator
    },
    { 
      id: 2, 
      title: 'Pré-départ',
      description: 'Visa, billets et assurance',
      icon: Plane
    },
    { 
      id: 3, 
      title: 'Mensuel',
      description: 'Dépenses mensuelles',
      icon: Wallet
    },
    { 
      id: 4, 
      title: 'Installation',
      description: 'Budget d\'installation',
      icon: Building2
    },
    { 
      id: 5, 
      title: 'Résumé',
      description: 'Budget total',
      icon: PiggyBank
    }
  ];

  const handleStepComplete = (stepData: any) => {
    if (currentStep === 4) {
      // Calculate total monthly expenses
      const housingCost = formData.housingTypes?.find(h => h.id === formData.housingType)?.costEUR || 
                         formData.housingTypes?.find(h => h.id === formData.housingType)?.cost || 
                         0;

      const monthlyExpenses = [
        housingCost,
        Number(stepData.groceriesCost) || 0,
        Number(stepData.restaurantsCost) || 0,
        Number(stepData.transportCost) || 0,
        Number(stepData.utilitiesCost) || 0,
        Number(stepData.phoneCost) || 0,
        Number(stepData.bankCost) || 0,
        Number(stepData.streamingCost) || 0,
        Number(stepData.leisureCost) || 0,
        Number(stepData.epargne) || 0
      ].reduce((a, b) => Math.round((a + b) * 100) / 100, 0);

      // Calculate total setup costs
      const totalSetupCosts = 
        parseInt(stepData.firstLastMonthRent) +
        parseInt(stepData.furnitureCost) +
        parseInt(stepData.clothingCost) +
        parseInt(stepData.groceryCost) +
        parseInt(stepData.flightCost) +
        parseInt(stepData.pvtCost);

      setBudgetData({
        ...stepData,
        monthlyExpenses,
        totalSetupCosts
      });
    }
    setCurrentStep(prev => prev + 1);
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const selectedCountry = budgetCountries.find(c => c.code === formData.country);

  // Rediriger vers la page d'accueil si l'utilisateur n'est pas connecté
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  // Fetch saved budgets
  React.useEffect(() => {
    const fetchSavedBudgets = async () => {
      if (!user) return;
      
      try {
        const budgetsRef = collection(db, 'budgets');
        const q = query(
          budgetsRef,
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc')
        );
        
        const snapshot = await getDocs(q);
        const budgets = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt.toDate()
        })) as SavedBudget[];
        
        setSavedBudgets(budgets);
      } catch (error) {
        console.error('Error fetching saved budgets:', error);
      } finally {
        setIsLoadingBudgets(false);
      }
    };

    fetchSavedBudgets();
  }, [user]);

  const handleSelectBudget = (budget: SavedBudget) => {
    setSelectedBudget(budget);
  };

  const handleDeleteBudget = async () => {
    if (!budgetToDelete) return;

    try {
      await deleteBudget(budgetToDelete.id);
      setSavedBudgets(prev => prev.filter(b => b.id !== budgetToDelete.id));
      setBudgetToDelete(null);
    } catch (error) {
      console.error('Error deleting budget:', error);
      alert('Erreur lors de la suppression du budget');
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
        isBudgetPage={true}
      />

      {/* Fixed Header */}
      {/* Main Content */}
      <main className="flex-1 pt-24 pb-16">
        <div className="max-w-[100rem] mx-auto px-2 sm:px-4 lg:px-8">
          {/* Steps Progress */}
          <div className="mb-12 sm:mb-16 px-2 sm:px-0">
            <BudgetSteps steps={steps} currentStep={currentStep} />
          </div>
          
          {/* Show saved budgets at step 1 */}
          {currentStep === 1 && savedBudgets.length > 0 && (
            <div className="mb-8">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                  Mes budgets sauvegardés
                </h2>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  {savedBudgets.length}/4 budgets
                </div>
              </div>
              
              {isBudgetLimitReached && (
                <div className="mb-6 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100 dark:border-amber-800/30">
                  <div className="flex items-start gap-3">
                    <div className="p-2 bg-white dark:bg-gray-800 rounded-lg">
                      <Info className="w-5 h-5 text-amber-500" />
                    </div>
                    <div>
                      <h3 className="text-sm font-medium text-amber-800 dark:text-amber-300 mb-1">
                        Limite de budgets atteinte
                      </h3>
                      <p className="text-sm text-amber-600 dark:text-amber-400">
                        Vous avez atteint la limite de 4 budgets sauvegardés. Pour créer un nouveau budget, veuillez d'abord en supprimer un.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <SavedBudgetsList
                budgets={savedBudgets}
                onSelectBudget={handleSelectBudget}
                onDeleteBudget={setBudgetToDelete}
                isLoading={isLoadingBudgets}
                showLimitWarning={isBudgetLimitReached}
              />
            </div>
          )}

          {/* Form or Results */}
          <div className="mt-8 sm:mt-12">
            {currentStep < 5 ? (
              isBudgetLimitReached && currentStep === 1 ? (
                <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
                  <div className="bg-amber-50 dark:bg-amber-900/20 rounded-xl p-6 border border-amber-100 dark:border-amber-800/30 text-center">
                    <div className="w-12 h-12 mx-auto mb-4 bg-amber-100 dark:bg-amber-900/30 rounded-full flex items-center justify-center">
                      <Info className="w-6 h-6 text-amber-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-amber-800 dark:text-amber-300 mb-2">
                      Impossible de créer un nouveau budget
                    </h3>
                    <p className="text-sm text-amber-600 dark:text-amber-400 max-w-lg mx-auto">
                      Vous avez atteint la limite de 4 budgets sauvegardés. Pour créer un nouveau budget, veuillez d'abord en supprimer un existant.
                    </p>
                  </div>
                </div>
              ) : (
              <BudgetForm
                countries={budgetCountries}
                onSubmit={handleStepComplete}
                step={currentStep}
                onBack={handleBack}
                onFormDataChange={setFormData}
                formData={formData}
                housingTypes={housingTypes}
                currency={currency}
                city={formData.city}
                countryName={selectedCountry?.name}
              />
              )
            ) : (
              <BudgetResults 
                data={budgetData}
                onBack={handleBack}
              />
            )}
          </div>
        </div>
      </main>

      <Footer />
      
      {/* Popup for viewing saved budget */}
      {selectedBudget && (
        <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50 flex flex-col">
          <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Budget sauvegardé
            </h2>
            <button
              onClick={() => setSelectedBudget(null)}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>
          <div className="flex-1 overflow-y-auto custom-scrollbar">
            <div className="flex-1 overflow-y-auto custom-scrollbar">
              <BudgetResults
                data={selectedBudget.data}
                onBack={() => setSelectedBudget(null)}
                isPopup={true}
                onClose={() => setSelectedBudget(null)}
              />
            </div>
          </div>
        </div>
      )}
      
      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={!!budgetToDelete}
        onClose={() => setBudgetToDelete(null)}
        onConfirm={handleDeleteBudget}
        itemName={`le budget pour ${budgetToDelete?.city || ''}`}
        confirmationWord="SUPPRIMER"
      />
      
      {/* Mobile Budget Summary */}
      {currentStep < 5 && (
        <MobileBudgetSummary
          formData={formData}
          housingTypes={housingTypes}
          currentStep={currentStep}
        />
      )}
    </div>
  );
}