import React from 'react';
import { Shield, Info, MessageCircle, PencilLine, Phone, Mail, Globe, ExternalLink } from 'lucide-react';

export function LegalFooter() {
  return (
    <div className="bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700">
      <div className="max-w-4xl mx-auto px-2 sm:px-6 lg:px-8 py-6 sm:py-12">
        <div className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-white dark:from-gray-800 dark:to-gray-800/95 rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 border border-gray-200/50 dark:border-gray-700/50">
          <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/10 to-accent-100/10 dark:from-primary-800/5 dark:to-accent-800/5 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
          
          <div className="relative space-y-6 sm:space-y-8">
            {/* Company Info */}
            <div className="text-center">
              <div className="flex items-center justify-center gap-1.5 sm:gap-2 mb-2 sm:mb-3">
                <Shield className="w-5 h-5 sm:w-6 sm:h-6 text-primary-500 dark:text-primary-400" />
                <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
                  ACS – Assurances voyage & expatriation
                </h3>
              </div>
              <div className="text-xs sm:text-sm text-gray-600 dark:text-gray-400 space-y-1">
                <p>Société de courtage d'assurances ∙ 153 rue de l'Université 75007 Paris ∙ France</p>
                <p>317 218 188 RCS Paris – S.A.S. au capital de 150 000 €</p>
                <p>
                  N° ORIAS 07 000 350 – Site Web ORIAS : 
                  <a 
                    href="http://www.orias.fr" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-primary-600 hover:text-primary-700 dark:text-primary-400 ml-1 hover:underline"
                  >
                    www.orias.fr
                  </a>
                </p>
              </div>
            </div>

            {/* Legal Info */}
            <div className="text-center max-w-2xl mx-auto px-2 sm:px-0">
              <div className="flex items-center justify-center gap-2 mb-3 sm:mb-4">
                <Info className="w-5 h-5 text-gray-400 dark:text-gray-500" />
                <h4 className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300">
                  Informations légales
                </h4>
              </div>
              <div className="text-[11px] sm:text-xs text-gray-500 dark:text-gray-400 space-y-2 leading-relaxed">
                <p>Assurance de Responsabilité Civile Professionnelle et Garantie Financière conformes au Code des Assurances</p>
                <p>Pour toute réclamation, vous pouvez écrire à notre Service Réclamations, à l'adresse ci-dessus.</p>
                <p>Exerce sous le contrôle de l'Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 Pl. de Budapest CS 92459, 75436 Paris ∙ France</p>
              </div>
            </div>

            {/* Contact Info */}
            <div className="flex flex-col items-center gap-3 sm:gap-4">
              <div className="flex items-center justify-center gap-2 mb-1 sm:mb-2">
                <div className="p-1.5 sm:p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                  <MessageCircle className="w-4 h-4 sm:w-5 sm:h-5 text-primary-500 dark:text-primary-400" />
                </div>
                <h4 className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300">
                  Contact
                </h4>
              </div>
              <div className="flex flex-col sm:flex-row items-center gap-3 sm:gap-6">
                <div className="flex items-center gap-3">
                  <div className="p-1.5 sm:p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                    <Phone className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" />
                  </div>
                  <a 
                    href="tel:+33140479100"
                    className="text-xs sm:text-sm text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
                  >
                    +33 (0)1 40 47 91 00
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <div className="p-1.5 sm:p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                    <Mail className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" />
                  </div>
                  <a 
                    href="mailto:pvtistes@acs-ami.com"
                    className="text-xs sm:text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 transition-colors"
                  >
                    pvtistes@acs-ami.com
                  </a>
                </div>
                <div className="flex items-center gap-3">
                  <div className="p-1.5 sm:p-2 bg-gray-100 dark:bg-gray-700 rounded-lg">
                    <Globe className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-gray-600 dark:text-gray-400" />
                  </div>
                  <a 
                    href="https://www.acs-ami.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-xs sm:text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300 transition-colors group"
                  >
                    www.acs-ami.com
                    <ExternalLink className="w-3 h-3 sm:w-3.5 sm:h-3.5 inline-block ml-1 transform transition-transform group-hover:translate-x-0.5" />
                  </a>
                </div>
              </div>
            </div>

            {/* Disclaimer */}
            <div className="text-center pt-4 sm:pt-6 border-t border-gray-200/50 dark:border-gray-700/50">
              <div className="flex items-center justify-center gap-2 mb-2 sm:mb-3">
                <PencilLine className="w-4 h-4 text-gray-400 dark:text-gray-500" />
                <h4 className="text-[10px] sm:text-xs font-medium text-gray-700 dark:text-gray-300">
                  Mentions légales
                </h4> </div>
              <p className="text-[10px] sm:text-xs text-gray-500 dark:text-gray-400 italic px-2 sm:px-0">
                Cette page, ainsi que toutes les informations relatives à l'assurance, sont rédigées sous la responsabilité exclusive d'ACS, société de courtage d'assurance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}