import React from 'react';
import { useAuth } from '../lib/auth-context';
import { BudgetSimulatorPage } from './BudgetSimulatorPage';
import { BudgetSimulatorLandingPage } from './BudgetSimulatorLandingPage';
import { SEO } from '../components/SEO';

interface LandingPageProps {
  onLogin: () => void;
}

export function BudgetPage({ onLogin }: LandingPageProps) {
  const { user } = useAuth();

  const seoConfig = {
    title: "Simulateur de budget PVT - Calculez votre budget avec pvtistes.net",
    description: "Planifiez votre PVT avec notre simulateur de budget gratuit. Estimez vos dépenses mensuelles, coûts d'installation et plus encore avec des données à jour !",
    image: "https://images.unsplash.com/photo-1554224155-6726b3ff858f?q=80&w=2671&auto=format&fit=crop",
    url: "https://app.pvtistes.net/simulateur-budget"
  };

  return (
    <>
      <SEO {...seoConfig} />
      {user ? <BudgetSimulatorPage /> : <BudgetSimulatorLandingPage onLogin={onLogin} />}
    </>
  );
}