import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { InterviewPreview } from '../types/interviews';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { budgetCountries } from '../data/budget-countries';
import { CountryFilter } from '../components/dossiers/CountryFilter';
import { SearchAndSort } from '../components/dossiers/SearchAndSort';
import { InterviewList } from '../components/interviews/InterviewList';
import { InterviewsHero } from '../components/interviews/InterviewsHero';
import { useAuth } from '../lib/auth-context';
import { fetchInterviews } from '../lib/api';

const ITEMS_PER_PAGE = 9;

export function InterviewsPage() {
  const { user, userInfo } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [interviews, setInterviews] = React.useState<InterviewPreview[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortBy, setSortBy] = React.useState<'date' | 'popularity'>('date');
  const observerTarget = React.useRef<HTMLDivElement>(null);

  const selectedCountry = searchParams.get('country');

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const fetchInterviewsData = React.useCallback(async (page: number) => {
    if (!userInfo) return;

    try {
      const data = await fetchInterviews(page, ITEMS_PER_PAGE, selectedCountry, sortBy, userInfo);
      const newInterviews = Array.isArray(data) ? data : data.error ? [] : [data];
      
      if (newInterviews.length < ITEMS_PER_PAGE) {
        setHasMore(false);
      }

      setInterviews(prev => page === 1 ? newInterviews : [...prev, ...newInterviews]);
    } catch (error) {
      console.error('Error fetching interviews:', error);
      setError('Impossible de charger les interviews pour le moment');
    } finally {
      setIsLoading(false);
    }
  }, [selectedCountry, sortBy, userInfo]);

  // Initial load
  React.useEffect(() => {
    if (user && userInfo) {
      setIsLoading(true);
      setCurrentPage(1);
      setHasMore(true);
      fetchInterviewsData(1);
    }
  }, [fetchInterviewsData, user, userInfo]);

  // Infinite scroll
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          setCurrentPage(prev => prev + 1);
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [hasMore, isLoading]);

  // Load more when page changes
  React.useEffect(() => {
    if (currentPage > 1) {
      fetchInterviewsData(currentPage);
    }
  }, [currentPage, fetchInterviewsData]);

  const handleCountrySelect = (countryCode: string | null) => {
    if (countryCode) {
      const apiCountryCodes: { [key: string]: string } = {
        'aus': 'australie',
        'nz': 'nouvelle-zelande',
        'ca': 'canada',
        'jpn': 'japon',
        'kor': 'coree-du-sud',
        'twn': 'taiwan',
        'arg': 'argentine',
        'chl': 'chilie',
        'col': 'colombie',
        'mex': 'mexique',
        'per': 'perou',
        'uru': 'uruguay',
        'bra': 'bresil',
        'hkg': 'hong-kong',
        'fra': 'france',
        'ecu': 'equateur'
      };

      const apiCountryCode = apiCountryCodes[countryCode.toLowerCase()] || countryCode.toLowerCase();
      setSearchParams({ country: apiCountryCode });
      setCurrentPage(1);
      setInterviews([]);
      setHasMore(true);
    } else {
      setSearchParams({});
      setCurrentPage(1);
      setInterviews([]);
      setHasMore(true);
    }
  };

  // Filter interviews based on search query
  const filteredInterviews = React.useMemo(() => {
    if (!searchQuery) return interviews;
    
    const searchTerms = searchQuery.toLowerCase().split(' ');
    return interviews.filter(item => 
      searchTerms.every(term => 
        item.title.toLowerCase().includes(term) ||
        item.shortDescription.toLowerCase().includes(term) ||
        item.interviewee.firstname.toLowerCase().includes(term) ||
        item.interviewee.lastname.toLowerCase().includes(term) ||
        item.interviewee.occupation.toLowerCase().includes(term)
      )
    );
  }, [interviews, searchQuery]);

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      <InterviewsHero />

      <CountryFilter
        countries={budgetCountries}
        selectedCountry={selectedCountry}
        onCountrySelect={handleCountrySelect}
      />

      <main className="flex-1 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <SearchAndSort
            searchQuery={searchQuery}
            onSearchChange={(query) => {
              setSearchQuery(query);
              setCurrentPage(1);
            }}
            sortBy={sortBy}
            onSortChange={(sort) => {
              setSortBy(sort);
              setCurrentPage(1);
              setInterviews([]);
              setHasMore(true);
            }}
          />

          <InterviewList
            interviews={filteredInterviews}
            isLoading={isLoading}
            error={error}
            searchQuery={searchQuery}
            selectedCountry={selectedCountry}
          />

          {/* Loading indicator and observer target */}
          {hasMore && !searchQuery && (
            <div 
              ref={observerTarget}
              className="flex justify-center py-8"
            >
              {isLoading && (
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-500 border-t-transparent"></div>
              )}
            </div>
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
}