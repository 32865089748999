import React from 'react';
import { TaskStatus as TaskStatusType } from '../types/todo';
import { CheckCircle2, Circle, Clock } from 'lucide-react';

interface TaskStatusProps {
  status: TaskStatusType;
  isPreview: boolean;
}

export function TaskStatus({ status, isPreview }: TaskStatusProps) {
  const getStatusLabel = (status: TaskStatusType) => {
    switch (status) {
      case 'completed':
        return 'Terminé';
      case 'in-progress':
        return 'En cours';
      case 'not-started':
        return 'Pas commencé';
      default:
        return status;
    }
  };

  const getStatusBadge = (status: TaskStatusType) => {
    const baseClasses = "flex items-center gap-2 px-2 py-1 rounded-full text-xs font-medium transition-colors";
    if (isPreview) {
      return `${baseClasses} bg-gray-100 text-gray-800`;
    }
    switch (status) {
      case 'completed':
        return `${baseClasses} bg-emerald-100 text-emerald-800 hover:bg-emerald-200`;
      case 'in-progress':
        return `${baseClasses} bg-amber-100 text-amber-800 hover:bg-amber-200`;
      default:
        return `${baseClasses} bg-red-100 text-red-800 hover:bg-red-200`;
    }
  };

  const getStatusIcon = (status: TaskStatusType) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 className="w-3.5 h-3.5 text-emerald-500" />;
      case 'in-progress':
        return <Clock className="w-3.5 h-3.5 text-amber-500" />;
      default:
        return <Circle className="w-3.5 h-3.5 text-red-500" />;
    }
  };

  return (
    <div className={getStatusBadge(status)}>
      {getStatusIcon(status)}
      <span>{getStatusLabel(status)}</span>
    </div>
  );
}