import React from 'react';
import { MapPin, Image as ImageIcon, Film, ArrowUpDown, Hash, User, ChevronDown, X } from 'lucide-react';
import { MAJOR_CITIES, budgetCountries } from '../../data/budget-countries';
import { MEDIA_TAGS } from '../../data/media-tags';
import { useSearchParams } from 'react-router-dom';

interface MediaSearchProps {
  onSearch: (filters: {
    country?: string;
    mediaType?: 'image' | 'video' | 'all';
    sortBy: 'date' | 'popularity';
    tags: string[];
    userId?: string;
  }) => void;
  currentUserId: string;
}

export function MediaSearch({ onSearch, currentUserId }: MediaSearchProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [country, setCountry] = React.useState('');
  const [mediaType, setMediaType] = React.useState<'image' | 'video' | 'all'>('all');
  const [sortBy, setSortBy] = React.useState<'date' | 'popularity'>('date');
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  const [showTagSelector, setShowTagSelector] = React.useState(false);
  const [showUserPosts, setShowUserPosts] = React.useState(false);
  const [showMobileFilters, setShowMobileFilters] = React.useState(false);
  const filterRef = React.useRef<HTMLDivElement>(null);

  // Store temporary filter states for mobile
  const [tempFilters, setTempFilters] = React.useState({
    country,
    mediaType,
    sortBy,
    selectedTags,
    showUserPosts
  });

  // Initialize from URL params
  React.useEffect(() => {
    const tag = searchParams.get('tag');
    const user = searchParams.get('user');
    if (tag && MEDIA_TAGS[tag]) {
      setSelectedTags([tag]);
      setTempFilters(prev => ({ ...prev, selectedTags: [tag] }));
    }
    if (user === 'me') {
      setShowUserPosts(true);
      setTempFilters(prev => ({ ...prev, showUserPosts: true }));
    }
  }, [searchParams]);

  // Close filters when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowTagSelector(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Lock body scroll when mobile filters are open
  React.useEffect(() => {
    if (showMobileFilters) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [showMobileFilters]);

  // Group tags by category
  const tagsByCategory = React.useMemo(() => {
    return Object.entries(MEDIA_TAGS).reduce((acc, [id, tag]) => {
      if (!acc[tag.category]) {
        acc[tag.category] = [];
      }
      acc[tag.category].push({ id, ...tag });
      return acc;
    }, {} as Record<string, Array<{ id: string; icon: string; label: string; category: string }>>);
  }, []);

  const handleTagSelect = (tagId: string) => {
    if (showMobileFilters) {
      // Update temporary state for mobile
      setTempFilters(prev => ({
        ...prev,
        selectedTags: prev.selectedTags.includes(tagId)
          ? prev.selectedTags.filter(t => t !== tagId)
          : [tagId]
      }));
    } else {
      // Update main state for desktop
      setSelectedTags(prev => {
        const newTags = prev.includes(tagId)
          ? prev.filter(t => t !== tagId)
          : [tagId];
        return newTags;
      });
      setShowTagSelector(false);
    }
  };

  const handleSearch = () => {
    // Update URL params
    const params = new URLSearchParams();
    if (selectedTags.length > 0) {
      params.set('tag', selectedTags[0]);
    }
    if (showUserPosts) {
      params.set('user', 'me');
    }
    setSearchParams(params);

    // Trigger search
    onSearch({
      country: country || undefined,
      mediaType: mediaType === 'all' ? undefined : mediaType,
      sortBy,
      tags: selectedTags,
      userId: showUserPosts ? currentUserId : undefined
    });
  };

  // Apply mobile filters
  const handleApplyFilters = () => {
    // Update main state with temporary values
    setCountry(tempFilters.country);
    setMediaType(tempFilters.mediaType);
    setSortBy(tempFilters.sortBy);
    setSelectedTags(tempFilters.selectedTags);
    setShowUserPosts(tempFilters.showUserPosts);

    // Update URL params
    const params = new URLSearchParams();
    if (tempFilters.selectedTags.length > 0) {
      params.set('tag', tempFilters.selectedTags[0]);
    }
    if (tempFilters.showUserPosts) {
      params.set('user', 'me');
    }
    setSearchParams(params);

    // Trigger search with new filters
    onSearch({
      country: tempFilters.country || undefined,
      mediaType: tempFilters.mediaType === 'all' ? undefined : tempFilters.mediaType,
      sortBy: tempFilters.sortBy,
      tags: tempFilters.selectedTags,
      userId: tempFilters.showUserPosts ? currentUserId : undefined
    });

    // Close mobile filters
    setShowMobileFilters(false);
  };

  // Reset mobile filters
  const handleResetFilters = () => {
    setTempFilters({
      country: '',
      mediaType: 'all',
      sortBy: 'date',
      selectedTags: [],
      showUserPosts: false
    });
  };

  // Debounced search for desktop only
  React.useEffect(() => {
    if (!showMobileFilters) {
      const timer = setTimeout(() => {
        handleSearch();
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [country, mediaType, sortBy, selectedTags, showUserPosts]);

  return (
    <>
      {/* Mobile Filter Button */}
      <div className="sm:hidden mb-4">
        <button
          onClick={() => {
            setTempFilters({
              country,
              mediaType,
              sortBy,
              selectedTags,
              showUserPosts
            });
            setShowMobileFilters(true);
          }}
          className="w-full flex items-center justify-between px-4 py-3 bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700"
        >
          <div className="flex items-center gap-2">
            <Hash className="w-5 h-5 text-gray-400" />
            <span className="text-sm text-gray-700 dark:text-gray-300">
              {selectedTags.length > 0 
                ? `${selectedTags.length} tag${selectedTags.length > 1 ? 's' : ''} sélectionné${selectedTags.length > 1 ? 's' : ''}`
                : 'Filtrer les posts'}
            </span>
          </div>
          <ChevronDown className="w-5 h-5 text-gray-400" />
        </button>
      </div>

      {/* Desktop Filters */}
      <div className="hidden sm:block space-y-4 bg-white dark:bg-gray-800 rounded-xl p-4 sm:p-6 border border-gray-200/50 dark:border-gray-700/50">
        {/* Top Actions Row */}
        <div className="flex flex-wrap items-center gap-3">
          {/* Left side - Sort buttons */}
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                setSortBy('date');
                onSearch({
                  country: country || undefined,
                  mediaType: mediaType === 'all' ? undefined : mediaType,
                  sortBy: 'date',
                  tags: selectedTags,
                  userId: showUserPosts ? currentUserId : undefined
                });
              }}
              className={`flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-lg transition-colors ${
                sortBy === 'date'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <span>Plus récents</span>
            </button>
            <button
              onClick={() => {
                setSortBy('popularity');
                onSearch({
                  country: country || undefined,
                  mediaType: mediaType === 'all' ? undefined : mediaType,
                  sortBy: 'popularity',
                  tags: selectedTags,
                  userId: showUserPosts ? currentUserId : undefined
                });
              }}
              className={`flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-lg transition-colors ${
                sortBy === 'popularity'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <span>Populaires</span>
            </button>
          </div>

          {/* Right side - Media type filters */}
          <div className="flex items-center gap-2 ml-auto">
            <button
              onClick={() => {
                setShowUserPosts(!showUserPosts);
                if (!showUserPosts) {
                  setSearchParams(new URLSearchParams({ user: 'me' }));
                } else {
                  const params = new URLSearchParams(searchParams);
                  params.delete('user');
                  setSearchParams(params);
                }
              }}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
                showUserPosts
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <User className="w-5 h-5" />
              <span className="hidden sm:inline">Mes posts</span>
            </button>
            <button
              onClick={() => setMediaType('all')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
                mediaType === 'all'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <span className="text-lg">📱</span>
              <span className="hidden sm:inline">Tout</span>
            </button>
            <button
              onClick={() => setMediaType('image')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
                mediaType === 'image'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <ImageIcon className="w-5 h-5" />
              <span className="hidden sm:inline">Photos</span>
            </button>
            <button
              onClick={() => setMediaType('video')}
              className={`flex items-center gap-2 px-3 py-2 rounded-lg transition-colors ${
                mediaType === 'video'
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <Film className="w-5 h-5" />
              <span className="hidden sm:inline">Vidéos</span>
            </button>
          </div>
        </div>

        {/* Location and Tags Row */}
        <div className="flex flex-col sm:flex-row gap-3">
          {/* Country selector */}
          <div className="relative flex-1 sm:flex-initial">
            <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="w-full pl-10 pr-4 py-2.5 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white text-sm appearance-none"
            >
              <option value="">Tous les pays</option>
              {budgetCountries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.flag} {country.name}
                </option>
              ))}
            </select>
          </div>

          {/* Tags selector */}
          <div className="relative flex-1" ref={filterRef}>
            <button
              onClick={() => setShowTagSelector(!showTagSelector)}
              className="w-full flex items-center justify-between px-4 py-2.5 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <div className="flex items-center gap-2">
                <Hash className="w-4 h-4" />
                <span>Filtrer par thème</span>
                {selectedTags.length > 0 && (
                  <span className="px-1.5 py-0.5 bg-primary-100 dark:bg-primary-900/30 text-primary-600 dark:text-primary-400 rounded-full text-xs">
                    {selectedTags.length}
                  </span>
                )}
              </div>
              <ChevronDown className={`w-4 h-4 transition-transform duration-200 ${showTagSelector ? 'rotate-180' : ''}`} />
            </button>

            {showTagSelector && (
              <div className="absolute z-20 left-0 right-0 mt-2 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700">
                <div className="p-3 max-h-[60vh] overflow-y-auto">
                  {Object.entries(tagsByCategory).map(([category, tags]) => (
                    <div key={category} className="mb-4 last:mb-0">
                      <div className="px-2 py-1">
                        <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase">
                          {category === 'status' ? 'Étapes PVT' :
                           category === 'logement' ? 'Type de logement' :
                           category === 'travail' ? 'Type de travail' :
                           category === 'lifestyle' ? 'Style de vie' :
                           category === 'social' ? 'Social' : category}
                        </h4>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        {tags.map(tag => (
                          <button
                            key={tag.id}
                            onClick={() => handleTagSelect(tag.id)}
                            className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm transition-colors ${
                              selectedTags.includes(tag.id)
                                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                                : 'hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300'
                            }`}
                          >
                            <span>{tag.icon}</span>
                            <span className="truncate">{tag.label}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Selected Tags */}
        {selectedTags.length > 0 && (
          <div className="flex flex-wrap gap-2 pt-2">
            {selectedTags.map(tagId => {
              const tag = MEDIA_TAGS[tagId];
              return (
                <button
                  key={tagId}
                  onClick={() => handleTagSelect(tagId)}
                  className="inline-flex items-center gap-2 px-3 py-1.5 bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 rounded-lg text-sm border border-primary-100/50 dark:border-primary-800/30"
                >
                  <span>{tag.icon}</span>
                  <span>{tag.label}</span>
                  <span className="ml-1">×</span>
                </button>
              );
            })}
            <button
              onClick={() => {
                setSelectedTags([]);
                setSearchParams(new URLSearchParams());
              }}
              className="text-sm text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
            >
              Effacer tout
            </button>
          </div>
        )}
      </div>

      {/* Mobile Filter Sheet */}
      {showMobileFilters && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 sm:hidden">
          <div 
            className="fixed inset-x-0 bottom-0 bg-white dark:bg-gray-800 rounded-t-2xl max-h-[90vh] flex flex-col"
            style={{ height: '90vh' }}
          >
            <div className="sticky top-0 flex items-center justify-between p-4 border-b dark:border-gray-700 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Filtres
              </h3>
              <button
                onClick={() => setShowMobileFilters(false)}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto p-4 space-y-6">
              {/* Sort options */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Trier par</h4>
                <div className="flex gap-2">
                  <button
                    onClick={() => setTempFilters(prev => ({ ...prev, sortBy: 'date' }))}
                    className={`flex-1 py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                      tempFilters.sortBy === 'date'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Plus récents
                  </button>
                  <button
                    onClick={() => setTempFilters(prev => ({ ...prev, sortBy: 'popularity' }))}
                    className={`flex-1 py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                      tempFilters.sortBy === 'popularity'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Populaires
                  </button>
                </div>
              </div>

              {/* Media type filter */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Type de média</h4>
                <div className="grid grid-cols-3 gap-2">
                  <button
                    onClick={() => setTempFilters(prev => ({ ...prev, mediaType: 'all' }))}
                    className={`py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                      tempFilters.mediaType === 'all'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Tout
                  </button>
                  <button
                    onClick={() => setTempFilters(prev => ({ ...prev, mediaType: 'image' }))}
                    className={`py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                      tempFilters.mediaType === 'image'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Photos
                  </button>
                  <button
                    onClick={() => setTempFilters(prev => ({ ...prev, mediaType: 'video' }))}
                    className={`py-2 px-3 rounded-lg text-sm font-medium transition-colors ${
                      tempFilters.mediaType === 'video'
                        ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                        : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                    }`}
                  >
                    Vidéos
                  </button>
                </div>
              </div>

              {/* Country selector */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Pays</h4>
                <select
                  value={tempFilters.country}
                  onChange={(e) => setTempFilters(prev => ({ ...prev, country: e.target.value }))}
                  className="w-full px-3 py-2 bg-gray-100 dark:bg-gray-700 border-0 rounded-lg text-sm text-gray-700 dark:text-gray-300"
                >
                  <option value="">Tous les pays</option>
                  {budgetCountries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.flag} {country.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Tags */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">Thèmes</h4>
                <div className="space-y-4">
                  {Object.entries(tagsByCategory).map(([category, tags]) => (
                    <div key={category}>
                      <h5 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase mb-2">
                        {category === 'status' ? 'Étapes PVT' :
                         category === 'logement' ? 'Type de logement' :
                         category === 'travail' ? 'Type de travail' :
                         category === 'lifestyle' ? 'Style de vie' :
                         category === 'social' ? 'Social' : category}
                      </h5>
                      <div className="grid grid-cols-2 gap-2">
                        {tags.map(tag => (
                          <button
                            key={tag.id}
                            onClick={() => handleTagSelect(tag.id)}
                            className={`flex items-center gap-2 px-3 py-2 rounded-lg text-sm transition-colors ${
                              tempFilters.selectedTags.includes(tag.id)
                                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                                : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                            }`}
                          >
                            <span>{tag.icon}</span>
                            <span className="truncate">{tag.label}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="sticky bottom-0 p-4 border-t dark:border-gray-700 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm space-y-3">
              <button
                onClick={handleApplyFilters}
                className="w-full px-4 py-3 bg-primary-500 hover:bg-primary-600 text-white font-medium rounded-xl"
              >
                Appliquer les filtres
              </button>
              <button
                onClick={handleResetFilters}
                className="w-full px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 font-medium rounded-xl"
              >
                Réinitialiser
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}