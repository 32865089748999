import React from 'react';
import { Building2, Info, Plus, X } from 'lucide-react';

interface StepFourProps {
  formData: {
    firstLastMonthRent: string;
    furnitureCost: string;
    clothingCost: string;
    groceryCost: string;
    setupExpenses?: {
      id: string;
      name: string;
      amount: string;
    }[];
  };
  onFormDataChange: (data: any) => void;
}

export function StepFour({ formData, onFormDataChange }: StepFourProps) {
  const [showAddExpense, setShowAddExpense] = React.useState(false);
  const [newExpenseName, setNewExpenseName] = React.useState('');
  const [newExpenseAmount, setNewExpenseAmount] = React.useState('');

  // Validation des champs obligatoires
  const isValid = React.useMemo(() => {
    return (
      Number(formData.firstLastMonthRent) > 0 &&
      Number(formData.furnitureCost) > 0 &&
      Number(formData.clothingCost) > 0 &&
      Number(formData.groceryCost) > 0
    );
  }, [formData]);

  const handleAddExpense = () => {
    if (!newExpenseName.trim() || !newExpenseAmount.trim()) return;

    const currentExpenses = formData.setupExpenses || [];
    if (currentExpenses.length >= 3) {
      alert('Vous ne pouvez pas ajouter plus de 3 dépenses personnalisées.');
      return;
    }

    const newExpense = {
      id: Date.now().toString(),
      name: newExpenseName.trim(),
      amount: newExpenseAmount
    };

    onFormDataChange({
      ...formData,
      setupExpenses: [...currentExpenses, newExpense]
    });

    setNewExpenseName('');
    setNewExpenseAmount('');
    setShowAddExpense(false);
  };

  const handleDeleteExpense = (id: string) => {
    const updatedExpenses = (formData.setupExpenses || []).filter(exp => exp.id !== id);
    onFormDataChange({
      ...formData,
      setupExpenses: updatedExpenses
    });
  };

  const handleUpdateExpenseAmount = (id: string, amount: string) => {
    const updatedExpenses = (formData.setupExpenses || []).map(exp =>
      exp.id === id ? { ...exp, amount } : exp
    );
    onFormDataChange({
      ...formData,
      setupExpenses: updatedExpenses
    });
  };

  // Set default values when housing type changes
  React.useEffect(() => {
    if (formData.housingType && formData.housingTypes?.length) {
      const selectedHousing = formData.housingTypes.find(h => h.id === formData.housingType);
      if (selectedHousing) {
        const housingCost = selectedHousing.costEUR || selectedHousing.cost;
        onFormDataChange({
          ...formData,
          firstLastMonthRent: housingCost.toString(),
          furnitureCost: '50',
          clothingCost: '100',
          groceryCost: formData.groceriesCost || '0'
        });
      }
    }
  }, [formData.housingType, formData.housingTypes]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
      <div className="bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/20 dark:to-amber-800/20 rounded-xl p-4 border border-amber-100/50 dark:border-amber-800/30 mb-8">
        <div className="flex gap-3">
          <div className="flex-shrink-0">
            <Info className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-amber-800 dark:text-amber-300 mb-1">
              Estimations et moyennes
            </h3>
            <p className="text-sm text-amber-700 dark:text-amber-400">
              Les coûts d'installation sont des estimations moyennes. Les dépenses réelles dépendront de vos besoins spécifiques et des prix locaux au moment de votre installation.
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4 mb-8">
        <div className="p-3 bg-primary-50 dark:bg-primary-900/20 rounded-xl">
          <Building2 className="w-6 h-6 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            Dépenses d'installation
          </h3>
          <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
            Les frais à prévoir pour votre installation
          </p>
        </div>
      </div>

      <div className="space-y-6">
        {/* Caution et avance de loyer */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Caution et avance de loyer (€) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            value={formData.firstLastMonthRent}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              onFormDataChange({ ...formData, firstLastMonthRent: value.toString() });
            }}
            min="0"
            step="0.01"
            className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
            required
          />
          <div className="mt-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4 border border-blue-100 dark:border-blue-800/30">
            <div className="flex items-start gap-3">
              <Info className="w-4 h-4 text-blue-500 dark:text-blue-400 mt-0.5 flex-shrink-0" />
              <div className="space-y-2 text-sm text-blue-700 dark:text-blue-300">
                <p>
                  <strong>Dépôt de garantie et premier loyer :</strong> Les pratiques varient selon les pays et les propriétaires.
                </p>
                <ul className="list-disc pl-4 space-y-1">
                  <li>La caution représente généralement 1 à 2 mois de loyer</li>
                  <li>Le premier mois de loyer est souvent demandé à l'avance</li>
                  <li>Certains pays exigent également le dernier mois de loyer</li>
                </ul>
                <p className="text-xs mt-2 pt-2 border-t border-blue-200/30 dark:border-blue-700/30">
                  💡 Conseil : Prévoyez au minimum l'équivalent de 3 mois de loyer pour couvrir la caution et le premier mois.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Mobilier */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Mobilier (€) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            value={formData.furnitureCost}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              onFormDataChange({ ...formData, furnitureCost: value.toString() });
            }}
            min="0"
            step="0.01"
            className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
            required
          />
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Meubles de base, literie, ustensiles de cuisine...</span>
          </p>
        </div>

        {/* Vêtements */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Vêtements (€) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            value={formData.clothingCost}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              onFormDataChange({ ...formData, clothingCost: value.toString() });
            }}
            min="0"
            step="0.01"
            className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
            required
          />
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Vêtements adaptés au climat local</span>
          </p>
        </div>

        {/* Courses initiales */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Courses initiales (€) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            value={formData.groceryCost}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              onFormDataChange({ ...formData, groceryCost: value.toString() });
            }}
            min="0"
            step="0.01"
            className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
            required
          />
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Premières courses pour équiper votre cuisine</span>
          </p>
        </div>

        {/* Dépenses personnalisées */}
        {formData.setupExpenses && formData.setupExpenses.length > 0 && (
          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Dépenses personnalisées
            </h4>
            {formData.setupExpenses.map((expense) => (
              <div key={expense.id} className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl">
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                    {expense.name}
                  </p>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 dark:text-gray-400">€</span>
                    </div>
                    <input
                      type="number"
                      value={expense.amount}
                      onChange={(e) => handleUpdateExpenseAmount(expense.id, e.target.value)}
                      min="0"
                      step="0.01"
                      className="w-full pl-8 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white text-sm"
                    />
                  </div>
                </div>
                <button
                  onClick={() => handleDeleteExpense(expense.id)}
                  className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Bouton pour ajouter une dépense personnalisée */}
        {(!formData.setupExpenses || formData.setupExpenses.length < 3) && (
          <button
            type="button"
            onClick={() => setShowAddExpense(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-primary-600 dark:text-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/20 rounded-lg transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>Ajouter une dépense personnalisée</span>
          </button>
        )}

        {/* Modal pour ajouter une dépense */}
        {showAddExpense && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
              <div className="p-4 border-b dark:border-gray-700">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Ajouter une dépense d'installation
                </h3>
              </div>
              <div className="p-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom de la dépense
                  </label>
                  <input
                    type="text"
                    value={newExpenseName}
                    onChange={(e) => setNewExpenseName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                    placeholder="Ex: Véhicule, Permis de conduire..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Montant (€)
                  </label>
                  <input
                    type="number"
                    value={newExpenseAmount}
                    onChange={(e) => setNewExpenseAmount(e.target.value)}
                    min="0"
                    step="0.01"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddExpense(false);
                      setNewExpenseName('');
                      setNewExpenseAmount('');
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    onClick={handleAddExpense}
                    disabled={!newExpenseName.trim() || !newExpenseAmount}
                    className="px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:bg-gray-400 disabled:cursor-not-allowed rounded-lg transition-colors"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isValid && (
          <div className="mt-6 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100 dark:border-amber-800/30">
            <div className="flex items-center gap-2">
              <Info className="w-5 h-5 text-amber-500" />
              <p className="text-sm text-amber-700 dark:text-amber-300">
                Veuillez remplir tous les champs obligatoires avant de continuer
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}