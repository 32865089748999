import React from 'react';
import { Task, TaskStatus } from '../types/todo';
import { TaskHeader } from './task/TaskHeader';
import { TaskSubtasks } from './task/TaskSubtasks'; 
import { TaskActions } from './task/TaskActions';
import { TaskNotes } from './task/TaskNotes';
import { TaskResources } from './TaskResources';
import { MousePointerClick, ChevronDown, ChevronUp } from 'lucide-react';

interface TaskItemProps {
  task: Task;
  isPreview: boolean;
  departureDate?: string;
  returnDate?: string;
  onUpdateStatus: (taskId: string, status: TaskStatus) => void;
  onEditTask: (task: Task) => void;
  onDeleteTask: (taskId: string) => void;
  onAddSubtask: (taskId: string) => void;
  onToggleSubtask: (taskId: string, subtaskId: string) => void;
  onUpdateNotes: (taskId: string, notes: string) => void;
  onDeleteSubtask: (taskId: string, subtaskId: string) => void;
  onUpdatePriority: (taskId: string, priority: string) => void;
}

export function TaskItem({
  task,
  isPreview,
  departureDate,
  returnDate,
  onUpdateStatus,
  onEditTask,
  onDeleteTask,
  onAddSubtask,
  onToggleSubtask,
  onUpdateNotes,
  onDeleteSubtask,
  onUpdatePriority
}: TaskItemProps) {
  const taskRef = React.useRef<HTMLDivElement>(null);
  const [hasInteracted, setHasInteracted] = React.useState(() => {
    return localStorage.getItem('interactedTasks')?.includes(task.id) || false;
  });
  const [isCollapsed, setIsCollapsed] = React.useState(task.status === 'completed');

  // Mettre à jour l'état de collapse quand le statut change
  React.useEffect(() => {
    setIsCollapsed(task.status === 'completed');
  }, [task.status]);

  const getDaysRemaining = () => {
    if (!departureDate || (task.category === 'return' && !returnDate)) return null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const target = new Date(task.category === 'return' ? returnDate : departureDate);
    const diffTime = target.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleToggleCollapse = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };

  const handleTaskClick = (e: React.MouseEvent) => {
    // Check if the click was on an interactive element or if we're in preview mode
    const isInteractiveElement = (e.target as HTMLElement).closest('a, button, input, textarea, [role="button"]');
    if (isInteractiveElement || isPreview) return;

    // Stop event propagation
    e.stopPropagation();

    if (!hasInteracted) {
      setHasInteracted(true);
      const interactedTasks = JSON.parse(localStorage.getItem('interactedTasks') || '[]');
      localStorage.setItem('interactedTasks', JSON.stringify([...interactedTasks, task.id]));
    }

    const nextStatus: TaskStatus = 
      task.status === 'not-started' ? 'in-progress' :
      task.status === 'in-progress' ? 'completed' : 'not-started';
    
    onUpdateStatus(task.id, nextStatus);
  };

  const handleUpdateNotes = () => {
    onUpdateNotes(task.id, task.notes || '');
  };

  // Fonction pour obtenir les styles en fonction du statut
  const getStatusStyles = () => {
    if (isPreview) return '';
    
    switch (task.status) {
      case 'completed':
        return `
          bg-emerald-50 hover:bg-emerald-100 dark:bg-emerald-900/30
          border-emerald-200 dark:border-emerald-700 
          shadow-[0_0_0_1px_rgba(16,185,129,0.1)] dark:shadow-[0_0_0_1px_rgba(16,185,129,0.2)]
        `;
      case 'in-progress':
        return `
          bg-amber-50 hover:bg-amber-100 dark:bg-amber-900/30
          border-amber-200 dark:border-amber-700
          shadow-[0_0_0_1px_rgba(245,158,11,0.1)] dark:shadow-[0_0_0_1px_rgba(245,158,11,0.2)]
        `;
      default:
        return `
          bg-white hover:bg-gray-50 dark:bg-gray-800
          border-gray-200 dark:border-white/[0.08]
          shadow-sm dark:shadow-[0_0_0_1px_rgba(255,255,255,0.05)]
          dark:ring-1 dark:ring-white/[0.05]
        `;
    }
  };

  // Fonction pour obtenir les styles du contenu en fonction du statut
  const getContentStyles = () => {
    if (isPreview) return '';
    
    switch (task.status) {
      case 'completed':
        return 'opacity-80';
      default:
        return '';
    }
  };

  return (
    <div 
      ref={taskRef}
      id={`task-${task.id}`}
      onClick={handleTaskClick}
      className={`
        group relative rounded-xl border transition-all duration-300 
        ${!isPreview ? 'cursor-pointer' : ''}
        ${getStatusStyles()}
      `}
      onTouchStart={(e) => {
        // Prevent scroll when interacting with task
        if (taskRef.current && taskRef.current.contains(e.target as Node)) {
          e.stopPropagation();
        }
      }}
    >
      {!hasInteracted && !isPreview && (
        <div className="absolute -right-2 -top-2 z-10 animate-bounce">
          <div className="relative">
            <div className="absolute inset-0 bg-primary-500/20 rounded-full blur-md animate-pulse"></div>
            <div className="relative bg-white dark:bg-gray-800 rounded-full p-2 shadow-lg border border-primary-500/50">
              <MousePointerClick className="w-4 h-4 text-primary-500" />
            </div>
          </div>
        </div>
      )}

      <div className="p-4 sm:p-6">
        <div className={getContentStyles()}>
          <TaskHeader
            isCollapsed={isCollapsed}
            onToggleCollapse={handleToggleCollapse}
            task={task}
            isPreview={isPreview}
            daysRemaining={getDaysRemaining()}
            onUpdateStatus={onUpdateStatus}
            onUpdatePriority={onUpdatePriority}
          />

          <div className={`mt-6 space-y-6 transition-all duration-300 ${
            isCollapsed ? 'h-0 opacity-0 overflow-hidden' : 'h-auto opacity-100'
          }`}>
            <div className="mt-6 space-y-6" onClick={e => e.stopPropagation()}>
              {task.subtasks.length > 0 && (
                <TaskSubtasks
                  taskId={task.id}
                  subtasks={task.subtasks}
                  isPreview={isPreview}
                  onToggleSubtask={onToggleSubtask}
                  onDeleteSubtask={onDeleteSubtask}
                />
              )}

              {!isPreview && (
                <TaskActions
                  taskId={task.id}
                  isPreview={isPreview}
                  onAddSubtask={onAddSubtask}
                  onUpdateNotes={handleUpdateNotes}
                  hasNotes={!!task.notes}
                />
              )}

              {task.notes && <TaskNotes notes={task.notes} />}

              <TaskResources 
                taskId={task.id} 
                defaultTaskId={task.defaultTaskId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}