import React from 'react';
import { CheckCircle2, Circle, ChevronUp, ChevronDown, X, AlertTriangle } from 'lucide-react';
import { Subtask } from '../../types/todo';

interface TaskSubtasksProps {
  taskId: string;
  subtasks: Subtask[];
  isPreview: boolean;
  onToggleSubtask: (taskId: string, subtaskId: string) => void;
  onDeleteSubtask: (taskId: string, subtaskId: string) => void;
}

export function TaskSubtasks({
  taskId,
  subtasks,
  isPreview,
  onToggleSubtask,
  onDeleteSubtask
}: TaskSubtasksProps) {
  const [expandedSubtasks, setExpandedSubtasks] = React.useState<Set<string>>(new Set());
  const [subtaskToDelete, setSubtaskToDelete] = React.useState<{id: string; title: string} | null>(null);

  const toggleSubtask = (subtaskId: string) => {
    setExpandedSubtasks(prev => {
      const next = new Set(prev);
      if (next.has(subtaskId)) {
        next.delete(subtaskId);
      } else {
        next.add(subtaskId);
      }
      return next;
    });
  };

  const handleConfirmDelete = () => {
    if (subtaskToDelete) {
      onDeleteSubtask(taskId, subtaskToDelete.id);
      setSubtaskToDelete(null);
    }
  };

  const shouldTruncate = (text: string) => text.length > 100;

  return (
    <>
      <div className="mb-4">
        <h4 className="text-sm font-medium text-gray-800 dark:text-gray-200 mb-3">
          Sous-tâches ({subtasks.filter(st => st.completed).length}/{subtasks.length})
        </h4>
        <div className="space-y-2">
          {subtasks.map(subtask => (
            <div 
              key={subtask.id} 
              className="bg-gray-50 dark:bg-gray-700/30 rounded-lg border border-gray-200/80 dark:border-gray-600/30 transition-all duration-200 hover:border-gray-300 dark:hover:border-gray-500/50"
            >
              <div className="p-2.5">
                <div className="flex items-start justify-between gap-2">
                  <div className="flex items-start min-w-0">
                    <button
                      onClick={() => onToggleSubtask(taskId, subtask.id)}
                      className="flex-shrink-0 mt-0.5 mr-2"
                      disabled={isPreview}
                    >
                      {subtask.completed ? (
                        <CheckCircle2 className={`w-4 h-4 ${isPreview ? 'text-gray-400' : 'text-emerald-500'}`} />
                      ) : (
                        <Circle className={`w-4 h-4 ${isPreview ? 'text-gray-400' : 'text-gray-400'}`} />
                      )}
                    </button>
                    <div className="min-w-0 flex-1">
                      <div 
                        className={`text-sm text-gray-700 dark:text-gray-200 ${
                          subtask.completed ? 'line-through text-gray-500 dark:text-gray-500' : ''
                        } ${
                          !expandedSubtasks.has(subtask.id) && shouldTruncate(subtask.title) ? 'line-clamp-2' : ''
                        }`}
                      >
                        {subtask.title}
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-1 flex-shrink-0">
                    {shouldTruncate(subtask.title) && (
                      <button
                        onClick={() => toggleSubtask(subtask.id)}
                        className="p-1 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 transition-colors"
                        title={expandedSubtasks.has(subtask.id) ? "Réduire" : "Voir plus"}
                      >
                        {expandedSubtasks.has(subtask.id) ? (
                          <ChevronUp className="w-4 h-4" />
                        ) : (
                          <ChevronDown className="w-4 h-4" />
                        )}
                      </button>
                    )}
                    {!isPreview && (
                      <button
                        onClick={() => setSubtaskToDelete({ id: subtask.id, title: subtask.title })}
                        className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                        title="Supprimer la sous-tâche"
                      >
                        <X className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {subtaskToDelete && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <div className="flex items-center space-x-2">
                <AlertTriangle className="w-5 h-5 text-red-500" />
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Confirmer la suppression
                </h3>
              </div>
            </div>
            <div className="p-4 space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Êtes-vous sûr de vouloir supprimer la sous-tâche "{subtaskToDelete.title}" ? Cette action est irréversible.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setSubtaskToDelete(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={handleConfirmDelete}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}