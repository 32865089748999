import React from 'react';
import { ListChecks, Globe2, Shield, Sparkles } from 'lucide-react';

const features = [
  {
    icon: <ListChecks className="w-6 h-6 text-primary-500" />,
    title: "Checklists intelligentes",
    description: "Des listes de tâches personnalisées pour chaque pays, mises à jour régulièrement."
  },
  {
    icon: <Globe2 className="w-6 h-6 text-primary-500" />,
    title: "Multi-destinations",
    description: "Couvre tous les pays proposant des PVT aux Français."
  },
  {
    icon: <Shield className="w-6 h-6 text-primary-500" />,
    title: "Informations fiables",
    description: "Contenu vérifié et mis à jour par l'équipe pvtistes.net."
  },
  {
    icon: <Sparkles className="w-6 h-6 text-primary-500" />,
    title: "Interface intuitive",
    description: "Une expérience utilisateur fluide et agréable pour organiser votre PVT."
  }
];

export function FeaturesSection() {
  return (
    <section className="py-8 sm:py-12 bg-gradient-to-b from-white to-gray-50 dark:from-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white dark:bg-gray-800 rounded-xl shadow-sm sm:shadow-premium p-4 sm:p-6 border border-gray-200 dark:border-gray-700"
            >
              <div className="w-10 sm:w-12 h-10 sm:h-12 bg-primary-50 dark:bg-primary-900/20 rounded-xl flex items-center justify-center mb-4">
                {feature.icon}
              </div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2">
                {feature.title}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}