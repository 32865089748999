import React from 'react';
import { X } from 'lucide-react';

interface CustomExpense {
  id: string;
  name: string;
  amount: string;
}

interface CustomExpenseListProps {
  expenses: CustomExpense[];
  onUpdateAmount: (id: string, amount: string) => void;
  onDelete: (id: string) => void;
}

export function CustomExpenseList({ expenses, onUpdateAmount, onDelete }: CustomExpenseListProps) {
  if (expenses.length === 0) return null;

  return (
    <div className="space-y-4 relative">
      {/* Compteur de dépenses */}
      <div className="absolute -top-2 right-0 px-2 py-1 bg-gray-100 dark:bg-gray-700 rounded-full text-xs text-gray-600 dark:text-gray-400">
        {expenses.length}/3 dépenses
      </div>

      {expenses.map((expense) => (
        <div
          key={expense.id}
          className="group relative overflow-hidden bg-gradient-to-br dark:bg-gray-800/50 rounded-xl border border-gray-200/50 dark:border-gray-700/50 p-6 hover:shadow-lg transition-all duration-300"
        >
          <div className="relative">
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-start gap-3 flex-1">
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h4 className="font-medium text-gray-900 dark:text-white">
                      {expense.name}
                    </h4>
                    <button
                      onClick={() => onDelete(expense.id)}
                      className="p-1 hover:bg-red-100 dark:hover:bg-red-900/20 rounded-lg transition-colors group"
                      title="Supprimer"
                    >
                      <X className="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-red-500" />
                    </button>
                  </div>
                  <div className="relative mt-2">
                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                      <span className="text-gray-500 dark:text-gray-400">€</span>
                    </div>
                    <input
                      type="number"
                      value={expense.amount}
                      onChange={(e) => {
                        const value = Math.max(0, Number(e.target.value));
                        onUpdateAmount(expense.id, value.toString());
                      }}
                      min="0"
                      step="0.01"
                      className="w-full pl-8 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}