import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User, 
  signOut, 
  signInWithCustomToken,
  onAuthStateChanged,
  updateProfile
} from 'firebase/auth';
import { auth } from './firebase';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

interface AuthError {
  message: string;
  field?: string;
}

enum UserClaim {
  ADMIN = 'isAdmin',
  EMAIL_VERIFIED = 'email_verified'
}

type UserClaimValue = boolean | string | number;

interface UserData {
  id: number;
  username: string;
  email: string;
  birthdate?: string;
  avatar: any;
}

interface UserInfo {
  user: UserData;
  token: string;
  claims: Array<{ key: UserClaim; value: UserClaimValue }>;
}

interface AuthContextType {
  user: User | null;
  userInfo: UserInfo | null;
  loading: boolean;
  error: AuthError | null;
  signIn: (username: string, password: string) => Promise<void>;
  signUp: (username: string, email: string, password: string, birthDate: string, referralSource: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  verifySession: () => Promise<boolean>;
  isAdmin: boolean;
}

const AuthContext = createContext<AuthContextType | null>(null);

const SESSION_VERIFY_INTERVAL = 10 * 60 * 1000; // 10 minutes

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AuthError | null>(null);

  // Regular session verification
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (user && userInfo) {
      intervalId = setInterval(() => {
        checkSession();
      }, SESSION_VERIFY_INTERVAL);
    };

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user, userInfo]);

  const checkSession = async () => {
    try {
      
      const idTokenResult = await auth.currentUser.getIdTokenResult();

      const response = await fetch(`${API_BASE_URL}/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userInfo?.token}`
        },
        body: JSON.stringify(
          {
            id_token_result: idTokenResult
          }
        )
      });

      if (!response.ok) {
        console.error('Session verification failed');
        await logout();
        return false;
      }
      
      return true;

    } catch (error) {
      console.error('Session verification error:', error);
      await logout();
      return false;
    }

  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        verifySession();
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const verifySession = async () => {

    try {

      const tokenResult = await auth.currentUser.getIdTokenResult();

      const response = await fetch(`${API_BASE_URL}/verify`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${tokenResult.token}`
        }
      });

      if (!response.ok) {
        console.warn('Session verification failed:', response.status);
        await logout();
        return false;
      }

      const data = await response.json();

      const userClaims = Object.entries(tokenResult.claims)
        .filter(([key]) => Object.values(UserClaim).includes(key as UserClaim))
        .map(([key, value]) => ({ 
          key: key as UserClaim, 
          value: value as UserClaimValue 
        }));
      
      setUserInfo({
        ...data,
        claims: userClaims
      });
      await fbUpdateProfile(data);
      
      return true;
    } catch (error) {
      console.error('Session verification error:', error);
      await logout();
      return false;
    }
  };

  const fbUpdateProfile = async (data) => {

    if (auth.currentUser.displayName !== data.user.username) {
      await updateProfile(auth.currentUser, {
        displayName: data.user.username
      });
    }

  }

  const signIn = async (username: string, password: string) => {
    try {
      setError(null);
      setLoading(true);
      
      const response = await fetch(`${API_BASE_URL}/authorize`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
      });

      const data = await response.json();

      if (!response.ok) {

        let error = '';
        if (data.errors) {
          error = Object.values(data.errors)[0] as string;
        } else {
          error = 'Erreur de connexion';
        }
        setError({ message: error });
        throw new Error(error);
      }

      await signInWithCustomToken(auth, data.jwt_token);

    } catch (error) {
      console.error('Sign in error:', error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }

  };

  const signUp = async (username: string, email: string, password: string, birthdate: string, referralSource: string) => {
    try {
      setError(null);
      setLoading(true);

      const response = await fetch(`${API_BASE_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          email,
          password,
          birthdate,
          referralSource
        })
      });

      const data = await response.json();

      if (!response.ok) {

        let error = '';
        if (data.errors) {
          error = Object.values(data.errors)[0] as string;
        } else {
          error = 'Erreur de connexion';
        }
        setError({ message: error });
        throw new Error(error);
      }

      await signInWithCustomToken(auth, data.jwt_token);


    } catch (error) {
      console.error('Sign up error:', error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (email: string) => {
    try {
      setError(null);

      const response = await fetch(`${API_BASE_URL}/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.errors) {
          const firstError = Object.values(data.errors)[0] as string;
          setError({ message: firstError });
        } else {
          setError({ message: 'Erreur de réinitialisation' });
        }
        return;
      }

      setError({ message: 'Un email de réinitialisation a été envoyé' });
    } catch (error) {
      console.error('Password reset error:', error);
      setError({ message: 'Une erreur est survenue lors de la réinitialisation' });
    }
  };

  const logout = async () => {
    try {
      setError(null);
      if (userInfo) {
        await fetch(`${API_BASE_URL}/me/signout`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${userInfo?.token}`
          }
        });
      }
      await signOut(auth);
      setUser(null);
      setUserInfo(null);
    } catch (error) {
      console.error('Logout error:', error);
      setError({ message: 'Une erreur est survenue lors de la déconnexion' });
    }
  };

  function getClaim(claim: UserClaim): UserClaimValue {
    return userInfo?.claims.find(c => c.key === claim)?.value ?? false;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      userInfo,
      loading, 
      error, 
      signIn, 
      signUp, 
      logout,
      resetPassword,
      verifySession,
      isAdmin: getClaim(UserClaim.ADMIN),
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};