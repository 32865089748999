import { EmbassySection } from '../types/embassy';

export const embassyData: EmbassySection[] = [
  {
    countryCode: 'aus',
    countryName: 'Australie',
    flag: '🇦🇺',
    mainEmbassy: {
      id: 'aus-embassy',
      name: 'Ambassade de France en Australie',
      type: 'embassy',
      address: '6 Perth Avenue, Yarralumla ACT 2600',
      city: 'Canberra',
      country: 'Australie',
      countryCode: 'aus',
      phone: '+61 2 6216 0100',
      email: 'admin@ambafrance-au.org',
      website: 'https://au.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -35.309326,
        lng: 149.121119
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+61 2 6216 0120',
      notes: 'Prise de rendez-vous obligatoire pour les démarches consulaires'
    },
    consulates: [
      {
        id: 'aus-sydney',
        name: 'Consulat général de France à Sydney',
        type: 'consulate',
        address: 'St Martins Tower, Level 26, 31 Market Street',
        city: 'Sydney',
        country: 'Australie',
        countryCode: 'aus',
        phone: '+61 2 9268 2400',
        email: 'info@consulfrance-sydney.org',
        website: 'https://sydney.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h00'
        },
        coordinates: {
          lat: -33.870453,
          lng: 151.206753
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+61 2 9268 2400'
      }
    ]
  },
  {
    countryCode: 'nz',
    countryName: 'Nouvelle-Zélande',
    flag: '🇳🇿',
    mainEmbassy: {
      id: 'nz-embassy',
      name: 'Ambassade de France en Nouvelle-Zélande',
      type: 'embassy',
      address: 'Level 12, 94 Dixon Street, Te Aro',
      city: 'Wellington',
      country: 'Nouvelle-Zélande',
      countryCode: 'nz',
      phone: '+64 4 384 25 55',
      email: 'admin@ambafrance-nz.org',
      website: 'https://nz.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -41.290668,
        lng: 174.775675
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+64 4 384 25 55',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'ca',
    countryName: 'Canada',
    flag: '🇨🇦',
    mainEmbassy: {
      id: 'ca-embassy',
      name: 'Ambassade de France au Canada',
      type: 'embassy',
      address: '42 Sussex Drive',
      city: 'Ottawa',
      country: 'Canada',
      countryCode: 'ca',
      phone: '+1 613-789-1795',
      email: 'admin@ambafrance-ca.org',
      website: 'https://ca.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 45.444369,
        lng: -75.693832
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+1 613-789-1795',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: [
      {
        id: 'ca-montreal',
        name: 'Consulat général de France à Montréal',
        type: 'consulate',
        address: '1501 McGill College Avenue',
        city: 'Montréal',
        country: 'Canada',
        countryCode: 'ca',
        phone: '+1 514-878-4385',
        email: 'info@consulfrance-montreal.org',
        website: 'https://montreal.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 8h30 - 16h30'
        },
        coordinates: {
          lat: 45.502346,
          lng: -73.572954
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+1 514-878-4385'
      },
      {
        id: 'ca-quebec',
        name: 'Consulat général de France à Québec',
        type: 'consulate',
        address: '500 Grande Allée Est',
        city: 'Québec',
        country: 'Canada',
        countryCode: 'ca',
        phone: '+1 418-266-2500',
        email: 'info@consulfrance-quebec.org',
        website: 'https://quebec.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h30'
        },
        coordinates: {
          lat: 46.806302,
          lng: -71.216034
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+1 418-266-2500'
      },
      {
        id: 'ca-toronto',
        name: 'Consulat général de France à Toronto',
        type: 'consulate',
        address: '2 Bloor Street East, Suite 2200',
        city: 'Toronto',
        country: 'Canada',
        countryCode: 'ca',
        phone: '+1 416-847-1900',
        email: 'info@consulfrance-toronto.org',
        website: 'https://toronto.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h30'
        },
        coordinates: {
          lat: 43.670460,
          lng: -79.386665
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+1 416-847-1900'
      },
      {
        id: 'ca-vancouver',
        name: 'Consulat général de France à Vancouver',
        type: 'consulate',
        address: '1130 West Pender Street, Suite 1100',
        city: 'Vancouver',
        country: 'Canada',
        countryCode: 'ca',
        phone: '+1 604-681-4345',
        email: 'info@consulfrance-vancouver.org',
        website: 'https://vancouver.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h30'
        },
        coordinates: {
          lat: 49.286339,
          lng: -123.119141
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+1 604-681-4345'
      }
    ]
  },
  {
    countryCode: 'jpn',
    countryName: 'Japon',
    flag: '🇯🇵',
    mainEmbassy: {
      id: 'jpn-embassy',
      name: 'Ambassade de France au Japon',
      type: 'embassy',
      address: '4-11-44 Minami-Azabu, Minato-ku',
      city: 'Tokyo',
      country: 'Japon',
      countryCode: 'jpn',
      phone: '+81 3-5798-6000',
      email: 'admin@ambafrance-jp.org',
      website: 'https://jp.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h30 - 17h30',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 35.650638,
        lng: 139.731325
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+81 3-5798-6000',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: [
      {
        id: 'jpn-kyoto',
        name: 'Consulat général de France à Kyoto',
        type: 'consulate',
        address: '8 Izumidono-cho Yoshida, Sakyo-ku',
        city: 'Kyoto',
        country: 'Japon',
        countryCode: 'jpn',
        phone: '+81 75-761-2988',
        email: 'info@consulfrance-kyoto.org',
        website: 'https://kyoto.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h30 - 17h00'
        },
        coordinates: {
          lat: 35.026971,
          lng: 135.786824
        },
        services: [
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+81 75-761-2988'
      }
    ]
  },
  {
    countryCode: 'kor',
    countryName: 'Corée du Sud',
    flag: '🇰🇷',
    mainEmbassy: {
      id: 'kor-embassy',
      name: 'Ambassade de France en Corée du Sud',
      type: 'embassy',
      address: '43-12 Seosomun-ro, Seodaemun-gu',
      city: 'Séoul',
      country: 'Corée du Sud',
      countryCode: 'kor',
      phone: '+82 2-3149-4300',
      email: 'admin@ambafrance-kr.org',
      website: 'https://kr.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 37.564718,
        lng: 126.969020
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+82 2-3149-4300',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'twn',
    countryName: 'Taïwan',
    flag: '🇹🇼',
    mainEmbassy: {
      id: 'twn-office',
      name: 'Bureau Français de Taipei',
      type: 'embassy',
      address: 'Room 1003, 10F, 205 Tun Hwa North Road',
      city: 'Taipei',
      country: 'Taïwan',
      countryCode: 'twn',
      phone: '+886 2-3518-5151',
      email: 'contact@france-taipei.org',
      website: 'https://france-taipei.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 25.079642,
        lng: 121.544837
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+886 2-3518-5151',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'arg',
    countryName: 'Argentine',
    flag: '🇦🇷',
    mainEmbassy: {
      id: 'arg-embassy',
      name: 'Ambassade de France en Argentine',
      type: 'embassy',
      address: 'Cerrito 1399',
      city: 'Buenos Aires',
      country: 'Argentine',
      countryCode: 'arg',
      phone: '+54 11 4515-7030',
      email: 'admin@ambafrance-ar.org',
      website: 'https://ar.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -34.595850,
        lng: -58.383057
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+54 11 4515-7030',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'chl',
    countryName: 'Chili',
    flag: '🇨🇱',
    mainEmbassy: {
      id: 'chl-embassy',
      name: 'Ambassade de France au Chili',
      type: 'embassy',
      address: 'Condell 65, Providencia',
      city: 'Santiago',
      country: 'Chili',
      countryCode: 'chl',
      phone: '+56 2 2470 8000',
      email: 'admin@ambafrance-cl.org',
      website: 'https://cl.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -33.435638,
        lng: -70.626234
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+56 2 2470 8000',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'col',
    countryName: 'Colombie',
    flag: '🇨🇴',
    mainEmbassy: {
      id: 'col-embassy',
      name: 'Ambassade de France en Colombie',
      type: 'embassy',
      address: 'Carrera 11 No. 93-12',
      city: 'Bogotá',
      country: 'Colombie',
      countryCode: 'col',
      phone: '+57 1 638 1400',
      email: 'admin@ambafrance-co.org',
      website: 'https://co.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 8h30 - 16h30',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 4.676940,
        lng: -74.047314
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+57 1 638 1400',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'mex',
    countryName: 'Mexique',
    flag: '🇲🇽',
    mainEmbassy: {
      id: 'mex-embassy',
      name: 'Ambassade de France au Mexique',
      type: 'embassy',
      address: 'Campos Elíseos 339, Polanco',
      city: 'Mexico',
      country: 'Mexique',
      countryCode: 'mex',
      phone: '+52 55 9171 9700',
      email: 'admin@ambafrance-mx.org',
      website: 'https://mx.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 8h30 - 16h30',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 19.428470,
        lng: -99.189972
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+52 55 9171 9700',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'per',
    countryName: 'Pérou',
    flag: '🇵🇪',
    mainEmbassy: {
      id: 'per-embassy',
      name: 'Ambassade de France au Pérou',
      type: 'embassy',
      address: 'Avenida Arequipa 3415, San Isidro',
      city: 'Lima',
      country: 'Pérou',
      countryCode: 'per',
      phone: '+51 1 215 8400',
      email: 'admin@ambafrance-pe.org',
      website: 'https://pe.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -12.094714,
        lng: -77.031167
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+51 1 215 8400',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'uru',
    countryName: 'Uruguay',
    flag: '🇺🇾',
    mainEmbassy: {
      id: 'uru-embassy',
      name: 'Ambassade de France en Uruguay',
      type: 'embassy',
      address: 'Bulevar Artigas 1341',
      city: 'Montevideo',
      country: 'Uruguay',
      countryCode: 'uru',
      phone: '+598 2 1724 0000',
      email: 'admin@ambafrance-uy.org',
      website: 'https://uy.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -34.906476,
        lng: -56.164279
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+598 2 1724 0000',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  },
  {
    countryCode: 'bra',
    countryName: 'Brésil',
    flag: '🇧🇷',
    mainEmbassy: {
      id: 'bra-embassy',
      name: 'Ambassade de France au Brésil',
      type: 'embassy',
      address: 'SES Avenida das Nações, Lote 04, Quadra 801',
      city: 'Brasília',
      country: 'Brésil',
      countryCode: 'bra',
      phone: '+55 61 3222-3999',
      email: 'admin@ambafrance-br.org',
      website: 'https://br.ambafrance.org',
      hours: {
        regular: 'Lundi au vendredi : 8h30 - 16h30',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: -15.802146,
        lng: -47.875757
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+55 61 3222-3999',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: [
      {
        id: 'bra-rio',
        name: 'Consulat général de France à Rio de Janeiro',
        type: 'consulate',
        address: 'Avenida Presidente Antônio Carlos 58',
        city: 'Rio de Janeiro',
        country: 'Brésil',
        countryCode: 'bra',
        phone: '+55 21 3974-6699',
        email: 'info@consulfrance-rio.org',
        website: 'https://riodejaneiro.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h00'
        },
        coordinates: {
          lat: -22.904315,
          lng: -43.174591
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+55 21 3974-6699'
      },
      {
        id: 'bra-sao-paulo',
        name: 'Consulat général de France à São Paulo',
        type: 'consulate',
        address: 'Avenida Paulista 1842',
        city: 'São Paulo',
        country: 'Brésil',
        countryCode: 'bra',
        phone: '+55 11 3371-5400',
        email: 'info@consulfrance-saopaulo.org',
        website: 'https://saopaulo.consulfrance.org',
        hours: {
          regular: 'Lundi au vendredi : 9h00 - 16h00'
        },
        coordinates: {
          lat: -23.561540,
          lng: -46.656141
        },
        services: [
          'Service des visas',
          'État civil',
          'Documents d\'identité'
        ],
        emergencyNumber: '+55 11 3371-5400'
      }
    ]
  },
  {
    countryCode: 'hkg',
    countryName: 'Hong Kong',
    flag: '🇭🇰',
    mainEmbassy: {
      id: 'hkg-consulate',
      name: 'Consulat général de France à Hong Kong et Macao',
      type: 'consulate',
      address: '26/F, Tower II, Admiralty Centre, 18 Harcourt Road',
      city: 'Hong Kong',
      country: 'Hong Kong',
      countryCode: 'hkg',
      phone: '+852 3752 9900',
      email: 'info@consulfrance-hongkong.org',
      website: 'https://hongkong.consulfrance.org',
      hours: {
        regular: 'Lundi au vendredi : 9h00 - 17h00',
        emergency: '24h/24 pour les urgences consulaires'
      },
      coordinates: {
        lat: 22.279932,
        lng: 114.165769
      },
      services: [
        'Service des visas',
        'État civil',
        'Documents d\'identité',
        'Protection consulaire'
      ],
      emergencyNumber: '+852 3752 9900',
      notes: 'Prise de rendez-vous en ligne obligatoire'
    },
    consulates: []
  }
];