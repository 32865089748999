import React from 'react';
import { SavedBudget } from '../../types/budget';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Building2, Calendar, PiggyBank, ArrowRight, Trash2 } from 'lucide-react';

interface SavedBudgetsListProps {
  budgets: SavedBudget[];
  onSelectBudget: (budget: SavedBudget) => void;
  onDeleteBudget: (budget: SavedBudget) => void;
  isLoading: boolean;
  showLimitWarning?: boolean;
}

export function SavedBudgetsList({ budgets, onSelectBudget, onDeleteBudget, isLoading, showLimitWarning }: SavedBudgetsListProps) {
  if (isLoading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="bg-white dark:bg-gray-800 rounded-xl p-6 animate-pulse">
            <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-1/3 mb-4" />
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/2" />
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (budgets.length === 0) {
    return (
      <div className="bg-gray-50 dark:bg-gray-800/50 rounded-xl p-6 text-center">
        <p className="text-gray-600 dark:text-gray-400">
          Aucun budget sauvegardé
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {budgets.map((budget) => (
        <div 
          key={budget.id}
          className={`relative bg-white dark:bg-gray-800 rounded-xl p-6 hover:shadow-lg transition-all duration-200 group border border-gray-200/50 dark:border-gray-700/50 hover:border-primary-200 dark:hover:border-primary-700 ${
            showLimitWarning ? 'ring-2 ring-amber-500/20 dark:ring-amber-400/20' : ''
          }`}
        >
          <div className="absolute top-3 right-3 flex items-center gap-3">
            <button
              onClick={() => onSelectBudget(budget)}
              className="hidden sm:flex items-center gap-2 px-3 py-1.5 text-primary-600 dark:text-primary-400 bg-primary-50 dark:bg-primary-900/20 hover:bg-primary-100 dark:hover:bg-primary-900/30 rounded-lg transition-all duration-200 border border-primary-100/50 dark:border-primary-800/30 group-hover:shadow-md"
            >
              <span className="text-sm font-medium">Voir le détail</span>
              <ArrowRight className="w-4 h-4 transform transition-transform group-hover:translate-x-1" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDeleteBudget(budget);
              }}
              className="p-2 text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 border border-gray-200/50 dark:border-gray-700/50"
              title="Supprimer ce budget"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>

          <button
            onClick={() => onSelectBudget(budget)}
            className="w-full text-left focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800 rounded-lg"
          >
            <div className="flex items-center mb-4 pr-12">
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-lg bg-primary-50 dark:bg-primary-900/20">
                  <Building2 className="w-5 h-5 text-primary-500" />
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {budget.city}, {budget.country}
                  </h3>
                  <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
                    <Calendar className="w-4 h-4" />
                    <span>
                      {format(budget.createdAt, 'dd MMMM yyyy', { locale: fr })}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-3">
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-1">
                  <PiggyBank className="w-4 h-4" />
                  <span>Budget total</span>
                </div>
                <span className="text-lg font-semibold text-gray-900 dark:text-white">
                  {budget.totalBudget.toLocaleString('fr-FR')} €
                </span>
              </div>
              
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-3">
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-1">
                  <Building2 className="w-4 h-4" />
                  <span>Dépenses mensuelles</span>
                </div>
                <span className="text-lg font-semibold text-gray-900 dark:text-white">
                  {budget.monthlyExpenses.toLocaleString('fr-FR')} €
                </span>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-3">
                <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400 mb-1">
                  <Calendar className="w-4 h-4" />
                  <span>Durée PVT</span>
                </div>
                <span className="text-lg font-semibold text-gray-900 dark:text-white">
                  {budget.data.pvtDuration} mois
                </span>
              </div>
              <div className="sm:hidden flex items-center justify-center gap-2 mt-4 p-2 text-primary-600 dark:text-primary-400 bg-primary-50 dark:bg-primary-900/20 rounded-lg border border-primary-100/50 dark:border-primary-800/30">
                <span className="text-sm font-medium">Voir le détail</span>
                <ArrowRight className="w-4 h-4" />
              </div>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}