import React from 'react';
import { Building2 } from 'lucide-react';

interface LoadingOverlayProps {
  city: string;
  countryName?: string;
}

export function LoadingOverlay({ city, countryName }: LoadingOverlayProps) {
  return (
    <div className="absolute inset-0 bg-white/80 dark:bg-gray-800/80 backdrop-blur-md flex items-center justify-center z-10 rounded-xl">
      <div className="text-center">
        <div className="relative w-16 h-16 mx-auto mb-4">
          {/* Rotating circle */}
          <div className="absolute inset-0 border-4 border-primary-200 dark:border-primary-800 rounded-full animate-[spin_3s_linear_infinite]" />
          <div className="absolute inset-0 border-4 border-transparent border-t-primary-500 rounded-full animate-[spin_2s_linear_infinite]" />
          <div className="absolute inset-0 border-4 border-transparent border-t-accent-500 rounded-full animate-[spin_1.5s_linear_infinite]" />
          
          {/* Center icon */}
          <div className="absolute inset-0 flex items-center justify-center">
            <Building2 className="w-6 h-6 text-primary-500 dark:text-primary-400 animate-pulse" />
          </div>
        </div>
        
        <h3 className="text-base font-medium text-gray-900 dark:text-white mb-2">
          Récupération des données
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-400 max-w-[280px] mx-auto">
          Nous récupérons les coûts de la vie pour {city}
          {countryName && <span>, {countryName}</span>}...
        </p>
      </div>
    </div>
  );
}