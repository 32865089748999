import React from 'react';

interface AcsSectionProps {
  title: string;
  content: string;
  image: string;
}

export function AcsSection({ title, content, image }: AcsSectionProps) {
  return (
    <div className="py-4 sm:py-12 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-8 sm:gap-12 items-center">
          <div className="max-w-xl">
            <img 
              src="https://pvtistes.net/wp-content/themes/pvtistest-2/static/images/acs/logo.png"
              alt="ACS"
              className="h-12 sm:h-16 w-auto mb-6 sm:mb-8"
            />
            <h2 
              className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300 bg-clip-text text-transparent mb-6"
            >
              Votre partenaire assurance depuis plus de 40 ans
            </h2>
            <div className="text-[13px] sm:text-base text-gray-600 dark:text-gray-400 space-y-4">
              <p>Découvrez l'assurance Globe PVT proposée par notre partenaire exclusif ACS - Assurances voyage & expatriation.</p>
              <p>Spécialiste de la mobilité internationale, ACS accompagne au plus près voyageurs et expatriés aux 4 coins du monde.</p>
              <p>ACS propose des solutions d'assurance santé et d'assistance adaptées, en gardant un excellent rapport qualité-prix, afin que chacun puisse se déplacer à travers le monde en toute sérénité. Chaque année, plus de 100 000 personnes font confiance à ACS.</p>
              <p className="text-xs text-gray-500 dark:text-gray-400 italic">Cette page, ainsi que toutes les informations relatives à l'assurance, sont rédigées sous la responsabilité exclusive d'ACS, société de courtage d'assurance.</p>
            </div>
          </div>
          <div>
            <img 
              src={image}
              alt="Globe PVT Benefits"
              className="w-full h-auto rounded-xl sm:rounded-2xl shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}