import React from 'react';
import { Info } from 'lucide-react';
import { UserPlus, X } from 'lucide-react';
import { TravelStyle, Language, Interest } from '../../types/matching';
import { StepOne } from './steps/StepOne';
import { StepTwo } from './steps/StepTwo';
import { StepThree } from './steps/StepThree';

interface CreateListingFormProps {
  onSubmit: (data: {
    firstName: string;
    age: number;
    location: string;
    bio: string;
    languages: Language[];
    interests: Interest[];
    preferences: {
      style: TravelStyle;
      budget: {
        min: number;
        max: number;
        currency: string;
      };
      startDate: Date;
      endDate: Date;
      flexibility: number;
      destination: string;
      city: string;
    };
  }) => void;
  onClose: () => void;
  initialData?: any;
  isEditing?: boolean;
}

export function CreateListingForm({ onSubmit, onClose, initialData, isEditing = false }: CreateListingFormProps) {
  const [step, setStep] = React.useState(1);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  // Lock body scroll when modal is open
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const [formData, setFormData] = React.useState({
    firstName: initialData?.firstName || '',
    birthDate: initialData?.birthDate || '',
    location: initialData?.location || '',
    bio: initialData?.bio || '',
    languages: initialData?.languages || [] as Language[],
    interests: initialData?.interests || [] as Interest[],
    preferences: {
      style: initialData?.preferences?.style || 'mixed' as TravelStyle,
      budget: {
        min: initialData?.preferences?.budget?.min || 1000,
        max: initialData?.preferences?.budget?.max || 3000,
        currency: initialData?.preferences?.budget?.currency || 'EUR'
      },
      startDate: initialData?.preferences?.startDate || new Date(),
      endDate: initialData?.preferences?.endDate || new Date(),
      flexibility: initialData?.preferences?.flexibility || 30,
      destination: initialData?.preferences?.destination || '',
      city: initialData?.preferences?.city || ''
    },
    pvtStatus: initialData?.pvtStatus || '',
    objectives: initialData?.objectives || []
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Validation détaillée
      const errors = [];

      // Validation du statut PVT
      if (!formData.pvtStatus) {
        errors.push('Le statut du PVT est obligatoire');
      }

      // Étape 1
      if (!formData.pvtStatus) {
        errors.push('Le statut du PVT est obligatoire');
      }
      // Étape 1
      if (!formData.firstName.trim()) {
        errors.push('Le prénom est obligatoire');
      }
      if (!formData.birthDate) {
        errors.push('La date de naissance est obligatoire');
      }
      if (!formData.location.trim()) {
        errors.push('La ville actuelle est obligatoire');
      }
      if (!formData.bio.trim()) {
        errors.push('La bio est obligatoire');
      }

      // Étape 2
      if (!formData.preferences.destination) {
        errors.push('La destination est obligatoire');
      }
      if (!formData.preferences.city) {
        errors.push('La ville de destination est obligatoire');
      }
      if (!formData.preferences.startDate) {
        errors.push('La date de départ est obligatoire');
      }
      if (!formData.preferences.endDate) {
        errors.push('La date de retour est obligatoire');
      }

      // Étape 3
      if (formData.languages.length === 0) {
        errors.push('Au moins une langue est obligatoire');
      }
      if (formData.interests.length === 0) {
        errors.push('Au moins un centre d\'intérêt est obligatoire');
      }

      if (errors.length > 0) {
        alert(errors.join('\n'));
        return;
      }

      // Validation de l'âge
      const birthDate = new Date(formData.birthDate);
      const age = Math.floor((new Date().getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25));
      if (age < 18 || age > 35) {
        alert('Vous devez avoir entre 18 et 35 ans pour participer au PVT');
        return;
      }

      // Validation des dates
      if (formData.preferences.endDate < formData.preferences.startDate) {
        alert('La date de retour doit être après la date de départ');
        return;
      }

      // Validation du budget
      if (formData.preferences.budget.max < formData.preferences.budget.min) {
        alert('Le budget maximum doit être supérieur au budget minimum');
        return;
      }

      onSubmit(formData);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateStep = (currentStep: number): boolean => {
    const newErrors: string[] = [];

    switch (currentStep) {
      case 1:
        if (!formData.pvtStatus) newErrors.push('Le statut du PVT est obligatoire');
        if (!formData.firstName.trim()) newErrors.push('Le prénom est obligatoire');
        if (!formData.birthDate) newErrors.push('La date de naissance est obligatoire');
        if (!formData.location.trim()) newErrors.push('La ville actuelle est obligatoire');
        if (!formData.bio.trim()) newErrors.push('La bio est obligatoire');
        break;

      case 2:
        if (!formData.preferences.destination) newErrors.push('La destination est obligatoire');
        if (!formData.preferences.city) newErrors.push('La ville de destination est obligatoire');
        if (!formData.preferences.startDate) newErrors.push('La date de départ est obligatoire');
        if (!formData.preferences.endDate) newErrors.push('La date de retour est obligatoire');
        if (formData.preferences.endDate < formData.preferences.startDate) {
          newErrors.push('La date de retour doit être après la date de départ');
        }
        if (formData.preferences.budget.max < formData.preferences.budget.min) {
          newErrors.push('Le budget maximum doit être supérieur au budget minimum');
        }
        break;

      case 3:
        if (formData.languages.length === 0) newErrors.push('Au moins une langue est obligatoire');
        if (formData.interests.length === 0) newErrors.push('Au moins un centre d\'intérêt est obligatoire');
        break;
    }

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleNextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
      setErrors([]);
      // Scroll to top on mobile
      if (window.innerWidth < 768) {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50 p-0 sm:p-4">
      <div className="relative w-full h-full sm:h-auto sm:max-h-[85vh] bg-white dark:bg-gray-800 sm:rounded-2xl shadow-premium sm:max-w-[1200px] flex flex-col">
        {/* Header */}
        <div className="sticky top-0 z-20 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b dark:border-gray-700">
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                <UserPlus className="w-5 h-5 text-primary-500" />
              </div>
              <div>
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {isEditing ? 'Modifier l\'annonce' : 'Créer une annonce'}
                </h2>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Étape {step} sur 3
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>

          {/* Progress bar */}
          <div className="h-1 bg-gray-200 dark:bg-gray-700">
            <div
              className="h-full bg-primary-500 transition-all duration-300"
              style={{ width: `${(step / 3) * 100}%` }}
            />
          </div>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 sm:p-6 space-y-6">
            {/* Error Messages */}
            {errors.length > 0 && (
              <div className="mb-6 p-4 bg-gradient-to-br from-amber-50 to-amber-100/50 dark:from-amber-900/20 dark:to-amber-800/20 rounded-xl border border-amber-100/50 dark:border-amber-800/30">
                <div className="flex items-start gap-3">
                  <div className="p-1.5 bg-amber-100 dark:bg-amber-900/30 rounded-lg">
                    <Info className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-amber-800 dark:text-amber-300 mb-2">
                      {step === 3 ? 'Informations manquantes' : 'Champs obligatoires'}
                    </h4>
                    <ul className="space-y-1.5">
                      {errors.map((error, index) => (
                        <li key={index} className="flex items-center gap-2 text-sm text-amber-700 dark:text-amber-400">
                          <div className="w-1 h-1 rounded-full bg-amber-500/50 dark:bg-amber-400/50" />
                          <span>{error}</span>
                        </li>
                      ))}
                    </ul>
                    <p className="mt-3 text-xs text-amber-600 dark:text-amber-400">
                      {step === 3 
                        ? "Ces informations sont nécessaires pour optimiser vos correspondances avec d'autres voyageurs."
                        : 'Veuillez remplir tous les champs obligatoires avant de continuer.'}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* Form Steps */}
            {step === 1 && <StepOne formData={formData} onFormDataChange={setFormData} errors={errors} />}
            {step === 2 && <StepTwo formData={formData} onFormDataChange={setFormData} errors={errors} />}
            {step === 3 && <StepThree formData={formData} onFormDataChange={setFormData} errors={errors} />}
          </div>
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 p-4 sm:p-6 border-t dark:border-gray-700 bg-gradient-to-br from-gray-50 to-white dark:from-gray-800/50 dark:to-gray-800">
          <div className="flex justify-between">
            <div>
              {step > 1 ? (
                <button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className="flex items-center gap-2 px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl transition-all duration-200 border border-gray-200 dark:border-gray-700"
                >
                  Retour
                </button>
              ) : (
                <button
                  type="button"
                  onClick={onClose}
                  className="flex items-center gap-2 px-6 py-3 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl transition-all duration-200 border border-gray-200 dark:border-gray-700"
                >
                  Annuler
                </button>
              )}
            </div>
            <div>
              {step < 3 ? (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 text-white text-sm font-medium rounded-xl transition-all duration-300 transform hover:scale-[1.02] shadow-sm hover:shadow-md"
                >
                  Suivant
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 text-white text-sm font-medium rounded-xl transition-all duration-300 transform hover:scale-[1.02] shadow-sm hover:shadow-md"
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                      <span>{isEditing ? 'Mise à jour...' : 'Publication...'}</span>
                    </>
                  ) : (
                    <span>{isEditing ? 'Mettre à jour' : 'Publier l\'annonce'}</span>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}