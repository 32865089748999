import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { insuranceData as staticInsuranceData } from '../data/insurance';
import { HeroSection } from '../components/insurance/HeroSection';
import { AcsSection } from '../components/insurance/AcsSection';
import { GeneralInfoSection } from '../components/insurance/GeneralInfoSection';
import { WhyChooseSection } from '../components/insurance/WhyChooseSection';
import { TestimonialsSection } from '../components/insurance/TestimonialsSection';
import { FaqSection } from '../components/insurance/FaqSection';
import { CtaSection } from '../components/insurance/CtaSection';
import { EmergencyInstructions } from '../components/insurance/EmergencyInstructions';
import { LegalFooter } from '../components/insurance/LegalFooter';
import { VideoSection } from '../components/insurance/VideoSection';
import { GuaranteesSection } from '../components/insurance/GuaranteesSection';
import { PricingSection } from '../components/insurance/PricingSection';

export function InsurancePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [insuranceData] = React.useState(staticInsuranceData);
  const [expandedFaqSection, setExpandedFaqSection] = React.useState<string | null>(null);
  const [expandedQuestion, setExpandedQuestion] = React.useState<string | null>(null);
  const [selectedFaqCategory, setSelectedFaqCategory] = React.useState<'all' | 'general' | 'canada' | 'other' | 'card'>('all');

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  // Valider et nettoyer les données de la FAQ
  const validFaqSections = React.useMemo(() => {
    if (!insuranceData?.faq?.sections) return [];
    
    const sections = insuranceData.faq.sections
      .filter(section => {
        return section && 
               typeof section.title === 'string' && 
               Array.isArray(section.questions) &&
               section.questions.length > 0;
      })
      .map(section => ({
        ...section,
        questions: section.questions.filter(q => 
          q && 
          typeof q.question === 'string' && 
          typeof q.answer === 'string' &&
          q.question.trim() !== '' &&
          q.answer.trim() !== ''
        ),
        category: section.title === 'Questions pour toutes les destinations'
          ? 'general'
          : section.title === 'Questions spécifiques à la destination Canada'
            ? 'canada'
            : section.title === 'Question spécifique aux destinations hors Canada'
              ? 'other'
              : section.title === 'Carte d\'assuré pour le contrat Globe PVT'
                ? 'card'
                : 'general'
      }))
      .filter(section => section.questions.length > 0);

    if (selectedFaqCategory === 'all') {
      return sections;
    }
    return sections.filter(section => section.category === selectedFaqCategory);
  }, [insuranceData, selectedFaqCategory]);

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      <main className="flex-1 pt-16">
        {insuranceData && (
          <>
            <HeroSection
              image={insuranceData.header.image}
              title={insuranceData.header.title}
              content={insuranceData.header.content}
            />

            <AcsSection
              title={insuranceData.ascHeader.title}
              content={insuranceData.ascHeader.content}
              image={insuranceData.intro.image}
            />

            <GeneralInfoSection />

            <WhyChooseSection
              title={insuranceData.whyChoosePVT.title}
              content={insuranceData.whyChoosePVT.content}
              sections={insuranceData.whyChoosePVT.sections}
            />

            <GuaranteesSection />

            <VideoSection />

            <PricingSection />

            <EmergencyInstructions />

            <TestimonialsSection />

            <FaqSection
              title={insuranceData.whyChoosePVT.title}
              content={insuranceData.whyChoosePVT.content}
              sections={validFaqSections}
              selectedCategory={selectedFaqCategory}
              onCategoryChange={setSelectedFaqCategory}
              expandedFaqSection={expandedFaqSection}
              setExpandedFaqSection={setExpandedFaqSection}
              expandedQuestion={expandedQuestion}
              setExpandedQuestion={setExpandedQuestion}
            />

            <CtaSection />
          </>
        )}

        <LegalFooter />
      </main>

      <Footer />
    </div>
  );
}