import React from 'react';
import { Globe2 } from 'lucide-react';
import { TravelStyle } from '../../../types/matching';
import { budgetCountries } from '../../../data/budget-countries';
import { MAJOR_CITIES } from '../../../data/budget-countries';
import { translateTravelStyle } from '../../../utils/matching';

interface StepTwoProps {
  formData: {
    preferences: {
      style: TravelStyle;
      budget: {
        min: number;
        max: number;
        currency: string;
      };
      startDate: Date;
      endDate: Date;
      flexibility: number;
      destination: string;
      city: string;
    };
  };
  onFormDataChange: (data: any) => void;
  errors: string[];
}

export function StepTwo({ formData, onFormDataChange, errors }: StepTwoProps) {
  // Get available cities for selected country
  const availableCities = React.useMemo(() => {
    if (!formData.preferences.destination) return [];
    return MAJOR_CITIES[formData.preferences.destination] || [];
  }, [formData.preferences.destination]);

  // Format date for input value
  const formatDateForInput = (date: Date | null): string => {
    if (!date) return '';
    try {
      return date instanceof Date && !isNaN(date.getTime()) 
        ? date.toISOString().split('T')[0]
        : '';
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  // Ensure we have valid dates
  React.useEffect(() => {
    const today = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(today.getFullYear() + 1);

    const startDate = formData.preferences.startDate instanceof Date && !isNaN(formData.preferences.startDate.getTime())
      ? formData.preferences.startDate
      : today;
    
    const endDate = formData.preferences.endDate instanceof Date && !isNaN(formData.preferences.endDate.getTime())
      ? formData.preferences.endDate
      : nextYear;

    if (!formData.preferences.startDate || !formData.preferences.endDate) {
      onFormDataChange({
        ...formData,
        preferences: {
          ...formData.preferences,
          startDate,
          endDate
        }
      });
    }
  }, []);

  return (
    <div className="space-y-8">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
          <Globe2 className="w-5 h-5 text-primary-500" />
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Préférences de voyage
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Définissez vos critères de voyage
          </p>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Style de voyage
        </label>
        <div className="grid grid-cols-3 gap-2">
          {(['backpacker', 'comfort', 'mixed'] as TravelStyle[]).map((style) => (
            <button
              key={style}
              type="button"
              onClick={() => onFormDataChange({
                ...formData,
                preferences: { ...formData.preferences, style }
              })}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                formData.preferences.style === style
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              {translateTravelStyle(style)}
            </button>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Budget mensuel (€)
        </label>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Minimum
            </label>
            <input
              type="number"
              min="500"
              step="100"
              value={formData.preferences.budget.min}
              onChange={(e) => onFormDataChange({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  budget: {
                    ...formData.preferences.budget,
                    min: parseInt(e.target.value)
                  }
                }
              })}
              className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Maximum
            </label>
            <input
              type="number"
              min={formData.preferences.budget.min}
              step="100"
              value={formData.preferences.budget.max}
              onChange={(e) => onFormDataChange({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  budget: {
                    ...formData.preferences.budget,
                    max: parseInt(e.target.value)
                  }
                }
              })}
              className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
            />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Dates de voyage
        </label>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Départ
            </label>
            <input
              type="date"
              value={formatDateForInput(formData.preferences.startDate)}
              onChange={(e) => onFormDataChange({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  startDate: new Date(e.target.value)
                }
              })}
              className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 dark:text-gray-400 mb-1">
              Retour
            </label>
            <input
              type="date"
              value={formatDateForInput(formData.preferences.endDate)}
              min={formatDateForInput(formData.preferences.startDate)}
              onChange={(e) => onFormDataChange({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  endDate: new Date(e.target.value)
                }
              })}
              className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
            />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Flexibilité sur les dates
        </label>
        <select
          value={formData.preferences.flexibility}
          onChange={(e) => onFormDataChange({
            ...formData,
            preferences: {
              ...formData.preferences,
              flexibility: parseInt(e.target.value)
            }
          })}
          className="w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
        >
          <option value="0">Dates fixes</option>
          <option value="15">± 15 jours</option>
          <option value="30">± 1 mois</option>
          <option value="60">± 2 mois</option>
          <option value="90">± 3 mois</option>
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Destination
          <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          value={formData.preferences.destination}
          onChange={(e) => onFormDataChange({
            ...formData,
            preferences: {
              ...formData.preferences,
              destination: e.target.value,
              city: '' // Reset city when country changes
            }
          })}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white mb-4 ${
            errors.includes('La destination est obligatoire')
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          required
        >
          <option value="">Sélectionnez un pays</option>
          {budgetCountries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.flag} {country.name}
            </option>
          ))}
        </select>

        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Ville
          <span className="text-red-500 ml-1">*</span>
        </label>
        <select
          value={formData.preferences.city}
          onChange={(e) => onFormDataChange({
            ...formData,
            preferences: {
              ...formData.preferences,
              city: e.target.value
            }
          })}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
            errors.includes('La ville de destination est obligatoire')
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          required
          disabled={!formData.preferences.destination}
        >
          <option value="">Sélectionnez une ville</option>
          {availableCities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
          Sélectionnez votre destination principale
        </p>
      </div>
    </div>
  );
}