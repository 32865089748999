import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { embassyData } from '../data/embassies';
import { Building2, Phone, Mail, Globe, Clock, AlertTriangle, MapPin, ChevronDown, ChevronUp, ExternalLink } from 'lucide-react';

export function EmbassyDirectoryPage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [expandedCountry, setExpandedCountry] = React.useState<string | null>(null);
  const [searchQuery, setSearchQuery] = React.useState('');

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const filteredData = React.useMemo(() => {
    if (!searchQuery) return embassyData;
    
    const query = searchQuery.toLowerCase();
    return embassyData.filter(section => 
      section.countryName.toLowerCase().includes(query) ||
      section.mainEmbassy.city.toLowerCase().includes(query) ||
      section.consulates.some(c => c.city.toLowerCase().includes(query))
    );
  }, [searchQuery]);

  if (!user) return null;

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}}
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      {/* Hero Section */}
      <div className="relative min-h-[300px] sm:min-h-[400px] flex items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1524995997946-a1c2e315a42f?q=80&w=2670&auto=format&fit=crop"
            alt="Embassy building"
            className="w-full h-full object-cover opacity-90 dark:opacity-70"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-gray-900/70 to-gray-900/95"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
          <div className="max-w-3xl">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-4 sm:mb-6">
              <Building2 className="w-4 h-4 text-primary-400" />
              <span className="text-xs sm:text-sm font-medium text-white">Répertoire consulaire</span>
            </div>

            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4 sm:mb-6">
              Ambassades et consulats français
            </h1>

            <p className="text-base sm:text-lg text-white/90 leading-relaxed">
              Retrouvez toutes les coordonnées des ambassades et consulats français dans les pays participants au PVT.
            </p>
          </div>
        </div>
      </div>

      <main className="flex-1 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Search */}
          <div className="mb-8">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Rechercher par pays ou ville..."
                className="w-full px-4 py-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <MapPin className="w-5 h-5 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Alert for emergency */}
          <div className="mb-8 bg-red-50 dark:bg-red-900/20 rounded-xl p-6 border border-red-100 dark:border-red-800/30">
            <div className="flex items-start gap-4">
              <div className="p-3 bg-red-100 dark:bg-red-900/30 rounded-xl">
                <AlertTriangle className="w-6 h-6 text-red-600 dark:text-red-400" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-red-900 dark:text-red-300 mb-2">
                  En cas d'urgence
                </h3>
                <p className="text-sm text-red-700 dark:text-red-400 mb-2">
                  En cas d'urgence absolue (accident grave, arrestation, disparition inquiétante, attentat, etc.), contactez le Centre de crise et de soutien du ministère des Affaires étrangères.
                </p>
                <p className="text-xs text-red-600 dark:text-red-400 mb-4">
                  Ce numéro est accessible 24h/24 et 7j/7, mais il est réservé aux urgences absolues uniquement.
                </p>
                <div className="flex items-center gap-3">
                  <Phone className="w-5 h-5 text-red-500" />
                  <span className="text-base font-medium text-red-900 dark:text-red-300">
                    Centre de crise : +33 1 43 17 53 53
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Embassy List */}
          <div className="space-y-6">
            {filteredData.map((section) => (
              <div 
                key={section.countryCode}
                className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden"
              >
                <button
                  onClick={() => setExpandedCountry(
                    expandedCountry === section.countryCode ? null : section.countryCode
                  )}
                  className="w-full flex items-center justify-between p-6 hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                >
                  <div className="flex items-center gap-4">
                    <span className="text-3xl">{section.flag}</span>
                    <div>
                      <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {section.countryName}
                      </h2>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {section.consulates.length + 1} représentation{section.consulates.length ? 's' : ''}
                      </p>
                    </div>
                  </div>
                  {expandedCountry === section.countryCode ? (
                    <ChevronUp className="w-5 h-5 text-gray-400" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  )}
                </button>

                {expandedCountry === section.countryCode && (
                  <div className="border-t border-gray-200 dark:border-gray-700">
                    {/* Main Embassy */}
                    <div className="p-6 space-y-6">
                      <div className="flex items-center gap-3 mb-4">
                        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                          <Building2 className="w-5 h-5 text-primary-500" />
                        </div>
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                          {section.mainEmbassy.name}
                        </h3>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="space-y-4">
                          <div className="flex items-start gap-3">
                            <MapPin className="w-5 h-5 text-gray-400 mt-1" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                Adresse
                              </p>
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                {section.mainEmbassy.address}
                                <br />
                                {section.mainEmbassy.city}, {section.mainEmbassy.country}
                              </p>
                            </div>
                          </div>

                          <div className="flex items-start gap-3">
                            <Phone className="w-5 h-5 text-gray-400 mt-1" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                Téléphone
                              </p>
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                {section.mainEmbassy.phone}
                              </p>
                              {section.mainEmbassy.emergencyNumber && (
                                <p className="text-sm text-red-600 dark:text-red-400 mt-1">
                                  Urgence : {section.mainEmbassy.emergencyNumber}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex items-start gap-3">
                            <Mail className="w-5 h-5 text-gray-400 mt-1" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                Email
                              </p>
                              <a 
                                href={`mailto:${section.mainEmbassy.email}`}
                                className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                              >
                                {section.mainEmbassy.email}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="space-y-4">
                          <div className="flex items-start gap-3">
                            <Clock className="w-5 h-5 text-gray-400 mt-1" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                Horaires
                              </p>
                              <p className="text-sm text-gray-600 dark:text-gray-400">
                                {section.mainEmbassy.hours.regular}
                              </p>
                              {section.mainEmbassy.hours.emergency && (
                                <p className="text-sm text-red-600 dark:text-red-400 mt-1">
                                  {section.mainEmbassy.hours.emergency}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="flex items-start gap-3">
                            <Globe className="w-5 h-5 text-gray-400 mt-1" />
                            <div>
                              <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                Site web
                              </p>
                              <a 
                                href={section.mainEmbassy.website}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center gap-1 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                              >
                                <span>Visiter le site</span>
                                <ExternalLink className="w-3.5 h-3.5" />
                              </a>
                            </div>
                          </div>

                          {section.mainEmbassy.notes && (
                            <div className="flex items-start gap-3">
                              <AlertTriangle className="w-5 h-5 text-amber-500 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Remarque importante
                                </p>
                                <p className="text-sm text-amber-600 dark:text-amber-400">
                                  {section.mainEmbassy.notes}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Consulates */}
                    {section.consulates.map((consulate) => (
                      <div 
                        key={consulate.id}
                        className="border-t border-gray-200 dark:border-gray-700 p-6"
                      >
                        <div className="flex items-center gap-3 mb-4">
                          <div className="p-2 bg-accent-50 dark:bg-accent-900/20 rounded-lg">
                            <Building2 className="w-5 h-5 text-accent-500" />
                          </div>
                          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                            {consulate.name}
                          </h3>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="space-y-4">
                            <div className="flex items-start gap-3">
                              <MapPin className="w-5 h-5 text-gray-400 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Adresse
                                </p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                  {consulate.address}
                                  <br />
                                  {consulate.city}, {consulate.country}
                                </p>
                              </div>
                            </div>

                            <div className="flex items-start gap-3">
                              <Phone className="w-5 h-5 text-gray-400 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Téléphone
                                </p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                  {consulate.phone}
                                </p>
                              </div>
                            </div>

                            <div className="flex items-start gap-3">
                              <Mail className="w-5 h-5 text-gray-400 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Email
                                </p>
                                <a 
                                  href={`mailto:${consulate.email}`}
                                  className="text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                                >
                                  {consulate.email}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="space-y-4">
                            <div className="flex items-start gap-3">
                              <Clock className="w-5 h-5 text-gray-400 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Horaires
                                </p>
                                <p className="text-sm text-gray-600 dark:text-gray-400">
                                  {consulate.hours.regular}
                                </p>
                              </div>
                            </div>

                            <div className="flex items-start gap-3">
                              <Globe className="w-5 h-5 text-gray-400 mt-1" />
                              <div>
                                <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                                  Site web
                                </p>
                                <a 
                                  href={consulate.website}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-flex items-center gap-1 text-sm text-primary-600 hover:text-primary-700 dark:text-primary-400"
                                >
                                  <span>Visiter le site</span>
                                  <ExternalLink className="w-3.5 h-3.5" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}