import React from 'react';
import { TravelProfile } from '../../types/matching';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { scrollToTop } from '../../utils/scroll';
import { MapPin, Calendar, Wallet, Globe2, Heart, X, MessageSquare, ExternalLink, Shield, CheckCircle2, Clock, XCircle } from 'lucide-react';
import { budgetCountries } from '../../data/budget-countries';
import { Link } from 'react-router-dom';
import { translateTravelStyle } from '../../utils/matching';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface MatchingCardProps {
  profile: TravelProfile;
  onLike: () => void;
  onPass: () => void;
  onMessage?: () => void;
  similarity?: number;
  isOwnProfile?: boolean;
}

export function MatchingCard({ profile, onLike, onPass, onMessage, similarity, isOwnProfile }: MatchingCardProps) {
  const [currentPhotoIndex, setCurrentPhotoIndex] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);
  const [localProfile, setLocalProfile] = React.useState(profile);
  const [viewCount, setViewCount] = React.useState(profile.viewCount || 0);
  const [cachedPhoto, setCachedPhoto] = React.useState<string | null>(null);

  // Fetch cached photo when profile loads
  React.useEffect(() => {
    const fetchCachedPhoto = async () => {
      if (profile.preferences.city) {
        try {
          const cacheRef = doc(db, 'photo_cache', profile.preferences.city.toLowerCase());
          const cacheDoc = await getDoc(cacheRef);
          
          if (cacheDoc.exists()) {
            const data = cacheDoc.data();
            const photos = data.photos;
            if (photos && photos.length > 0) {
              // Sélectionner une photo aléatoire du cache
              const randomPhoto = photos[Math.floor(Math.random() * photos.length)];
              setCachedPhoto(randomPhoto.url);
            }
          }
        } catch (error) {
          console.error('Error fetching cached photo:', error);
        }
      }
    };

    fetchCachedPhoto();
  }, [profile.preferences.city]);

  // Update local profile when profile changes
  React.useEffect(() => {
    setLocalProfile(profile);
    setViewCount(profile.viewCount || 0);
  }, [profile]);

  // Get country image for destination
  const getDestinationImage = (countryCode: string) => {
    const country = budgetCountries.find(c => c.code === countryCode);
    return country?.image || 'https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2670&auto=format&fit=crop';
  };

  // Calculate age from birthDate
  const getAge = (birthDate: any) => {
    if (!birthDate) return null;
    let date;
    try {
      // Try toDate() first for Firestore Timestamps
      date = birthDate.toDate?.() || new Date(birthDate);
    } catch (error) {
      // If toDate() fails, try creating a new date
      date = new Date(birthDate);
    }
    return Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24 * 365.25));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    
    if (isLeftSwipe) {
      onPass();
    } else if (isRightSwipe) {
      onLike();
    }
  };

  const handleUsernameClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`https://pvtistes.net/membre/${localProfile.username}/`, '_blank', 'noopener,noreferrer');
  };

  return (
    <Link
      to={`/matching/${profile.id}`}
      onClick={() => scrollToTop()}
      className={`group relative overflow-hidden w-full transform transition-all duration-300 hover:-translate-y-0.5 rounded-xl ${
        localProfile.isOwnProfile
          ? 'bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 shadow-premium border border-primary-100/50 dark:border-primary-800/30'
          : 'bg-white dark:bg-gray-800 shadow-sm hover:shadow-md border border-gray-200/50 dark:border-gray-700/50'
      }`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Decorative gradient orb */}
      {isOwnProfile && (
        <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
      )}

      {/* Photos Carousel */}
      <div className="relative aspect-[3/2] sm:aspect-[16/9] bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-700 dark:to-gray-800 overflow-hidden">
        {localProfile.photos && localProfile.photos.length > 0 ? (
          <img
            src={localProfile.photos[currentPhotoIndex]}
            alt={localProfile.firstName}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 sm:object-center"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = cachedPhoto || getDestinationImage(localProfile.preferences.destination);
            }}
          />
        ) : (
          <img
            src={cachedPhoto || getDestinationImage(localProfile.preferences.destination)}
            alt={localProfile.preferences.city}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
          />
        )}
        
        {/* Stats Overlay */}
        <div className="absolute top-3 left-3 right-3 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="px-2 py-1 bg-black/40 backdrop-blur-[2px] rounded-lg border border-white/10 text-center">
              <span className="text-xs text-white/90">
                {format(
                  localProfile.createdAt.toDate?.() || new Date(localProfile.createdAt),
                  'dd MMM yyyy',
                  { locale: fr }
                )}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="px-2 py-1 bg-black/40 backdrop-blur-[2px] rounded-lg border border-white/10 text-center">
              <span className="text-xs text-white/90">
                {viewCount} vues
              </span>
            </div>
          </div>
        </div>

        {/* Photo Navigation */}
        {localProfile.photos && localProfile.photos.length > 1 && (
          <div className="absolute top-4 left-0 right-0 flex justify-center gap-1.5">
            {localProfile.photos.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPhotoIndex(index)}
                className={`w-1.5 h-1.5 rounded-full transition-all duration-300 ${
                  index === currentPhotoIndex
                    ? 'bg-white scale-125'
                    : 'bg-white/40 hover:bg-white/60'
                }`}
              />
            ))}
          </div>
        )}

        {/* Basic Info Overlay */}
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent p-3 sm:p-4">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <h3 className="text-base sm:text-lg font-semibold text-white">
                {localProfile.firstName}, {getAge(localProfile.birthDate)} ans
              </h3>
              {localProfile.username && (
                <button
                  onClick={handleUsernameClick}
                  className="hidden sm:inline-flex items-center gap-1 text-sm text-white/80 hover:text-white transition-colors group"
                >
                  @{localProfile.username}
                  <ExternalLink className="w-3 h-3 opacity-0 group-hover:opacity-100 transition-opacity" />
                </button>
              )}
              {localProfile.isOwnProfile && (
                <span className="text-xs bg-primary-500/20 text-white px-2 py-0.5 rounded-full border border-white/20 backdrop-blur-sm">
                  Mon profil
                </span>
              )}
            </div>

            <div className="flex flex-col sm:flex-row sm:items-center gap-y-1 sm:gap-x-3">
              <div className="flex items-center gap-1.5 text-white/90">
                <MapPin className="w-3.5 h-3.5" />
                <span className="text-xs sm:text-sm">De {localProfile.location}</span>
              </div>
              <div className="flex items-center gap-1.5 text-white/90">
                <Globe2 className="w-3.5 h-3.5" />
                <span className="text-xs sm:text-sm">
                  Vers {localProfile.preferences.city}, {budgetCountries.find(c => c.code === localProfile.preferences.destination)?.name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="p-3 sm:p-4">
        {/* Travel Preferences */}
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calendar className="w-3.5 h-3.5 text-gray-400" />
              <span className="text-xs text-gray-600 dark:text-gray-400">Départ prévu</span>
            </div>
            <span className="text-xs font-medium text-gray-900 dark:text-white">
              {format(
                localProfile.preferences.startDate.toDate?.() || new Date(localProfile.preferences.startDate),
                'MMMM yyyy',
                { locale: fr }
              )}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Shield className="w-3.5 h-3.5 text-gray-400" />
              <span className="text-xs text-gray-600 dark:text-gray-400">Statut du PVT</span>
            </div>
            <div>
              {localProfile.pvtStatus === 'obtained' ? (
                <span className="inline-flex items-center gap-1.5 px-2 py-1 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 rounded-full border border-emerald-100/50 dark:border-emerald-800/30 text-xs font-medium">
                  <CheckCircle2 className="w-3 h-3" />
                  <span>Obtenu</span>
                </span>
              ) : localProfile.pvtStatus === 'pending' ? (
                <span className="inline-flex items-center gap-1.5 px-2 py-1 bg-amber-50 dark:bg-amber-900/20 text-amber-600 dark:text-amber-400 rounded-full border border-amber-100/50 dark:border-amber-800/30 text-xs font-medium">
                  <Clock className="w-3 h-3" />
                  <span>En cours</span>
                </span>
              ) : (
                <span className="inline-flex items-center gap-1.5 px-2 py-1 bg-gray-50 dark:bg-gray-700/50 text-gray-600 dark:text-gray-400 rounded-full border border-gray-200/50 dark:border-gray-700/30 text-xs font-medium">
                  <XCircle className="w-3 h-3" />
                  <span>Non demandé</span>
                </span>
              )}
            </div>
          </div>
          
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Globe2 className="w-3.5 h-3.5 text-gray-400" />
              <span className="text-xs text-gray-600 dark:text-gray-400">Style de voyage</span>
            </div>
            <span className="text-xs font-medium text-gray-900 dark:text-white">
              {translateTravelStyle(localProfile.preferences.style)}
            </span>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Wallet className="w-3.5 h-3.5 text-gray-400" />
              <span className="text-xs text-gray-600 dark:text-gray-400">Budget mensuel</span>
            </div>
            <span className="text-xs font-medium text-gray-900 dark:text-white">
              {localProfile.preferences.budget.min.toLocaleString('fr-FR')} - {localProfile.preferences.budget.max.toLocaleString('fr-FR')} €
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}