import React from 'react';
import { ArrowLeft, Share2, Edit } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ProfileHeaderProps {
  onShare: () => void;
  onEdit?: () => void;
  isOwnProfile: boolean;
}

export function ProfileHeader({ onShare, onEdit, isOwnProfile }: ProfileHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14">
          <button
            onClick={() => navigate('/matching')}
            className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
            <span className="font-medium">Retour aux profils</span>
          </button>
          <div className="flex items-center gap-2 sm:gap-4">
            {isOwnProfile && onEdit && (
              <button
                onClick={onEdit}
                className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
              >
                <Edit className="w-5 h-5" />
                <span className="hidden sm:inline font-medium">Modifier</span>
              </button>
            )}
            <button
              onClick={onShare}
              className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <Share2 className="w-5 h-5" />
              <span className="hidden sm:inline font-medium">Partager</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}