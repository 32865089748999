import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { TermsModal } from '../components/TermsModal';

export function TermsPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(true);

  const handleClose = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  return <TermsModal isOpen={isModalOpen} onClose={handleClose} />;
}