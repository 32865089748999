import React from 'react';
import { useTheme } from '../lib/theme-context';
import { Facebook, Instagram, Linkedin, Youtube, Twitter } from 'lucide-react';
import { TikTokIcon } from './icons/TikTokIcon';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../hooks/useIsMobile';

export function Footer() {
  const { theme } = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) return null;
  
  return (
    <footer className="relative mt-16">
      {/* Background image with overlay */}
      <div className="relative bg-gray-900 text-gray-400">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1473448912268-2022ce9509d8?q=80&w=2641&auto=format&fit=crop"
            alt=""
            className="w-full h-full object-cover opacity-25 dark:opacity-15"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/60 to-gray-900/95"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
          {/* Stats en mobile */}
          <div className="grid grid-cols-2 sm:hidden gap-4 mb-8">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-4 text-center">
              <span className="block text-xl text-white font-semibold">1.2M</span>
              <span className="text-sm">Messages</span>
            </div>
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-4 text-center">
              <span className="block text-xl text-white font-semibold">481k</span>
              <span className="text-sm">Membres</span>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-8">
            {/* Logo et Stats (desktop) */}
            <div className="col-span-2">
              <div className="flex flex-col items-start">
                <img 
                  src="https://pvtistes.net/wp-content/uploads/2025/01/rgb_pvtistes_logo_negative_white.png" 
                  alt="PVTistes Logo" 
                  className="h-8 sm:h-10 w-auto mb-4"
                />
                <p className="text-sm sm:text-base text-gray-400 italic mb-6">
                  Parce qu'ici on a déjà un pied là-bas.
                </p>
              </div>
              <div className="hidden sm:block text-sm space-y-2">
                <div className="flex items-center gap-2">
                  <span className="text-white font-semibold">1.2M</span>
                  <span>Messages</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-white font-semibold">481k</span>
                  <span>Membres</span>
                </div>
              </div>
            </div>

            {/* Liens utiles */}
            <div className="col-span-1">
              <h3 className="text-white text-sm font-semibold uppercase mb-4">Liens utiles</h3>
              <ul className="space-y-2 text-sm">
                <li><a href="https://pvtistes.net/guide-ultime-pvt/" className="hover:text-white transition-colors">Mode d'emploi</a></li>
                <li><a href="https://pvtistes.net/bonsplans/" className="hover:text-white transition-colors">Bons plans</a></li>
                <li><a href="https://pvtistes.net/guides/" className="hover:text-white transition-colors">Guides PVT/WHV</a></li>
                <li><a href="https://pvtistes.net/reseaux-sociaux/" className="hover:text-white transition-colors">Réseaux sociaux</a></li>
              </ul>
            </div>

            {/* À propos */}
            <div className="col-span-1">
              <h3 className="text-white text-sm font-semibold uppercase mb-4">À propos</h3>
              <ul className="space-y-2 text-sm">
                <li><a href="https://pvtistes.net/qui-sommes-nous/" className="hover:text-white transition-colors">Qui sommes-nous ?</a></li>
                <li><a href="https://pvtistes.net/medias/" className="hover:text-white transition-colors">Médias</a></li>
                <li><a href="https://pvtistes.net/pvtistes-net-mobile/" className="hover:text-white transition-colors">App mobile</a></li>
                <li><a href="https://pvtistes.net/pvt-etudiant/" className="hover:text-white transition-colors">Étudiants en PVT</a></li>
              </ul>
            </div>

            {/* Réseaux sociaux */}
            <div className="col-span-2">
              <h3 className="text-white text-sm font-semibold uppercase mb-4">Suivez-nous</h3>
              <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <a href="https://www.facebook.com/pvtistes/" className="flex items-center gap-3 hover:text-white transition-colors">
                  <Facebook className="w-5 h-5" />
                  <span className="text-sm">Facebook</span>
                </a>
                <a href="https://x.com/pvtistes" className="flex items-center gap-3 hover:text-white transition-colors">
                  <Twitter className="w-5 h-5" />
                  <span className="text-sm">Twitter</span>
                </a>
                <a href="https://www.instagram.com/pvtistes/" className="flex items-center gap-3 hover:text-white transition-colors">
                  <Instagram className="w-5 h-5" />
                  <span className="text-sm">Instagram</span>
                </a>
                <a href="https://www.linkedin.com/company/pvtistes.net" className="flex items-center gap-3 hover:text-white transition-colors">
                  <Linkedin className="w-5 h-5" />
                  <span className="text-sm">LinkedIn</span>
                </a>
                <a href="https://www.youtube.com/user/pvtistesnet" className="flex items-center gap-3 hover:text-white transition-colors">
                  <Youtube className="w-5 h-5" />
                  <span className="text-sm">YouTube</span>
                </a>
                <a href="https://www.tiktok.com/@pvtistes" className="flex items-center gap-3 hover:text-white transition-colors">
                  <TikTokIcon className="w-5 h-5" />
                  <span className="text-sm">TikTok</span>
                </a>
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="mt-8 pt-8 border-t border-gray-800">
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <p className="text-xs">Copyright © 2005-{new Date().getFullYear()} PVTISTES.NET</p>
              <div className="flex flex-wrap justify-center items-center gap-4 text-xs">
                <Link to="/cgu" className="hover:text-white transition-colors">CGU</Link>
                <span className="hidden sm:block">•</span>
                <Link to="/confidentialite" className="hover:text-white transition-colors">Confidentialité</Link>
                <span className="hidden sm:block">•</span>
                <a 
                  href="https://pvtistes.net/forum/sendmessage.php" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="hover:text-white transition-colors"
                >
                  Nous contacter
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}