import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { TravelProfile } from '../types/matching';
import { doc, getDoc, updateDoc, deleteDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { MessageSquare, Trash2 } from 'lucide-react';
import { CreateListingForm } from '../components/matching/CreateListingForm';
import { ProfileHeader } from '../components/matching/profile/ProfileHeader';
import { ProfileHero } from '../components/matching/profile/ProfileHero';
import { ProfilePreferences } from '../components/matching/profile/ProfilePreferences';
import { ProfileLanguages } from '../components/matching/profile/ProfileLanguages';
import { ProfileInterests } from '../components/matching/profile/ProfileInterests';
import { ProfileComments } from '../components/matching/profile/ProfileComments';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';

export function ProfileDetailPage() {
  const { user } = useAuth();
  const { userInfo } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState<TravelProfile | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [isOwnProfile, setIsOwnProfile] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [hasViewedProfile, setHasViewedProfile] = React.useState(false);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  // Fetch profile data
  React.useEffect(() => {
    const fetchProfile = async () => {
      if (!id) return;
      
      try {
        setIsLoading(true);
        setError(null);
        
        const profileRef = doc(db, 'travel_profiles', id);
        const profileDoc = await getDoc(profileRef);
        
        if (!profileDoc.exists()) {
          setError('Profil non trouvé');
          return;
        }

        const profileData = profileDoc.data();
        const isOwn = profileData.userId === user?.uid;
        setIsOwnProfile(isOwn);

        // Ne pas incrémenter les vues pour son propre profil
        if (!isOwn && !hasViewedProfile) {
          // Incrémenter le compteur de vues
          const views = profileData.views || [];
          const viewCount = (profileData.viewCount || 0) + 1;
          
          await updateDoc(profileRef, {
            viewCount,
            views: arrayUnion({
              userId: user.uid,
              timestamp: new Date(),
              userAgent: navigator.userAgent
            })
          });

          setHasViewedProfile(true);
          profileData.viewCount = viewCount;
        }
        
        setProfile({
          id: profileDoc.id,
          ...profileData,
          username: isOwn ? userInfo?.user?.username : profileData.username,
          isOwnProfile: profileData.userId === user?.uid
        } as TravelProfile);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Impossible de charger le profil');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchProfile();
    }
  }, [id, user, userInfo?.user?.username]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Profil de ${profile?.firstName}`,
          text: profile?.bio,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Lien copié dans le presse-papier !');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleEditProfile = () => {
    setShowEditForm(true);
  };

  const handleUpdateProfile = async (updatedData: any) => {
    try {
      if (!profile || !user) return;

      const profileRef = doc(db, 'travel_profiles', profile.id);
      await updateDoc(profileRef, {
        ...updatedData,
        updatedAt: new Date()
      });

      // Refresh profile data
      const updatedProfileDoc = await getDoc(profileRef);
      setProfile({
        id: updatedProfileDoc.id,
        ...updatedProfileDoc.data(),
        isOwnProfile: true
      } as TravelProfile);

      setShowEditForm(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Erreur lors de la mise à jour du profil');
    }
  };

  const handleDeleteProfile = async () => {
    try {
      if (!profile || !user || !isOwnProfile) return;

      const profileRef = doc(db, 'travel_profiles', profile.id);
      await deleteDoc(profileRef);
      
      // Redirect to matching page after successful deletion
      navigate('/matching');
    } catch (error) {
      console.error('Error deleting profile:', error);
      alert('Erreur lors de la suppression du profil');
    }
  };

  const handleMessage = () => {
    if (!profile) return;
    window.open(`https://pvtistes.net/mon-profil/messages/?to=${profile.userId}`, '_blank', 'noopener,noreferrer');
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      <ProfileHeader
        onShare={handleShare}
        onEdit={handleEditProfile}
        isOwnProfile={isOwnProfile}
      />

      <main className="flex-1 pt-32">
        {isLoading ? (
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="animate-pulse space-y-8">
              <div className="aspect-[4/5] bg-gray-200 dark:bg-gray-700 rounded-2xl" />
              <div className="space-y-4">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-lg w-1/3" />
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-lg w-2/3" />
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-lg w-1/2" />
              </div>
            </div>
          </div>
        ) : error || !profile ? (
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
              <p className="text-red-600 dark:text-red-400">{error || 'Profil non trouvé'}</p>
            </div>
          </div>
        ) : (
          <>
            <ProfileHero profile={profile} />

            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
              <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
                <div className="p-6 space-y-8">
                  {/* Action Buttons */}
                  <div className="flex flex-col sm:flex-row gap-3">
                    <div className="flex items-center justify-center gap-2 px-4 py-2.5 bg-gray-100 dark:bg-gray-700/50 rounded-xl">
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        {profile.viewCount || 0} vues
                      </span>
                    </div>
                    <div className="flex items-center justify-center gap-2 px-4 py-2.5 bg-gray-100 dark:bg-gray-700/50 rounded-xl">
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        Publié le {format(profile.createdAt.toDate(), 'dd MMMM yyyy', { locale: fr })}
                      </span>
                    </div>
                    {isOwnProfile ? (
                      <button 
                        onClick={() => setShowDeleteConfirmation(true)}
                        className="flex items-center justify-center gap-2 px-4 py-3 text-red-600 hover:text-white bg-red-50 hover:bg-red-600 dark:bg-red-900/20 dark:text-red-400 dark:hover:text-white dark:hover:bg-red-600 rounded-xl transition-colors w-full"
                      >
                        <Trash2 className="w-5 h-5" />
                        <span>Supprimer mon annonce</span>
                      </button>
                    ) : (
                      <button 
                        onClick={handleMessage}
                        className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-primary-600 hover:bg-primary-700 text-white rounded-xl transition-colors shadow-sm hover:shadow-md"
                      >
                        <MessageSquare className="w-4 h-4 sm:w-5 sm:h-5" />
                        <span className="text-sm sm:text-base">Envoyer un message</span>
                      </button>
                    )}
                  </div>

                  <div>
                    <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-2 sm:mb-3">
                      Présentation
                    </h2>
                    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 whitespace-pre-wrap">
                      {profile.bio}
                    </p>
                  </div>

                  {profile.objectives && profile.objectives.length > 0 && (
                    <div>
                      <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
                        Objectifs du PVT
                      </h2>
                      <div className="flex flex-wrap gap-3">
                        {profile.objectives.map((objectiveId) => {
                          const objective = {
                            language: { icon: '🗣️', label: 'Améliorer une langue' },
                            career: { icon: '💼', label: 'Faire évoluer sa carrière' },
                            travel: { icon: '🌎', label: 'Voyager et explorer' },
                            nature: { icon: '🌿', label: 'Expérience en pleine nature' },
                            culture: { icon: '🎉', label: 'Découvrir la culture locale & faire la fête' }
                          }[objectiveId];

                          if (!objective) return null;

                          return (
                            <div
                              key={objectiveId}
                              className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 dark:bg-gray-700 rounded-lg"
                            >
                              <span>{objective.icon}</span>
                              <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                {objective.label}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  <ProfilePreferences profile={profile} />
                  <ProfileLanguages languages={profile.languages} />
                  <ProfileInterests interests={profile.interests} />
                  
                  <ProfileComments 
                    profile={profile}
                    currentUserId={user.uid}
                    userInfo={userInfo}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </main>

      {/* Edit Form Modal */}
      {showEditForm && profile && (
        <CreateListingForm
          onSubmit={handleUpdateProfile}
          onClose={() => setShowEditForm(false)}
          initialData={{
            firstName: profile.firstName,
            birthDate: (profile.birthDate.toDate?.() || new Date(profile.birthDate)).toISOString().split('T')[0],
            location: profile.location,
            bio: profile.bio,
            languages: profile.languages,
            interests: profile.interests,
            preferences: {
              ...profile.preferences,
              startDate: profile.preferences.startDate.toDate?.() || new Date(profile.preferences.startDate),
              endDate: profile.preferences.endDate.toDate?.() || new Date(profile.preferences.endDate)
            },
            pvtStatus: profile.pvtStatus,
            objectives: profile.objectives || []
          }}
          isEditing={true}
        />
      )}

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDeleteProfile}
        itemName="votre annonce"
        confirmationWord="SUPPRIMER"
        description="Cette action est irréversible. Toutes les données de votre annonce seront définitivement supprimées."
      />

      <Footer />
    </div>
  );
}