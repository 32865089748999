import React from 'react';
import { Home, ListChecks, Calculator, Menu, X, UserPlus, Camera } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  isActive: boolean;
}

function NavLink({ to, children, isActive }: NavLinkProps) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Link
      to={to}
      onClick={handleClick}
      className={`flex flex-col items-center gap-1 p-2 transition-colors ${
        isActive 
          ? 'text-primary-600 dark:text-primary-400'
          : 'text-gray-500 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-400'
      } pb-4`}
    >
      {children}
    </Link>
  );
}

interface MobileNavBarProps {
  onOpenMenu: () => void;
  isMenuOpen: boolean;
}

export function MobileNavBar({ onOpenMenu, isMenuOpen }: MobileNavBarProps) {
  const location = useLocation();
  
  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 md:hidden">
      <div className="bg-white/95 dark:bg-gray-900/95 border-t border-gray-200/20 dark:border-gray-700/20 backdrop-blur-lg py-2 px-2">
        <div className="grid grid-cols-5 gap-1">
          <NavLink
            to="/"
            isActive={location.pathname === '/'}
          >
            <Home className="w-5 h-5" />
            <span className="text-xs font-medium">Accueil</span>
          </NavLink>
          
          <NavLink
            to="/simulateur-budget"
            isActive={location.pathname === '/simulateur-budget'}
          >
            <Calculator className="w-5 h-5" />
            <span className="text-xs font-medium">Budget</span>
          </NavLink>

          <NavLink
            to="/media"
            isActive={location.pathname === '/media'}
          >
            <Camera className="w-5 h-5" />
            <span className="text-xs font-medium">Photos</span>
          </NavLink>
          
          <NavLink
            to="/matching"
            isActive={location.pathname === '/matching'}
          >
            <UserPlus className="w-5 h-5" />
            <span className="text-xs font-medium">PVTmates</span>
          </NavLink>
          
          <button
            onClick={onOpenMenu}
            className="flex flex-col items-center gap-1 p-2 pb-4 text-gray-500 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-400 transition-colors"
          >
            {isMenuOpen ? (
              <>
                <X className="w-5 h-5" />
                <span className="text-xs font-medium">Fermer</span>
              </>
            ) : (
              <>
                <Menu className="w-5 h-5" />
                <span className="text-xs font-medium">Menu</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}