import React from 'react';
import { Info, Calculator } from 'lucide-react';

interface GroceriesBreakdownProps {
  priceItems: any[];
  currency: string;
  eurPrices?: any[];
}

export function GroceriesBreakdown({ priceItems, currency, eurPrices }: GroceriesBreakdownProps) {
  const GROCERY_ITEMS = [
    { id: 8, name: 'Lait (1 litre)' },
    { id: 9, name: 'Pain blanc frais (500g)' },
    { id: 11, name: 'Œufs (12)' },
    { id: 12, name: 'Fromage local (1kg)' },
    { id: 19, name: 'Filets de poulet (1kg)' },
    { id: 110, name: 'Pommes (1kg)' },
    { id: 112, name: 'Pommes de terre (1kg)' },
    { id: 116, name: 'Tomates (1kg)' }
  ];

  const getItemPrice = (itemId: number) => {
    const item = priceItems.find(item => item.item_id === itemId);
    const eurItem = eurPrices?.find(item => item.item_id === itemId);
    
    if (!item) return null;

    return {
      price: item.average_price,
      eurPrice: eurItem?.average_price
    };
  };

  const totalPrices = React.useMemo(() => {
    const prices = GROCERY_ITEMS.map(item => getItemPrice(item.id))
      .filter(price => price !== null);

    return prices.reduce((sum, price) => sum + (price?.eurPrice || price?.price || 0), 0);
  }, [priceItems, eurPrices]);

  return (
    <div className="mt-4 bg-gray-50 dark:bg-gray-700/30 rounded-xl p-4 sm:p-6">
      <div className="flex items-start sm:items-center gap-2 mb-4">
        <Info className="hidden sm:block w-4 h-4 text-gray-500 dark:text-gray-400" />
        <p className="text-sm text-gray-600 dark:text-gray-400">
          Prix moyens constatés pour vous aider à estimer votre budget
        </p>
      </div>

      <div className="space-y-2">
        {GROCERY_ITEMS.map(item => {
          const prices = getItemPrice(item.id);
          if (!prices) return null;

          return (
            <div key={item.id} className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50 last:border-0">
              <span className="text-sm text-gray-600 dark:text-gray-400 mb-1 sm:mb-0">
                {item.name}
              </span>
              <div className="text-right font-mono text-base sm:text-sm font-medium text-primary-600 dark:text-primary-400">
                {(prices.eurPrice || prices.price).toFixed(2)} €
              </div>
            </div>
          );
        })}

        {/* Total */}
        <div className="mt-6 pt-4 border-t-2 border-gray-200 dark:border-gray-600">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Calculator className="hidden sm:block w-4 h-4 text-primary-500" />
              <span className="text-base font-medium text-gray-700 dark:text-gray-300">
                Total des produits
              </span>
            </div>
            <div className="text-right font-mono text-lg font-bold text-primary-600 dark:text-primary-400">
              {totalPrices.toFixed(2)} €
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}