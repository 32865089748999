import React from 'react';
import { X, ListPlus, PencilIcon } from 'lucide-react';

interface TaskModalsProps {
  editingNotes: string | null;
  notesDraft: string;
  addingSubtaskFor: string | null;
  newSubtaskTitle: string;
  onCloseNotes: () => void;
  onSaveNotes: () => void;
  onUpdateNotesDraft: (value: string) => void;
  onCloseSubtask: () => void;
  onSaveSubtask: (e: React.FormEvent) => void;
  onUpdateSubtaskTitle: (value: string) => void;
}

const MAX_NOTE_LENGTH = 2000;
const MAX_SUBTASK_LENGTH = 200;

export function TaskModals({
  editingNotes,
  notesDraft,
  addingSubtaskFor,
  newSubtaskTitle,
  onCloseNotes,
  onSaveNotes,
  onUpdateNotesDraft,
  onCloseSubtask,
  onSaveSubtask,
  onUpdateSubtaskTitle
}: TaskModalsProps) {
  return (
    <>
      {editingNotes && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                Notes pour la tâche
              </h2>
              <button
                onClick={onCloseNotes}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>
            <div className="p-4">
              <div className="mb-2 flex justify-between items-center">
                <label className="text-sm text-gray-600 dark:text-gray-400">
                  Note détaillée
                </label>
                <span className={`text-xs ${
                  notesDraft.length > MAX_NOTE_LENGTH * 0.9 
                    ? 'text-red-500 dark:text-red-400' 
                    : 'text-gray-500 dark:text-gray-400'
                }`}>
                  {notesDraft.length}/{MAX_NOTE_LENGTH}
                </span>
              </div>
              <textarea
                value={notesDraft}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_NOTE_LENGTH) {
                    onUpdateNotesDraft(e.target.value);
                  }
                }}
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                rows={6}
                placeholder="Ajoutez vos notes ici..."
                maxLength={MAX_NOTE_LENGTH}
              />
              <div className="flex justify-end space-x-3 mt-4">
                <button
                  onClick={onCloseNotes}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={onSaveNotes}
                  className="px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md transition-colors"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addingSubtaskFor && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                Ajouter une sous-tâche
              </h2>
              <button
                onClick={onCloseSubtask}
                className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>
            <form 
              className="p-4"
              onSubmit={onSaveSubtask}
            >
              <div className="mb-4">
                <div className="flex justify-between items-center mb-1">
                  <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    Contenu de la sous-tâche
                  </label>
                  <span className={`text-xs ${
                    newSubtaskTitle.length > MAX_SUBTASK_LENGTH * 0.9 
                      ? 'text-red-500 dark:text-red-400' 
                      : 'text-gray-500 dark:text-gray-400'
                  }`}>
                    {newSubtaskTitle.length}/{MAX_SUBTASK_LENGTH}
                  </span>
                </div>
                <input
                  type="text"
                  value={newSubtaskTitle}
                  onChange={(e) => {
                    if (e.target.value.length <= MAX_SUBTASK_LENGTH) {
                      onUpdateSubtaskTitle(e.target.value);
                    }
                  }}
                  className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                  placeholder="Entrez le contenu de la sous-tâche..."
                  maxLength={MAX_SUBTASK_LENGTH}
                  autoFocus
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onCloseSubtask}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md transition-colors"
                  disabled={!newSubtaskTitle.trim()}
                >
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}