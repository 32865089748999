import React from 'react';
import { Mail, RefreshCw, X } from 'lucide-react';
import { useAuth } from '../lib/auth-context';

export function EmailVerificationBanner() {

  return null;
  
  const { user, resendVerificationEmail, error } = useAuth();
  const [isResending, setIsResending] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);

  if (!user || user.emailVerified || !isVisible) return null;

  const handleResend = async () => {
    setIsResending(true);
    await resendVerificationEmail();
    setIsResending(false);
  };

  return (
    <div className="fixed bottom-0 inset-x-0 z-50">
      <div className="bg-gradient-to-r from-primary-500 to-accent-500 text-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0">
                <Mail className="w-5 h-5" />
              </div>
              <div className="flex-1 text-sm">
                <p className="font-medium">
                  Vérifiez votre adresse email
                </p>
                <p className="text-white/90">
                  Un email de vérification a été envoyé à {user.email}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={handleResend}
                disabled={isResending}
                className="flex items-center gap-2 px-3 py-1.5 bg-white/20 hover:bg-white/30 rounded-lg text-sm font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isResending ? (
                  <RefreshCw className="w-4 h-4 animate-spin" />
                ) : (
                  <RefreshCw className="w-4 h-4" />
                )}
                <span>Renvoyer l'email</span>
              </button>
              <button
                onClick={() => setIsVisible(false)}
                className="p-1.5 hover:bg-white/20 rounded-lg transition-colors"
                title="Fermer"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}