import React from 'react';
import { BookOpen, ArrowRight } from 'lucide-react';

export function GuidePromo() {
  return (
    <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
      <div className="p-4">
        <div className="flex items-center gap-3 mb-4">
          <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
            <BookOpen className="w-5 h-5 text-primary-500 dark:text-primary-400" />
          </div>
          <div>
            <h4 className="text-base font-medium text-gray-900 dark:text-white">
              Nos guides gratuits
            </h4>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Tout savoir sur le PVT
            </p>
          </div>
        </div>

        <div className="space-y-2 mb-4">
          <a href="https://pvtistes.net/guides/" className="block group">
            <div className="relative aspect-video rounded-lg overflow-hidden">
              <img 
                src="https://pvtistes.net/wp-content/themes/pvtistest-2/static/images/images-rd/guides.png"
                alt="Guide PVT"
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 p-3">
                <p className="text-base font-medium text-white">
                  Guides complets et détaillés
                </p>
              </div>
            </div>
          </a>
        </div>

        <a
          href="https://pvtistes.net/guides/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center gap-2 w-full px-4 py-2.5 bg-primary-50 dark:bg-primary-900/20 hover:bg-primary-100 dark:hover:bg-primary-900/30 text-primary-600 dark:text-primary-400 text-base font-medium rounded-lg transition-colors"
        >
          <span>Découvrir</span>
          <ArrowRight className="w-4 h-4" />
        </a>
      </div>
    </div>
  );
}