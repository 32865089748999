import React from 'react';
import { Task } from '../types/todo';

interface ProgressBarProps {
  tasks: Task[];
}

export function ProgressBar({ tasks }: ProgressBarProps) {
  const total = tasks.length;
  const completed = tasks.filter(t => t.status === 'completed').length;
  const inProgress = tasks.filter(t => t.status === 'in-progress').length;
  
  const completedPercentage = (completed / total) * 100;
  const inProgressPercentage = (inProgress / total) * 100;

  const stats = {
    completed,
    inProgress,
    notStarted: total - completed - inProgress
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center gap-2">
          <div className="w-2 h-2 rounded-full bg-emerald-500" />
          <span className="text-sm text-gray-600 dark:text-gray-300">
            {Math.round(completedPercentage)}% terminé
          </span>
        </div>
        <div className="text-sm font-medium text-gray-700 dark:text-gray-200">
          {completed}/{total} tâches
        </div>
      </div>
      <div className="h-1.5 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
        <div
          className="h-full bg-gradient-to-r from-emerald-500 to-emerald-600 transition-all duration-500 ease-in-out"
          style={{ width: `${completedPercentage}%` }}
        />
        <div
          className="h-full bg-gradient-to-r from-amber-500 to-amber-600 transition-all duration-500 ease-in-out -mt-1.5"
          style={{ width: `${inProgressPercentage}%` }}
        />
      </div>
      <div className="flex items-center justify-between mt-3 text-xs text-gray-500 dark:text-gray-400">
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-emerald-500" />
            <span>{stats.completed} terminées</span>
          </div>
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-amber-500" />
            <span>{stats.inProgress} en cours</span>
          </div>
          <div className="flex items-center gap-1.5">
            <div className="w-1.5 h-1.5 rounded-full bg-gray-300 dark:bg-gray-600" />
            <span>{stats.notStarted} à faire</span>
          </div>
        </div>
      </div>
    </div>
  );
}