import React from 'react';
import { ArrowLeft, Plus, RefreshCw, Trash2, HelpCircle, Eye, EyeOff, Mail } from 'lucide-react';
import { Country } from '../types/todo';

interface TodoListHeaderProps {
  country: Country;
  onBack: () => void;
  isPreview: boolean;
  onAddList?: () => void;
  onDeleteList?: () => void;
  onShowGuide: () => void;
  onShowResetConfirmation: () => void;
  onAddTask: () => void;
  showCompleted: boolean;
  onToggleShowCompleted: () => void;
  onSendEmail?: () => void;
}

export function TodoListHeader({
  country,
  onBack,
  isPreview,
  onAddList,
  onDeleteList,
  onShowGuide,
  onShowResetConfirmation,
  onAddTask,
  showCompleted,
  onToggleShowCompleted,
  onSendEmail
}: TodoListHeaderProps) {
  const [isSendingEmail, setIsSendingEmail] = React.useState(false);
  const [emailCooldown, setEmailCooldown] = React.useState(0);
  const cooldownRef = React.useRef<NodeJS.Timeout>();

  // Clear interval on unmount
  React.useEffect(() => {
    return () => {
      if (cooldownRef.current) {
        clearInterval(cooldownRef.current);
      }
    };
  }, []);

  const handleSendEmail = async () => {
    if (!onSendEmail || isSendingEmail || emailCooldown > 0) return;
    
    setIsSendingEmail(true);
    try {
      await onSendEmail();
      // Start cooldown
      setEmailCooldown(30);
      cooldownRef.current = setInterval(() => {
        setEmailCooldown(prev => {
          if (prev <= 1) {
            clearInterval(cooldownRef.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } finally {
      setIsSendingEmail(false);
    }
  };

  return (
    <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-lg border-b border-gray-200/50 dark:border-gray-700/50">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-14 sm:h-16 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center gap-3 min-w-0">
            <button
              onClick={onBack}
              className="flex-shrink-0 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
            >
              <ArrowLeft className="w-5 h-5 sm:w-6 sm:h-6 text-gray-600 dark:text-gray-400" />
            </button>
            <div className="min-w-0">
              <h1 className="text-base sm:text-xl font-bold text-gray-900 dark:text-white truncate">
                PVT {country.name}
              </h1>
            </div>
          </div>

          <div className="flex items-center gap-1 sm:gap-3">
            {isPreview ? (
              <button
                onClick={onAddList}
                className="flex items-center gap-1 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 rounded-lg transition-colors"
              >
                <Plus className="w-4 h-4" />
                <span className="hidden sm:inline">Ajouter</span>
              </button>
            ) : (
              <>
                <button
                  onClick={onToggleShowCompleted}
                  className="p-2 text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors"
                  title={showCompleted ? "Masquer les tâches terminées" : "Afficher les tâches terminées"}
                >
                  {showCompleted ? (
                    <EyeOff className="w-4 h-4 sm:w-5 sm:h-5" />
                  ) : (
                    <Eye className="w-4 h-4 sm:w-5 sm:h-5" />
                  )}
                </button>
                <button
                  onClick={onShowGuide}
                  className="p-2 text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors"
                  title="Voir le guide"
                >
                  <HelpCircle className="w-4 h-4 sm:w-5 sm:h-5" />
                </button>
                <button
                  onClick={onShowResetConfirmation}
                  className="p-2 text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400 transition-colors"
                  title="Réinitialiser"
                >
                  <RefreshCw className="w-4 h-4 sm:w-5 sm:h-5" />
                </button>
                <button
                  onClick={handleSendEmail}
                  disabled={isSendingEmail || emailCooldown > 0}
                  className={`p-2 relative transition-colors ${
                    emailCooldown > 0
                      ? 'text-gray-400 dark:text-gray-600 cursor-not-allowed'
                      : 'text-gray-500 hover:text-accent-500 dark:text-gray-400 dark:hover:text-accent-400'
                  }`}
                  title="Envoyer par email"
                >
                  {isSendingEmail ? (
                    <div className="w-4 h-4 sm:w-5 sm:h-5 border-2 border-accent-500/30 border-t-accent-500 rounded-full animate-spin" />
                  ) : (
                    <Mail className="w-4 h-4 sm:w-5 sm:h-5" />
                  )}
                  {emailCooldown > 0 && (
                    <div className="absolute -top-1 -right-1 w-5 h-5 bg-gray-100 dark:bg-gray-700 rounded-full flex items-center justify-center text-[10px] font-medium text-gray-600 dark:text-gray-400">
                      {emailCooldown}
                    </div>
                  )}
                </button>
                <button
                  onClick={onAddTask}
                  className="flex items-center gap-1 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2 text-sm font-medium text-white bg-accent-500 hover:bg-accent-600 rounded-lg transition-colors"
                >
                  <Plus className="w-4 h-4" />
                  <span className="hidden sm:inline">Tâche</span>
                </button>
                <button
                  onClick={onDeleteList}
                  className="p-2 text-red-500 hover:text-red-600 transition-colors"
                  title="Supprimer la liste"
                >
                  <Trash2 className="w-4 h-4 sm:w-5 sm:h-5" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}