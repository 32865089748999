import React from 'react';
import { Task, Country } from '../types/todo';
import { TaskList } from '../components/TaskList';
import { ProgressBar } from '../components/ProgressBar';
import { TaskForm } from '../components/TaskForm';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { GuideModal } from '../components/GuideModal';
import { TaskInfo } from '../components/TaskInfo';
import { TodoListHeader } from '../components/TodoListHeader';
import { PreviewOverlay } from '../components/PreviewOverlay';
import { TaskNavigation } from '../components/TaskNavigation';
import { TravelDates } from '../components/TravelDates';
import { TutorialPromo } from '../components/TutorialPromo';
import { GuidePromo } from '../components/GuidePromo';
import { useTaskManagement } from '../hooks/useTaskManagement';
import { CategoryPill } from '../components/task/CategoryPill';
import { getCountriesFromFirebase, getUserLists } from '../lib/firebase-service';
import { MessageSquare, Users, Clock, ArrowRight } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Alert } from '../components/Alert';
import { BackButton } from '../components/BackButton'; 
import { sendEmail } from '../lib/api';
import { formatChecklistForEmail } from '../utils/checklist';
import { useAuth } from '../lib/auth-context';

interface TodoListPageProps {
  countryCode: string;
  onBack: () => void;
  isPreview?: boolean;
  onAddList?: () => void;
  onDeleteList?: () => void;
  userId?: string;
  isCreatingList?: boolean;
}

export function TodoListPage({
  countryCode,
  onBack,
  isPreview = false,
  onAddList,
  onDeleteList,
  userId,
  isCreatingList = false
}: TodoListPageProps) {
  const [country, setCountry] = React.useState<Country | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { userInfo } = useAuth();
  const [listCreatedAt, setListCreatedAt] = React.useState<Date | undefined>();
  const [showHeaderGuide, setShowHeaderGuide] = React.useState(false);
  const [showCompleted, setShowCompleted] = React.useState(true);
  const [activeCategory, setActiveCategory] = React.useState<string>('all');
  const [messageStats, setMessageStats] = React.useState<{
    totalMessages: number;
    activeUsers: number;
    lastMessageTime: Date | null;
  }>({
    totalMessages: 0,
    activeUsers: 0,
    lastMessageTime: null
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const countries = await getCountriesFromFirebase();
        const foundCountry = countries.find(c => c.code === countryCode);
        if (foundCountry) {
          setCountry(foundCountry);
        }

        if (!isPreview && userId) {
          const lists = await getUserLists(userId);
          const userList = lists.find(list => list.countryCode === countryCode);
          if (userList) {
            setListCreatedAt(userList.createdAt);
          }
        }

        // Fetch message stats
        const messagesRef = collection(db, 'messages');
        const q = query(
          messagesRef,
          where('thread_code', '==', countryCode),
          where('status', '==', 'active')
        );
        const snapshot = await getDocs(q);
        
        const uniqueUsers = new Set(snapshot.docs.map(doc => doc.data().user_id));
        
        const messages = snapshot.docs.map(doc => ({
          ...doc.data(),
          created_at: doc.data().created_at?.toDate()
        }));
        const sortedMessages = messages.sort((a, b) => 
          b.created_at?.getTime() - a.created_at?.getTime()
        );

        setMessageStats({
          totalMessages: snapshot.size,
          activeUsers: uniqueUsers.size,
          lastMessageTime: sortedMessages[0]?.created_at || null
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [countryCode, isPreview, userId]);

  const getOptimizedImageUrl = (url: string): string => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname.includes('unsplash.com')) {
        const baseUrl = url.split('?')[0];
        return `${baseUrl}?w=3000&q=85&fit=crop&crop=entropy&auto=format`;
      }
      return url;
    } catch (e) {
      console.error('Invalid image URL:', e);
      return url;
    }
  };

  const {
    tasks,
    departureDate,
    returnDate,
    isAddingTask,
    editingTask,
    showResetConfirmation,
    showGuide,
    taskToDelete,
    handleUpdateStatus,
    handleDeleteTask,
    handleConfirmDeleteTask,
    handleAddSubtask,
    handleToggleSubtask,
    handleCreateTask,
    handleUpdateTask,
    handleUpdateNotes,
    handleResetToDefaults,
    handleTaskClick,
    handleDeleteSubtask,
    handleUpdateDepartureDate,
    handleUpdateReturnDate,
    handleUpdatePriority,
    handleCloseGuide,
    setIsAddingTask,
    setEditingTask,
    setShowResetConfirmation,
    setShowGuide,
    setTaskToDelete
  } = useTaskManagement(countryCode, isPreview, userId);

  const filteredTasks = React.useMemo(() => {
    if (showCompleted) return tasks;
    return tasks.filter(task => task.status !== 'completed');
  }, [tasks, showCompleted]);

  const filteredTasksByCategory = React.useMemo(() => {
    if (activeCategory === 'all') return filteredTasks;
    return filteredTasks.filter(task => task.category === activeCategory);
  }, [filteredTasks, activeCategory]);

  if (isLoading || !country) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-[8.5rem] pb-mobile main-content">
      <CategoryPill 
        activeCategory={activeCategory}
        onCategoryChange={setActiveCategory}
      />

      <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-lg border-b border-gray-200/50 dark:border-gray-700/50">
        <div className="max-w-7xl mx-auto">
          <div className="flex items-center h-14 px-4 sm:px-6 lg:px-8">
            <div className="flex items-center gap-3 min-w-0">
              <BackButton onBack={onBack} />
              <div className="min-w-0">
                <h1 className="text-base sm:text-lg font-bold text-gray-900 dark:text-white truncate">
                  PVT {country.name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative w-full h-[30vh] sm:h-96 md:h-[32rem] rounded-b-[2.5rem] overflow-hidden">
        <img
          src={getOptimizedImageUrl(country.image)}
          alt={country.name}
          className="w-full h-full object-cover"
          loading="eager"
          fetchpriority="high"
        />
        
        {/* Message Stats Overlay */}
        {!isPreview && messageStats.totalMessages > 0 && (
          <div className="absolute top-4 sm:top-6 right-4 sm:right-6">
            <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl overflow-hidden">
              <div className="p-3 sm:p-4">
                {/* Stats Row */}
                <div className="flex items-center gap-3 sm:gap-4 mb-3">
                  <div className="flex items-center gap-2">
                    <MessageSquare className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-white/90" />
                    <span className="text-xs sm:text-sm font-medium text-white/90">
                      {messageStats.totalMessages} messages
                    </span>
                  </div>
                  <div className="h-4 w-px bg-white/20" />
                  <div className="flex items-center gap-2">
                    <Users className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-white/90" />
                    <span className="text-xs sm:text-sm font-medium text-white/90">
                      {messageStats.activeUsers} participants
                    </span>
                  </div>
                </div>

                {/* CTA Button */}
                <button
                  onClick={() => window.dispatchEvent(new CustomEvent('openMessageCenter'))}
                  className="w-full flex items-center justify-center gap-2 px-3 py-1.5 bg-white/20 hover:bg-white/30 text-white text-xs sm:text-sm rounded-lg transition-colors group"
                >
                  <span>Rejoindre la discussion</span>
                  <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <TodoListHeader
        country={country}
        onBack={onBack}
        isPreview={isPreview}
        onAddList={onAddList}
        onDeleteList={onDeleteList}
        onShowGuide={() => setShowHeaderGuide(true)}
        onShowResetConfirmation={() => setShowResetConfirmation(true)}
        onAddTask={() => setIsAddingTask(true)}
        showCompleted={showCompleted}
        onToggleShowCompleted={() => setShowCompleted(!showCompleted)}
        onSendEmail={async () => {
          const button = document.querySelector('button[title="Envoyer par email"]');
          if (button) {
            button.classList.add('pointer-events-none', 'opacity-50');
          }

          if (!userInfo?.user?.email) return;
          try {
            await sendEmail(
              `Checklist PVT ${country.name}`,
              formatChecklistForEmail({
                tasks,
                country,
                departureDate,
                returnDate
              }),
              userInfo.user.email,
              userInfo
            );
            // Show success message
            const alert = document.createElement('div');
            alert.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 px-6 py-3 rounded-xl shadow-lg border border-emerald-100 dark:border-emerald-800/30 z-50 flex items-center gap-3 animate-fade-in';
            alert.innerHTML = `
              <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Checklist envoyée par email avec succès !</span>
            `;
            document.body.appendChild(alert);
            setTimeout(() => alert.remove(), 5000);
          } catch (error) {
            console.error('Error sending checklist email:', error);
            // Show error message
            const alert = document.createElement('div');
            alert.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-3 rounded-xl shadow-lg border border-red-100 dark:border-red-800/30 z-50 flex items-center gap-3 animate-fade-in';
            alert.innerHTML = `
              <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span>Erreur lors de l'envoi de l'email</span>
            `;
            document.body.appendChild(alert);
            setTimeout(() => alert.remove(), 5000);
          }
          finally {
            if (button) {
              button.classList.remove('pointer-events-none', 'opacity-50');
            }
          }
        }}
      />

      <div className="relative max-w-[100rem] mx-auto mt-12">
        <div className="lg:flex lg:gap-6 px-1 sm:px-4">
          {!isPreview && (
            <div className="hidden lg:block lg:w-80 flex-shrink-0">
              <div className="sticky top-[calc(8.5rem+1rem)] h-[calc(100vh-8.5rem-2rem)] overflow-y-auto">
                <TaskNavigation 
                  tasks={filteredTasks} 
                  onTaskClick={handleTaskClick}
                  onUpdateStatus={handleUpdateStatus}
                />
              </div>
            </div>
          )}
          
          <div className="flex-1">
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium -mt-20 mx-1 sm:mx-0">
              <div className="p-3 sm:p-6">
                {!isPreview && <TaskInfo createdAt={listCreatedAt} />}
                <div className="mt-4">
                  <ProgressBar tasks={tasks} />
                </div>
              </div>
            </div>

            {!isPreview && (
              <div className="mt-6">
                <TutorialPromo country={country} />
                <div className="mt-4">
                  <TravelDates
                    departureDate={departureDate}
                    returnDate={returnDate}
                    onDepartureDateChange={handleUpdateDepartureDate}
                    onReturnDateChange={handleUpdateReturnDate}
                  />
                </div>
              </div>
            )}

              <div className={isPreview ? 'opacity-75' : ''}>
                <TaskList
                  tasks={filteredTasksByCategory}
                  departureDate={departureDate}
                  returnDate={returnDate}
                  onUpdateStatus={handleUpdateStatus}
                  onDeleteTask={handleDeleteTask}
                  onAddSubtask={handleAddSubtask}
                  onToggleSubtask={handleToggleSubtask}
                  onUpdateNotes={handleUpdateNotes}
                  onDeleteSubtask={handleDeleteSubtask}
                  isPreview={isPreview}
                  onEditTask={setEditingTask}
                  onUpdatePriority={handleUpdatePriority}
                />
              </div>

              {isPreview && onAddList && (
                <PreviewOverlay 
                  onAddList={onAddList} 
                  onBack={onBack}
                  isCreating={isCreatingList}
                />
              )}

            {!isPreview && (
              <div className="xl:hidden mt-4">
                <GuidePromo />
              </div>
            )}
          </div>

          {!isPreview && (
            <div className="hidden xl:block w-80 flex-shrink-0">
              <div className="sticky top-[calc(8.5rem+1rem)]">
                <GuidePromo />
              </div>
            </div>
          )}
        </div>
      </div>

      {isAddingTask && !isPreview && (
        <TaskForm
          onSubmit={handleCreateTask}
          onClose={() => setIsAddingTask(false)}
        />
      )}

      {editingTask && !isPreview && (
        <TaskForm
          initialTask={editingTask}
          onSubmit={handleUpdateTask}
          onClose={() => setEditingTask(null)}
        />
      )}

      <DeleteConfirmationModal
        isOpen={showResetConfirmation}
        onClose={() => setShowResetConfirmation(false)}
        onConfirm={handleResetToDefaults}
        itemName="votre checklist"
        confirmationWord="RÉINITIALISER"
        description="Cette action va réinitialiser toutes vos tâches à leur état initial. Vos modifications seront perdues. Cette action est irréversible."
      />

      <DeleteConfirmationModal
        isOpen={!!taskToDelete}
        onClose={() => setTaskToDelete(null)}
        onConfirm={handleConfirmDeleteTask}
        itemName={`la tâche "${taskToDelete?.title}"`}
        confirmationWord="SUPPRIMER"
      />

      <GuideModal
        isOpen={showGuide}
        onClose={handleCloseGuide}
        isIntroGuide={true}
      />

      <GuideModal
        isOpen={showHeaderGuide}
        onClose={() => setShowHeaderGuide(false)}
        isIntroGuide={false}
      />
    </div>
  );
}