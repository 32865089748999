import React from 'react';
import { Info, Calculator } from 'lucide-react';

interface UtilitiesBreakdownProps {
  priceItems: any[];
  currency: string;
  eurPrices?: any[];
}

export function UtilitiesBreakdown({ priceItems, currency, eurPrices }: UtilitiesBreakdownProps) {
  const UTILITIES_ITEMS = [
    { id: 30, name: 'Charges mensuelles (électricité, chauffage, eau)' },
    { id: 33, name: 'Internet mensuel' }
  ];

  const getItemPrice = (itemId: number) => {
    const item = priceItems.find(item => item.item_id === itemId);
    const eurItem = eurPrices?.find(item => item.item_id === itemId);
    
    if (!item) return null;

    return {
      price: item.average_price,
      eurPrice: eurItem?.average_price
    };
  };

  return (
    <div className="mt-4 bg-gray-50 dark:bg-gray-700/30 rounded-xl p-4 sm:p-6">
      <div className="flex items-start gap-3 mb-4"> 
        <Info className="hidden sm:block w-5 h-5 flex-shrink-0 mt-0.5 text-gray-500 dark:text-gray-400" />
        <p className="text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
          Prix moyens constatés pour les charges (électricité, chauffage, eau). Le montant affiché a été automatiquement ajusté en fonction du type de logement sélectionné.
        </p>
      </div>

      <div className="space-y-2">
        {UTILITIES_ITEMS.map(item => {
          const prices = getItemPrice(item.id);
          if (!prices) return null;

          return (
            <div key={item.id} className="flex flex-col sm:flex-row sm:items-center sm:justify-between py-2 border-b border-gray-200/50 dark:border-gray-700/50 last:border-0">
              <span className="text-sm text-gray-600 dark:text-gray-400 mb-1 sm:mb-0">
                {item.name}
              </span>
              <div className="text-right font-mono text-base sm:text-sm font-medium text-primary-600 dark:text-primary-400">
                {(prices.eurPrice || prices.price).toLocaleString('fr-FR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
              </div>
            </div>
          );
        })}

        {/* Note explicative */}
        <div className="mt-6 pt-4 border-t-2 border-gray-200 dark:border-gray-600">
          <div className="flex items-start gap-3">
            <Calculator className="hidden sm:block w-4 h-4 flex-shrink-0 mt-0.5 text-gray-400 dark:text-gray-500" />
            <p className="text-xs text-gray-500 dark:text-gray-400 leading-relaxed">
              Les charges sont calculées proportionnellement au type de logement choisi. Par exemple, un studio consommera moins qu'un grand appartement. Les montants peuvent varier selon la saison et la zone géographique. Certains logements incluent les charges dans le loyer.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}