import React from 'react';
import { Interest } from '../../../types/matching';

interface ProfileInterestsProps {
  interests: Interest[];
}

export function ProfileInterests({ interests }: ProfileInterestsProps) {
  return (
    <div>
      <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
        Centres d'intérêt
      </h2>
      <div className="flex flex-wrap gap-3">
        {interests.map((interest) => (
          <div
            key={interest.id}
            className="flex items-center gap-2 px-3 py-1.5 bg-gray-100 dark:bg-gray-700 rounded-lg"
          >
            <span>{interest.icon}</span>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {interest.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}