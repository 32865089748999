import React from 'react';
import { Info, Wallet, ShoppingCart, Train, Zap, CreditCard, Film, PiggyBank, Utensils, Plus, Smartphone } from 'lucide-react';
import { getCityLivingCosts } from '../../../lib/numbeo-service';
import { MonthlyExpenseCategory } from './monthly/MonthlyExpenseCategory';
import { CustomExpenseForm } from './monthly/CustomExpenseForm';
import { CustomExpenseList } from './monthly/CustomExpenseList';
import { MonthlyTotal } from './monthly/MonthlyTotal';

interface StepThreeProps {
  formData: {
    country: string;
    city: string;
    isNomadMode: boolean;
    housingType: string;
    groceriesCost: string;
    restaurantsCost: string;
    transportCost: string;
    phoneCost: string;
    internetCost: string;
    utilitiesCost: string;
    bankCost: string;
    streamingCost: string;
    leisureCost: string;
    epargne: string;
    expenseFields: { id: string; name: string; amount: string }[];
  };
  onFormDataChange: (data: any) => void;
}

const GROCERY_ITEM_IDS = [8, 9, 11, 12, 19, 110, 112, 116]; // IDs for basic groceries

const categories = [
  {
    id: 'groceries',
    name: 'Courses alimentaires',
    icon: ShoppingCart,
    field: 'groceriesCost',
    gradient: 'from-emerald-500/20 to-emerald-600/20',
    iconColor: 'text-emerald-500',
    description: 'Budget courses et alimentation',
    required: true,
    numbeoIds: GROCERY_ITEM_IDS
  },
  {
    id: 'restaurants',
    name: 'Restaurants',
    icon: Utensils,
    field: 'restaurantsCost',
    gradient: 'from-orange-500/20 to-orange-600/20',
    iconColor: 'text-orange-500',
    description: 'Repas au restaurant et fast-food',
    required: true,
    numbeoIds: [1, 2, 3]
  },
  {
    id: 'transport',
    name: 'Transport',
    icon: Train,
    field: 'transportCost',
    gradient: 'from-amber-500/20 to-amber-600/20',
    iconColor: 'text-amber-500',
    description: 'Transports en commun ou essence',
    required: true,
    numbeoIds: [20]
  },
  {
    id: 'utilities',
    name: 'Charges',
    icon: Zap,
    field: 'utilitiesCost',
    gradient: 'from-purple-500/20 to-purple-600/20',
    iconColor: 'text-purple-500',
    description: 'Électricité, eau, gaz',
    required: true,
    numbeoIds: [30]
  },
  {
    id: 'phone',
    name: 'Forfait mobile',
    icon: Smartphone,
    field: 'phoneCost',
    gradient: 'from-blue-500/20 to-blue-600/20',
    iconColor: 'text-blue-500',
    description: 'Forfait mobile avec data',
    required: true,
    numbeoIds: [34]
  },
  {
    id: 'bank',
    name: 'Frais bancaires',
    icon: CreditCard,
    field: 'bankCost',
    gradient: 'from-gray-500/20 to-gray-600/20',
    iconColor: 'text-gray-500',
    defaultValue: '5', // Valeur par défaut pour les frais bancaires
    required: true
  },
  {
    id: 'streaming',
    name: 'Services de streaming',
    icon: Film,
    field: 'streamingCost',
    gradient: 'from-pink-500/20 to-pink-600/20',
    iconColor: 'text-pink-500',
    description: 'Netflix, Disney+, Spotify, Deezer, Apple Music...',
    defaultValue: '10',
    required: true
  },
  {
    id: 'leisure',
    name: 'Loisirs',
    icon: Film,
    field: 'leisureCost',
    gradient: 'from-rose-500/20 to-rose-600/20',
    iconColor: 'text-rose-500',
    description: 'Sport, cinéma, sorties',
    required: false,
    numbeoIds: [40, 44, 6, 7]
  },
  {
    id: 'savings',
    name: 'Épargne',
    icon: PiggyBank,
    field: 'epargne',
    gradient: 'from-green-500/20 to-green-600/20',
    iconColor: 'text-green-500',
    description: 'Épargne mensuelle souhaitée',
    required: false
  }
];

export function StepThree({ formData, onFormDataChange }: StepThreeProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [costData, setCostData] = React.useState<any>(null);
  const [editingExpenseId, setEditingExpenseId] = React.useState<string | null>(null);
  const [showAddExpense, setShowAddExpense] = React.useState(false);
  const [customExpenses, setCustomExpenses] = React.useState<{id: string; name: string; amount: string}[]>(
    formData.expenseFields || []
  );

  const handleAddCustomExpense = (name: string, amount: string) => {
    // Vérifier si la limite de 3 dépenses est atteinte
    if (customExpenses.length >= 3) {
      alert('Vous ne pouvez pas ajouter plus de 3 dépenses personnalisées.');
      setShowAddExpense(false);
      return;
    }

    const newExpense = {
      id: Date.now().toString(),
      name: name.trim(),
      amount
    };

    const updatedExpenses = [...customExpenses, newExpense];
    setCustomExpenses(updatedExpenses);
    onFormDataChange({
      ...formData,
      expenseFields: updatedExpenses
    });
    setShowAddExpense(false);
  };

  const handleUpdateCustomExpense = (id: string, amount: string) => {
    const updatedExpenses = customExpenses.map(expense =>
      expense.id === id ? { ...expense, amount } : expense
    );
    setCustomExpenses(updatedExpenses);
    onFormDataChange({
      ...formData,
      expenseFields: updatedExpenses
    });
  };

  const handleDeleteCustomExpense = (id: string) => {
    const updatedExpenses = customExpenses.filter(expense => expense.id !== id);
    setCustomExpenses(updatedExpenses);
    onFormDataChange({
      ...formData,
      expenseFields: updatedExpenses
    });
  };

  const selectedHousing = React.useMemo(() => {
    if (!costData?.housingTypes || !formData.housingType) return null;
    return costData.housingTypes.find((h: any) => h.id === formData.housingType);
  }, [costData?.housingTypes, formData.housingType]);

  const monthlyTotal = React.useMemo(() => {
    // Récupérer le coût du logement sélectionné
    const housingCost = selectedHousing ? (selectedHousing.costEUR || selectedHousing.cost) : 0;

    // Calculer le total mensuel uniquement à partir des valeurs saisies
    const expensesTotal = [
      Number(formData.groceriesCost) || 0,
      Number(formData.restaurantsCost) || 0,
      Number(formData.transportCost) || 0,
      Number(formData.utilitiesCost) || 0,
      Number(formData.phoneCost) || 0,
      Number(formData.bankCost) || 0,
      Number(formData.streamingCost) || 0,
      Number(formData.leisureCost) || 0,
      Number(formData.epargne) || 0,
      ...customExpenses.map(expense => Number(expense.amount) || 0)
    ].reduce((a, b) => a + b, 0);

    // Ajouter le coût du logement au total
    return expensesTotal + housingCost;
  }, [formData, selectedHousing, customExpenses]);

  const isValid = React.useMemo(() => {
    return categories
      .filter(cat => cat.required)
      .every(cat => Number(formData[cat.field]) > 0);
  }, [formData]);

  React.useEffect(() => {
    const loadCityData = async () => {
      if (!formData.city || !formData.country) return;

      try {
        setIsLoading(true);
        const data = await getCityLivingCosts(formData.city, formData.country);
        setCostData(data);

        if (data?.priceItems) {
          const updatedFormData = { ...formData };
          
          // Définir la valeur par défaut pour les frais bancaires si non définie
          if (!formData.bankCost || formData.bankCost === '0') {
            updatedFormData.bankCost = '5';
          }

          // Définir la valeur par défaut pour le streaming si non définie
          if (!formData.streamingCost || formData.streamingCost === '0') {
            updatedFormData.streamingCost = '10';
          }

          categories.forEach(category => {
            if (category.numbeoIds) {
              if (category.id === 'groceries') {
                const defaultGroceriesCost = calculateDefaultGroceriesCost(data.priceItems, data.eurPrices);
                if (!formData.groceriesCost || formData.groceriesCost === '0') {
                  updatedFormData.groceriesCost = defaultGroceriesCost.toString();
                }
              } else if (category.id === 'restaurants') {
                const defaultRestaurantsCost = calculateDefaultRestaurantsCost(data.priceItems, data.eurPrices);
                if (!formData.restaurantsCost || formData.restaurantsCost === '0') {
                  updatedFormData.restaurantsCost = defaultRestaurantsCost.toString();
                }
              } else if (category.id === 'utilities') {
                const utilitiesCost = calculateDefaultUtilitiesCost(
                  data.priceItems,
                  data.eurPrices,
                  formData.housingType
                );
                
                if (!formData[category.field] || formData[category.field] === '0') {
                  updatedFormData[category.field] = utilitiesCost.toString();
                }
              } else if (category.id === 'leisure') {
                const defaultLeisureCost = calculateDefaultLeisureCost(data.priceItems, data.eurPrices);
                if (!formData.leisureCost || formData.leisureCost === '0') {
                  updatedFormData.leisureCost = defaultLeisureCost.toString();
                }
              } else if (category.id === 'phone') {
                const phoneCost = getPhoneCost(data.priceItems, data.eurPrices);
                if (!formData.phoneCost || formData.phoneCost === '0') {
                  updatedFormData.phoneCost = phoneCost.toString();
                }
              } else {
                const relevantPrices = (data.eurPrices || data.priceItems).filter((item: any) => 
                  category.numbeoIds.includes(item.item_id)
                );
                
                if (relevantPrices.length > 0) {
                  const avgPrice = Math.round(
                    relevantPrices.reduce((sum: number, item: any) => 
                      sum + item.average_price, 0
                    ) / relevantPrices.length
                  );
                  
                  if (!formData[category.field] || formData[category.field] === '0') {
                    updatedFormData[category.field] = avgPrice.toString();
                  }
                }
              }
            }
          });

          onFormDataChange(updatedFormData);
        }
      } catch (error) {
        console.error('Error loading city data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadCityData();
  }, [formData.city, formData.country]);

  const calculateDefaultGroceriesCost = (priceItems: any[], eurPrices?: any[]) => {
    const prices = eurPrices || priceItems;
    const basicItems = prices.filter(item => GROCERY_ITEM_IDS.includes(item.item_id));
    if (basicItems.length === 0) return 0;
    
    const weeklyTotal = basicItems.reduce((sum, item) => sum + item.average_price, 0);
    return Math.round(weeklyTotal * 4);
  };

  const calculateDefaultRestaurantsCost = (priceItems: any[], eurPrices?: any[]) => {
    const prices = eurPrices || priceItems;
    
    const inexpensiveMeal = prices.find(item => item.item_id === 1)?.average_price || 0;
    const midRangeMeal = prices.find(item => item.item_id === 2)?.average_price || 0;
    const mcdonaldsMeal = prices.find(item => item.item_id === 3)?.average_price || 0;
    
    const monthlyTotal = 
      (inexpensiveMeal * 2) + 
      (midRangeMeal / 2) + 
      (mcdonaldsMeal * 2);
    
    return Math.round(monthlyTotal);
  };

  const getPhoneCost = (priceItems: any[], eurPrices?: any[]) => {
    const prices = eurPrices || priceItems;
    const phoneItem = prices.find(item => item.item_id === 34);
    if (!phoneItem) {
      console.warn('No phone price found');
      return 0;
    }
    
    return Math.round(phoneItem.average_price * 100) / 100;
  };

  const calculateDefaultLeisureCost = (priceItems: any[], eurPrices?: any[]) => {
    const prices = eurPrices || priceItems;
    
    const gymMembership = prices.find(item => item.item_id === 40)?.average_price || 0;
    const movieTicket = prices.find(item => item.item_id === 44)?.average_price || 0;
    const coffee = prices.find(item => item.item_id === 6)?.average_price || 0;
    const beer = prices.find(item => item.item_id === 7)?.average_price || 0;
    
    const monthlyTotal = 
      gymMembership + 
      (movieTicket * 2) + 
      (coffee * 4) + 
      (beer * 4);
    
    return Math.round(monthlyTotal);
  };

  const calculateDefaultUtilitiesCost = (priceItems: any[], eurPrices?: any[], housingType?: string) => {
    const prices = eurPrices || priceItems;
    const baseUtilities = prices.find(item => item.item_id === 30)?.average_price || 0;
    
    const apartmentSizes = {
      shared_center: 0.3,
      shared_outside: 0.3,
      studio_center: 0.4,
      studio_outside: 0.4,
      one_bed_center: 0.6,
      one_bed_outside: 0.6,
      two_bed_center: 0.8,
      two_bed_outside: 0.8,
      apartment_center: 1,
      apartment_outside: 1
    };

    const costRatio = housingType ? apartmentSizes[housingType] : 1;
    const adjustedCost = baseUtilities * costRatio;
    
    return Math.round(adjustedCost);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
      <div className="bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-900/20 dark:to-amber-800/20 rounded-xl p-4 border border-amber-100/50 dark:border-amber-800/30 mb-8">
        <div className="flex gap-3">
          <div className="flex-shrink-0">
            <Info className="w-5 h-5 text-amber-600 dark:text-amber-400" />
          </div>
          <div>
            <h3 className="text-sm font-medium text-amber-800 dark:text-amber-300 mb-1">
              Estimations et moyennes
            </h3>
            <p className="text-sm text-amber-700 dark:text-amber-400">
              Les montants suggérés sont calculés à partir des prix moyens constatés dans votre ville. N'hésitez pas à les ajuster en fonction de votre style de vie, de votre quartier et de vos habitudes personnelles.
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4 mb-8">
        <div className="p-3 bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-xl">
          <Wallet className="w-6 h-6 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            Budget mensuel
          </h3>
          <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
            Estimez vos dépenses mensuelles
          </p>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
        </div>
      ) : (
        <div className="space-y-4">
          {categories.map((category) => (
            <MonthlyExpenseCategory
              key={category.id}
              category={category}
              value={formData[category.field]}
              onChange={(value) => onFormDataChange({ ...formData, [category.field]: value })}
              costData={costData}
              isNomadMode={formData.isNomadMode}
              housingType={formData.housingType}
            />
          ))}

          <CustomExpenseList
            expenses={customExpenses}
            onUpdateAmount={handleUpdateCustomExpense}
            onDelete={handleDeleteCustomExpense}
          />

          <button
            type="button"
            onClick={() => setShowAddExpense(true)}
            disabled={customExpenses.length >= 3}
            className="w-full flex items-center justify-center gap-2 p-4 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl hover:border-primary-500 dark:hover:border-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/20 transition-all duration-200"
          >
            <Plus className="w-5 h-5 text-gray-400 group-hover:text-primary-500" />
            <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
              {customExpenses.length >= 3 
                ? 'Limite de 3 dépenses atteinte'
                : 'Ajouter une dépense personnalisée'
              }
            </span>
          </button>

          {showAddExpense && (
            <CustomExpenseForm
              onSubmit={handleAddCustomExpense}
              onClose={() => setShowAddExpense(false)}
            />
          )}

          {!isValid && (
            <div className="mt-6 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100 dark:border-amber-800/30">
              <div className="flex items-center gap-2">
                <Info className="w-5 h-5 text-amber-500" />
                <p className="text-sm text-amber-700 dark:text-amber-300">
                  Veuillez remplir tous les champs obligatoires avant de continuer
                </p>
              </div>
            </div>
          )}

          <div className="mt-8">
            <MonthlyTotal
              total={monthlyTotal}
              housingCost={selectedHousing ? (selectedHousing.costEUR || selectedHousing.cost) : undefined}
            />
          </div>
        </div>
      )}
    </div>
  );
}