import React from 'react';
import { MapPin, GraduationCap, Briefcase } from 'lucide-react';
import { TravelProfile } from '../../../types/matching';
import { getAge } from '../../../utils/date';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../lib/firebase';

interface ProfileHeroProps {
  profile: TravelProfile;
}

export function ProfileHero({ profile }: ProfileHeroProps) {
  const [cityPhoto, setCityPhoto] = React.useState<string | null>(null);

  // Fetch cached photo when profile loads
  React.useEffect(() => {
    const fetchCachedPhoto = async () => {
      if (profile.preferences.city) {
        try {
          const cacheRef = doc(db, 'photo_cache', profile.preferences.city.toLowerCase());
          const cacheDoc = await getDoc(cacheRef);
          
          if (cacheDoc.exists()) {
            const data = cacheDoc.data();
            const photos = data.photos;
            if (photos && photos.length > 0) {
              // Sélectionner une photo aléatoire du cache
              const randomPhoto = photos[Math.floor(Math.random() * photos.length)];
              setCityPhoto(randomPhoto.url);
            }
          }
        } catch (error) {
          console.error('Error fetching cached photo:', error);
        }
      }
    };

    fetchCachedPhoto();
  }, [profile.preferences.city]);

  return (
    <div className="relative h-[500px] sm:h-[600px] overflow-hidden">
      {/* Background blur effect */}
      <img
        src={profile.photos?.[0] || cityPhoto || `https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2670&auto=format&fit=crop`}
        alt={profile.firstName}
        className="absolute inset-0 w-full h-full object-cover filter blur-lg scale-110 opacity-20"
        loading="eager"
        fetchpriority="high"
      />
      
      {/* Main photo */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="relative w-full h-full sm:h-auto sm:max-w-4xl sm:aspect-[3/2] sm:rounded-2xl overflow-hidden shadow-2xl">
          <img
            src={profile.photos?.[0] || cityPhoto || `https://images.unsplash.com/photo-1501785888041-af3ef285b470?q=80&w=2670&auto=format&fit=crop`}
            alt={profile.firstName}
            className="absolute inset-0 w-full h-full object-cover sm:object-center"
            loading="eager"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
        </div>
      </div>
      
      {/* Profile info */}
      <div className="absolute inset-x-0 bottom-0 p-4 sm:p-8 bg-gradient-to-t from-black/75 via-black/50 to-transparent">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 leading-tight">
            {profile.firstName}, {getAge(profile.birthDate)} ans
            {profile.username && (
              <a 
                href={`https://pvtistes.net/membre/${profile.username}/`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                className="ml-2 text-sm sm:text-base font-normal text-white/80 hover:text-white transition-colors inline-flex items-center gap-1"
              >
                @{profile.username}
                <span className="w-4 h-4 rounded-full bg-white/10 flex items-center justify-center">
                  <svg className="w-2.5 h-2.5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <path d="M15 3h6v6" />
                    <path d="M10 14L21 3" />
                  </svg>
                </span>
              </a>
            )}
            {profile.pvtStatus === 'obtained' && (
              <span className="ml-2 text-xs sm:text-sm bg-emerald-500/20 text-emerald-300 px-3 py-1 rounded-full border border-emerald-500/20 backdrop-blur-sm">
                PVT obtenu
              </span>
            )}
          </h1>

          <div className="flex flex-wrap items-center gap-x-6 gap-y-3">
            {profile.location && (
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center backdrop-blur-sm">
                  <MapPin className="w-4 h-4 text-white/90" />
                </div>
                <span className="text-sm sm:text-base text-white/90">
                  {profile.location}
                </span>
              </div>
            )}
            {profile.occupation && (
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center backdrop-blur-sm">
                  <Briefcase className="w-4 h-4 text-white/90" />
                </div>
                <span className="text-sm sm:text-base text-white/90">
                  {profile.occupation}
                </span>
              </div>
            )}
            {profile.diploma && (
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center backdrop-blur-sm">
                  <GraduationCap className="w-4 h-4 text-white/90" />
                </div>
                <span className="text-sm sm:text-base text-white/90">
                  {profile.diploma}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}