import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Newspaper, Book } from 'lucide-react';
import { UnifiedContent } from '../../types/content';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { scrollToTop } from '../../utils/scroll';

interface ContentCardProps {
  content: UnifiedContent;
}

export function ContentCard({ content }: ContentCardProps) {
  const handleClick = () => {
    // Scroll to top before navigating
    scrollToTop();
  };

  const getTypeIcon = () => {
    return content.type === 'news' ? (
      <Newspaper className="w-4 h-4 text-primary-500" />
    ) : (
      <Book className="w-4 h-4 text-primary-500" />
    );
  };

  const getTypeLabel = () => {
    return content.type === 'news' ? 'Actualité' : 'Dossier';
  };

  return (
    <Link
      to={`/${content.type === 'news' ? 'news' : 'dossiers'}/${content.id}`}
      onClick={handleClick}
      className="group bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md border border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-200 transform hover:-translate-y-1"
    >
      <div className="relative aspect-[16/9] overflow-hidden">
        <img
          src={content.cover || content.thumbnail}
          alt={content.title}
          className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            if (!target.src.includes('fallback')) {
              target.src = 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2670&auto=format&fit=crop';
            }
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        
        <div className="absolute top-3 left-3">
          <div className="flex items-center gap-2 px-2.5 py-1.5 bg-white/90 dark:bg-gray-800/90 rounded-full backdrop-blur-sm">
            {getTypeIcon()}
            <span className="text-xs font-medium text-gray-900 dark:text-white">
              {getTypeLabel()}
            </span>
          </div>
        </div>
        
        <div className="absolute bottom-3 left-3 right-3">
          <h2 className="text-sm sm:text-base font-semibold text-white line-clamp-2 leading-snug sm:leading-snug">
            {content.title}
          </h2>
        </div>
      </div>

      <div className="p-4">
        <p className="text-sm text-gray-600 dark:text-gray-400 line-clamp-2 mb-4">
          {content.description}
        </p>

        <div className="flex items-center justify-between">
          <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
            <Calendar className="w-3.5 h-3.5 mr-1.5" />
            <span>
              {format(parseISO(content.created), 'dd MMM yyyy', { locale: fr })}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <img
              src={content.author.avatar || `https://ui-avatars.com/api/?name=${content.author.firstname}&background=random`}
              alt={content.author.firstname}
              className="w-5 h-5 rounded-full"
            />
            <span className="text-xs font-medium text-gray-600 dark:text-gray-400">
              {content.author.firstname}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}