import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, Share2, X } from 'lucide-react';
import { News } from '../types/news';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { ReadingProgress } from '../components/ReadingProgress';
import { fetchNewsById } from '../lib/api';

const cleanImageUrl = (url: string): string => {
  if (!url) return '';
  return url.replace(/-\d+x\d+(?=\.[a-zA-Z]+$)/, '');
};

const cleanContent = (content: string): string => {
  // First clean image URLs
  let cleanedContent = content.replace(
    /src="([^"]+)-\d+x\d+(\.[a-zA-Z]+)"/g,
    (match, path, extension) => `src="${path}${extension}"`
  );

  // Then wrap YouTube iframes in responsive containers
  cleanedContent = cleanedContent.replace(
    /(<iframe[^>]*(?:youtube\.com|youtu\.be)[^>]*>.*?<\/iframe>)/g,
    '<div class="video-container">$1</div>'
  );

  // Clean up styles and formatting
  cleanedContent = cleanedContent
    .replace(/style="[^"]*color:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*background-color:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*font-weight:[^";]*;?[^"]*"/g, '')
    .replace(/style="[^"]*text-decoration:\s*underline[^";]*;?[^"]*"/g, 'class="custom-underline"')
    .replace(/<\/?(?:b|strong)>/g, '')
    .replace(/<\/?em>/g, '')
    .replace(/<\/?u>/g, '')
    .replace(/<a[^>]*>(<img[^>]*>)<\/a>/g, '$1');

  return cleanedContent;
};

export function NewsDetailPage() {
  const { user, userInfo } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [news, setNews] = React.useState<News | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  React.useEffect(() => {
    const fetchNews = async () => {
      if (!id || !userInfo) return;
      
      setIsLoading(true);
      setError(null);

      try {
        const data = await fetchNewsById(id, userInfo);
        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error);
        setError('Impossible de charger l\'actualité pour le moment');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchNews();
    }
    window.scrollTo(0, 0);
  }, [id, user, userInfo]);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: news?.title,
          text: news?.excerpt,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Lien copié dans le presse-papier !');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />
      
      <ReadingProgress color="accent" />

      <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-14">
            <button
              onClick={() => navigate('/content')}
              className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5 flex-shrink-0" />
              <span className="font-medium">Retour aux actualités</span>
            </button>
            {news && (
              <div className="flex items-center gap-4">
                <button
                  onClick={handleShare}
                  className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  <Share2 className="w-5 h-5" />
                  <span className="hidden sm:inline font-medium">Partager</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <main className="flex-1 pt-32">
        {isLoading ? (
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50">
              <div className="p-4 sm:p-6 md:p-8 lg:p-12">
                <div className="animate-pulse space-y-8">
                  {/* Title skeleton */}
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4" />
                  
                  {/* Content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                  
                  {/* Image skeleton */}
                  <div className="aspect-video bg-gray-200 dark:bg-gray-700 rounded-xl" />
                  
                  {/* More content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : error || !news ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
              <p className="text-red-600 dark:text-red-400">{error || 'Actualité non trouvée'}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="relative h-[300px] sm:h-[400px]">
              <img
                src={cleanImageUrl(news.cover || news.thumbnail)}
                alt={news.title}
                className="absolute inset-0 w-full h-full object-cover"
                loading="eager"
                fetchpriority="high"
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  if (!target.src.includes('fallback')) {
                    target.src = 'https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2670&auto=format&fit=crop';
                  }
                }}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/70 to-black/80" />
              
              <div className="absolute inset-x-0 bottom-0 p-6 sm:p-8">
                <div className="max-w-3xl mx-auto">
                  <div className="flex flex-wrap items-center gap-4 text-white/80 mb-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      <span className="text-sm">
                        {format(parseISO(news.created), 'dd MMMM yyyy', { locale: fr })}
                      </span>
                    </div>
                  </div>

                  <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 leading-tight">
                    {news.title}
                  </h1>

                  <div className="flex items-center gap-4">
                    <img
                      src={news.author.avatar || `https://ui-avatars.com/api/?name=${news.author.firstname}&background=random`}
                      alt={news.author.firstname}
                      className="w-8 h-8 rounded-full ring-2 ring-white/20"
                    />
                    <div>
                      <span className="block text-white font-medium text-sm">
                        {news.author.firstname} {news.author.lastname}
                      </span>
                      <span className="text-xs text-white/80">
                        {news.author.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
              <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50">
                <div className="p-4 sm:p-6 md:p-8 lg:p-12">
                  <div 
                    ref={contentRef}
                    className="prose prose-lg dark:prose-invert mx-auto
                             prose-headings:font-semibold
                             prose-p:text-gray-700 dark:prose-p:text-gray-300
                             prose-a:no-underline
                             prose-img:rounded-xl prose-img:shadow-lg
                             sm:prose-h1:text-3xl
                             sm:prose-h2:text-2xl
                             sm:prose-h3:text-xl
                             sm:prose-h4:text-lg
                             prose-li:text-gray-700 dark:prose-li:text-gray-300"
                    dangerouslySetInnerHTML={{ 
                      __html: cleanContent(news.content)
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </main>

      <Footer />
    </div>
  );
}