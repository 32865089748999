import React from 'react';
import { Shield, CheckCircle2, ArrowRight } from 'lucide-react';

export function GuaranteesSection() {
  return (
    <div className="py-4 sm:py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
          <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-gradient-to-br from-primary-100/20 via-accent-100/20 to-primary-100/20 dark:from-primary-800/10 dark:via-accent-800/10 dark:to-primary-800/10 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-3xl pointer-events-none" />
          
          <div className="relative p-8 sm:p-12">
            <div className="flex items-center gap-3 mb-8">
              <div className="p-2 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                <Shield className="w-6 h-6 text-primary-500 dark:text-primary-400" />
              </div>
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white">
                Les principales garanties
              </h2>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Frais médicaux et hospitalisation
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Prise en charge à 100% des frais réels, sans franchise ni délai de carence, illimité dans le pays de PVT.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Hospitalisation sans avance de frais
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Prise en charge directe en cas d'hospitalisation : vous n'avancez pas les frais !
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Assistance complète
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Rapatriement, retour anticipé en cas de décès d'un proche, présence d'un membre de votre famille si hospitalisation de plus de 7 jours (hôtel et billet d'avion inclus).
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Responsabilité civile
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Protection en responsabilité civile vie privée incluse dans votre contrat.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Assurance bagages
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Vos bagages sont assurés pendant les trajets aller-retour.
                    </p>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-50 dark:bg-emerald-900/20 flex items-center justify-center mt-0.5">
                    <CheckCircle2 className="w-5 h-5 text-emerald-500 dark:text-emerald-400" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      Support réactif depuis 2005
                    </h3>
                    <p className="text-[15px] sm:text-base text-gray-600 dark:text-gray-400">
                      Un support réactif et convivial sur nos forums depuis 2005 !
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-gray-200/50 dark:border-gray-700/50">
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
                  Pour plus de détails sur ces garanties, consultez la notice d'information Globe PVT correspondant à votre destination.
                </p>
                <div className="flex items-center gap-4">
                  <a
                    href="https://www.acs-ami.com/files/pdf/Globe-PVT-EIC-Canada-IB-fr.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-4 py-2 bg-white dark:bg-gray-800 text-primary-600 dark:text-primary-400 rounded-lg transition-colors hover:bg-gray-50 dark:hover:bg-gray-700 text-sm font-medium"
                  >
                    <span>PVT/EIC Canada</span>
                    <ArrowRight className="w-4 h-4" />
                  </a>
                  <a
                    href="https://www.acs-ami.com/files/pdf/Globe-PVT-WHV-IB-fr.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 px-4 py-2 bg-white dark:bg-gray-800 text-primary-600 dark:text-primary-400 rounded-lg transition-colors hover:bg-gray-50 dark:hover:bg-gray-700 text-sm font-medium"
                  >
                    <span>Autres destinations</span>
                    <ArrowRight className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}