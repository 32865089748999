import React from 'react';
import { UserPlus, Users, ArrowRight, MapPin, Calendar } from 'lucide-react';

export function PvtmatesPromo() {
  return (
    <section className="py-12 sm:py-20 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 dark:from-gray-800 dark:to-gray-900 rounded-2xl shadow-premium">
          {/* Background image with overlay */}
          <div className="absolute inset-0">
            <img 
              src="https://images.unsplash.com/photo-1527631746610-bca00a040d60?q=80&w=2574&auto=format&fit=crop"
              alt="Voyageurs PVT"
              className="w-full h-full object-cover opacity-80"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-gray-900/80 via-gray-900/60 to-gray-900/40" />
          </div>

          <div className="relative">
            {/* Content */}
            <div className="p-8 sm:p-12 lg:p-16 flex flex-col lg:flex-row items-center gap-8 lg:gap-16">
              {/* Text Content */}
              <div className="flex-1 text-center lg:text-left">
                <div className="inline-flex items-center gap-2 px-3 py-1.5 bg-white/10 backdrop-blur-sm rounded-full border border-white/20 mb-6">
                  <UserPlus className="w-4 h-4 text-primary-400" />
                  <span className="text-sm font-medium text-white">Nouveau</span>
                </div>

                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4">
                  Trouvez votre compagnon de PVT
                </h2>

                <p className="text-base sm:text-lg text-gray-300 mb-8 max-w-2xl mx-auto lg:mx-0">
                  Rencontrez d'autres voyageurs qui partagent vos dates, votre destination et votre style de voyage. Échangez et préparez votre PVT ensemble !
                </p>

                <div className="flex flex-wrap justify-center lg:justify-start gap-4 mb-8">
                  <div className="flex items-center gap-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-xl">
                    <Users className="w-5 h-5 text-primary-400" />
                    <span className="text-sm font-medium text-white">50K+ voyageurs actifs</span>
                  </div>
                  <div className="flex items-center gap-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-xl">
                    <MapPin className="w-5 h-5 text-primary-400" />
                    <span className="text-sm font-medium text-white">15+ destinations</span>
                  </div>
                  <div className="flex items-center gap-2 px-4 py-2 bg-white/10 backdrop-blur-sm rounded-xl">
                    <Calendar className="w-5 h-5 text-primary-400" />
                    <span className="text-sm font-medium text-white">Matching par dates</span>
                  </div>
                </div>
               
              </div>

              {/* Stats Cards */}
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 lg:w-80">
                <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-3 bg-primary-500/20 rounded-xl">
                      <Users className="w-6 h-6 text-primary-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white">Matching intelligent</h3>
                      <p className="text-sm text-gray-400">Trouvez des voyageurs compatibles</p>
                    </div>
                  </div>
                  <div className="h-1 bg-gradient-to-r from-primary-500 to-accent-500 rounded-full" />
                </div>

                <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-6">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-3 bg-accent-500/20 rounded-xl">
                      <UserPlus className="w-6 h-6 text-accent-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white">Profils vérifiés</h3>
                      <p className="text-sm text-gray-400">Communauté de confiance</p>
                    </div>
                  </div>
                  <div className="h-1 bg-gradient-to-r from-accent-500 to-primary-500 rounded-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}