import React from 'react';
import { MessageList } from './MessageList';
import { MessageInput } from './MessageInput';
import { ThreadSelector } from './ThreadSelector';
import { MessageThread } from './types';
import { useAuth } from '../../lib/auth-context';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { X, MessageSquare, Menu, AlertCircle } from 'lucide-react';
import { getCountriesFromFirebase } from '../../lib/firebase-service';

interface MessageCenterProps {
  isOpen: boolean;
  onClose: () => void;
  countryCode?: string;
}

export function MessageCenter({ isOpen, onClose, countryCode }: MessageCenterProps) {
  const { user } = useAuth();
  const [isClosing, setIsClosing] = React.useState(false);
  const [activeThread, setActiveThread] = React.useState<MessageThread>(
    countryCode ? { code: countryCode, type: 'country' } : { code: 'general_discussion', type: 'general' }
  );
  const [messages, setMessages] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showThreads, setShowThreads] = React.useState(false);
  const [replyingTo, setReplyingTo] = React.useState<{ id: string; userEmail: string; username: string; userId: string } | null>(null);
  const [countryName, setCountryName] = React.useState<string>('');
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  // Update active thread when countryCode changes
  React.useEffect(() => {
    if (countryCode) {
      setActiveThread({ code: countryCode, type: 'country' });
    }
  }, [countryCode]);

  // Handle closing animation
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 200);
  };

  // Scroll to bottom when messages are loaded or thread changes
  React.useEffect(() => {
    if (!isLoading && messagesContainerRef.current) {
      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
      }, 100);
    }
  }, [isLoading, activeThread.code]);

  // Real-time message subscription
  React.useEffect(() => {
    if (!isOpen) return;

    setIsLoading(true);
    const messagesRef = collection(db, 'messages');
    const q = query(
      messagesRef,
      where('thread_code', '==', activeThread.code),
      where('status', '==', 'active'),
      orderBy('created_at', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(newMessages);
      setIsLoading(false);
    }, (error) => {
      console.error('Error in message subscription:', error);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [isOpen, activeThread.code]);

  // Fetch country name when needed
  React.useEffect(() => {
    const fetchCountryName = async () => {
      if (activeThread.type === 'country') {
        try {
          const countries = await getCountriesFromFirebase();
          const country = countries.find(c => c.code === activeThread.code);
          if (country) {
            setCountryName(country.name);
          }
        } catch (error) {
          console.error('Error fetching country name:', error);
        }
      }
    };

    fetchCountryName();
  }, [activeThread]);

  // Lock body scroll when modal is open
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getThreadTitle = () => {
    switch (activeThread.type) {
      case 'country':
        return `Discussion ${countryName}`;
      case 'bug':
        return 'Signalements de bugs';
      default:
        return 'Discussion générale';
    }
  };

  return (
    <div className={`fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-0 sm:p-4 z-[100] transition-opacity duration-200 ${
      isClosing ? 'opacity-0' : 'opacity-100'
    }`}>
      <div className="bg-white dark:bg-gray-800 w-full h-full sm:h-[85vh] sm:max-w-5xl sm:rounded-2xl shadow-premium flex flex-col">
        {/* Header with moderation notice */}
        <div className="sticky top-0 z-20 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm">
          <div className="flex items-center justify-between p-4 border-b dark:border-gray-700">
            <div className="flex items-center gap-3">
              <button
                onClick={() => setShowThreads(prev => !prev)}
                className="sm:hidden p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                aria-label="Toggle threads"
              >
                <Menu className="w-5 h-5 text-gray-500" />
              </button>
              <MessageSquare className="w-5 h-5 text-primary-500" />
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                {getThreadTitle()}
              </h2>
            </div>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              aria-label="Close"
            >
              <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          </div>
          <div className="px-4 py-3 bg-blue-50 dark:bg-blue-900/20 border-b border-blue-100 dark:border-blue-800/30">
            <div className="flex items-center gap-2">
              <AlertCircle className="w-4 h-4 text-blue-500 dark:text-blue-400 flex-shrink-0" />
              <p className="text-sm font-medium text-blue-700 dark:text-blue-300">
                Les messages sont modérés. Veuillez rester courtois et respectueux.
              </p>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-1 overflow-hidden relative">
          {/* Thread Selector - Mobile Drawer */}
          <div 
            className={`absolute inset-y-0 left-0 w-64 bg-white dark:bg-gray-800 border-r dark:border-gray-700 transform transition-transform duration-300 ease-in-out z-10 sm:static sm:transform-none ${
              showThreads ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'
            }`}
          >
            <div className="p-4 overflow-y-auto custom-scrollbar h-full">
              <ThreadSelector
                activeThread={activeThread}
                onThreadChange={(thread) => {
                  setActiveThread(thread);
                  setShowThreads(false);
                  setReplyingTo(null);
                }}
                countryCode={countryCode}
              />
            </div>
          </div>

          {/* Messages */}
          <div className="flex-1 flex flex-col overflow-hidden">
            {isLoading ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-500 border-t-transparent" />
              </div>
            ) : (
              <div ref={messagesContainerRef} className="flex-1 overflow-y-auto custom-scrollbar">
                <MessageList 
                  messages={messages} 
                  onReply={(message) => setReplyingTo({ 
                    id: message.id, 
                    userEmail: message.user_email,
                    username: message.username,
                    userId: message.user_id
                  })}
                />
              </div>
            )}

            {/* Input */}
            <div className="border-t dark:border-gray-700 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm">
              <MessageInput
                threadCode={activeThread.code}
                threadType={activeThread.type}
                userId={user?.uid || ''}
                userEmail={user?.email || ''}
                replyingTo={replyingTo}
                onCancelReply={() => setReplyingTo(null)}
                scrollContainerRef={scrollContainerRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}