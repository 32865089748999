import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { VideoProvider } from './lib/video-context';
import { HomePage } from './pages/HomePage';
import { TodoListPage } from './pages/TodoListPage';
import { UnifiedContentPage } from './pages/UnifiedContentPage';
import { LandingPage } from './pages/LandingPage';
import { EmbassyDirectoryPage } from './pages/EmbassyDirectoryPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { AboutPage } from './pages/AboutPage';
import { DeleteAccountPage } from './pages/DeleteAccountPage';
import { BudgetPage } from './pages/BudgetPage';
import { MatchingPage } from './pages/MatchingPage';
import { ProfileDetailPage } from './pages/ProfileDetailPage';
import { MediaPage } from './pages/MediaPage';
import { CityDataPage } from './pages/CityDataPage';
import { DossierPage } from './pages/DossierPage';
import { NewsDetailPage } from './pages/NewsDetailPage';
import { InterviewsPage } from './pages/InterviewsPage';
import { InterviewDetailPage } from './pages/InterviewDetailPage';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { AuthModal } from './components/AuthModal';
import { DeleteConfirmationModal } from './components/DeleteConfirmationModal';
import { EmailVerificationBanner } from './components/EmailVerificationBanner';
import { FloatingDiscussion } from './components/FloatingDiscussion';
import { SplashScreen } from './components/SplashScreen';
import { useAuth } from './lib/auth-context';
import { useExternalLinks } from './hooks/useExternalLinks';
import { createUserList, deleteUserList, getUserLists, getCountriesFromFirebase } from './lib/firebase-service';
import { Task, Country } from './types/todo';
import { InsurancePage } from './pages/InsurancePage';

function AppContent() {
  useExternalLinks();
  const { user, userInfo, isAdmin } = useAuth();
  const [countries, setCountries] = React.useState<Country[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthModalOpen, setIsAuthModalOpen] = React.useState(false);
  const [currentCountry, setCurrentCountry] = React.useState<string | null>(null);
  const [isSelectingCountry, setIsSelectingCountry] = React.useState(false);
  const [userLists, setUserLists] = React.useState<string[]>([]);
  const [showSplash, setShowSplash] = React.useState(true);
  const [isCreatingList, setIsCreatingList] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState<{
    isOpen: boolean;
    countryCode: string | null;
    fromDashboard: boolean;
  }>({
    isOpen: false,
    countryCode: null,
    fromDashboard: false
  });

  // Handle splash screen
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const handleExternalLinks = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const link = target.closest('a');
      
      if (!link) return;
      
      const href = link.getAttribute('href');
      if (!href) return;
      
      if (href.startsWith('http') && !href.includes(window.location.hostname)) {
        e.preventDefault();
        window.open(href, '_blank', 'noopener,noreferrer');
      }
    };

    document.addEventListener('click', handleExternalLinks);
    return () => document.removeEventListener('click', handleExternalLinks);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getCountriesFromFirebase();
        setCountries(countriesData);
        
        if (user) {
          const lists = await getUserLists(user.uid);
          setUserLists(lists.map(list => list.countryCode));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const availableCountries = React.useMemo(() => {
    return countries.filter(country => !userLists.includes(country.code));
  }, [countries, userLists]);

  const updatedCountries = React.useMemo(() => {
    return countries.map(country => ({
      ...country,
      hasList: userLists.includes(country.code)
    }));
  }, [countries, userLists]);

  const handleOpenSelection = () => {
    if (!user) {
      setIsAuthModalOpen(true);
      return;
    }
    setIsSelectingCountry(true);
  };

  const handleCloseSelection = () => {
    setIsSelectingCountry(false);
  };

  const handleSelectCountry = (code: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentCountry(code);
    setIsSelectingCountry(false);
  };

  const handleManageList = (code: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentCountry(code);
  };

  const handleAddList = async () => {
    if (!currentCountry || !user) {
      console.error('Missing required data for list creation');
      return;
    }

    try {
      setIsCreatingList(true);
      const country = countries.find(c => c.code === currentCountry);
      if (!country) {
        throw new Error(`Country not found: ${currentCountry}`);
      }

      if (!Array.isArray(country.defaultTasks)) {
        throw new Error('Invalid default tasks data');
      }

      const tasks = country.defaultTasks.map(task => {
        if (!task.id || !task.title) {
          throw new Error('Invalid task data');
        }

        return {
          id: task.id,
          defaultTaskId: task.id,
          title: task.title,
          description: task.description || '',
          status: 'not-started',
          priority: task.priority || 'medium',
          subtasks: (task.subtasks || []).map(st => ({
            ...st,
            completed: false
          })),
          notes: task.notes || '',
          category: task.category || 'uncategorized',
          createdAt: new Date(),
          updatedAt: new Date()
        } as Task;
      });

      await createUserList(user.uid, currentCountry, tasks, userInfo);
      setUserLists(prev => [...prev, currentCountry]);
      setIsSelectingCountry(false);
      // Afficher une alerte de succès
      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 left-1/2 -translate-x-1/2 bg-emerald-50 dark:bg-emerald-900/20 text-emerald-600 dark:text-emerald-400 px-6 py-3 rounded-xl shadow-lg border border-emerald-100 dark:border-emerald-800/30 z-50 flex items-center gap-3 animate-fade-in';
      alert.innerHTML = `
        <svg class="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>Checklist créée avec succès !&nbsp;&nbsp;Un email récapitulatif vous a été envoyé.</span>
      `;
      document.body.appendChild(alert);
      setTimeout(() => alert.remove(), 5000);
    } catch (error) {
      console.error('Error creating list:', error);
      throw new Error('Failed to create list. Please try again.');
    } finally {
      setIsCreatingList(false);
    }
  };

  const handleDeleteList = (countryCode: string, fromDashboard: boolean = false) => {
    setDeleteConfirmation({
      isOpen: true,
      countryCode,
      fromDashboard
    });
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.countryCode && user) {
      const lists = await getUserLists(user.uid);
      const listToDelete = lists.find(list => list.countryCode === deleteConfirmation.countryCode);
      if (listToDelete) {
        await deleteUserList(listToDelete.id);
        setUserLists(prev => prev.filter(code => code !== deleteConfirmation.countryCode));
        if (!deleteConfirmation.fromDashboard) {
          setCurrentCountry(null);
        }
      }
    }
    setDeleteConfirmation({
      isOpen: false,
      countryCode: null,
      fromDashboard: false
    });
  };

  if (showSplash) {
    return <SplashScreen />;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Routes>
        <Route path="/cgu" element={<TermsPage />} />
        <Route path="/confidentialite" element={<PrivacyPage />} />
        <Route path="/a-propos" element={<AboutPage />} />
        <Route path="/delete-account" element={<DeleteAccountPage />} />
        <Route path="/ambassades" element={<EmbassyDirectoryPage />} />
        <Route path="/simulateur-budget" element={<BudgetPage onLogin={() => setIsAuthModalOpen(true)} />} />
        <Route path="/matching" element={<MatchingPage />} />
        <Route path="/matching/:id" element={<ProfileDetailPage />} />
        <Route path="/city-data/:countryCode/:city" element={<CityDataPage />} />
        <Route path="/media" element={<MediaPage />} />
        <Route path="/content" element={<UnifiedContentPage />} />
        <Route path="/news/:id" element={<NewsDetailPage />} />
        <Route path="/dossiers/:id" element={<DossierPage />} />
        <Route path="/interviews" element={<InterviewsPage />} />
        <Route path="/interviews/:id" element={<InterviewDetailPage />} />
        <Route path="/assurance" element={<InsurancePage />} />
        <Route
          path="/*"
          element={
            <>
              {!user ? (
                <LandingPage onLogin={() => setIsAuthModalOpen(true)} />
              ) : (
                <>
                  <Navbar 
                    onLogin={() => setIsAuthModalOpen(true)} 
                    isBudgetPage={false}
                    countryCode={currentCountry}
                  />
                  
                  <main className="flex-1 p-4 lg:p-8 pb-16 sm:pb-8">
                    {currentCountry ? (
                      <TodoListPage
                        countryCode={currentCountry}
                        onBack={() => setCurrentCountry(null)}
                        isPreview={!userLists.includes(currentCountry)}
                        onAddList={handleAddList}
                        onDeleteList={() => handleDeleteList(currentCountry)}
                        userId={user?.uid}
                        isCreatingList={isCreatingList}
                      />
                    ) : (
                      <HomePage
                        userLists={userLists}
                        onCreateList={handleOpenSelection}
                        onManageList={handleManageList}
                        onDeleteList={handleDeleteList}
                        availableCountries={availableCountries}
                        isSelectingCountry={isSelectingCountry}
                        onCloseSelection={handleCloseSelection}
                        onSelectCountry={handleSelectCountry}
                        countries={updatedCountries}
                        isAuthenticated={!!user}
                      />
                    )}
                  </main>
                  <Footer />
                </>
              )}
            </>
          }
        />
      </Routes>

      <DeleteConfirmationModal
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, countryCode: null, fromDashboard: false })}
        onConfirm={handleConfirmDelete}
        itemName={deleteConfirmation.countryCode ? countries.find(c => c.code === deleteConfirmation.countryCode)?.name || '' : ''}
        confirmationWord="SUPPRIMER"
      />

      {isAuthModalOpen && (
        <AuthModal 
          isOpen={true} 
          onClose={() => setIsAuthModalOpen(false)} 
        />
      )}

      <EmailVerificationBanner />
      <FloatingDiscussion countryCode={currentCountry} />
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <VideoProvider>
        <AppContent />
      </VideoProvider>
    </Router>
  );
}