import React from 'react';
import { Play } from 'lucide-react';
import { VideoContext } from '../../../lib/video-context';

interface PostMediaProps {
  type: 'image' | 'video';
  mediaUrl: string;
  previewUrl?: string;
  thumbnail80Url?: string;
  caption: string;
}

export function PostMedia({
  type,
  mediaUrl,
  previewUrl,
  thumbnail80Url,
  caption
}: PostMediaProps) {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const videoContext = React.useContext(VideoContext);

  const handleVideoClick = () => {
    if (!videoRef.current) return;
    
    if (!isPlaying) {
      videoContext.setCurrentlyPlaying(videoRef.current);
    } else {
      videoContext.setCurrentlyPlaying(null);
    }

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  if (type === 'image') {
    return (
      <div className="relative bg-gray-100 dark:bg-gray-800">
        <div className="relative aspect-square">
          <div className="absolute inset-0 animate-pulse bg-gradient-to-r from-gray-200 to-gray-300 dark:from-gray-700 dark:to-gray-600 transition-opacity duration-300 opacity-100" />
          <img
            src={thumbnail80Url || previewUrl || mediaUrl}
            alt={caption}
            className="absolute inset-0 w-full h-full object-cover relative z-10 transition-opacity duration-300 opacity-0"
            loading="lazy"
            decoding="async"
            onLoad={(e) => {
              const target = e.target as HTMLImageElement;
              const loader = target.parentElement?.querySelector('.animate-pulse');
              if (loader) {
                loader.classList.remove('opacity-100');
              }
              target.classList.add('opacity-100');
              if (previewUrl && target.src === thumbnail80Url) {
                const highResImage = new Image();
                highResImage.onload = () => {
                  target.src = previewUrl || mediaUrl;
                };
                highResImage.src = previewUrl || mediaUrl;
              }
            }}
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              if (target.src === thumbnail80Url && previewUrl) {
                target.src = previewUrl;
              } else if (target.src !== mediaUrl) {
                target.src = mediaUrl;
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="relative bg-black aspect-square">
      {/* Mute button */}
      <button
        onClick={() => {
          setIsMuted(!isMuted);
          if (videoRef.current) {
            videoRef.current.muted = !isMuted;
          }
        }}
        className="absolute top-4 right-4 p-2 bg-black/50 hover:bg-black/70 text-white rounded-lg backdrop-blur-sm transition-colors z-20"
        title={isMuted ? "Activer le son" : "Couper le son"}
      >
        {isMuted ? (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M11 5L6 9H2v6h4l5 4V5z" />
            <path d="M23 9l-6 6" />
            <path d="M17 9l6 6" />
          </svg>
        ) : (
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M11 5L6 9H2v6h4l5 4V5z" />
            <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07" />
          </svg>
        )}
      </button>

      <video
        ref={videoRef}
        src={`${mediaUrl}#t=0.001`}
        className="w-full h-full object-cover"
        playsInline
        preload="metadata"
        poster={previewUrl}
        muted={isMuted}
        onClick={handleVideoClick}
        onPlay={() => {
          setIsPlaying(true);
          videoContext.setCurrentlyPlaying(videoRef.current);
        }}
        onPause={() => {
          setIsPlaying(false);
          if (videoContext.currentlyPlaying === videoRef.current) {
            videoContext.setCurrentlyPlaying(null);
          }
        }}
      />
      
      <div className="absolute inset-0 bg-black/30 pointer-events-none" style={{ opacity: isPlaying ? 0 : 1 }} />
      
      {!isPlaying && (
        <div 
          className="absolute inset-0 flex items-center justify-center bg-black/20"
          onClick={handleVideoClick}
        >
          <div className="p-4 rounded-full bg-black/50 backdrop-blur-sm hover:bg-black/70 transition-colors">
            <Play className="w-8 h-8 text-white" />
          </div>
        </div>
      )}
    </div>
  );
}