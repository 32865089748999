import React from 'react';
import { Info } from 'lucide-react';

interface SectionHeaderProps {
  title: string;
  description: string;
}

export function SectionHeader({ title, description }: SectionHeaderProps) {
  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-white dark:from-gray-800/50 dark:to-gray-800 rounded-xl border border-gray-100 dark:border-gray-700/50 p-4 sm:p-5 mb-6">
      <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-primary-100/20 to-accent-100/20 dark:from-primary-800/10 dark:to-accent-800/10 rounded-full transform translate-x-32 -translate-y-32 blur-3xl pointer-events-none" />
      
      <div className="relative">
        <div className="flex items-center gap-3 mb-2">
          <div className="flex-shrink-0">
            <div className="p-2 bg-primary-50 dark:bg-primary-900/30 rounded-xl shadow-sm">
              <Info className="w-4 h-4 text-primary-500 dark:text-primary-400" />
            </div>
          </div>
          <h2 className="text-lg sm:text-lg font-bold bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300 bg-clip-text text-transparent">
            {title}
          </h2>
        </div>
        <div className="ml-11">
          <p className="text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}