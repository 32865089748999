import React from 'react';

interface TaskNotesProps {
  notes: string;
}

export function TaskNotes({ notes }: TaskNotesProps) {
  if (!notes) return null;

  return (
    <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-700/50 rounded-lg">
      <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Notes</h4>
      <div 
        className="prose prose-sm dark:prose-invert max-w-none text-gray-600 dark:text-gray-400"
        dangerouslySetInnerHTML={{ __html: notes }}
      />
    </div>
  );
}