import React from 'react';
import { Users } from 'lucide-react';
import { InterviewPreview } from '../../types/interviews';
import { InterviewCard } from './InterviewCard';

interface InterviewListProps {
  interviews: InterviewPreview[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  selectedCountry: string | null;
}

export function InterviewList({ interviews, isLoading, error, searchQuery, selectedCountry }: InterviewListProps) {
  if (isLoading && interviews.length === 0) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <div className="bg-gray-200 dark:bg-gray-700 rounded-xl overflow-hidden">
              <div className="aspect-[16/9]" />
              <div className="p-4 space-y-3">
                <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-3/4" />
                <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-1/2" />
                <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-full" />
                <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-2/3" />
                <div className="flex items-center gap-2 pt-2">
                  <div className="w-8 h-8 bg-gray-300 dark:bg-gray-600 rounded-full" />
                  <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-24" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  if (interviews.length === 0) {
    return (
      <div className="text-center py-12">
        <Users className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
          {searchQuery
            ? 'Aucun résultat trouvé'
            : selectedCountry
              ? 'Aucune interview disponible pour ce pays'
              : 'Aucun récit disponible'}
        </h3>
        <p className="text-gray-500 dark:text-gray-400">
          {searchQuery
            ? 'Essayez de modifier vos critères de recherche'
            : selectedCountry
              ? 'Essayez de sélectionner un autre pays ou revenez plus tard'
              : 'De nouveaux récits seront bientôt disponibles'}
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {interviews.map((interview) => (
        <InterviewCard key={interview.id} interview={interview} />
      ))}
    </div>
  );
}