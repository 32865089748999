import React from 'react';
import { Languages } from 'lucide-react';
import { Language } from '../../../types/matching';

interface ProfileLanguagesProps {
  languages: Language[];
}

export function ProfileLanguages({ languages }: ProfileLanguagesProps) {
  return (
    <div>
      <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-3 sm:mb-4">
        Langues
      </h2>
      <div className="flex flex-wrap gap-3">
        {languages.map((lang) => (
          <div
            key={lang.code}
            className={`flex items-center gap-2 px-3 py-1.5 rounded-lg ${
              lang.level === 'native'
                ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                : 'bg-gray-100 dark:bg-gray-700'
            }`}
          >
            <Languages className="w-4 h-4 text-gray-500 dark:text-gray-400" />
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {lang.name}
            </span>
            <span className={`text-xs capitalize ${
              lang.level === 'native'
                ? 'text-primary-500 dark:text-primary-400'
                : 'text-gray-500 dark:text-gray-400'
            }`}>
              ({lang.level})
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}