import React from 'react';
import { MoreVertical, Trash2, AlertTriangle } from 'lucide-react';

interface PostHeaderProps {
  username: string;
  avatar?: string;
  userId: string;
  currentUserId: string;
  onDelete?: () => void;
}

export function PostHeader({ username, avatar, userId, currentUserId, onDelete }: PostHeaderProps) {
  const [showOptions, setShowOptions] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  return (
    <>
      <div className="relative flex items-center justify-between p-4 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm z-20">
        <div className="flex items-center gap-3">
          <img
            src={avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&size=32&color=fff`}
            alt={username}
            className="w-8 h-8 rounded-full ring-2 ring-gray-200/50 dark:ring-gray-700/50"
            loading="eager"
            decoding="async"
            fetchpriority="high"
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&size=32&color=fff`;
            }}
          />
          <span className="font-medium text-gray-900 dark:text-white">
            {username}
          </span>
        </div>
        
        <div className="relative">
          <button
            onClick={() => setShowOptions(!showOptions)}
            className={`p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors ${
              userId !== currentUserId ? 'hidden' : ''
            }`}
          >
            <MoreVertical className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
          
          {showOptions && onDelete && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 rounded-xl shadow-lg border border-gray-200 dark:border-gray-700 py-1">
              <button
                onClick={() => {
                  setShowOptions(false);
                  setShowDeleteConfirmation(true);
                }}
                className="w-full flex items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
              >
                <Trash2 className="w-4 h-4" />
                <span>Supprimer</span>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <div className="flex items-center space-x-2">
                <AlertTriangle className="w-5 h-5 text-amber-500" />
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Confirmer la suppression
                </h3>
              </div>
            </div>
            <div className="p-4 space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Êtes-vous sûr de vouloir supprimer ce post ? Cette action est irréversible.
              </p>
              <div className="flex justify-end gap-3">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={() => {
                    onDelete();
                    setShowDeleteConfirmation(false);
                  }}
                  className="px-4 py-2 text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-500 rounded-lg transition-colors"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}