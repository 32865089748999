export const VISA_COSTS: { [key: string]: number } = {
  'aus': 400,
  'nz': 421,
  'ca': 245,
  'jpn': 0,
  'kor': 72,
  'twn': 95,
  'arg': 0,
  'chl': 130,
  'col': 0,
  'mex': 49,
  'per': 0,
  'uru': 60,
  'bra': 100,
  'hkg': 28,
  'fra': 99,
  'ecu': 0
};

export const FLIGHT_RANGES: { [key: string]: { min: number; max: number; avg: number } } = {
  'aus': { min: 800, max: 2000, avg: 1200 },
  'nz': { min: 900, max: 2200, avg: 1400 },
  'ca': { min: 400, max: 1000, avg: 700 },
  'jpn': { min: 600, max: 1200, avg: 800 },
  'kor': { min: 600, max: 1200, avg: 800 },
  'twn': { min: 600, max: 1200, avg: 800 },
  'arg': { min: 700, max: 1500, avg: 1000 },
  'chl': { min: 700, max: 1500, avg: 1000 },
  'col': { min: 600, max: 1200, avg: 900 },
  'mex': { min: 600, max: 1200, avg: 900 },
  'per': { min: 700, max: 1500, avg: 1000 },
  'uru': { min: 700, max: 1500, avg: 1000 },
  'bra': { min: 600, max: 1200, avg: 900 },
  'hkg': { min: 600, max: 1200, avg: 800 },
  'fra': { min: 100, max: 300, avg: 200 },
  'ecu': { min: 700, max: 1500, avg: 1000 }
};

