import React from 'react';
import { BookOpen, ArrowRight } from 'lucide-react';
import { Country } from '../types/todo';

interface TutorialPromoProps {
  country: Country;
}

export function TutorialPromo({ country }: TutorialPromoProps) {
  if (!country.tutorialUrl) return null;

  return (
    <div className="hidden sm:block relative h-[200px] rounded-xl overflow-hidden">
      {/* Background Image with slight blur */}
      <div className="absolute inset-0">
        <img 
          src={country.image}
          alt={`Guide PVT ${country.name}`}
          className="w-full h-full object-cover filter blur-[2px] scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50" />
      </div>

      {/* Content */}
      <div className="relative h-full flex items-center justify-between p-8 z-20">
        <div className="flex items-center gap-3 sm:gap-6">
          <div className="hidden sm:block w-16 h-16 rounded-full overflow-hidden">
            <img 
              src={country.image}
              alt={country.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h3 className="text-lg sm:text-2xl font-bold text-white mb-1 sm:mb-2">
              Tutoriel PVT {country.name}
            </h3>
            <p className="text-sm sm:text-base text-white/80">
              Un guide étape par étape pour obtenir votre PVT !
            </p>
          </div>
        </div>

        <a
          href={country.tutorialUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="hidden sm:flex items-center gap-3 px-4 sm:px-6 py-2 sm:py-3 bg-white/10 hover:bg-white/20 backdrop-blur-sm rounded-xl transition-all duration-300 group hover:scale-105"
        >
          <span className="text-white font-medium">Voir le tutoriel</span>
          <ArrowRight className="w-5 h-5 text-white transition-transform duration-300 group-hover:translate-x-1" />
        </a>

        {/* Mobile CTA */}
        <a
          href={country.tutorialUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="sm:hidden p-3 bg-white/10 hover:bg-white/20 backdrop-blur-sm rounded-xl transition-all duration-300 hover:scale-105"
        >
          <ArrowRight className="w-5 h-5 text-white" />
        </a>
      </div>
    </div>
  );
}