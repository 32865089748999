import React from 'react';
import { Task, TaskStatus, TaskPriority } from '../types/todo';
import { getCountriesFromFirebase, getUserLists, updateUserList } from '../lib/firebase-service';
import confetti from 'canvas-confetti';

const fireConfetti = () => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
    colors: ['#22c55e', '#10b981', '#059669'],
    angle: 90,
    startVelocity: 30,
    gravity: 0.8,
    shapes: ['circle', 'square'],
    ticks: 200
  });
};

export function useTaskManagement(countryCode: string, isPreview: boolean, userId?: string) {
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [departureDate, setDepartureDate] = React.useState('');
  const [returnDate, setReturnDate] = React.useState('');
  const [isAddingTask, setIsAddingTask] = React.useState(false);
  const [editingTask, setEditingTask] = React.useState<Task | null>(null);
  const [listId, setListId] = React.useState<string | null>(null);
  const [showResetConfirmation, setShowResetConfirmation] = React.useState(false);
  const [showGuide, setShowGuide] = React.useState(false);
  const [canCloseGuide, setCanCloseGuide] = React.useState(false);
  const [taskToDelete, setTaskToDelete] = React.useState<Task | null>(null);
  const [isNewList, setIsNewList] = React.useState(false);
  
  // Effet pour détecter une nouvelle liste
  React.useEffect(() => {
    const checkIfNewList = async () => {
      if (!isPreview && userId) {
        const lists = await getUserLists(userId);
        const userList = lists.find(list => list.countryCode === countryCode);
        if (userList) {
          // Vérifier si la liste a été créée il y a moins de 10 secondes
          const creationTime = userList.createdAt.getTime();
          const now = new Date().getTime();
          if (now - creationTime < 10000) {
            setIsNewList(true);
          }
        }
      }
    };

    checkIfNewList();
  }, [isPreview, userId, countryCode]);

  // Effet pour gérer l'affichage automatique du guide
  React.useEffect(() => {
    if (isNewList) {
      // Afficher le guide après 5 secondes
      const showTimer = setTimeout(() => {
        setShowGuide(true);
        
        // Permettre la fermeture après 8 secondes
        const closeTimer = setTimeout(() => {
          setCanCloseGuide(true);
        }, 8000);

        return () => clearTimeout(closeTimer);
      }, 5000);

      return () => clearTimeout(showTimer);
    }
  }, [isNewList]);

  // Modifier la fonction de fermeture du guide
  const handleCloseGuide = () => {
    if (canCloseGuide) {
      setShowGuide(false);
      setCanCloseGuide(false);
      setIsNewList(false);
    }
  };

  // Charger les tâches initiales et les dates
  React.useEffect(() => {
    const initializeTasks = async () => {
      try {
        if (!isPreview && userId) {
          const lists = await getUserLists(userId);
          const userList = lists.find(list => list.countryCode === countryCode);
          if (userList) {
            setListId(userList.id);
            setTasks(userList.tasks);
            setDepartureDate(userList.departureDate || '');
            setReturnDate(userList.returnDate || '');
            return;
          }
        }

        // Si on est en mode preview ou qu'aucune liste n'existe, charger les tâches par défaut
        const countries = await getCountriesFromFirebase();
        const country = countries.find(c => c.code === countryCode);
        if (country) {
          setTasks(country.defaultTasks.map(task => ({
            id: task.id!, // Utiliser l'ID existant
            title: task.title!,
            description: task.description || '',
            status: task.status || 'not-started',
            priority: task.priority || 'medium',
            subtasks: task.subtasks || [],
            notes: task.notes || '',
            category: task.category || 'uncategorized',
            createdAt: new Date(),
            updatedAt: new Date()
          })));
        }
      } catch (error) {
        console.error('Error initializing tasks:', error);
      }
    };

    initializeTasks();
  }, [countryCode, isPreview, userId]);

  const saveToFirebase = React.useCallback(async (updatedTasks: Task[], newDepartureDate?: string, newReturnDate?: string) => {
    if (!isPreview && userId && listId) {
      try {
        await updateUserList(
          listId,
          updatedTasks,
          newDepartureDate !== undefined ? newDepartureDate : departureDate,
          newReturnDate !== undefined ? newReturnDate : returnDate
        );
      } catch (error) {
        console.error('Error saving tasks and dates:', error);
      }
    }
  }, [isPreview, userId, listId, departureDate, returnDate]);

  const handleUpdateDepartureDate = async (date: string) => {
    setDepartureDate(date);
    await saveToFirebase(tasks, date, returnDate);
  };

  const handleUpdateReturnDate = async (date: string) => {
    setReturnDate(date);
    await saveToFirebase(tasks, departureDate, date);
  };

  const handleUpdateStatus = async (taskId: string, status: TaskStatus) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const updatedSubtasks = task.subtasks.map(subtask => ({
          ...subtask,
          completed: status === 'completed'
        }));

        return {
          ...task,
          status,
          subtasks: updatedSubtasks,
          updatedAt: new Date()
        };
      }
      return task;
    });

    if (status === 'completed') {
      fireConfetti();
    }

    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  const handleConfirmDeleteTask = async () => {
    if (taskToDelete) {
      const updatedTasks = tasks.filter(task => task.id !== taskToDelete.id);
      setTasks(updatedTasks);
      await saveToFirebase(updatedTasks);
      setTaskToDelete(null);
    }
  };

  const handleDeleteTask = (taskId: string) => {
    const task = tasks.find(t => t.id === taskId);
    if (task) {
      setTaskToDelete(task);
    }
  };

  const handleAddSubtask = async (taskId: string, title: string) => {
    if (!title.trim()) return;
    
    const updatedTasks = tasks.map(task =>
      task.id === taskId
        ? {
            ...task,
            subtasks: [
              ...task.subtasks,
              { id: Date.now().toString(), title: title.trim(), completed: false }
            ],
            updatedAt: new Date()
          }
        : task
    );
    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  const handleToggleSubtask = async (taskId: string, subtaskId: string) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const updatedSubtasks = task.subtasks.map(subtask =>
          subtask.id === subtaskId
            ? { ...subtask, completed: !subtask.completed }
            : subtask
        );

        let newStatus: TaskStatus = task.status;
        const allCompleted = updatedSubtasks.every(st => st.completed);
        const anyCompleted = updatedSubtasks.some(st => st.completed);
        const hasSubtasks = updatedSubtasks.length > 0;

        if (hasSubtasks) {
          if (allCompleted) {
            newStatus = 'completed';
            fireConfetti();
          } else if (anyCompleted) {
            newStatus = 'in-progress';
          } else {
            newStatus = 'not-started';
          }
        }

        return {
          ...task,
          status: newStatus,
          subtasks: updatedSubtasks,
          updatedAt: new Date()
        };
      }
      return task;
    });

    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  const handleCreateTask = async (taskData: Partial<Task>) => {
    const newTask: Task = {
      id: Date.now().toString(), // Pour les nouvelles tâches créées manuellement
      title: taskData.title!,
      description: taskData.description || '',
      status: taskData.status || 'not-started',
      priority: taskData.priority || 'medium',
      subtasks: taskData.subtasks || [],
      notes: taskData.notes || '',
      category: taskData.category || 'uncategorized',
      createdAt: new Date(),
      updatedAt: new Date()
    };

    const updatedTasks = [...tasks, newTask];
    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
    setIsAddingTask(false);
  };

  const handleUpdateTask = async (taskData: Partial<Task>) => {
    if (!editingTask) return;

    const updatedTasks = tasks.map(task =>
      task.id === editingTask.id
        ? {
            ...task,
            ...taskData,
            updatedAt: new Date()
          }
        : task
    );
    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
    setEditingTask(null);
  };

  const handleUpdateNotes = async (taskId: string, notes: string) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId
        ? { ...task, notes, updatedAt: new Date() }
        : task
    );
    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  const handleResetToDefaults = async () => {
    try {
      const countries = await getCountriesFromFirebase();
      const country = countries.find(c => c.code === countryCode);
      if (country) {
        const defaultTasks = country.defaultTasks.map(task => ({
          id: task.id!, // Utiliser l'ID existant
          defaultTaskId: task.id, // Préserver le defaultTaskId
          title: task.title!,
          description: task.description || '',
          status: 'not-started',
          priority: task.priority || 'medium',
          subtasks: task.subtasks || [],
          notes: task.notes || '',
          category: task.category || 'uncategorized',
          createdAt: new Date(),
          updatedAt: new Date()
        }));
        setTasks(defaultTasks);
        await saveToFirebase(defaultTasks);
      }
    } catch (error) {
      console.error('Error resetting tasks:', error);
    }
    setShowResetConfirmation(false);
  };

  const handleTaskClick = (taskId: string) => {
    const element = document.getElementById(`task-${taskId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('highlight-task');
      setTimeout(() => {
        element.classList.remove('highlight-task');
      }, 2000);
    }
  };

  const handleDeleteSubtask = async (taskId: string, subtaskId: string) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId
        ? {
            ...task,
            subtasks: task.subtasks.filter(st => st.id !== subtaskId),
            updatedAt: new Date()
          }
        : task
    );
    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  const handleUpdatePriority = async (taskId: string, priority: TaskPriority) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        return {
          ...task,
          priority,
          updatedAt: new Date()
        };
      }
      return task;
    });

    setTasks(updatedTasks);
    await saveToFirebase(updatedTasks);
  };

  return {
    tasks,
    departureDate,
    returnDate,
    isAddingTask,
    editingTask,
    showResetConfirmation,
    showGuide,
    canCloseGuide,
    taskToDelete,
    handleUpdateStatus,
    handleDeleteTask,
    handleConfirmDeleteTask,
    handleAddSubtask,
    handleToggleSubtask,
    handleCreateTask,
    handleUpdateTask,
    handleUpdateNotes,
    handleResetToDefaults,
    handleTaskClick,
    handleDeleteSubtask,
    handleUpdateDepartureDate,
    handleUpdateReturnDate,
    handleUpdatePriority,
    handleCloseGuide,
    setIsAddingTask,
    setEditingTask,
    setShowResetConfirmation,
    setShowGuide: handleCloseGuide,
    setTaskToDelete
  };
}