import React from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Reply, ThumbsUp, ThumbsDown, Flag, Trash2, AlertTriangle, MessageSquare, ArrowDown, ArrowUp } from 'lucide-react';
import { useAuth } from '../../lib/auth-context';
import { doc, updateDoc, arrayUnion, arrayRemove, deleteDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface Message {
  id: string;
  content: string;
  user_id: string;
  user_email: string;
  username: string;
  created_at: { toDate: () => Date };
  likes: string[];
  dislikes: string[];
  reply_to?: {
    id: string;
    user_email: string;
    username: string;
    user_id?: string;
  };
}

interface MessageListProps {
  messages: Message[];
  onReply: (message: Message) => void;
}

export function MessageList({ messages, onReply }: MessageListProps) {
  const { user, userInfo, isAdmin } = useAuth();
  const [messageToDelete, setMessageToDelete] = React.useState<Message | null>(null);
  const [sortNewestFirst, setSortNewestFirst] = React.useState(() => {
    const savedSort = localStorage.getItem('messageSort');
    return savedSort === null ? false : savedSort === 'newest';
  });
  const messagesStartRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [prevMessagesLength, setPrevMessagesLength] = React.useState(0);

  // Save sort preference
  React.useEffect(() => {
    localStorage.setItem('messageSort', sortNewestFirst ? 'newest' : 'oldest');
  }, [sortNewestFirst]);

  React.useEffect(() => {
    if (messages.length > prevMessagesLength && scrollContainerRef.current) {
      const previousScrollHeight = scrollContainerRef.current.scrollHeight;
      const previousScrollTop = scrollContainerRef.current.scrollTop;
      
      setTimeout(() => {
        if (scrollContainerRef.current) {
          const newScrollHeight = scrollContainerRef.current.scrollHeight;
          const heightDifference = newScrollHeight - previousScrollHeight;
          scrollContainerRef.current.scrollTop = previousScrollTop + heightDifference;
        }
      }, 0);
    }
    
    setPrevMessagesLength(messages.length);
  }, [messages, prevMessagesLength]);

  // Organize messages in threads
  const organizedMessages = React.useMemo(() => {
    const threads: { [key: string]: Message[] } = {};
    const topLevelMessages: Message[] = [];
    
    messages.forEach(message => {
      if (message.reply_to) {
        // Find the original parent message (level 1)
        const parentId = message.reply_to.id;
        const parent = messages.find(m => m.id === parentId);
        
        // If parent is a reply, use its parent's ID instead
        const threadId = parent?.reply_to ? parent.reply_to.id : parentId;
        
        if (!threads[threadId]) {
          threads[threadId] = [];
        }
        threads[threadId].push(message);
      } else {
        topLevelMessages.push(message);
      }
    });

    Object.keys(threads).forEach(threadId => {
      threads[threadId].sort((a, b) => 
        a.created_at.toDate().getTime() - b.created_at.toDate().getTime()
      );
    });

    return { threads, topLevelMessages };
  }, [messages]);

  const handleReaction = async (messageId: string, isLike: boolean) => {
    if (!user) return;

    try {
      const messageRef = doc(db, 'messages', messageId);
      const message = messages.find(m => m.id === messageId);
      if (!message) return;

      if (message.user_id === user.uid) return;

      const hasLiked = (message.likes || []).includes(user.uid);
      const hasDisliked = (message.dislikes || []).includes(user.uid);

      if (isLike) {
        if (hasDisliked) {
          await updateDoc(messageRef, {
            dislikes: arrayRemove(user.uid)
          });
        }
        await updateDoc(messageRef, {
          likes: hasLiked ? arrayRemove(user.uid) : arrayUnion(user.uid)
        });
      } else {
        if (hasLiked) {
          await updateDoc(messageRef, {
            likes: arrayRemove(user.uid)
          });
        }
        await updateDoc(messageRef, {
          dislikes: hasDisliked ? arrayRemove(user.uid) : arrayUnion(user.uid)
        });
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
    }
  };

  const handleReport = async (messageId: string) => {
    if (!user) return;

    try {
      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, {
        reported: true,
        reported_by: user.uid,
        reported_at: new Date()
      });

      alert('Message signalé aux modérateurs.');
    } catch (error) {
      console.error('Error reporting message:', error);
    }
  };

  const handleDelete = async () => {
    if (!messageToDelete || !isAdmin) return;

    try {
      await deleteDoc(doc(db, 'messages', messageToDelete.id));
      setMessageToDelete(null);
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Erreur lors de la suppression du message');
    }
  };

  const formatDate = (date: Date) => {
    return formatDistanceToNow(date, { addSuffix: true, locale: fr });
  };

  const handleReply = (message: Message) => {
    onReply({
      id: message.id,
      content: message.content,
      user_id: message.user_id,
      user_email: message.user_email,
      username: message.username,
      created_at: message.created_at,
      likes: message.likes,
      dislikes: message.dislikes
    });
  };

  const MessageComponent = ({ message, isReply = false }: { message: Message; isReply?: boolean }) => (
    <div className={`group flex gap-3 ${isReply ? 'ml-8 mt-2' : ''}`}>
      <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-br from-primary-100 to-accent-100 dark:from-primary-900/30 dark:to-accent-900/30 flex items-center justify-center">
        <span className="text-sm font-medium text-primary-700 dark:text-primary-300">
          {userInfo?.user?.username ? userInfo.user.username[0].toUpperCase() : '?'}
        </span>
      </div>

      <div className="flex-1 max-w-[85%] min-w-0">
        <div className="flex items-center gap-2 mb-1">
          <span className="text-sm font-medium text-gray-900 dark:text-white">
            {message.user_id === user?.uid ? userInfo?.user?.username : message.username}
          </span>
          <span className="text-xs text-gray-500 dark:text-gray-400">
            {formatDate(message.created_at.toDate())}
          </span>
        </div>

        <div className={`rounded-2xl px-4 py-2.5 ${
          message.user_id === user?.uid
            ? 'bg-accent-500 text-white'
            : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white'
        }`}>
          <p 
            className="message-text whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: message.content.replace(
                /(https?:\/\/[^\s]+)/g,
                '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-primary-600 hover:text-primary-700 dark:text-primary-400 dark:hover:text-primary-300">$1</a>'
              )
            }}
          />
        </div>

        <div className="flex items-center gap-2 mt-1">
          {message.user_id !== user?.uid && (
            <>
              <button
                onClick={() => handleReaction(message.id, true)}
                className={`flex items-center gap-1 px-2 py-1 text-sm rounded-full transition-colors ${
                  message.likes?.includes(user?.uid || '')
                    ? 'text-primary-600 dark:text-primary-400'
                    : 'text-gray-500 hover:text-primary-600 dark:hover:text-primary-400'
                }`}
              >
                <ThumbsUp className="w-3.5 h-3.5" />
                {message.likes?.length > 0 && (
                  <span className="message-action">{message.likes.length}</span>
                )}
              </button>

              <button
                onClick={() => handleReaction(message.id, false)}
                className={`flex items-center gap-1 px-2 py-1 text-sm rounded-full transition-colors ${
                  message.dislikes?.includes(user?.uid || '')
                    ? 'text-red-600 dark:text-red-400'
                    : 'text-gray-500 hover:text-red-600 dark:hover:text-red-400'
                }`}
              >
                <ThumbsDown className="w-3.5 h-3.5" />
                {message.dislikes?.length > 0 && (
                  <span className="message-action">{message.dislikes.length}</span>
                )}
              </button>
            </>
          )}

          <button
            onClick={() => onReply(message)}
            className="flex items-center gap-1 px-2 py-1 message-action text-gray-500 hover:text-primary-600 dark:hover:text-primary-400 rounded-full transition-colors"
          >
            <Reply className="w-3.5 h-3.5" />
            <span>Répondre</span>
          </button>
          
          {isAdmin && (
            <button
              onClick={() => setMessageToDelete(message)}
              className="flex items-center gap-1 px-2 py-1 message-action text-gray-500 hover:text-red-600 dark:hover:text-red-400 rounded-full transition-colors"
            >
              <Trash2 className="w-3.5 h-3.5" />
              <span>Supprimer</span>
            </button>
          )}

          {message.user_id !== user?.uid && !isAdmin && (
            <button
              onClick={() => handleReport(message.id)}
              className="flex items-center gap-1 px-2 py-1 message-action text-gray-500 hover:text-red-600 dark:hover:text-red-400 rounded-full transition-colors ml-auto opacity-0 group-hover:opacity-100"
            >
              <Flag className="w-3.5 h-3.5" />
              <span>Signaler</span>
            </button>
          )}
        </div>

        {isReply && message.reply_to && (
          <div className="flex items-center gap-2">
            <span className="text-xs text-gray-600 dark:text-gray-400">
              Réponse à {message.reply_to.user_id === user?.uid ? userInfo?.user?.username : message.reply_to.username}
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div ref={scrollContainerRef} className="flex flex-col p-4 space-y-3 custom-scrollbar">
      <div ref={messagesStartRef} />
      
      {/* Sort Toggle Button */}
      <div className="flex justify-end mb-2">
        <button
          onClick={() => setSortNewestFirst(!sortNewestFirst)}
          className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
        >
          {sortNewestFirst ? (
            <>
              <ArrowDown className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              <span>Plus récents en haut</span>
            </>
          ) : (
            <>
              <ArrowUp className="w-4 h-4 text-gray-500 dark:text-gray-400" />
              <span>Plus anciens en haut</span>
            </>
          )}
        </button>
      </div>

      {/* Messages */}
      {organizedMessages.topLevelMessages.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full text-center p-6">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 to-accent-500/10 dark:from-primary-500/5 dark:to-accent-500/5 blur-2xl" />
            <div className="relative w-16 h-16 mb-4 bg-gradient-to-br from-primary-100 to-accent-100 dark:from-primary-900/30 dark:to-accent-900/30 rounded-2xl flex items-center justify-center transform transition-transform hover:scale-105">
              <MessageSquare className="w-8 h-8 text-primary-500 dark:text-primary-400" />
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
            Aucun message pour le moment
          </h3>
          <p className="text-base text-gray-600 dark:text-gray-400 max-w-sm">
            Soyez le premier à participer à la discussion et aidez la communauté à grandir !
          </p>
          <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl border border-gray-200/50 dark:border-gray-700/50 max-w-sm">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Partagez votre expérience, posez vos questions ou donnez des conseils aux autres voyageurs.
            </p>
          </div>
        </div>
      ) : (
        [...organizedMessages.topLevelMessages]
        .sort((a, b) => {
          const aTime = a.created_at.toDate().getTime();
          const bTime = b.created_at.toDate().getTime();
          return sortNewestFirst ? bTime - aTime : aTime - bTime;
        })
        .map(message => (
          <React.Fragment key={message.id}>
            <MessageComponent message={message} />
            {/* Replies */}
            {organizedMessages.threads[message.id]?.sort((a, b) => {
              const aTime = a.created_at.toDate().getTime();
              const bTime = b.created_at.toDate().getTime();
              return aTime - bTime; // Les réponses toujours chronologiquement
            }).map(reply => (
              <MessageComponent key={reply.id} message={reply} isReply={true} />
            ))}
          </React.Fragment>
        ))
      )}

      {/* Delete Confirmation Modal */}
      {messageToDelete && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-[100]">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md">
            <div className="p-4 border-b dark:border-gray-700">
              <div className="flex items-center space-x-2">
                <AlertTriangle className="w-5 h-5 text-red-500" />
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Confirmer la suppression
                </h3>
              </div>
            </div>
            <div className="p-4 space-y-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Êtes-vous sûr de vouloir supprimer ce message ? Cette action est irréversible.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setMessageToDelete(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  Annuler
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}