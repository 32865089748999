import React from 'react';
import { Helmet } from 'react-helmet-async';

const APP_BASE_URL = import.meta.env.VITE_APP_BASE_URL;

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
}

export function SEO({ 
  title = 'Checklist PVT/WHV - Organisez votre voyage avec pvtistes.net', 
  description = 'Organisez et suivez votre PVT/WHV étape par étape avec notre checklist interactive gratuite. Préparez votre départ, gérez votre budget et ne manquez aucune étape importante !', 
  image = 'https://pvtistes.net/wp-content/uploads/2025/01/sign_fullcolor.png',
  url = APP_BASE_URL
}: SEOProps) {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
}