import React from 'react';
import { UserPlus, Target, CheckCircle2, Clock, XCircle } from 'lucide-react';

interface StepOneProps {
  formData: {
    firstName: string;
    birthDate: string;
    location: string;
    bio: string;
    objectives: string[];
    pvtStatus: 'obtained' | 'pending' | 'not-requested';
  };
  onFormDataChange: (data: any) => void;
  errors: string[];
}

const PVT_STATUS = [
  { id: 'obtained', label: 'Déjà obtenu', icon: CheckCircle2, color: 'text-emerald-500 dark:text-emerald-400' },
  { id: 'pending', label: 'En cours', icon: Clock, color: 'text-amber-500 dark:text-amber-400' },
  { id: 'not-requested', label: 'Pas encore demandé', icon: XCircle, color: 'text-red-500 dark:text-red-400' }
];

const PVT_OBJECTIVES = [
  { id: 'language', label: 'Améliorer une langue' },
  { id: 'career', label: 'Faire évoluer sa carrière' },
  { id: 'travel', label: 'Voyager et explorer' },
  { id: 'nature', label: 'Expérience en pleine nature' },
  { id: 'culture', label: 'Découvrir la culture locale & faire la fête' }
];

export function StepOne({ formData, onFormDataChange, errors }: StepOneProps) {
  const getAge = (birthDate: string): number | null => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
          <UserPlus className="w-5 h-5 text-primary-500" />
        </div>
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Informations de base
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Commençons par les informations essentielles
          </p>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Prénom
          <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="text"
          value={formData.firstName}
          onChange={(e) => onFormDataChange({ ...formData, firstName: e.target.value })}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
            errors.includes('Le prénom est obligatoire')
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1" htmlFor="birthDate">
          Date de naissance
          <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="date"
          id="birthDate"
          value={formData.birthDate}
          onChange={(e) => onFormDataChange({ ...formData, birthDate: e.target.value })}
          max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split('T')[0]}
          min={new Date(new Date().setFullYear(new Date().getFullYear() - 35)).toISOString().split('T')[0]}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
            errors.includes('La date de naissance est obligatoire')
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          required
        />
        <div className="mt-1 flex items-center justify-between">
          <p className="text-xs text-gray-500 dark:text-gray-400">
            Vous devez avoir entre 18 et 35 ans pour participer au PVT
          </p>
          {formData.birthDate && (
            <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
              {getAge(formData.birthDate)} ans
            </p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Ville actuelle
          <span className="text-red-500 ml-1">*</span>
        </label>
        <input
          type="text"
          value={formData.location}
          onChange={(e) => onFormDataChange({ ...formData, location: e.target.value })}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
            errors.includes('La ville actuelle est obligatoire')
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Bio
          <span className="text-red-500 ml-1">*</span>
          <span className="text-xs text-gray-500 dark:text-gray-400 ml-2">
            (min. 100 caractères)
          </span>
        </label>
        <textarea
          value={formData.bio}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= 600) {
              onFormDataChange({ ...formData, bio: value });
            }
          }}
          minLength={100}
          maxLength={600}
          className={`w-full px-3 py-2 bg-white dark:bg-gray-800 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white h-32 resize-none ${
            errors.includes('La bio est obligatoire') || (formData.bio.length > 0 && formData.bio.length < 100)
              ? 'border-red-500 dark:border-red-500'
              : 'border-gray-300 dark:border-gray-600'
          }`}
          placeholder="Parlez de vous, de vos projets..."
          required
        />
        <div className="mt-1.5 flex items-center justify-between">
          <p className="text-xs text-gray-500 dark:text-gray-400">
            Décrivez votre personnalité, vos objectifs et ce que vous recherchez
          </p>
          <div className={`text-xs font-medium ${
            formData.bio.length < 100
              ? 'text-red-500'
              : formData.bio.length >= 500
              ? 'text-amber-500 dark:text-amber-400'
              : 'text-gray-500 dark:text-gray-400'
          }`}>
            {formData.bio.length}/600
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Statut du PVT
          <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="grid grid-cols-3 gap-2">
          {PVT_STATUS.map(status => (
            <button
              key={status.id}
              type="button"
              onClick={() => onFormDataChange({
                ...formData,
                pvtStatus: status.id as 'obtained' | 'pending' | 'not-requested'
              })}
              className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                formData.pvtStatus === status.id
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20 shadow-sm'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <status.icon className={`w-4 h-4 ${status.color}`} />
              {status.label}
            </button>
          ))}
        </div>
        {errors.includes('Le statut du PVT est obligatoire') && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-400">
            Veuillez sélectionner un statut
          </p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Objectifs du PVT
          <span className="text-red-500 ml-1">*</span>
        </label>
        <div className="space-y-2">
          {PVT_OBJECTIVES.map(objective => (
            <button
              key={objective.id}
              type="button"
              onClick={() => {
                const newObjectives = formData.objectives?.includes(objective.id)
                  ? formData.objectives.filter(id => id !== objective.id)
                  : [...(formData.objectives || []), objective.id];
                onFormDataChange({ ...formData, objectives: newObjectives });
              }}
              className={`w-full flex items-center gap-3 p-3 rounded-lg text-left transition-colors ${
                formData.objectives?.includes(objective.id)
                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 ring-1 ring-primary-500/20'
                  : 'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700'
              }`}
            >
              <Target className="w-5 h-5 flex-shrink-0" />
              <span className="text-sm">{objective.label}</span>
            </button>
          ))}
        </div>
        {errors.includes('Les objectifs sont obligatoires') && (
          <p className="mt-1 text-xs text-red-500">
            Veuillez sélectionner au moins un objectif
          </p>
        )}
        <p className="mt-2 text-xs text-gray-500 dark:text-gray-400">
          Sélectionnez un ou plusieurs objectifs
        </p>
      </div>
    </div>
  );
}