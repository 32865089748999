import { Timestamp } from 'firebase/firestore';

export function getAge(birthDate: Date | Timestamp | null): number | null {
  if (!birthDate) return null;
  
  let date: Date;
  try {
    // Try toDate() first for Firestore Timestamps
    date = birthDate instanceof Timestamp ? birthDate.toDate() : new Date(birthDate);
  } catch (error) {
    console.error('Error converting birthDate:', error);
    return null;
  }

  const today = new Date();
  let age = today.getFullYear() - date.getFullYear();
  const monthDiff = today.getMonth() - date.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
    age--;
  }
  
  return age;
}