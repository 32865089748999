import React from 'react';
import { Plane, Shield, Wallet, Info, Plus, X } from 'lucide-react';
import { BudgetNotice } from '../BudgetNotice';
import { GlobePvtPromo } from '../GlobePvtPromo';
import { VISA_COSTS, FLIGHT_RANGES } from '../../../constants/visa-costs';

interface CustomExpense {
  id: string;
  name: string;
  amount: string;
}

interface StepTwoProps {
  formData: {
    country: string;
    flightCost: string;
    insuranceCost: string;
    pvtCost: string;
    preDepartureExpenses?: CustomExpense[];
  };
  onFormDataChange: (data: any) => void;
}

export function StepTwo({ formData, onFormDataChange }: StepTwoProps) {
  const [showAddExpense, setShowAddExpense] = React.useState(false);
  const [newExpenseName, setNewExpenseName] = React.useState('');
  const [newExpenseAmount, setNewExpenseAmount] = React.useState('');

  // Récupérer les coûts spécifiques au pays
  const flightRange = FLIGHT_RANGES[formData.country] || { min: 600, max: 1500, avg: 1000 };
  const visaCost = VISA_COSTS[formData.country] || 0;

  // Mettre à jour le coût du visa quand le pays change
  React.useEffect(() => {
    if (formData.country && formData.pvtCost !== visaCost.toString()) {
      onFormDataChange({ ...formData, pvtCost: visaCost.toString() });
    }
  }, [formData.country]);

  const handleAddExpense = () => {
    if (!newExpenseName.trim() || !newExpenseAmount.trim()) return;

    const currentExpenses = formData.preDepartureExpenses || [];
    if (currentExpenses.length >= 3) {
      alert('Vous ne pouvez pas ajouter plus de 3 dépenses personnalisées.');
      return;
    }

    const newExpense = {
      id: Date.now().toString(),
      name: newExpenseName.trim(),
      amount: newExpenseAmount
    };

    onFormDataChange({
      ...formData,
      preDepartureExpenses: [...currentExpenses, newExpense]
    });

    setNewExpenseName('');
    setNewExpenseAmount('');
    setShowAddExpense(false);
  };

  const handleDeleteExpense = (id: string) => {
    const updatedExpenses = (formData.preDepartureExpenses || []).filter(exp => exp.id !== id);
    onFormDataChange({
      ...formData,
      preDepartureExpenses: updatedExpenses
    });
  };

  const handleUpdateExpenseAmount = (id: string, amount: string) => {
    const updatedExpenses = (formData.preDepartureExpenses || []).map(exp =>
      exp.id === id ? { ...exp, amount } : exp
    );
    onFormDataChange({
      ...formData,
      preDepartureExpenses: updatedExpenses
    });
  };

  // Validation des champs obligatoires
  const isValid = React.useMemo(() => {
    return (
      Number(formData.flightCost) > 0 &&
      Number(formData.insuranceCost) > 0 &&
      Number(formData.pvtCost) > 0
    );
  }, [formData]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium p-6 sm:p-8 border border-gray-200/50 dark:border-gray-700/50">
      <BudgetNotice 
        message="Les coûts affichés sont des moyennes constatées. Les prix des billets d'avion varient selon la saison et la compagnie. Les frais de visa et d'assurance sont donnés à titre indicatif."
      />

      <div className="flex items-center gap-4 mb-8">
        <div className="p-3 bg-primary-50 dark:bg-primary-900/20 rounded-xl">
          <Plane className="w-6 h-6 text-primary-500 dark:text-primary-400" />
        </div>
        <div>
          <h3 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            Dépenses avant le départ
          </h3>
          <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
            Les frais à prévoir avant votre départ
          </p>
        </div>
      </div>

      <div className="space-y-8">
        {/* Billet d'avion */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Prix du billet d'avion (€) <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <Plane className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="number"
              value={formData.flightCost}
              onChange={(e) => {
                const value = Math.max(0, Number(e.target.value));
                onFormDataChange({ ...formData, flightCost: value.toString() });
              }}
              min="0"
              step="0.01"
              className="w-full pl-12 pr-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
              required
            />
          </div>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Prix moyen constaté : {flightRange.avg}€ (entre {flightRange.min}€ et {flightRange.max}€)</span>
          </p>
        </div>

        {/* Assurance Globe PVT */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Assurance Globe PVT (€ / mois) <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <Shield className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="number"
              value={formData.insuranceCost}
              onChange={(e) => {
                const value = Math.max(0, Number(e.target.value));
                onFormDataChange({ ...formData, insuranceCost: value.toString() });
              }}
              min="0"
              step="0.01"
              className="w-full pl-12 pr-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
              required
            />
          </div>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Tarif de base : 31,84€ / mois</span>
          </p>
        </div>

        {/* Prix du PVT */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Prix du PVT (€) <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <Wallet className="w-5 h-5 text-gray-400" />
            </div>
            <input
              type="number"
              value={formData.pvtCost}
              onChange={(e) => {
                const value = Math.max(0, Number(e.target.value));
                onFormDataChange({ ...formData, pvtCost: value.toString() });
              }}
              min="0"
              step="0.01"
              className="w-full pl-12 pr-4 py-3 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
              required
            />
          </div>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex items-center gap-1.5">
            <Info className="w-4 h-4" />
            <span>Prix par défaut : {VISA_COSTS[formData.country] || 0}€</span>
          </p>
        </div>

        {/* Dépenses personnalisées */}
        {formData.preDepartureExpenses && formData.preDepartureExpenses.length > 0 && (
          <div className="space-y-4">
            <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
              Dépenses personnalisées
            </h4>
            {formData.preDepartureExpenses.map((expense) => (
              <div key={expense.id} className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl">
                <div className="flex-1">
                  <p className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                    {expense.name}
                  </p>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 dark:text-gray-400">€</span>
                    </div>
                    <input
                      type="number"
                      value={expense.amount}
                      onChange={(e) => handleUpdateExpenseAmount(expense.id, e.target.value)}
                      min="0"
                      step="0.01"
                      className="w-full pl-8 pr-4 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white text-sm"
                    />
                  </div>
                </div>
                <button
                  onClick={() => handleDeleteExpense(expense.id)}
                  className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Bouton pour ajouter une dépense personnalisée */}
        {(!formData.preDepartureExpenses || formData.preDepartureExpenses.length < 3) && (
          <button
            type="button"
            onClick={() => setShowAddExpense(true)}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-primary-600 dark:text-primary-400 hover:bg-primary-50 dark:hover:bg-primary-900/20 rounded-lg transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>Ajouter une dépense personnalisée</span>
          </button>
        )}

        {/* Modal pour ajouter une dépense */}
        {showAddExpense && (
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md">
              <div className="p-4 border-b dark:border-gray-700">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Ajouter une dépense
                </h3>
              </div>
              <div className="p-4 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Nom de la dépense
                  </label>
                  <input
                    type="text"
                    value={newExpenseName}
                    onChange={(e) => setNewExpenseName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                    placeholder="Ex: Vaccins, Passeport..."
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Montant (€)
                  </label>
                  <input
                    type="number"
                    value={newExpenseAmount}
                    onChange={(e) => setNewExpenseAmount(e.target.value)}
                    min="0"
                    step="0.01"
                    className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => {
                      setShowAddExpense(false);
                      setNewExpenseName('');
                      setNewExpenseAmount('');
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                  >
                    Annuler
                  </button>
                  <button
                    type="button"
                    onClick={handleAddExpense}
                    disabled={!newExpenseName.trim() || !newExpenseAmount}
                    className="px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 disabled:bg-gray-400 disabled:cursor-not-allowed rounded-lg transition-colors"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isValid && (
          <div className="p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100 dark:border-amber-800/30">
            <div className="flex items-center gap-2">
              <Info className="w-5 h-5 text-amber-500" />
              <p className="text-sm text-amber-700 dark:text-amber-300">
                Veuillez remplir tous les champs obligatoires avant de continuer
              </p>
            </div>
          </div>
        )}

        {/* Globe PVT Promo - Maintenant en bas */}
        <div className="pt-6 mt-6 border-t border-gray-200 dark:border-gray-700">
          <GlobePvtPromo />
        </div>
      </div>
    </div>
  );
}