import { storage, db } from './firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, addDoc, query, where, orderBy, getDocs, doc, getDoc, updateDoc, deleteDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { MediaPost, MediaUploadProgress } from '../types/media';
import { generateImageSizes } from '../utils/image';

// Maximum file sizes
export const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB
export const MAX_VIDEO_SIZE = 150 * 1024 * 1024; // 150MB

// Allowed file types
const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const ALLOWED_VIDEO_TYPES = ['video/mp4', 'video/quicktime'];

export const uploadMedia = async (
  file: File,
  userId: string,
  onProgress?: (progress: MediaUploadProgress) => void
): Promise<string | { fullUrl: string; previewUrl: string; thumbnail80Url: string; thumbnail32Url: string }> => {
  // Validate file type
  if (!ALLOWED_IMAGE_TYPES.includes(file.type) && !ALLOWED_VIDEO_TYPES.includes(file.type)) {
    throw new Error('Type de fichier non supporté');
  }

  // Validate file size
  const isVideo = ALLOWED_VIDEO_TYPES.includes(file.type);
  const maxSize = isVideo ? MAX_VIDEO_SIZE : MAX_IMAGE_SIZE;
  if (file.size > maxSize) {
    throw new Error(`La taille du fichier dépasse la limite de ${maxSize / (1024 * 1024)}MB`);
  }

  // Create storage reference
  const timestamp = Date.now();
  const fileExtension = file.name.split('.').pop() || (isVideo ? 'mp4' : 'jpg');
  const basePath = `media/${isVideo ? 'videos' : 'images'}/${userId}_${timestamp}`;

  if (isVideo) {
    // Upload video directly
    const videoRef = ref(storage, `${basePath}.${fileExtension}`);
    const uploadTask = uploadBytesResumable(videoRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) {
            onProgress({ progress });
          }
        },
        (error) => {
          if (onProgress) {
            onProgress({ progress: 0, error: error.message });
          }
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            if (onProgress) {
              onProgress({ progress: 100, url: downloadURL });
            }
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  } else {
    // Generate compressed images
    try {
      const sizes = await generateImageSizes(file);
      
      // Upload all sizes in parallel
      const uploadPromises = [
        uploadBytesResumable(ref(storage, `${basePath}_full.jpg`), sizes.full),
        uploadBytesResumable(ref(storage, `${basePath}_preview.jpg`), sizes.preview),
        uploadBytesResumable(ref(storage, `${basePath}_80.jpg`), sizes.thumbnail80),
        uploadBytesResumable(ref(storage, `${basePath}_32.jpg`), sizes.thumbnail32)
      ];
      
      // Track total progress
      let totalProgress = 0;
      uploadPromises.forEach(uploadTask => {
        uploadTask.on('state_changed', (snapshot) => {
          const taskProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 25;
          totalProgress += taskProgress;
          if (onProgress) {
            onProgress({ progress: Math.min(totalProgress, 100) });
          }
        });
      });
      
      // Wait for all uploads to complete
      const uploadResults = await Promise.all(uploadPromises);
      
      // Get all URLs
      const urls = await Promise.all(
        uploadResults.map(result => getDownloadURL(result.ref))
      );
      
      const [fullUrl, previewUrl, thumbnail80Url, thumbnail32Url] = urls;
      
      if (onProgress) {
        onProgress({
          progress: 100,
          url: { fullUrl, previewUrl, thumbnail80Url, thumbnail32Url }
        });
      }
      
      return {
        fullUrl,
        previewUrl,
        thumbnail80Url,
        thumbnail32Url
      };
    } catch (error) {
      if (onProgress) {
        onProgress({ progress: 0, error: error instanceof Error ? error.message : 'Upload failed' });
      }
      throw error;
    }
  }
};

export const createPost = async (post: Omit<MediaPost, 'id' | 'createdAt' | 'updatedAt'>) => {
  const postRef = await addDoc(collection(db, 'media_posts'), {
    ...post,
    createdAt: new Date(),
    updatedAt: new Date()
  });
  return postRef.id;
};

export const getPosts = async (userId?: string) => {
  try {
    const postsRef = collection(db, 'media_posts');
    let q = query(postsRef, orderBy('createdAt', 'desc'));
    
    if (userId) {
      q = query(postsRef, where('userId', '==', userId), orderBy('createdAt', 'desc'));
    }
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as MediaPost[];
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
};

export const likePost = async (postId: string, userId: string) => {
  const postRef = doc(db, 'media_posts', postId);
  await updateDoc(postRef, {
    likes: arrayUnion(userId),
    updatedAt: new Date()
  });
};

export const unlikePost = async (postId: string, userId: string) => {
  const postRef = doc(db, 'media_posts', postId);
  await updateDoc(postRef, {
    likes: arrayRemove(userId),
    updatedAt: new Date()
  });
};

export const addComment = async (postId: string, comment: Omit<Comment, 'id' | 'createdAt'>) => {
  const postRef = doc(db, 'media_posts', postId);
  const commentId = Date.now().toString();
  await updateDoc(postRef, {
    comments: arrayUnion({
      ...comment,
      id: commentId,
      createdAt: new Date()
    }),
    updatedAt: new Date()
  });
  return commentId;
};

export const deletePost = async (postId: string) => {
  // Get post data first
  const postRef = doc(db, 'media_posts', postId);
  try {
    const postDoc = await getDoc(postRef);
    
    if (!postDoc.exists()) {
      throw new Error('Post not found');
    }
    
    const post = postDoc.data();
    
    // Extract userId and timestamp from the URL
    // Handle both full URLs and storage paths
    let urlMatch;
    if (post.mediaUrl.includes('firebasestorage.googleapis.com')) {
      // Full URL pattern
      urlMatch = post.mediaUrl.match(/media%2F(images|videos)%2F([^_]+)_(\d+)/);
    } else {
      // Storage path pattern
      urlMatch = post.mediaUrl.match(/media\/(images|videos)\/([^_]+)_(\d+)/);
    }

    if (!urlMatch) {
      throw new Error('Invalid media URL format');
    }

    const [, mediaType, userId, timestamp] = urlMatch;
    const basePath = `media/${mediaType}/${userId}_${timestamp}`;
    const deletePromises = [];

    if (mediaType === 'images') {
      // Delete all image variants
      const variants = ['full', 'preview', '80', '32'];
      for (const variant of variants) {
        const imageRef = ref(storage, `${basePath}_${variant}.jpg`);
        deletePromises.push(
          deleteObject(imageRef)
            .catch(error => {
              console.warn(`Failed to delete ${variant} image:`, error);
            })
        );
      }
    } else {
      // Delete video file
      const videoRef = ref(storage, `${basePath}.mp4`);
      deletePromises.push(
        deleteObject(videoRef)
          .catch(error => {
            console.warn('Failed to delete video:', error);
          })
      );
    }

    // Wait for all deletions to complete
    await Promise.all([
      ...deletePromises,
      deleteDoc(postRef) // Delete the Firestore document
    ]);

  } catch (error) {
    console.error('Error deleting post:', error);
    throw error instanceof Error ? error : new Error('Failed to delete post');
  }
};