import React from 'react';
import { ExternalLink, ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { Resource } from '../types/todo';
import { collection, query, where, getDocs, doc, updateDoc, increment, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../lib/auth-context';

interface TaskResourcesProps {
  taskId: string;
  defaultTaskId?: string;
}

export function TaskResources({ taskId, defaultTaskId }: TaskResourcesProps) {
  const { user } = useAuth();
  const [resources, setResources] = React.useState<Resource[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);

  // Use different number of resources per page based on screen size and total resources
  const RESOURCES_PER_PAGE = React.useMemo(() => {
    // Check if we're on mobile using window.matchMedia
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    // If there's only one resource, show it full width
    if (resources.length === 1) return 1;
    // Otherwise use responsive layout
    return isMobile ? 1 : 2;
  }, [resources.length]);

  const totalPages = Math.ceil(resources.length / RESOURCES_PER_PAGE);

  React.useEffect(() => {
    const loadResources = async () => {
      try {
        const resourcesRef = collection(db, 'resources-v2');
        const searchTaskId = defaultTaskId || taskId.split('-').pop();
        
        if (!searchTaskId) {
          console.warn('Invalid task ID format');
          setIsLoading(false);
          return;
        }

        const q = query(resourcesRef, where('linkedTasks', 'array-contains', searchTaskId));
        const snapshot = await getDocs(q);
        const loadedResources = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Resource[];
        
        setResources(loadedResources);
      } catch (error) {
        console.error('Error loading resources:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadResources();
  }, [taskId, defaultTaskId]);

  // Update resources per page when window resizes
  React.useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches;
      const newResourcesPerPage = resources.length === 1 ? 1 : (isMobile ? 1 : 2);
      
      // Adjust current page if needed
      const newTotalPages = Math.ceil(resources.length / newResourcesPerPage);
      if (currentPage >= newTotalPages) {
        setCurrentPage(Math.max(0, newTotalPages - 1));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [resources.length, currentPage]);

  const trackResourceClick = async (resourceId: string) => {
    if (!user) return;

    try {
      await addDoc(collection(db, 'resource-clicks'), {
        resourceId,
        userId: user.uid,
        taskId: defaultTaskId || taskId,
        timestamp: new Date(),
        userAgent: navigator.userAgent,
        url: window.location.href
      });

      const resourceRef = doc(db, 'resources-v2', resourceId);
      await updateDoc(resourceRef, {
        clickCount: increment(1),
        lastClickAt: new Date()
      });
    } catch (error) {
      console.error('Error tracking resource click:', error);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    
    if (isLeftSwipe && currentPage < totalPages - 1) {
      setCurrentPage(prev => prev + 1);
    } else if (isRightSwipe && currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const getOptimizedImageUrl = (url: string): string => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname.includes('unsplash.com')) {
        const baseUrl = url.split('?')[0];
        // Use larger image for single resource
        if (resources.length === 1) {
          return `${baseUrl}?q=85&w=1600&h=900&fit=crop&crop=entropy&auto=format`;
        }
        return `${baseUrl}?q=80&w=800&h=400&fit=crop&crop=entropy&auto=format`;
      }
      return url;
    } catch (e) {
      console.error('Invalid image URL:', e);
      return url;
    }
  };

  const handleResourceClick = async (resourceId: string) => {
    await trackResourceClick(resourceId);
  };

  if (isLoading || resources.length === 0) return null;

  return (
    <div className="mt-8">
      {/* Header */}
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-200 dark:border-gray-700/50"></div>
        </div>
        <div className="relative flex justify-center">
          <div className="px-4 text-sm text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800">
            Ressources recommandées
          </div>
        </div>
      </div>

      {/* Resources Grid */}
      <div className="mt-6">
        <div className="relative">
          {/* Navigation Buttons */}
          {totalPages > 1 && (
            <>
              {currentPage > 0 && (
                <button
                  onClick={() => setCurrentPage(prev => prev - 1)}
                  className="absolute -left-4 top-1/2 -translate-y-1/2 w-8 h-8 rounded-full bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 flex items-center justify-center text-gray-600 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-400 transition-all duration-200 z-10"
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
              )}
              {currentPage < totalPages - 1 && (
                <button
                  onClick={() => setCurrentPage(prev => prev + 1)}
                  className="absolute -right-4 top-1/2 -translate-y-1/2 w-8 h-8 rounded-full bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 flex items-center justify-center text-gray-600 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-400 transition-all duration-200 z-10"
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              )}
            </>
          )}

          {/* Resources Slider */}
          <div 
            className="overflow-hidden"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div 
              className="flex transition-transform duration-300 ease-out"
              style={{ transform: `translateX(-${currentPage * 100}%)` }}
            >
              {Array.from({ length: totalPages }).map((_, pageIndex) => (
                <div key={pageIndex} className="w-full flex-shrink-0 px-1">
                  <div className={`grid grid-cols-1 ${resources.length === 1 ? '' : 'md:grid-cols-2'} gap-6`}>
                    {resources
                      .slice(pageIndex * RESOURCES_PER_PAGE, (pageIndex + 1) * RESOURCES_PER_PAGE)
                      .map((resource) => (
                        <a
                          key={resource.id}
                          href={resource.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handleResourceClick(resource.id)}
                          className={`group block relative bg-white dark:bg-gray-800 rounded-2xl shadow-sm hover:shadow-lg border border-gray-200 dark:border-gray-700 overflow-hidden transition-all duration-300 ${
                            resources.length === 1 ? 'md:flex md:h-64 md:max-w-6xl md:mx-auto' : ''
                          }`}
                        >
                          {resources.length === 1 ? (
                            // Single resource banner layout
                            <>
                              <div className="grid grid-cols-1 md:grid-cols-2 w-full">
                                {/* Section Image */}
                                <div className="relative w-full h-56 md:h-full overflow-hidden">
                                  <img
                                    src={getOptimizedImageUrl(resource.image)}
                                    alt={resource.title}
                                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                  />
                                  <div className="absolute inset-0 bg-gradient-to-t md:bg-gradient-to-r from-black/60 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                </div>

                                {/* Section Texte */}
                                <div className="w-full p-6 flex flex-col justify-center relative">
                                  <div className="absolute top-4 right-4">
                                    <div className="p-2 rounded-full bg-white/90 dark:bg-gray-800/90 shadow-sm backdrop-blur-sm border border-gray-200/50 dark:border-gray-700/50 transform translate-y-2 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
                                      <ExternalLink className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                                    </div>
                                  </div>

                                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">
                                    {resource.title}
                                  </h3>
                                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 line-clamp-4">
                                    {resource.description}
                                  </p>
                                  <div className="flex items-center text-primary-600 dark:text-primary-400 text-sm font-medium group-hover:text-primary-700 dark:group-hover:text-primary-300 transition-colors mt-auto">
                                    <span>En savoir plus</span>
                                    <ArrowRight className="w-4 h-4 ml-1.5 transform group-hover:translate-x-1 transition-transform" />
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            // Multiple resources layout
                            <div className="relative">
                              <div className="absolute top-3 right-3 z-10">
                                <div className="p-2 rounded-full bg-white/90 dark:bg-gray-800/90 shadow-sm backdrop-blur-sm border border-gray-200/50 dark:border-gray-700/50">
                                  <ExternalLink className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                                </div>
                              </div>

                              <div className="relative aspect-video overflow-hidden">
                                <img
                                  src={getOptimizedImageUrl(resource.image)}
                                  alt={resource.title}
                                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 transition-opacity duration-200 group-hover:opacity-100" />
                              </div>
                              
                              <div className="p-4">
                                <h3 className="text-sm font-medium text-gray-900 dark:text-white truncate">
                                  {resource.title}
                                </h3>
                                <p className="mt-1.5 text-sm text-gray-600 dark:text-gray-400 line-clamp-2">
                                  {resource.description}
                                </p>
                              </div>
                            </div>
                          )}
                        </a>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Pagination Dots */}
          {totalPages > 1 && (
            <div className="flex justify-center mt-4 space-x-2">
              {Array.from({ length: totalPages }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index)}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    currentPage === index
                      ? 'bg-gray-400 dark:bg-gray-500 scale-125'
                      : 'bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500/50'
                  }`}
                  aria-label={`Go to page ${index + 1}`}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}