import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Calendar, MessageSquare, ChevronRight, ChevronLeft, Share2 } from 'lucide-react';
import { Dossier } from '../types/dossiers';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { ReadingProgress } from '../components/ReadingProgress';
import { fetchDossierById } from '../lib/api';

export function DossierPage() {
  const { user, userInfo } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [dossier, setDossier] = React.useState<Dossier | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [activeChapter, setActiveChapter] = React.useState(0);
  const contentRef = React.useRef<HTMLDivElement>(null);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  React.useEffect(() => {
    const fetchDossier = async () => {
      if (!id || !userInfo) return;
      
      setIsLoading(true);
      setError(null);

      try {
        const data = await fetchDossierById(id, userInfo);
        setDossier(data);
      } catch (error) {
        console.error('Error fetching dossier:', error);
        setError('Impossible de charger le dossier pour le moment');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchDossier();
    }
    window.scrollTo(0, 0);
  }, [id, user, userInfo]);

  const handleChapterChange = (index: number) => {
    setActiveChapter(index);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: dossier?.title,
          text: dossier?.shortDescription,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        alert('Lien copié dans le presse-papier !');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />
      
      <ReadingProgress color="primary" />

      <div className="fixed top-16 left-0 right-0 z-40 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b border-gray-200/50 dark:border-gray-700/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-14">
            <button
              onClick={() => navigate('/dossiers')}
              className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span className="font-medium">Retour aux dossiers</span>
            </button>
            {dossier && (
              <div className="flex items-center gap-4">
                <button
                  onClick={handleShare}
                  className="flex items-center gap-2 px-3 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                >
                  <Share2 className="w-5 h-5" />
                  <span className="hidden sm:inline font-medium">Partager</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <main className="flex-1 pt-32">
        {isLoading ? (
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-premium border border-gray-200/50 dark:border-gray-700/50">
              <div className="p-4 sm:p-6 md:p-8 lg:p-12">
                <div className="animate-pulse space-y-8">
                  {/* Title skeleton */}
                  <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded-lg w-3/4" />
                  
                  {/* Content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                  
                  {/* Image skeleton */}
                  <div className="aspect-video bg-gray-200 dark:bg-gray-700 rounded-xl" />
                  
                  {/* More content skeleton */}
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6" />
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-4/6" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : error || !dossier ? (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="bg-red-50 dark:bg-red-900/20 rounded-xl p-6 text-center">
              <p className="text-red-600 dark:text-red-400">{error || 'Dossier non trouvé'}</p>
            </div>
          </div>
        ) : (
          <>
            <div className="relative h-[300px] sm:h-[400px]">
              <img
                src={dossier.cover}
                alt={dossier.title}
                className="absolute inset-0 w-full h-full object-cover"
                loading="eager"
                fetchpriority="high"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/70 to-black/80" />
              
              <div className="absolute inset-x-0 bottom-0 p-6 sm:p-8">
                <div className="max-w-3xl mx-auto">
                  <div className="flex flex-wrap items-center gap-4 text-white/80 mb-4">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      <span className="text-sm">
                        {format(parseISO(dossier.created), 'dd MMMM yyyy', { locale: fr })}
                      </span>
                    </div>
                  </div>

                  <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-white mb-4 leading-tight">
                    {dossier.title}
                  </h1>

                  <div className="flex items-center gap-4">
                    <img
                      src={dossier.author.avatar || `https://ui-avatars.com/api/?name=${dossier.author.firstname}&background=random`}
                      alt={dossier.author.firstname}
                      className="w-8 h-8 rounded-full ring-2 ring-white/20"
                    />
                    <div>
                      <span className="block text-white font-medium text-sm">
                        {dossier.author.firstname} {dossier.author.lastname}
                      </span>
                      <span className="text-xs text-white/80">
                        {dossier.author.location}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                {dossier.chapters && dossier.chapters.length > 1 && (
                  <div className="lg:col-span-3 order-2 lg:order-1">
                    <div className="lg:sticky lg:top-32">
                      <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50 overflow-hidden">
                        <div className="p-3 border-b border-gray-200/50 dark:border-gray-700/50">
                          <h2 className="text-sm font-medium text-gray-900 dark:text-white">
                            Chapitres
                          </h2>
                        </div>
                        <nav className="p-2">
                          {dossier.chapters.map((chapter, index) => (
                            <button
                              key={chapter.id}
                              onClick={() => handleChapterChange(index)}
                              className={`w-full flex items-center gap-2 p-2 rounded-lg text-left transition-colors ${
                                activeChapter === index
                                  ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                                  : 'hover:bg-gray-50 dark:hover:bg-gray-700/50 text-gray-700 dark:text-gray-300'
                              }`}
                            >
                              <span className="text-sm">{chapter.title}</span>
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                )}

                <div className={`${dossier.chapters && dossier.chapters.length > 1 ? 'lg:col-span-9' : 'lg:col-span-12'} order-1 lg:order-2 max-w-4xl mx-auto`}>
                  <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200/50 dark:border-gray-700/50">
                    <div className="p-4 sm:p-6 md:p-8 lg:p-12">
                      {dossier.chapters && dossier.chapters.length > 1 && (
                        <h2 className="text-base sm:text-lg font-bold text-gray-900 dark:text-white mb-4">
                          {dossier.chapters[activeChapter]?.title}
                        </h2>
                      )}

                      <div 
                        ref={contentRef}
                        className="prose prose-lg dark:prose-invert mx-auto
                                 prose-headings:font-semibold
                                 prose-p:text-gray-700 dark:prose-p:text-gray-300
                                 prose-a:no-underline
                                 prose-img:rounded-xl prose-img:shadow-lg
                                 sm:prose-h1:text-3xl
                                 sm:prose-h2:text-2xl
                                 sm:prose-h3:text-xl
                                 sm:prose-h4:text-lg
                                 prose-li:text-gray-700 dark:prose-li:text-gray-300"
                        dangerouslySetInnerHTML={{ 
                          __html: (dossier.chapters?.[activeChapter]?.content || dossier.content)
                            .replace(/style="[^"]*color:[^";]*;?[^"]*"/g, '')
                            .replace(/style="[^"]*background-color:[^";]*;?[^"]*"/g, '')
                            .replace(/style="[^"]*font-weight:[^";]*;?[^"]*"/g, '')
                            .replace(/style="[^"]*text-decoration:\s*underline[^";]*;?[^"]*"/g, 'class="custom-underline"')
                            .replace(/<\/?(?:b|strong)>/g, '')
                            .replace(/<\/?em>/g, '')
                            .replace(/<\/?u>/g, '')
                            .replace(/<a[^>]*>(<img[^>]*>)<\/a>/g, '$1')
                            .replace(
                              /<div class="post-it"[^>]*>([\s\S]*?)<\/div>/g,
                              (match, content) => `
                                <div class="post-it">
                                  ${content.trim()}
                                </div>
                              `
                            )
                        }}
                      />

                      {dossier.chapters && dossier.chapters.length > 1 && (
                        <div className="flex items-center justify-between mt-8 pt-6 border-t border-gray-200/50 dark:border-gray-700/50">
                          <button
                            onClick={() => handleChapterChange(activeChapter - 1)}
                            disabled={activeChapter === 0}
                            className="flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <ChevronLeft className="w-4 h-4" />
                            <span>Précédent</span>
                          </button>
                          <button
                            onClick={() => handleChapterChange(activeChapter + 1)}
                            disabled={!dossier.chapters || activeChapter === dossier.chapters.length - 1}
                            className="flex items-center gap-2 px-3 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <span>Suivant</span>
                            <ChevronRight className="w-4 h-4" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>

      <Footer />
    </div>
  );
}