// Liste des villes principales par pays
export const MAJOR_CITIES: { [key: string]: string[] } = {
  'aus': [
    'Adelaide',
    'Brisbane',
    'Cairns',
    'Canberra',
    'Darwin',
    'Geelong',
    'Gold Coast',
    'Hobart',
    'Melbourne',
    'Newcastle',
    'Perth',
    'Sunshine Coast',
    'Sydney',
    'Townsville',
    'Wollongong'
  ],
  'nz': [
    'Auckland',
    'Christchurch',
    'Dunedin',
    'Hamilton',
    'Invercargill',
    'Napier-Hastings',
    'Nelson',
    'New Plymouth',
    'Palmerston North',
    'Queenstown',
    'Rotorua',
    'Tauranga',
    'Wellington',
    'Whanganui',
    'Whangarei'
  ],
  'ca': [
    'Calgary',
    'Edmonton',
    'Halifax',
    'Hamilton',
    'Kelowna',
    'Kitchener',
    'London',
    'Montreal',
    'Ottawa',
    'Quebec City',
    'St. John\'s',
    'Toronto',
    'Vancouver',
    'Victoria',
    'Winnipeg'
  ],
  'jpn': [
    'Fukuoka',
    'Kawasaki',
    'Kobe',
    'Kyoto',
    'Nagoya',
    'Osaka',
    'Saitama',
    'Sapporo',
    'Tokyo',
    'Yokohama'
  ],
  'kor': [
    'Busan',
    'Changwon',
    'Daegu',
    'Daejeon',
    'Goyang',
    'Gwangju',
    'Incheon',
    'Seoul',
    'Suwon',
    'Ulsan'
  ],
  'twn': [
    'Changhua',
    'Chiayi',
    'Hsinchu',
    'Kaohsiung',
    'Keelung',
    'Pingtung',
    'Taichung',
    'Tainan',
    'Taipei',
    'Yilan'
  ],
  'arg': [
    'Buenos Aires',
    'Cordoba',
    'La Plata',
    'Mar del Plata',
    'Mendoza',
    'Rosario',
    'Salta',
    'San Juan',
    'San Miguel de Tucuman',
    'Santa Fe'
  ],
  'chl': [
    'Antofagasta',
    'Arica',
    'Chillan',
    'Concepcion',
    'La Serena',
    'Rancagua',
    'Santiago',
    'Talca',
    'Temuco',
    'Valparaiso'
  ],
  'col': [
    'Barranquilla',
    'Bogota',
    'Bucaramanga',
    'Cali',
    'Cartagena',
    'Cucuta',
    'Manizales',
    'Medellin',
    'Pereira',
    'Santa Marta'
  ],
  'mex': [
    'Guadalajara',
    'Juarez',
    'Leon',
    'Merida',
    'Mexico City',
    'Monterrey',
    'Puebla',
    'Queretaro',
    'Tijuana',
    'Torreon'
  ],
  'per': [
    'Arequipa',
    'Chimbote',
    'Chiclayo',
    'Cusco',
    'Huancayo',
    'Iquitos',
    'Lima',
    'Piura',
    'Tacna',
    'Trujillo'
  ],
  'uru': [
    'Las Piedras',
    'Maldonado',
    'Melo',
    'Mercedes',
    'Montevideo',
    'Paysandu',
    'Rivera',
    'Salto',
    'Tacuarembo'
  ],
  'bra': [
    'Belo Horizonte',
    'Brasilia',
    'Curitiba',
    'Fortaleza',
    'Manaus',
    'Porto Alegre',
    'Recife',
    'Rio de Janeiro',
    'Salvador',
    'Sao Paulo'
  ],
  'hkg': ['Hong Kong'],
  'fra': [
    'Bordeaux',
    'Lille',
    'Lyon',
    'Marseille',
    'Montpellier',
    'Nantes',
    'Nice',
    'Paris',
    'Strasbourg',
    'Toulouse'
  ],
  'ecu': [
    'Ambato',
    'Cuenca',
    'Guayaquil',
    'Ibarra',
    'Loja',
    'Machala',
    'Manta',
    'Portoviejo',
    'Quito',
    'Santo Domingo'
  ]
};

export const budgetCountries: Country[] = [
  {
    code: 'aus',
    name: 'Australie',
    flag: '🇦🇺',
    image: 'https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'nz',
    name: 'Nouvelle-Zélande',
    flag: '🇳🇿',
    image: 'https://images.unsplash.com/photo-1507699622108-4be3abd695ad?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'ca',
    name: 'Canada',
    flag: '🇨🇦',
    image: 'https://images.unsplash.com/photo-1503614472-8c93d56e92ce?q=80&w=2611&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'jpn',
    name: 'Japon',
    flag: '🇯🇵',
    image: 'https://images.unsplash.com/photo-1480796927426-f609979314bd?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'kor',
    name: 'Corée du Sud',
    flag: '🇰🇷',
    image: 'https://images.unsplash.com/photo-1517154421773-0529f29ea451?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'twn',
    name: 'Taïwan',
    flag: '🇹🇼',
    image: 'https://plus.unsplash.com/premium_photo-1661951189203-12decb9d7f8e?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'arg',
    name: 'Argentine',
    flag: '🇦🇷',
    image: 'https://images.unsplash.com/photo-1612294037637-ec328d0e075e?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'chl',
    name: 'Chili',
    flag: '🇨🇱',
    image: 'https://images.unsplash.com/photo-1546800273-4d63ff843853?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'col',
    name: 'Colombie',
    flag: '🇨🇴',
    image: 'https://images.unsplash.com/photo-1576018753502-1a55b66cc44a?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'mex',
    name: 'Mexique',
    flag: '🇲🇽',
    image: 'https://images.unsplash.com/photo-1518105779142-d975f22f1b0a?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'per',
    name: 'Pérou',
    flag: '🇵🇪',
    image: 'https://images.unsplash.com/photo-1526392060635-9d6019884377?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'uru',
    name: 'Uruguay',
    flag: '🇺🇾',
    image: 'https://images.unsplash.com/photo-1624416254374-b471bfcaac81?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'bra',
    name: 'Brésil',
    flag: '🇧🇷',
    image: 'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'hkg',
    name: 'Hong Kong',
    flag: '🇭🇰',
    image: 'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&q=80&w=800',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'fra',
    name: 'France',
    flag: '🇫🇷',
    image: 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?q=80&w=2673&auto=format&fit=crop&ixlib=rb-4.0.3',
    hasList: false,
    defaultTasks: []
  },
  {
    code: 'ecu',
    name: 'Équateur',
    flag: '🇪🇨',
    image: 'https://images.unsplash.com/photo-1504457047772-27faf1c00561?q=80&w=2670&auto=format&fit=crop',
    hasList: false,
    defaultTasks: []
  }
].sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));