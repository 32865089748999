import React from 'react';
import { Task, TaskStatus } from '../types/todo';
import { TaskCategory } from './TaskCategory';
import { TaskModals } from './TaskModals';
import { Plane, Building, Home, ChevronRight, ChevronLeft, LayoutGrid, ListChecks } from 'lucide-react';

interface TaskListProps {
  tasks: Task[];
  departureDate?: string;
  returnDate?: string;
  onUpdateStatus: (taskId: string, status: TaskStatus) => void;
  onDeleteTask: (taskId: string) => void;
  onAddSubtask: (taskId: string, title: string) => void;
  onToggleSubtask: (taskId: string, subtaskId: string) => void;
  onUpdateNotes: (taskId: string, notes: string) => void;
  onEditTask: (task: Task) => void;
  onDeleteSubtask: (taskId: string, subtaskId: string) => void;
  onUpdatePriority: (taskId: string, priority: string) => void;
  isPreview?: boolean;
}

export function TaskList({
  tasks,
  departureDate,
  returnDate,
  onUpdateStatus,
  onDeleteTask,
  onAddSubtask,
  onToggleSubtask,
  onUpdateNotes,
  onEditTask,
  onDeleteSubtask,
  onUpdatePriority,
  isPreview = false
}: TaskListProps) {
  const [editingNotes, setEditingNotes] = React.useState<string | null>(null);
  const [notesDraft, setNotesDraft] = React.useState<string>('');
  const [addingSubtaskFor, setAddingSubtaskFor] = React.useState<string | null>(null);
  const [newSubtaskTitle, setNewSubtaskTitle] = React.useState('');
  const [activeCategory, setActiveCategory] = React.useState<string | 'all'>('all');
  const [showLeftScroll, setShowLeftScroll] = React.useState(false);
  const [showRightScroll, setShowRightScroll] = React.useState(true);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const checkScrollButtons = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft < scrollWidth - clientWidth - 5);
    }
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = 200;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      checkScrollButtons();
      scrollContainer.addEventListener('scroll', checkScrollButtons);
      return () => scrollContainer.removeEventListener('scroll', checkScrollButtons);
    }
  }, []);

  const tasksByCategory = React.useMemo(() => {
    const grouped = tasks.reduce((acc, task) => {
      const category = task.category || 'uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(task);
      return acc;
    }, {} as Record<string, Task[]>);

    const orderedCategories = ['before-departure', 'after-arrival', 'return'];
    return orderedCategories.reduce((acc, category) => {
      if (grouped[category]) {
        acc[category] = grouped[category];
      }
      return acc;
    }, {} as Record<string, Task[]>);
  }, [tasks]);

  const handleSaveNotes = () => {
    if (editingNotes) {
      onUpdateNotes(editingNotes, notesDraft);
      setEditingNotes(null);
    }
  };

  const handleSaveSubtask = (e: React.FormEvent) => {
    e.preventDefault();
    if (addingSubtaskFor && newSubtaskTitle.trim()) {
      onAddSubtask(addingSubtaskFor, newSubtaskTitle);
      setAddingSubtaskFor(null);
      setNewSubtaskTitle('');
    }
  };

  const categories = [
    { id: 'all', label: 'Tout afficher', icon: <LayoutGrid className="w-5 h-5" /> },
    { id: 'before-departure', label: 'Préparer votre départ', icon: <ListChecks className="w-5 h-5" /> },
    { id: 'after-arrival', label: 'Le jour du départ', icon: <Plane className="w-5 h-5" /> },
    { id: 'return', label: 'Les démarches à l\'arrivée', icon: <Building className="w-5 h-5" /> }
  ];

  return (
    <div className="space-y-8">
      {/* Desktop View - All Categories */}
      <div className="hidden lg:block space-y-8">
        {Object.entries(tasksByCategory).map(([category, categoryTasks]) => (
          <TaskCategory
            key={category}
            category={category}
            tasks={categoryTasks}
            isPreview={isPreview}
            departureDate={departureDate}
            returnDate={returnDate}
            onUpdateStatus={onUpdateStatus}
            onDeleteTask={onDeleteTask}
            onAddSubtask={(taskId) => setAddingSubtaskFor(taskId)}
            onToggleSubtask={onToggleSubtask}
            onUpdateNotes={(taskId, notes) => {
              setEditingNotes(taskId);
              setNotesDraft(notes);
            }}
            onEditTask={onEditTask}
            onDeleteSubtask={onDeleteSubtask}
            onUpdatePriority={onUpdatePriority}
          />
        ))}
      </div>

      {/* Mobile View - Active Category or All Categories */}
      <div className="lg:hidden">
        {activeCategory === 'all' ? (
          // Afficher toutes les catégories
          <div className="space-y-8">
            {Object.entries(tasksByCategory).map(([category, categoryTasks]) => (
              <TaskCategory
                key={category}
                category={category}
                tasks={categoryTasks}
                isPreview={isPreview}
                departureDate={departureDate}
                returnDate={returnDate}
                onUpdateStatus={onUpdateStatus}
                onDeleteTask={onDeleteTask}
                onAddSubtask={(taskId) => setAddingSubtaskFor(taskId)}
                onToggleSubtask={onToggleSubtask}
                onUpdateNotes={(taskId, notes) => {
                  setEditingNotes(taskId);
                  setNotesDraft(notes);
                }}
                onEditTask={onEditTask}
                onDeleteSubtask={onDeleteSubtask}
                onUpdatePriority={onUpdatePriority}
              />
            ))}
          </div>
        ) : (
          // Afficher uniquement la catégorie sélectionnée
          tasksByCategory[activeCategory] && (
            <TaskCategory
              category={activeCategory}
              tasks={tasksByCategory[activeCategory]}
              isPreview={isPreview}
              departureDate={departureDate}
              returnDate={returnDate}
              onUpdateStatus={onUpdateStatus}
              onDeleteTask={onDeleteTask}
              onAddSubtask={(taskId) => setAddingSubtaskFor(taskId)}
              onToggleSubtask={onToggleSubtask}
              onUpdateNotes={(taskId, notes) => {
                setEditingNotes(taskId);
                setNotesDraft(notes);
              }}
              onEditTask={onEditTask}
              onDeleteSubtask={onDeleteSubtask}
              onUpdatePriority={onUpdatePriority}
            />
          )
        )}
      </div>

      <TaskModals
        editingNotes={editingNotes}
        notesDraft={notesDraft}
        addingSubtaskFor={addingSubtaskFor}
        newSubtaskTitle={newSubtaskTitle}
        onCloseNotes={() => setEditingNotes(null)}
        onSaveNotes={handleSaveNotes}
        onUpdateNotesDraft={setNotesDraft}
        onCloseSubtask={() => {
          setAddingSubtaskFor(null);
          setNewSubtaskTitle('');
        }}
        onSaveSubtask={handleSaveSubtask}
        onUpdateSubtaskTitle={setNewSubtaskTitle}
      />
    </div>
  );
}