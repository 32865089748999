import React from 'react';
import { ArrowRight, CheckCircle2, Globe2, Users, Lock, Download, Share } from 'lucide-react';
import { Link } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { getCountriesFromFirebase } from '../../lib/firebase-service';

interface HeroSectionProps {
  onLogin: () => void;
  countriesCount: number;
}

// Fallback image that we know exists
const FALLBACK_IMAGE = 'https://images.unsplash.com/photo-1539635278303-d4002c07eae3?q=80&w=2670&auto=format&fit=crop';

export function HeroSection({ onLogin, countriesCount }: HeroSectionProps) {
  const [isMobile, setIsMobile] = React.useState(false);
  const [heroImage, setHeroImage] = React.useState(FALLBACK_IMAGE);
  const [isImageLoading, setIsImageLoading] = React.useState(true);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    // Fetch random country for hero image
    const fetchRandomCountry = async () => {
      try {
        const countries = await getCountriesFromFirebase();
        if (countries && countries.length > 0) {
          const randomCountry = countries[Math.floor(Math.random() * countries.length)];
          
          // Pre-load the image
          const img = new Image();
          img.onload = () => {
            setHeroImage(randomCountry.image);
            setIsImageLoading(false);
          };
          img.onerror = () => {
            console.warn('Failed to load country image, using fallback');
            setHeroImage(FALLBACK_IMAGE);
            setIsImageLoading(false);
          };
          img.src = randomCountry.image;
        } else {
          setIsImageLoading(false);
        }
      } catch (error) {
        console.error('Error fetching random country:', error);
        setIsImageLoading(false);
      }
    };

    fetchRandomCountry();
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <section className="relative min-h-[85vh] sm:min-h-[90vh] flex items-center">
      <div className="absolute inset-0">
        {/* Preload fallback image */}
        <link rel="preload" as="image" href={FALLBACK_IMAGE} />
        
        <img 
          src={heroImage}
          alt="Hero background"
          className={`w-full h-full object-cover transition-opacity duration-500 ${
            isImageLoading ? 'opacity-0' : 'opacity-100'
          }`}
          loading="eager"
          fetchpriority="high"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/75 to-transparent" />
      </div>
      
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-20">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <h1 className="font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-white via-white to-white/80">
              <span className="block text-3xl sm:text-4xl md:text-5xl xl:text-6xl leading-tight">
                Organisez votre PVT
              </span>
              <span className="block text-2xl sm:text-3xl md:text-4xl xl:text-5xl mt-2 text-white/90">
                en toute simplicité
              </span>
            </h1>

            <p className="text-base sm:text-lg md:text-xl text-white/80 mb-8 leading-relaxed max-w-2xl mx-auto lg:mx-0">
              La checklist interactive qui vous accompagne à chaque étape de votre PVT : de la préparation au départ, à votre passage à l'immigration, jusqu'à l'organisation de votre arrivée. Ne manquez aucune étape importante de votre aventure !
            </p>

            <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 w-full sm:w-auto justify-center lg:justify-start">
              <button
                onClick={onLogin}
                className="w-full sm:w-auto inline-flex items-center justify-center px-6 py-3.5 bg-accent-500 hover:bg-accent-600 text-white text-base font-medium rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-[1.02]"
              >
                <span>Créer ma checklist</span>
                <ArrowRight className="w-5 h-5 ml-2 flex-shrink-0" />
              </button>
        
            
            </div>

            <div className="mt-12 flex flex-wrap items-center gap-4 sm:gap-8 justify-center lg:justify-start text-sm sm:text-base">
              <div className="flex items-center gap-2">
                <CheckCircle2 className="w-5 h-5 text-primary-500" />
                <span className="text-white/80">Gratuit</span>
              </div>
              <div className="flex items-center gap-2">
                <Users className="w-5 h-5 text-primary-500" />
                <span className="text-white/80">500k+ membres</span>
              </div>
              <div className="flex items-center gap-2">
                <Globe2 className="w-5 h-5 text-primary-500" />
                <span className="text-white/80">{countriesCount}+ pays</span>
              </div>
            </div>
          </div>

          {/* Installation QR Code Section - Only shown on desktop */}
          {!isMobile && (
            <div className="hidden lg:flex justify-center">
              <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-2xl p-8 text-center">
                <div className="flex items-center justify-center gap-3 mb-4">
                  {navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') ? (
                    <Share className="w-6 h-6 text-white" />
                  ) : (
                    <Download className="w-6 h-6 text-white" />
                  )}
                  <h3 className="text-xl font-semibold text-white">
                    Installez l'application
                  </h3>
                </div>
                
                <p className="text-white/80 text-sm mb-6">
                  Scannez ce QR code avec votre téléphone pour installer l'application et accéder à vos checklists même hors connexion.
                </p>

                <div className="bg-white p-4 rounded-xl mb-4 inline-block">
                  <QRCodeSVG 
                    value={window.location.origin}
                    size={180}
                    level="H"
                    includeMargin={true}
                    imageSettings={{
                      src: "https://pvtistes.net/wp-content/uploads/2025/01/sign_fullcolor.png",
                      height: 35,
                      width: 35,
                      excavate: true
                    }}
                  />
                </div>

                <p className="text-white/60 text-xs">
                  Compatible iOS et Android
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}