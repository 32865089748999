import React from 'react';
import { ListChecks, Plane, Building2, ChevronDown } from 'lucide-react';

interface CategoryPillProps {
  activeCategory: string;
  onCategoryChange: (category: string) => void;
}

export function CategoryPill({ activeCategory, onCategoryChange }: CategoryPillProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(true);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);
  const pillRef = React.useRef<HTMLDivElement>(null);
  const headerOffset = 136; // Height of fixed header (8.5rem)

  // Handle touch events
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isSwipeDown = distance < -50;
    if (isSwipeDown && isOpen) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    const handleScroll = () => {
      // Show pill when near top or after scrolling down
      if (window.scrollY < 100 || window.scrollY > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
        setIsOpen(false);
      }
    };

    // Set initial visibility
    handleScroll();
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const categories = [
    { id: 'all', label: 'Toutes les tâches', icon: ListChecks },
    { id: 'before-departure', label: 'Préparer votre départ', icon: ListChecks },
    { id: 'after-arrival', label: 'Le jour du départ', icon: Plane },
    { id: 'return', label: 'Les démarches à l\'arrivée', icon: Building2 }
  ];

  const activeLabel = categories.find(c => c.id === activeCategory)?.label || 'Toutes les tâches';
  const ActiveIcon = categories.find(c => c.id === activeCategory)?.icon || ListChecks;

  const scrollToCategory = (categoryId: string) => {
    setIsOpen(false);
    
    // Always scroll to top first
    window.scrollTo({ 
      top: 0,
      behavior: 'smooth'
    });

    if (categoryId === 'all') {
      return;
    }

    // Then scroll to specific category after a small delay
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      setTimeout(() => {
        const elementPosition = categoryElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }, 100);
    }
  };

  return (
    <div 
      ref={pillRef}
      className={`md:hidden fixed left-4 right-4 z-[60] transition-all duration-300 ${
        isVisible 
          ? 'bottom-24 sm:bottom-8 opacity-100 translate-y-0' 
          : 'bottom-16 opacity-0 translate-y-4'
      }`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between px-5 py-3 bg-accent-500 hover:bg-accent-600 text-white rounded-full shadow-lg transition-colors"
        >
          <div className="flex items-center gap-3">
            <ActiveIcon className="w-5 h-5 text-white" />
            <span className="text-sm font-medium text-white">
              {activeLabel}
            </span>
          </div>
          <ChevronDown className={`w-5 h-5 text-white/80 transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`} />
        </button>

        {isOpen && (
          <div 
            className="fixed inset-0 z-[70] bg-black/60 backdrop-blur-sm flex items-end"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setIsOpen(false);
              }
            }}
          >
            <div className="w-full bg-white dark:bg-gray-800 rounded-t-xl shadow-lg border border-gray-200/50 dark:border-gray-700/50 py-2 max-h-[70vh] overflow-y-auto">
            {categories.map(category => {
              const Icon = category.icon;
              return (
                <button
                  key={category.id}
                  onClick={() => {
                    scrollToCategory(category.id);
                    onCategoryChange(category.id);
                    setIsOpen(false);
                  }}
                  className={`w-full flex items-center gap-3 px-5 py-3 text-sm transition-colors ${
                    activeCategory === category.id
                      ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                      : 'text-gray-700 dark:text-gray-300'
                  }`}
                >
                  <Icon className="w-5 h-5" />
                  <span>{category.label}</span>
                </button>
              );
            })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}