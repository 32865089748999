/**
 * Sanitize a document ID to be valid for Firestore
 * Firestore document IDs must:
 * - Be valid UTF-8 strings
 * - Be no longer than 1500 bytes
 * - Cannot contain forward slashes
 * - Cannot solely consist of a single period '.' or double periods '..'
 * - Cannot match the regular expression __.*__
 */
export function sanitizeDocumentId(id: string): string {
  if (!id) return '';
  
  return String(id)
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing whitespace
    .replace(/[^a-z0-9-]/g, '-') // Replace invalid chars with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
}