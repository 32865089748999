import React from 'react';
import { Building2, Users, Wallet, ShoppingCart, Train, Zap, CreditCard, Film, PiggyBank, Utensils, Smartphone, Plus, Info } from 'lucide-react';
import { BudgetFormData, HousingType } from '../../types/budget';
import { NOMAD_HOUSING_TYPES } from '../../constants/housing';

interface BudgetSummaryBoxProps {
  formData: BudgetFormData;
  housingTypes: HousingType[];
  currency: string;
  city?: string;
  countryName?: string;
  currentStep?: number;
}

export function BudgetSummaryBox({ 
  formData, 
  housingTypes, 
  currency, 
  city, 
  countryName,
  currentStep = 1,
}: BudgetSummaryBoxProps) {
  const selectedHousing = React.useMemo(() => {
    if (!formData.housingType) return null;
    
    if (formData.isNomadMode) {
      const nomadType = NOMAD_HOUSING_TYPES.find(h => h.id === formData.housingType);
      return nomadType ? {
        ...nomadType,
        cost: Number(formData.customHousingCost || nomadType.cost)
      } : null;
    }
    
    return formData.housingTypes?.find(h => h.id === formData.housingType) || null;
  }, [formData.housingType, formData.housingTypes, formData.isNomadMode, formData.customHousingCost]);

  const formatPrice = (amount: number) => amount.toLocaleString('fr-FR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl border border-gray-200 dark:border-gray-700 shadow-sm">
      <div className="p-4 sm:p-6">
        {city && countryName && (
          <div className="text-sm text-gray-500 dark:text-gray-400 mb-6">
            {city}, {countryName}
            {formData.isCouple && (
              <div className="mt-1 flex items-center gap-2 text-primary-600 dark:text-primary-400">
                <Users className="w-4 h-4" />
                <span>Mode couple</span>
              </div>
            )}
          </div>
        )}

        <div className="space-y-6">
          {/* Logement sélectionné */}
          {selectedHousing ? (
            <div>
              <div className="flex items-center gap-2 mb-3">
                <Building2 className="w-4 h-4 text-primary-500" />
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  Logement sélectionné
                </h4>
              </div>

              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-400">{selectedHousing.name}</span>
                  <span className="font-medium text-gray-900 dark:text-white">
                    {formatPrice(formData.isNomadMode ? Number(formData.customHousingCost || selectedHousing.cost) : (selectedHousing.costEUR || selectedHousing.cost))} €/mois
                  </span>
                </div>
              </div>
            </div>
          ) : currentStep >= 1 && formData.city && (
            <div className="bg-amber-50 dark:bg-amber-900/20 rounded-lg p-4 border border-amber-100 dark:border-amber-800/30">
              <div className="flex items-center gap-2 text-amber-700 dark:text-amber-300">
                <Info className="w-4 h-4 flex-shrink-0" />
                <p className="text-sm">Veuillez sélectionner un type de logement pour continuer</p>
              </div>
            </div>
          )}

          {/* Dépenses avant le départ */}
          {currentStep >= 2 && Number(formData.flightCost) > 0 && (
            <div>
              <div className="flex items-center gap-2 mb-3">
                <Wallet className="w-4 h-4 text-primary-500" />
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  Dépenses avant le départ
                </h4>
              </div>

              <div className="space-y-2 text-sm">
                {[
                  { label: 'Billet d\'avion', value: formData.flightCost, isDouble: true },
                  { label: 'Visa PVT', value: formData.pvtCost, isDouble: true },
                  { 
                    label: 'Assurance PVT', 
                    value: Number(formData.insuranceCost) * Number(formData.pvtDuration), 
                    suffix: `(${formData.insuranceCost}€ × ${formData.pvtDuration} mois)`,
                    isDouble: true
                  }
                ].map(item => Number(item.value) > 0 && (
                  <div key={item.label} className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      {item.label} {item.suffix && <span className="text-xs">({item.suffix})</span>}
                      {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}

                {/* Dépenses personnalisées avant départ */}
                {formData.preDepartureExpenses?.map(expense => (
                  <div key={expense.id} className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      {expense.name}
                      {formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(expense.amount) * (formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Dépenses mensuelles */}
          {currentStep >= 3 && selectedHousing && (
            <div>
              <div className="flex items-center gap-2 mb-3">
                <Wallet className="w-4 h-4 text-primary-500" />
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  Dépenses mensuelles
                  {formData.pvtDuration && (
                    <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                      (sur {formData.pvtDuration} mois)
                    </span>
                  )}
                </h4>
              </div>

              <div className="space-y-2 text-sm">
                {selectedHousing && (
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <Building2 className="w-4 h-4 text-blue-500" />
                      <span className="text-gray-600 dark:text-gray-400">Loyer</span>
                    </div>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(formData.isNomadMode ? Number(formData.customHousingCost || selectedHousing.cost) : (selectedHousing.costEUR || selectedHousing.cost))} €
                    </span>
                  </div>
                )}

                {[
                  { label: 'Courses alimentaires', value: formData.groceriesCost, icon: ShoppingCart, color: 'text-emerald-500', isDouble: true },
                  { label: 'Restaurants & Sorties', value: formData.restaurantsCost, icon: Utensils, color: 'text-orange-500', isDouble: true },
                  { label: 'Transport', value: formData.transportCost, icon: Train, color: 'text-amber-500', isDouble: true },
                  { label: 'Charges', value: formData.utilitiesCost, icon: Zap, color: 'text-purple-500', isDouble: false },
                  { label: 'Forfait mobile', value: formData.phoneCost, icon: Smartphone, color: 'text-blue-500', isDouble: true },
                  { label: 'Frais bancaires', value: formData.bankCost, icon: CreditCard, color: 'text-gray-500', isDouble: true },
                  { label: 'Streaming', value: formData.streamingCost, icon: Film, color: 'text-pink-500', isDouble: false },
                  { label: 'Loisirs', value: formData.leisureCost, icon: Film, color: 'text-rose-500', isDouble: true },
                  { label: 'Épargne', value: formData.epargne, icon: PiggyBank, color: 'text-green-500', isDouble: true },
                  // Dépenses personnalisées mensuelles
                  ...(formData.expenseFields || []).map(expense => ({
                    label: expense.name,
                    value: expense.amount,
                    icon: Plus,
                    color: 'text-indigo-500',
                    isDouble: true
                  }))
                ].map(item => Number(item.value) > 0 && (
                  <div key={item.label} className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <item.icon className={`w-4 h-4 ${item.color}`} />
                      <span className="text-gray-600 dark:text-gray-400">
                        {item.label}
                        {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                      </span>
                    </div>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Dépenses d'installation */}
          {currentStep >= 4 && (
            Number(formData.firstLastMonthRent) > 0 ||
            Number(formData.furnitureCost) > 0 ||
            Number(formData.clothingCost) > 0 ||
            Number(formData.groceryCost) > 0 ||
            (formData.setupExpenses && formData.setupExpenses.length > 0)
          ) && (
            <div>
              <div className="flex items-center gap-2 mb-3">
                <Building2 className="w-4 h-4 text-primary-500" />
                <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                  Dépenses d'installation
                </h4>
              </div>

              <div className="space-y-2 text-sm">
                {[
                  { label: 'Caution et avance', value: formData.firstLastMonthRent, isDouble: false },
                  { label: 'Mobilier', value: formData.furnitureCost, isDouble: false },
                  { label: 'Vêtements', value: formData.clothingCost, isDouble: true },
                  { label: 'Courses initiales', value: formData.groceryCost, isDouble: false }
                ].filter(item => Number(item.value) > 0).map(item => (
                  <div key={item.label} className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      {item.label}
                      {item.isDouble && formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(item.value) * (item.isDouble && formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}

                {/* Dépenses personnalisées d'installation */}
                {formData.setupExpenses?.map(expense => (
                  <div key={expense.id} className="flex justify-between">
                    <span className="text-gray-600 dark:text-gray-400">
                      {expense.name}
                      {formData.isCouple && <span className="text-xs text-primary-500"> × 2</span>}
                    </span>
                    <span className="font-medium text-gray-900 dark:text-white">
                      {formatPrice(Number(expense.amount) * (formData.isCouple ? 2 : 1))} €
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Message si aucune donnée n'est encore saisie */}
          {currentStep === 1 && !selectedHousing && !formData.city && (
            <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4 text-center">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Sélectionnez une ville et un type de logement pour voir le résumé de votre budget
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}