import React from 'react';
import { Shield, Info } from 'lucide-react';

export function GeneralInfoSection() {
  return (
    <div className="py-16 sm:py-24 bg-white dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative overflow-hidden bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-900/20 dark:to-accent-900/20 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
          <div className="absolute inset-0">
            <img 
              src="https://images.unsplash.com/photo-1530099486328-e021101a494a?q=80&w=2547&auto=format&fit=crop"
              alt=""
              className="w-full h-full object-cover opacity-10 dark:opacity-5"
            />
            <div className="absolute inset-0 bg-gradient-to-br from-primary-50/95 to-accent-50/95 dark:from-primary-900/95 dark:to-accent-900/95" />
          </div>

          <div className="relative grid md:grid-cols-2 gap-8 p-6 sm:p-8 md:p-12">
            {/* Left Column */}
            <div>
              <div className="flex items-center gap-3 mb-6">
                <div className="p-2 bg-white dark:bg-gray-800 rounded-xl shadow-sm">
                  <Shield className="w-6 h-6 text-primary-500 dark:text-primary-400" />
                </div>
                <h2 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
                  Informations générales
                </h2>
              </div>

              <h3 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                Pourquoi prendre une assurance ?
              </h3>

              <div className="prose prose-sm sm:prose-base dark:prose-invert">
                <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                  Pour profiter de votre PVT en toute sérénité. Avec une assurance voyage adaptée, en cas de problème durant votre PVT, vous serez accompagné·e ! Partir bien assuré(e) c'est indispensable quand on va séjourner à l'étranger.
                </p>
                <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                  Sachez que l'assurance santé & assistance est généralement obligatoire : elle fait partie des formalités à remplir pour valider votre PVT.
                </p>
              </div>

              <div className="mt-8 flex items-start gap-3 p-4 bg-amber-50 dark:bg-amber-900/20 rounded-xl border border-amber-100/50 dark:border-amber-800/30">
                <div className="flex-shrink-0 p-1.5 bg-amber-100 dark:bg-amber-900/30 rounded-lg">
                  <Info className="w-4 h-4 text-amber-600 dark:text-amber-400" />
                </div>
                <p className="text-sm text-amber-800 dark:text-amber-300">
                  Une assurance voyage est souvent exigée pour l'obtention de votre PVT. Elle vous protège en cas de problème de santé, d'accident ou d'imprévu pendant votre séjour.
                </p>
              </div>
            </div>

            {/* Right Column - Image */}
            <div className="relative aspect-[4/3] md:aspect-auto">
              <img
                src="https://images.unsplash.com/photo-1539635278303-d4002c07eae3?q=80&w=2670&auto=format&fit=crop"
                alt="Voyageur avec une assurance"
                className="absolute inset-0 w-full h-full object-cover rounded-xl shadow-lg"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl" />
              
              <div className="absolute inset-x-0 bottom-0 p-6">
                <div className="bg-white/10 backdrop-blur-sm border border-white/20 rounded-xl p-4">
                  <h4 className="text-lg font-semibold text-white mb-2">
                    Une protection complète
                  </h4>
                  <ul className="space-y-2">
                    <li className="flex items-center gap-2 text-sm text-white/90">
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400" />
                      <span>Frais médicaux & hospitalisation</span>
                    </li>
                    <li className="flex items-center gap-2 text-sm text-white/90">
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400" />
                      <span>Assistance rapatriement 24h/24</span>
                    </li>
                    <li className="flex items-center gap-2 text-sm text-white/90">
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400" />
                      <span>Responsabilité civile</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}