import React from 'react';
import { X, Tag as TagIcon, MapPin, Info, Globe2, Camera, Film, Hash } from 'lucide-react';
import { MediaUploader } from './MediaUploader';
import { createPost } from '../../lib/media-service';
import { budgetCountries, MAJOR_CITIES } from '../../data/budget-countries';
import { MEDIA_TAGS } from '../../data/media-tags';

interface CreatePostModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  username: string;
  userAvatar?: string;
}

const MAX_CAPTION_LENGTH = 2000;

export function CreatePostModal({ isOpen, onClose, userId, username, userAvatar }: CreatePostModalProps) {
  const [mediaUrl, setMediaUrl] = React.useState('');
  const [mediaType, setMediaType] = React.useState<'image' | 'video'>('image');
  const [caption, setCaption] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [city, setCity] = React.useState('');
  const [customCity, setCustomCity] = React.useState('');
  const [showCustomCity, setShowCustomCity] = React.useState(false);
  const [tags, setTags] = React.useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [showTagSelector, setShowTagSelector] = React.useState(false);

  // Lock body scroll when modal is open
  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = '0px'; // Prevent layout shift
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, [isOpen]);

  // Group tags by category
  const tagsByCategory = React.useMemo(() => {
    return Object.entries(MEDIA_TAGS).reduce((acc, [id, tag]) => {
      if (!acc[tag.category]) {
        acc[tag.category] = [];
      }
      acc[tag.category].push({ id, ...tag });
      return acc;
    }, {} as Record<string, Array<{ id: string; icon: string; label: string; category: string }>>);
  }, []);

  const handleUploadComplete = (url: string, type: 'image' | 'video') => {
    setMediaUrl(url);
    setMediaType(type);
    setError(null);
  };

  const handleUploadError = (error: string) => {
    setError(error);
  };

  const handleSelectTag = (tagId: string) => {
    if (!tags.includes(tagId) && tags.length < 5) {
      setTags([...tags, tagId]);
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const validateForm = (): string | null => {
    if (!mediaUrl) return 'Veuillez sélectionner une photo ou une vidéo';
    if (!caption.trim()) return 'La description est obligatoire';
    if (!country) return 'Le pays est obligatoire';
    if (!city && !customCity) return 'La ville est obligatoire';
    if (tags.length === 0) return 'Veuillez sélectionner au moins un tag';
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      setError(null);
        
      await createPost({
        userId,
        username,
        avatar: userAvatar,
        mediaUrl,
        mediaType,
        caption,
        country,
        city: showCustomCity ? customCity : city,
        tags,
        likes: [],
        comments: []
      });

      onClose();
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Erreur lors de la création du post');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-[100]"
      style={{ 
        position: 'fixed',
        overflow: 'hidden'
      }}
    >
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Container */}
      <div className="absolute inset-0 flex items-center justify-center p-0 sm:p-4">
        <div className="relative w-full h-full sm:h-auto sm:max-h-[85vh] bg-white dark:bg-gray-800 sm:rounded-2xl shadow-xl sm:max-w-2xl flex flex-col overflow-hidden">
          {/* Header */}
          <div className="sticky top-0 z-20 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm border-b dark:border-gray-700">
            <div className="flex items-center justify-between p-4">
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                Créer un post
              </h2>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>
          </div>

          {/* Content - Scrollable */}
          <div className="flex-1 overflow-y-auto">
            <div className="p-4 space-y-6">
              <form onSubmit={handleSubmit} className="space-y-6">
                {!mediaUrl ? (
                  <div>
                    <div className="flex items-center gap-3 mb-4">
                      <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                        <Camera className="w-5 h-5 text-primary-500" />
                      </div>
                      <div>
                        <h3 className="text-base font-medium text-gray-900 dark:text-white">
                          Partagez votre moment
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Photos et vidéos de votre PVT
                        </p>
                      </div>
                    </div>
                    <MediaUploader
                      onUploadComplete={handleUploadComplete}
                      onUploadError={handleUploadError}
                      userId={userId}
                    />
                  </div>
                ) : (
                  <div className="relative">
                    {mediaType === 'image' ? (
                      <img
                        src={mediaUrl}
                        alt="Preview"
                        className="w-full aspect-square object-cover rounded-xl"
                      />
                    ) : (
                      <video
                        src={mediaUrl}
                        className="w-full aspect-square object-contain rounded-xl"
                        controls
                      />
                    )}
                    <button
                      type="button"
                      onClick={() => setMediaUrl('')}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                )}

                {error && (
                  <div className="p-3 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 text-sm rounded-lg">
                    {error}
                  </div>
                )}

                <div>
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-3">
                      <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                        <Info className="w-5 h-5 text-primary-500" />
                      </div>
                      <div>
                        <h3 className="text-base font-medium text-gray-900 dark:text-white">
                          Description <span className="text-red-500">*</span>
                        </h3>
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                          Décrivez votre photo ou vidéo
                        </p>
                      </div>
                    </div>
                    <span className={`text-xs ${
                      caption.length > MAX_CAPTION_LENGTH * 0.9 
                        ? 'text-red-500 dark:text-red-400' 
                        : 'text-gray-500 dark:text-gray-400'
                    }`}>
                      {caption.length}/{MAX_CAPTION_LENGTH}
                    </span>
                  </div>
                  <textarea
                    value={caption}
                    onChange={(e) => setCaption(e.target.value)}
                    className={`w-full px-3 py-2 bg-gray-50 dark:bg-gray-700 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
                      caption.length > 0 && !caption.trim()
                        ? 'border-red-500 dark:border-red-500'
                        : 'border-gray-200 dark:border-gray-600'
                    }`}
                    rows={3}
                    maxLength={MAX_CAPTION_LENGTH}
                    placeholder="Décrivez votre post..."
                    required
                  />
                </div>

                <div>
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                      <Globe2 className="w-5 h-5 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 dark:text-white">
                        Localisation <span className="text-red-500">*</span>
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Où cette photo a-t-elle été prise ?
                      </p>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="space-y-3">
                      <select
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        className={`w-full px-4 py-2 bg-gray-50 dark:bg-gray-700 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
                          !country ? 'border-red-500 dark:border-red-500' : 'border-gray-200 dark:border-gray-600'
                        }`}
                        required
                      >
                        <option value="">Sélectionnez un pays</option>
                        {budgetCountries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {`${country.name} ${country.flag}`}
                          </option>
                        ))}
                      </select>

                      {country && (
                        <div className="space-y-3">
                          <select
                            value={showCustomCity ? 'other' : city}
                            onChange={(e) => {
                              if (e.target.value === 'other') {
                                setShowCustomCity(true);
                                setCity('');
                              } else {
                                setShowCustomCity(false);
                                setCity(e.target.value);
                              }
                            }}
                            className={`w-full px-4 py-2 bg-gray-50 dark:bg-gray-700 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
                              !city && !showCustomCity ? 'border-red-500 dark:border-red-500' : 'border-gray-200 dark:border-gray-600'
                            }`}
                            required
                          >
                            <option value="">Sélectionnez une ville</option>
                            {MAJOR_CITIES[country]?.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                            <option value="other">Autre ville...</option>
                          </select>

                          {showCustomCity && (
                            <input
                              type="text"
                              value={customCity}
                              onChange={(e) => setCustomCity(e.target.value)}
                              placeholder="Entrez le nom de la ville"
                              className={`w-full px-4 py-2 bg-gray-50 dark:bg-gray-700 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white ${
                                !customCity ? 'border-red-500 dark:border-red-500' : 'border-gray-200 dark:border-gray-600'
                              }`}
                              required
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-lg">
                      <Hash className="w-5 h-5 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-base font-medium text-gray-900 dark:text-white">
                        Tags <span className="text-red-500">*</span>
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Sélectionnez au moins 1 tag (max. 5)
                      </p>
                    </div>
                  </div>

                  {/* Selected Tags */}
                  {tags.length > 0 && (
                    <div className="flex flex-wrap gap-2 mb-4">
                      {tags.map(tagId => {
                        const tag = MEDIA_TAGS[tagId];
                        return (
                          <span
                            key={tagId}
                            className="inline-flex items-center gap-1 px-2 py-1 bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400 rounded-lg text-sm border border-primary-100/50 dark:border-primary-800/30"
                          >
                            {tag.icon}
                            <span>{tag.label}</span>
                            <button
                              type="button"
                              onClick={() => handleRemoveTag(tagId)}
                              className="p-0.5 hover:bg-primary-100 dark:hover:bg-primary-800/30 rounded-full transition-colors"
                            >
                              <X className="w-3 h-3" />
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  )}

                  {/* Tag Categories */}
                  {tags.length < 5 && (
                    <div className="space-y-4">
                      {Object.entries(tagsByCategory).map(([category, tags]) => (
                        <div key={category}>
                          <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase mb-2">
                            {category}
                          </h4>
                          <div className="flex flex-wrap gap-2">
                            {tags.map(tag => (
                              <button
                                key={tag.id}
                                type="button"
                                onClick={() => handleSelectTag(tag.id)}
                                disabled={tags.includes(tag.id)}
                                className={`inline-flex items-center gap-1 px-2 py-1 rounded-lg text-sm transition-colors ${
                                  tags.includes(tag.id)
                                    ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
                                    : 'bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-600'
                                }`}
                              >
                                <span>{tag.icon}</span>
                                <span>{tag.label}</span>
                              </button>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

          {/* Footer - Fixed */}
          <div className="sticky bottom-0 p-4 border-t dark:border-gray-700 bg-white/95 dark:bg-gray-800/95 backdrop-blur-sm">
            <button
              type="submit"
              disabled={!mediaUrl || isSubmitting}
              className="w-full px-4 py-2.5 bg-primary-500 hover:bg-primary-600 disabled:bg-gray-300 dark:disabled:bg-gray-600 text-white rounded-lg transition-colors flex items-center justify-center gap-2"
              onClick={handleSubmit}
            >
              {isSubmitting ? (
                <>
                  <div className="w-4 h-4 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  <span>Publication...</span>
                </>
              ) : (
                <span>Publier</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}