import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Plus, Info } from 'lucide-react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { TravelProfile } from '../types/matching';
import { MatchingFilters } from '../components/matching/MatchingFilters';
import { MatchingList } from '../components/matching/MatchingList';
import { CreateListingForm } from '../components/matching/CreateListingForm';
import { collection, query, where, getDocs, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { createTravelProfile } from '../lib/firebase-service';

export function MatchingPage() {
  const { user } = useAuth();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [profiles, setProfiles] = React.useState<TravelProfile[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [showCreateForm, setShowCreateForm] = React.useState(false);
  const [hasExistingProfile, setHasExistingProfile] = React.useState(false);

  // Initial load of profiles
  React.useEffect(() => {
    const fetchProfiles = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // Vérifier si l'utilisateur a déjà un profil
        const userProfileQuery = query(
          collection(db, 'travel_profiles'),
          where('userId', '==', user?.uid),
          where('isActive', '==', true)
        );
        const userProfileSnapshot = await getDocs(userProfileQuery);
        setHasExistingProfile(!userProfileSnapshot.empty);

        const profilesRef = collection(db, 'travel_profiles');
        const q = query(
          profilesRef,
          where('isActive', '==', true),
          orderBy('createdAt', 'desc')
        );

        const snapshot = await getDocs(q);
        const fetchedProfiles = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as TravelProfile[];

        setProfiles(fetchedProfiles);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        setError('Impossible de charger les profils pour le moment');
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchProfiles();
    }
  }, [user]);

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  const handleFilterChange = async (filters: any) => {
    try {
      setIsLoading(true);
      setError(null);

      const profilesRef = collection(db, 'travel_profiles');
      let queryConditions = [
        where('isActive', '==', true)
      ];

      // Filtre par destination si sélectionnée
      if (filters.destination) {
        const q = query(
          profilesRef,
          where('preferences.destination', '==', filters.destination),
          where('isActive', '==', true),
          orderBy('createdAt', 'desc')
        );
        const snapshot = await getDocs(q);
        const fetchedProfiles = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          isOwnProfile: doc.data().userId === user?.uid
        })) as TravelProfile[];

        setProfiles(fetchedProfiles);
        return;
      }

      // Si pas de destination sélectionnée, récupérer tous les profils
      const q = query(
        profilesRef,
        where('isActive', '==', true),
        orderBy('createdAt', 'desc')
      );
      const snapshot = await getDocs(q);
      const fetchedProfiles = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        username: doc.data().userId === user?.uid ? userInfo?.user?.username : doc.data().username,
        isOwnProfile: doc.data().userId === user?.uid
      })) as TravelProfile[];

      setProfiles(fetchedProfiles);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setError('Impossible de charger les profils pour le moment');
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to calculate age
  const getAge = (birthDate: any): number => {
    if (!birthDate) return 0;
    const date = birthDate.toDate ? birthDate.toDate() : new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const monthDiff = today.getMonth() - date.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  };

  const handleLike = async (profile: TravelProfile) => {
    // TODO: Implement like logic
    console.log('Liked profile:', profile.id);
  };

  const handlePass = async (profile: TravelProfile) => {
    // TODO: Implement pass logic
    console.log('Passed profile:', profile.id);
  };

  const handleMessage = async (profile: TravelProfile) => {
    // TODO: Implement message logic
    console.log('Message to profile:', profile.id);
  };

  const handleCreateListing = async (data: any) => {
    try {
      if (!user) return;
      setError(null);
      setIsLoading(true);
      
      const newProfileId = await createTravelProfile({
        userId: user.uid,
        username: userInfo?.user?.username,
        ...data
      });
      
      // Récupérer le nouveau profil créé
      const profileRef = doc(db, 'travel_profiles', newProfileId);
      const profileDoc = await getDoc(profileRef);
      
      if (profileDoc.exists()) {
        // Ajouter le nouveau profil à la liste avec isOwnProfile=true
        const newProfile = {
          id: profileDoc.id,
          ...profileDoc.data(),
          isOwnProfile: true
        } as TravelProfile;
        
        setProfiles(prev => [newProfile, ...prev]);
        setHasExistingProfile(true);
      }
      
      setShowCreateForm(false);
    } catch (error) {
      console.error('Error creating listing:', error);
      setError('Impossible de créer l\'annonce. Veuillez vérifier vos informations et réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      {/* Hero Section */}
      <div className="hidden sm:flex relative min-h-[500px] items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1527631746610-bca00a040d60?q=80&w=2574&auto=format&fit=crop"
            alt=""
            className="w-full h-full object-cover opacity-90 dark:opacity-70"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-gray-900/70 to-gray-900/95"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-6 lg:px-8 py-24">
          <div className="max-w-3xl">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-4 sm:mb-6">
              <Users className="w-4 h-4 text-primary-400" />
              <span className="text-xs sm:text-sm font-medium text-white">Compagnons de voyage</span>
            </div>

            <h1 className="text-3xl lg:text-4xl font-bold text-white mb-6">
              Trouvez votre compagnon de PVT
            </h1>

            <p className="text-lg text-white/90 leading-relaxed">
              Rencontrez d'autres voyageurs qui partagent vos dates, votre destination et votre style de voyage.
            </p>
          </div>
        </div>
      </div>

      <main className="flex-1 pt-20 sm:pt-0 pb-6 sm:py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 mb-8">
            <div className="flex-1">
              <MatchingFilters onFilterChange={handleFilterChange} />
            </div>
            {hasExistingProfile ? (
              <div className="flex items-center gap-2 px-4 py-2 bg-amber-50 dark:bg-amber-900/20 text-amber-700 dark:text-amber-300 rounded-lg border border-amber-100 dark:border-amber-800/30">
                <Info className="w-5 h-5" />
                <span className="text-sm">Vous avez déjà une annonce active</span>
              </div>
            ) : (
              <button
                onClick={() => setShowCreateForm(true)}
                className="flex items-center justify-center gap-2 px-6 py-3 bg-primary-600 hover:bg-primary-700 text-white rounded-xl transition-colors shadow-sm hover:shadow-md w-full sm:w-auto"
              >
                <Plus className="w-5 h-5" />
                <span className="font-medium">Créer une annonce</span>
              </button>
            )}
          </div>

          <MatchingList
            profiles={profiles}
            onLike={handleLike}
            onPass={handlePass}
            onMessage={handleMessage}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </main>
      
      {showCreateForm && (
        <CreateListingForm
          onSubmit={handleCreateListing}
          onClose={() => setShowCreateForm(false)}
        />
      )}

      <Footer />
    </div>
  );
}