interface Testimonial {
  id: string;
  author: string;
  content: string;
  avatar?: string;
  rating?: number;
}

export const testimonials: Testimonial[] = [
  {
    id: '1',
    author: 'ElBen',
    content: "Actuellement en PVT dans un pays et victime d'une agression il y a 6 jours, je suis déjà très satisfait des échanges avec Global PVT. Ces contacts ainsi que ceux du consulat français qui m'accompagne me permettent aujourd'hui d'être assuré de soins et de contrôles préventifs. Cela faisait longtemps qu'on ne s'était pas autant occupé de moi et c'est déjà une très belle chose même si mon expérience PVT devait prendre fin prochainement.",
    rating: 5
  },
  {
    id: '2',
    author: 'myriem',
    content: "Les conseillers sont disponibles surtout via la plateforme PVTistes et nous sommes rappelés rapidement pour répondre à nos questions. Le fait de pouvoir parler et avoir un suivi avec une réelle personne via le partenariat PVTistes est un gros plus.",
    rating: 5
  },
  {
    id: '3',
    author: 'marion',
    content: "Merci à Sandra pour sa réactivité à mes questions par rapport à l'assurance, j'ai eu des réponses claires par messages et j'ai pu obtenir toutes les réponses que je voulais.",
    rating: 5
  },
  {
    id: '4',
    author: 'Christel',
    content: "Excellent service ! Très bien accompagnée lors de la souscription de mes contrats (j'ai souscrit à plusieurs reprises). Les équipes sont très disponibles et toujours de bon conseil. Fort heureusement, je n'ai pas eu de problème médical... Sans aucun doute, je souscrirai dès mon prochain voyage. Je recommande à 100% !!",
    rating: 5
  },
  {
    id: '5',
    author: 'Daphne',
    content: "J'étais dans une situation particulière et j'ai écrit via le site des pvtistes pour savoir si dans ma situation je pouvais souscrire à l'assurance. J'ai eu une rapidité de la réponse avec des consignes et au final un arrangement. Pour moi une très bonne expérience avec eux : professionnels, efficaces, conciliants et impliqués. Je recommande.",
    rating: 5
  },
  {
    id: '6',
    author: 'pauline054',
    content: "Adhésion très simple, termes du contrat faciles à lire.",
    rating: 5
  },
  {
    id: '7',
    author: 'Clg52blub',
    content: "Le site est clair, les étapes également, et on reçoit des mails pour nous confirmer la prise en compte de notre demande ainsi que pour nous tenir au courant du remboursement.",
    rating: 5
  }
];