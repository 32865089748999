import React from 'react';
import { Info } from 'lucide-react';
import { GroceriesBreakdown } from '../../GroceriesBreakdown';
import { RestaurantsBreakdown } from '../../RestaurantsBreakdown';
import { TransportBreakdown } from '../../TransportBreakdown';
import { UtilitiesBreakdown } from '../../UtilitiesBreakdown';
import { LeisureBreakdown } from '../../LeisureBreakdown';
import { NomadExpensesBreakdown } from './NomadExpensesBreakdown';

interface MonthlyExpenseCategoryProps {
  category: {
    id: string;
    name: string;
    icon: React.ElementType;
    field: string;
    gradient: string;
    iconColor: string;
    description?: string;
    required?: boolean;
  };
  value: string;
  onChange: (value: string) => void;
  costData?: any;
  isNomadMode?: boolean;
  housingType?: string;
}

export function MonthlyExpenseCategory({ 
  category, 
  value, 
  onChange, 
  costData,
  isNomadMode,
  housingType
}: MonthlyExpenseCategoryProps) {
  const renderBreakdown = () => {
    if (category.id === 'utilities' && isNomadMode) {
      return <NomadExpensesBreakdown housingType={housingType || ''} />;
    }

    if (!costData?.priceItems) return null;

    switch (category.id) {
      case 'groceries':
        return (
          <GroceriesBreakdown 
            priceItems={costData.priceItems}
            currency={costData.currency}
            eurPrices={costData.eurPrices}
          />
        );
      case 'restaurants':
        return (
          <RestaurantsBreakdown 
            priceItems={costData.priceItems}
            currency={costData.currency}
            eurPrices={costData.eurPrices}
          />
        );
      case 'transport':
        return (
          <TransportBreakdown 
            priceItems={costData.priceItems}
            currency={costData.currency}
            eurPrices={costData.eurPrices}
          />
        );
      case 'utilities':
        return (
          <UtilitiesBreakdown 
            priceItems={costData.priceItems}
            currency={costData.currency}
            eurPrices={costData.eurPrices}
          />
        );
      case 'leisure':
        return (
          <LeisureBreakdown 
            priceItems={costData.priceItems}
            currency={costData.currency}
            eurPrices={costData.eurPrices}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="group relative overflow-hidden bg-gradient-to-br dark:bg-gray-800/50 rounded-xl border border-gray-200/50 dark:border-gray-700/50 p-6 hover:shadow-lg transition-all duration-300">
      <div className={`absolute inset-0 bg-gradient-to-br ${category.gradient} opacity-20 dark:opacity-10`} />
      
      <div className="relative">
        <div className="flex items-center gap-3 mb-4">
          <div className={`p-2 rounded-lg bg-white dark:bg-gray-800 ${category.iconColor}`}>
            <category.icon className="w-5 h-5" />
          </div>
          <div>
            <label className="block text-base font-medium text-gray-900 dark:text-white">
              {category.name} {category.required && <span className="text-red-500">*</span>}
            </label>
            {category.description && (
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {category.description}
              </p>
            )}
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
            <span className="text-gray-500 dark:text-gray-400">€</span>
          </div>
          <input
            type="number"
            value={value}
            onChange={(e) => {
              const value = Math.max(0, Number(e.target.value));
              onChange(value.toString());
            }}
            min="0"
            step="0.01"
            className="w-full pl-8 pr-4 py-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white transition-shadow text-base"
            required={category.required}
          />
        </div>

        {renderBreakdown()}
      </div>
    </div>
  );
}