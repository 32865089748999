import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Users } from 'lucide-react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { useAuth } from '../lib/auth-context';
import { MediaFeed } from '../components/media/MediaFeed';
import { CreatePostModal } from '../components/media/CreatePostModal';
import { MediaSearch } from '../components/media/MediaSearch';
import { useLocation } from 'react-router-dom';

export function MediaPage() {
  const { user, userInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [refreshKey, setRefreshKey] = React.useState(0);
  const [searchFilters, setSearchFilters] = React.useState({
    query: '',
    country: undefined,
    city: undefined,
    tags: [] as string[],
    mediaType: undefined as 'image' | 'video' | undefined,
    userId: undefined as string | undefined
  });

  // Handle modal close and refresh
  const handleModalClose = () => {
    setShowCreateModal(false);
    setRefreshKey(prev => prev + 1); // Force refresh of MediaFeed
  };

  // Redirect to home if not authenticated
  React.useEffect(() => {
    if (!user) {
      navigate('/', { replace: true });
    }
  }, [user, navigate]);

  if (!user || !userInfo) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col pb-mobile">
      <Navbar 
        onLogin={() => {}} 
        onAdminClick={() => {}}
        onHomeClick={() => {}}
        isAdminMode={false}
      />

      {/* Hero Section */}
      <div className="hidden sm:flex relative min-h-[400px] items-center">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1527631746610-bca00a040d60?q=80&w=2574&auto=format&fit=crop"
            alt=""
            className="w-full h-full object-cover opacity-90 dark:opacity-70"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 via-gray-900/70 to-gray-900/95"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-6 lg:px-8 py-24">
          <div className="max-w-3xl">
            <div className="inline-flex items-center gap-2 px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white/10 backdrop-blur-sm border border-white/20 mb-8 sm:mb-8">
              <Users className="w-4 h-4 text-primary-400" />
              <span className="text-xs sm:text-sm font-medium text-white">Communauté PVT</span>
            </div>

            <h1 className="text-3xl lg:text-4xl font-bold text-white mb-6">
              Partagez vos moments PVT
            </h1>

            <p className="text-lg text-white/90 leading-relaxed">
              Photos, vidéos, stories… Faites découvrir vos plus beaux souvenirs de PVT à la communauté !
            </p>
          </div>
        </div>
      </div>

      <main className="flex-1 pt-20 sm:pt-0 pb-6 sm:py-12">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Search */}
          <div className="mb-4 sm:mb-8">
            <MediaSearch 
              onSearch={setSearchFilters} 
              currentUserId={user.uid}
            />
          </div>

          {/* Create Post Button */}
          <div className="mb-4 sm:mb-8">
            <button
              onClick={() => setShowCreateModal(true)}
              className="group relative w-full flex items-center justify-center gap-3 px-4 sm:px-6 py-3 sm:py-4 bg-primary-500 hover:bg-primary-600 text-white rounded-xl transition-all duration-300 transform hover:scale-[1.02] shadow-lg hover:shadow-xl overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-white/[0.07] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <Plus className="w-5 h-5 text-white/90" />
              <span className="font-medium text-sm sm:text-lg">Partagez votre moment PVT</span>
            </button>
          </div>

          {/* Media Feed */}
          <MediaFeed 
            key={refreshKey}
            refreshKey={refreshKey}
            currentUserId={user.uid} 
            filters={searchFilters}
          />
        </div>
      </main>

      {/* Create Post Modal */}
      {showCreateModal && (
        <CreatePostModal
          isOpen={true}
          onClose={handleModalClose}
          userId={user.uid}
          username={userInfo.user.username}
          userAvatar={userInfo.user.avatar}
        />
      )}

      <Footer />
    </div>
  );
}