import React from 'react';
import { ListPlus, StickyNote } from 'lucide-react';

interface TaskActionsProps {
  taskId: string;
  isPreview: boolean;
  onAddSubtask: (taskId: string) => void;
  onUpdateNotes: () => void;
  hasNotes?: boolean;
}

export function TaskActions({
  taskId,
  isPreview,
  onAddSubtask,
  onUpdateNotes,
  hasNotes = false
}: TaskActionsProps) {
  if (isPreview) return null;

  return (
    <div className="flex items-start gap-2">
      <button
        onClick={() => onAddSubtask(taskId)}
        className="flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-primary-600 hover:text-white dark:text-primary-400 dark:hover:text-white bg-primary-50 hover:bg-primary-600 dark:bg-primary-900/20 dark:hover:bg-primary-600 rounded-lg transition-all duration-200"
        title="Ajouter une sous-tâche"
      >
        <ListPlus className="w-4 h-4" />
        <span>Ajouter sous-tâche</span>
      </button>
      <button
        onClick={onUpdateNotes}
        className="flex items-center gap-1.5 px-3 py-1.5 text-sm font-medium text-accent-600 hover:text-white dark:text-accent-400 dark:hover:text-white bg-accent-50 hover:bg-accent-600 dark:bg-accent-900/20 dark:hover:bg-accent-600 rounded-lg transition-all duration-200"
        title={hasNotes ? "Modifier la note" : "Ajouter une note"}
      >
        <StickyNote className="w-4 h-4" />
        <span>{hasNotes ? 'Modifier note' : 'Ajouter note'}</span>
      </button>
    </div>
  );
}