import React from 'react';
import { CountryCard } from '../components/CountryCard';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { Country, TodoList, Task } from '../types/todo';
import { getUserLists, getCountriesFromFirebase } from '../lib/firebase-service';
import { useAuth, UserInfo } from '../lib/auth-context';
import { InstructionsBox } from '../components/InstructionsBox';
import { SectionHeader } from '../components/SectionHeader';
import { GuidePromoSection } from '../components/GuidePromoSection';
import { WhvAustraliaPromo } from '../components/WhvAustraliaPromo';
import { MessageCenterPromo } from '../components/MessageCenterPromo';

interface HomePageProps {
  userLists: string[];
  onCreateList: () => void;
  onManageList: (code: string) => void;
  onDeleteList: (code: string, fromDashboard: boolean) => void;
  availableCountries: Country[];
  isSelectingCountry: boolean;
  onCloseSelection: () => void;
  onSelectCountry: (code: string) => void;
  countries: Country[];
  isAuthenticated: boolean;
}

export function HomePage({
  userLists,
  onCreateList,
  onManageList,
  onDeleteList,
  availableCountries,
  isSelectingCountry,
  onCloseSelection,
  onSelectCountry,
  countries,
  isAuthenticated
}: HomePageProps) {
  const { user, userInfo } = useAuth();
  const [userListsData, setUserListsData] = React.useState<TodoList[]>([]);
  const [isVisible, setIsVisible] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);
  const [firebaseCountries, setFirebaseCountries] = React.useState<Country[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  
  React.useEffect(() => {
    setIsVisible(true);
    
    const fetchCountries = async () => {
      try {
        const countries = await getCountriesFromFirebase();
        // Sort countries alphabetically by name
        const sortedCountries = countries.sort((a, b) => 
          a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' })
        );
        setFirebaseCountries(sortedCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchCountries();
  }, []);

  React.useEffect(() => {
    if (user) {
      getUserLists(user.uid).then((lists) => {
        setUserListsData(lists);
      });
    }
  }, [user]);

  // Sort user countries and other countries alphabetically
  const userCountries = React.useMemo(() => {
    return firebaseCountries
      .filter(country => userLists.includes(country.code))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));
  }, [firebaseCountries, userLists]);

  const otherCountries = React.useMemo(() => {
    return firebaseCountries
      .filter(country => !userLists.includes(country.code))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));
  }, [firebaseCountries, userLists]);
  
  const countryGroups = otherCountries.reduce((acc, country, index) => {
    const groupIndex = Math.floor(index / 4);
    if (!acc[groupIndex]) {
      acc[groupIndex] = [];
    }
    acc[groupIndex].push(country);
    return acc;
  }, [] as Country[][]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % countryGroups.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + countryGroups.length) % countryGroups.length);
  };

  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      nextSlide();
    } else if (isRightSwipe) {
      prevSlide();
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px] pt-14">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary-500 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className={`pt-16 space-y-6 transition-opacity duration-500 main-content ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <InstructionsBox />
      
      <div className="space-y-8">
        {userCountries.length > 0 && (
          <div>
            <SectionHeader
              title="Mes checklists"
              description="Retrouvez ici toutes vos checklists personnalisées. Suivez votre progression, mettez à jour vos tâches et gérez vos dates de voyage pour chaque destination."
            />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {userCountries.map(country => {
                const userList = userListsData.find(list => list.countryCode === country.code);
                return (
                  <CountryCard
                    key={country.code}
                    country={country}
                    onCreateList={() => onSelectCountry(country.code)}
                    onManageList={onManageList}
                    onDeleteList={onDeleteList}
                    tasks={userList?.tasks || []}
                    departureDate={userList?.departureDate}
                    returnDate={userList?.returnDate}
                    createdAt={userList?.createdAt}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div>
          <SectionHeader
            title="Les checklists disponibles"
            description="Découvrez toutes les destinations PVT disponibles. Chaque checklist est soigneusement préparée avec les étapes essentielles pour votre voyage, de la préparation au retour. Sélectionnez un pays pour commencer votre aventure."
          />
          
          <div className="md:hidden relative">
            {/* Swipe indicator overlay - Only shown on first visit */}
            <div className="absolute inset-0 z-10 pointer-events-none">
              <div className="h-full flex items-center justify-between px-4">
                <div className="w-8 h-8 rounded-full bg-white/80 dark:bg-gray-800/80 shadow-lg backdrop-blur-sm flex items-center justify-center animate-pulse">
                  <ArrowLeft className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                </div>
                <div className="w-8 h-8 rounded-full bg-white/80 dark:bg-gray-800/80 shadow-lg backdrop-blur-sm flex items-center justify-center animate-pulse">
                  <ArrowRight className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                </div>
              </div>
            </div>

            <div 
              className="overflow-hidden touch-pan-y"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <div 
                className="flex transition-transform duration-300 ease-in-out"
                style={{ transform: `translateX(-${currentSlide * 100}%)` }}
              >
                {countryGroups.map((group, groupIndex) => (
                  <div 
                    key={groupIndex}
                    className="w-full flex-shrink-0 grid grid-cols-2 gap-4"
                  >
                    {group.map((country) => (
                      <button
                        key={country.code}
                        onClick={() => onSelectCountry(country.code)}
                        className="relative overflow-hidden rounded-xl aspect-[4/3]"
                      >
                        <img
                          src={country.image}
                          alt={country.name}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                        <div className="absolute inset-0 p-3 flex flex-col justify-end">
                          <div className="flex items-center space-x-2 mb-1">
                            <span className="text-lg">{country.flag}</span>
                            <h3 className="text-sm font-bold text-white">{country.name}</h3>
                          </div>
                          <div className="flex items-center text-white/80 text-xs font-medium">
                            <span>Créer une checklist</span>
                            <ArrowRight className="w-4 h-4 ml-1" />
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            
            {countryGroups.length > 1 && (
              <div className="absolute -bottom-12 left-0 right-0 flex flex-col items-center gap-2">
                <div className="flex justify-center gap-2">
                  {countryGroups.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentSlide(index)}
                      className={`w-2 h-2 rounded-full transition-all duration-300 ${
                        currentSlide === index 
                          ? 'bg-primary-500 scale-125' 
                          : 'bg-gray-300 dark:bg-gray-700 hover:bg-primary-400 dark:hover:bg-primary-600'
                      }`}
                      aria-label={`Aller à la page ${index + 1}`}
                    />
                  ))}
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {currentSlide + 1} / {countryGroups.length}
                </p>
              </div>
            )}
          </div>

          <div className="hidden md:grid md:grid-cols-2 lg:grid-cols-4 gap-4">
            {otherCountries.map((country) => (
              <button
                key={country.code}
                onClick={() => onSelectCountry(country.code)}
                className="relative overflow-hidden rounded-xl aspect-[4/3]"
              >
                <img
                  src={country.image}
                  alt={country.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                <div className="absolute inset-0 p-3 flex flex-col justify-end">
                  <div className="flex items-center space-x-2 mb-1">
                    <span className="text-lg">{country.flag}</span>
                    <h3 className="text-sm font-bold text-white">{country.name}</h3>
                  </div>
                  <div className="flex items-center text-white/80 text-xs font-medium">
                    <span>Créer une checklist</span>
                    <ArrowRight className="w-4 h-4 ml-1" />
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Message Center Promo */}
        <div className="mt-16 sm:mt-24">
          <MessageCenterPromo />
        </div>
      </div>
    
    </div>
  );
}