import { MAX_IMAGE_SIZE } from '../lib/media-service';

// Maximum dimensions for different image sizes
const MAX_DIMENSIONS = {
  thumbnail32: { width: 32, height: 32 },
  thumbnail80: { width: 80, height: 80 },
  preview: { width: 600, height: 600 },
  full: { width: 2048, height: 2048 }
};

// Quality settings for different image sizes
const QUALITY_SETTINGS = {
  thumbnail: 0.8,
  preview: 0.85,
  full: 0.9
};

/**
 * Compresses an image using canvas
 */
export async function compressImage(
  file: File,
  maxWidth: number,
  maxHeight: number,
  quality: number = 0.8
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      
      // Calculate new dimensions while maintaining aspect ratio
      let width = img.width;
      let height = img.height;
      
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      
      // Create canvas and context
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }
      
      // Draw image with smoothing
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, width, height);
      
      // Convert to blob
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Could not create blob'));
            return;
          }
          resolve(blob);
        },
        'image/jpeg',
        quality
      );
    };
    
    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      reject(new Error('Error loading image'));
    };
  });
}

/**
 * Generates multiple sizes of an image
 */
export async function generateImageSizes(file: File): Promise<{
  full: Blob;
  preview: Blob;
  thumbnail80: Blob;
  thumbnail32: Blob;
}> {
  // Check if file is too large
  if (file.size > MAX_IMAGE_SIZE) {
    throw new Error(`L'image ne doit pas dépasser ${MAX_IMAGE_SIZE / (1024 * 1024)}MB`);
  }

  try {
    // Generate all sizes in parallel
    const [full, preview, thumbnail80, thumbnail32] = await Promise.all([
      compressImage(
        file,
        MAX_DIMENSIONS.full.width,
        MAX_DIMENSIONS.full.height,
        QUALITY_SETTINGS.full
      ),
      compressImage(
        file,
        MAX_DIMENSIONS.preview.width,
        MAX_DIMENSIONS.preview.height,
        QUALITY_SETTINGS.preview
      ),
      compressImage(
        file,
        MAX_DIMENSIONS.thumbnail80.width,
        MAX_DIMENSIONS.thumbnail80.height,
        QUALITY_SETTINGS.thumbnail
      ),
      compressImage(
        file,
        MAX_DIMENSIONS.thumbnail32.width,
        MAX_DIMENSIONS.thumbnail32.height,
        QUALITY_SETTINGS.thumbnail
      )
    ]);

    // Verify all blobs were created successfully
    if (!full || !preview || !thumbnail80 || !thumbnail32) {
      throw new Error('Failed to generate one or more image sizes');
    }
    return {
      full,
      preview,
      thumbnail80,
      thumbnail32
    };
  } catch (error) {
    console.error('Error generating image sizes:', error);
    throw new Error('Erreur lors de la génération des différentes tailles d\'image');
  }
}
