import React from 'react';
import { PieChart, BarChart, ArrowRight } from 'lucide-react';

interface BudgetChartsProps {
  data: {
    monthlyExpenses: {
      category: string;
      amount: number;
    }[];
    preDepartureCosts: {
      category: string;
      amount: number;
    }[];
    setupCosts: {
      category: string;
      amount: number;
    }[];
  };
}

export function BudgetCharts({ data }: BudgetChartsProps) {
  const [activeTab, setActiveTab] = React.useState<'monthly' | 'initial' | 'setup'>('monthly');
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  // Calculer les totaux
  const monthlyTotal = data.monthlyExpenses.reduce((sum, item) => sum + item.amount, 0);
  const preDepartureTotal = data.preDepartureCosts.reduce((sum, item) => sum + item.amount, 0);
  const setupTotal = data.setupCosts.reduce((sum, item) => sum + item.amount, 0);

  // Fonction pour dessiner le graphique en secteurs
  const drawPieChart = React.useCallback((ctx: CanvasRenderingContext2D, data: { category: string; amount: number }[], total: number) => {
    const colors = [
      '#EF4136', // primary-500
      '#0DB6DB', // accent-500
      '#10B981', // emerald-500
      '#F59E0B', // amber-500
      '#8B5CF6', // violet-500
      '#EC4899', // pink-500
      '#6366F1', // indigo-500
      '#F97316'  // orange-500
    ];

    let startAngle = -Math.PI / 2;
    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;
    const radius = Math.min(centerX, centerY) * 0.8;

    data.forEach((item, index) => {
      const sliceAngle = (2 * Math.PI * item.amount) / total;
      
      // Dessiner le secteur
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, startAngle + sliceAngle);
      ctx.closePath();
      
      ctx.fillStyle = colors[index % colors.length];
      ctx.fill();
      
      // Ajouter une légère ombre
      ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
      ctx.shadowBlur = 5;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      
      // Calculer la position du texte
      const midAngle = startAngle + sliceAngle / 2;
      const textRadius = radius * 0.7;
      const textX = centerX + Math.cos(midAngle) * textRadius;
      const textY = centerY + Math.sin(midAngle) * textRadius;
      
      // Dessiner le pourcentage
      ctx.fillStyle = '#FFFFFF';
      ctx.font = '14px Inter';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const percentage = Math.round((item.amount / total) * 100);
      if (percentage > 5) { // N'afficher que si le secteur est assez grand
        ctx.fillText(`${percentage}%`, textX, textY);
      }
      
      startAngle += sliceAngle;
    });
  }, [dimensions]);

  // Effet pour gérer le redimensionnement
  React.useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current) {
        const container = canvasRef.current.parentElement;
        if (container) {
          const { width, height } = container.getBoundingClientRect();
          setDimensions({ width, height });
          canvasRef.current.width = width * window.devicePixelRatio;
          canvasRef.current.height = height * window.devicePixelRatio;
          canvasRef.current.style.width = `${width}px`;
          canvasRef.current.style.height = `${height}px`;
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Effet pour dessiner le graphique
  React.useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx || dimensions.width === 0) return;

    // Effacer le canvas
    ctx.clearRect(0, 0, dimensions.width * window.devicePixelRatio, dimensions.height * window.devicePixelRatio);
    
    // Mettre à l'échelle pour la résolution de l'écran
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    
    // Dessiner le graphique approprié
    switch (activeTab) {
      case 'monthly':
        drawPieChart(ctx, data.monthlyExpenses, monthlyTotal);
        break;
      case 'initial':
        drawPieChart(ctx, data.preDepartureCosts, preDepartureTotal);
        break;
      case 'setup':
        drawPieChart(ctx, data.setupCosts, setupTotal);
        break;
    }
    
    // Réinitialiser l'échelle
    ctx.scale(1 / window.devicePixelRatio, 1 / window.devicePixelRatio);
  }, [activeTab, data, dimensions, drawPieChart, monthlyTotal, preDepartureTotal, setupTotal]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-premium p-6 mt-8">
      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-6 flex items-center gap-2">
        <PieChart className="hidden sm:block w-5 h-5 text-primary-500" />
        Répartition des dépenses
      </h3>

      {/* Tabs */}
      <div className="flex flex-wrap gap-2 mb-6">
        <button
          onClick={() => setActiveTab('monthly')}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'monthly'
              ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
          }`}
        >
          <BarChart className="w-4 h-4" />
          <span>Dépenses mensuelles</span>
        </button>
        <button
          onClick={() => setActiveTab('initial')}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'initial'
              ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
          }`}
        >
          <BarChart className="w-4 h-4" />
          <span>Dépenses avant le départ</span>
        </button>
        <button
          onClick={() => setActiveTab('setup')}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === 'setup'
              ? 'bg-primary-50 dark:bg-primary-900/20 text-primary-600 dark:text-primary-400'
              : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-gray-700'
          }`}
        >
          <BarChart className="w-4 h-4" />
          <span>Dépenses d'installation</span>
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Chart */}
        <div className="hidden sm:block relative aspect-square">
          <canvas
            ref={canvasRef}
            className="w-full h-full"
          />
        </div>

        {/* Legend */}
        <div className="flex flex-col justify-center w-full">
          <div className="space-y-4">
            {(activeTab === 'monthly' ? data.monthlyExpenses : 
              activeTab === 'initial' ? data.preDepartureCosts : 
              data.setupCosts).map((item, index) => (
              <div key={item.category} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="w-3 h-3 rounded-full" style={{ 
                    backgroundColor: [
                      '#EF4136', '#0DB6DB', '#10B981', '#F59E0B',
                      '#8B5CF6', '#EC4899', '#6366F1', '#F97316'
                    ][index % 8]
                  }} />
                  <span className="text-sm text-gray-700 dark:text-gray-300">
                    {item.category}
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {item.amount.toLocaleString('fr-FR')} €
                  </span>
                  <span className="text-xs text-gray-500 dark:text-gray-400 ml-2">
                    ({Math.round((item.amount / (
                      activeTab === 'monthly' ? monthlyTotal : 
                      activeTab === 'initial' ? preDepartureTotal :
                      setupTotal
                    )) * 100)}%)
                  </span>
                </div>
              </div>
            ))}

            <div className="pt-4 mt-2 border-t border-gray-200 dark:border-gray-700">
              <div className="flex justify-between items-center">
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total
                </span>
                <span className="text-base font-semibold text-primary-600 dark:text-primary-400">
                  {(activeTab === 'monthly' ? monthlyTotal : 
                    activeTab === 'initial' ? preDepartureTotal :
                    setupTotal).toLocaleString('fr-FR')} €
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}