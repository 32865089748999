import React from 'react';
import { Info, CheckCircle2, RefreshCw, Shield, Globe2, X } from 'lucide-react';

export function InstructionsBox() {
  const [isVisible, setIsVisible] = React.useState(() => {
    return localStorage.getItem('instructionsBoxDismissed') !== 'true';
  });

  const handleDismiss = () => {
    localStorage.setItem('instructionsBoxDismissed', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="relative overflow-hidden w-full bg-gradient-to-br from-primary-50 to-accent-50 dark:from-primary-950/50 dark:to-accent-950/50 rounded-2xl shadow-premium border border-primary-100/50 dark:border-primary-800/30">
      {/* Close button */}
      <div className="absolute top-2 right-2 z-10">
        <button
          onClick={handleDismiss}
          className="p-2 bg-white/80 dark:bg-gray-800/80 hover:bg-white dark:hover:bg-gray-800 rounded-full transition-colors shadow-sm backdrop-blur-sm"
          title="Fermer"
        >
          <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
        </button>
      </div>

      <div className="relative p-4 sm:p-6">
        <div className="grid lg:grid-cols-2 gap-0">
          {/* Image Section - Hidden on mobile, shown on desktop */}
          <div className="hidden lg:block relative">
            <div className="absolute inset-0">
              <img
                src="https://images.unsplash.com/photo-1574260031597-bcd9eb192b4f?q=80&w=2574&auto=format&fit=crop"
                alt="Van près de l'océan"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-black/60 via-black/40 to-transparent" />
            </div>

            {/* Content overlay on the image */}
            <div className="relative h-auto flex items-center">
              <div className="p-8">
                <div className="max-w-lg">
                  <h2 className="text-3xl font-bold text-white mb-4">
                    Organisez votre PVT en toute simplicité
                  </h2>
                  
                  <p className="text-white/90 text-lg leading-relaxed mb-6">
                    Chaque étape de votre PVT est soigneusement détaillée grâce à nos listes complètes qui couvrent l'intégralité de votre parcours.
                  </p>

                  <div className="grid grid-cols-2 gap-3">
                    <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                      <div className="flex items-center gap-3 mb-2">
                        <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                          <Globe2 className="w-5 h-5 text-white" />
                        </div>
                        <div className="text-lg font-bold text-white">15+</div>
                      </div>
                      <p className="text-sm text-white/80">Pays disponibles</p>
                    </div>

                    <div className="bg-white/10 backdrop-blur-sm rounded-xl p-4 border border-white/20">
                      <div className="flex items-center gap-3 mb-2">
                        <div className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center">
                          <CheckCircle2 className="w-5 h-5 text-white" />
                        </div>
                        <div className="text-lg font-bold text-white">100%</div>
                      </div>
                      <p className="text-sm text-white/80">Personnalisable</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="bg-gradient-to-br from-gray-50/50 to-white/50 dark:from-gray-800/50 dark:to-gray-900/50 backdrop-blur-sm">
            {/* Mobile Image Section */}
            <div className="lg:hidden relative h-64 sm:h-80">
              <img
                src="https://images.unsplash.com/photo-1574260031597-bcd9eb192b4f?q=80&w=2574&auto=format&fit=crop"
                alt="Van près de l'océan"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/60 to-transparent" />
              <div className="relative h-full p-4 sm:p-6 flex flex-col justify-end">
                <h2 className="text-xl sm:text-2xl font-bold text-white mb-2 sm:mb-3">
                  Organisez votre PVT en toute simplicité
                </h2>
                <p className="text-white/90 text-sm sm:text-base mb-3 sm:mb-4">
                  Chaque étape de votre PVT est soigneusement détaillée grâce à nos listes complètes qui couvrent l'intégralité de votre parcours.
                </p>

                <div className="grid grid-cols-2 gap-3">
                  <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20 shadow-sm">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                        <Globe2 className="w-4 h-4 text-white" />
                      </div>
                      <div>
                        <div className="text-lg font-bold text-white">15+</div>
                        <p className="text-xs text-white/80">Pays disponibles</p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white/10 backdrop-blur-sm rounded-xl p-3 border border-white/20 shadow-sm">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center">
                        <CheckCircle2 className="w-4 h-4 text-white" />
                      </div>
                      <div>
                        <div className="text-lg font-bold text-white">100%</div>
                        <p className="text-xs text-white/80">Personnalisable</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 sm:p-8">
              <div className="max-w-lg">
                <div className="flex items-center gap-3 mb-6">
                  <div className="p-2 bg-primary-50 dark:bg-primary-900/20 rounded-xl">
                    <Shield className="w-5 h-5 text-primary-500 dark:text-primary-400" />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                    Comment ça marche ?
                  </h3>
                </div>

                <div className="space-y-6">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-emerald-100 dark:bg-emerald-900/30 flex items-center justify-center">
                      <span className="text-sm font-bold text-emerald-600 dark:text-emerald-400">1</span>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white mb-1">
                        Choisissez votre destination
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Sélectionnez parmi nos destinations PVT disponibles
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 dark:bg-blue-900/30 flex items-center justify-center">
                      <span className="text-sm font-bold text-blue-600 dark:text-blue-400">2</span>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white mb-1">
                        Personnalisez votre checklist
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Adaptez les tâches selon vos besoins et votre planning
                      </p>
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-900/30 flex items-center justify-center">
                      <span className="text-sm font-bold text-purple-600 dark:text-purple-400">3</span>
                    </div>
                    <div>
                      <h4 className="font-medium text-gray-900 dark:text-white mb-1">
                        Suivez votre progression
                      </h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Gardez un œil sur l'avancement de vos préparatifs
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-8 p-4 bg-gray-50 dark:bg-gray-800/50 rounded-xl border border-gray-200/50 dark:border-gray-700/50">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-amber-100 dark:bg-amber-900/30 rounded-lg">
                      <Info className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Toutes nos informations sont vérifiées et mises à jour régulièrement par l'équipe pvtistes.net
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Dismiss button */}
        <div className="mt-4 sm:mt-6 flex justify-center sm:justify-end">
          <button
            onClick={handleDismiss}
            className="flex items-center justify-center gap-2 px-6 py-2.5 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 text-gray-900 dark:text-white text-sm font-medium rounded-xl shadow-sm hover:shadow-md transition-all duration-200 border border-gray-200/50 dark:border-gray-700/50 w-full sm:w-auto"
          >
            J'ai compris
          </button>
        </div>
      </div>
    </div>
  );
}