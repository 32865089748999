import React from 'react';
import { ThumbsUp, Send } from 'lucide-react';
import { TravelProfile } from '../../../types/matching';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { doc, updateDoc, arrayUnion, arrayRemove, Timestamp } from 'firebase/firestore';
import { db } from '../../../lib/firebase';

interface ProfileCommentsProps {
  profile: TravelProfile;
  currentUserId: string;
  userInfo: any;
}

export function ProfileComments({ profile, currentUserId, userInfo }: ProfileCommentsProps) {
  const [newComment, setNewComment] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [localComments, setLocalComments] = React.useState(profile.comments || []);
  const commentRef = React.useRef<HTMLTextAreaElement>(null);

  // Update local comments when profile comments change
  React.useEffect(() => {
    setLocalComments(profile.comments || []);
  }, [profile.comments]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newComment.trim() || isSubmitting) return;
    if (!profile?.id) return;

    setIsSubmitting(true);
    try {
      const comment = {
        id: Date.now().toString(),
        userId: currentUserId,
        username: userInfo?.user?.username || 'Anonyme',
        content: newComment.trim(),
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
        likes: []
      };

      const profileRef = doc(db, 'travel_profiles', profile.id);
      await updateDoc(profileRef, {
        comments: arrayUnion(comment)
      });

      // Update local state immediately
      setLocalComments(prevComments => [...prevComments, comment]);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      alert('Erreur lors de l\'ajout du commentaire');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLike = async (commentId: string) => {
    try {
      if (!profile) return;

      const comment = localComments.find(c => c.id === commentId);
      if (!comment) return;

      // Prevent user from liking their own comment
      if (comment.userId === currentUserId) {
        return;
      }

      const hasLiked = comment.likes.includes(currentUserId);
      const profileRef = doc(db, 'travel_profiles', profile.id);
      
      // Create updated comments array with new like status
      const updatedComments = localComments.map(c => {
        if (c.id === commentId) {
          return {
            ...c,
            likes: hasLiked 
              ? c.likes.filter(id => id !== currentUserId)
              : [...c.likes, currentUserId]
          };
        }
        return c;
      });

      // Update local state immediately
      setLocalComments(updatedComments);

      // Update Firestore
      await updateDoc(profileRef, {
        comments: updatedComments
      });
    } catch (error) {
      console.error('Error updating like:', error);
      // Revert local state on error
      setLocalComments(profile.comments || []);
    }
  };

  return (
    <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-700">
      <h2 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white mb-6">
        Commentaires
      </h2>

      {/* Comments List */}
      <div className="space-y-6 mb-6">
        {localComments.map((comment) => (
          <div key={comment.id} className="bg-gray-50 dark:bg-gray-700/50 rounded-xl p-3 sm:p-4">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-gradient-to-br from-primary-500 to-accent-500 flex items-center justify-center text-white text-sm font-medium">
                  {comment.username[0].toUpperCase()}
                </div>
                <div>
                  <span className="text-sm font-medium text-gray-900 dark:text-white">
                    {comment.username}
                  </span>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    {format(comment.createdAt.toDate(), 'dd MMM yyyy à HH:mm', { locale: fr })}
                  </p>
                </div>
              </div>
              {comment.userId !== currentUserId && (
                <button
                  onClick={() => handleLike(comment.id)}
                  className={`flex items-center gap-1.5 p-2 rounded-lg transition-colors ${
                    comment.likes.includes(currentUserId)
                      ? 'text-primary-600 dark:text-primary-400'
                      : 'text-gray-500 dark:text-gray-400 hover:text-primary-600 dark:hover:text-primary-400'
                  }`}
                >
                  <ThumbsUp className="w-4 h-4" />
                  {comment.likes.length > 0 && (
                    <span className="text-xs">{comment.likes.length}</span>
                  )}
                </button>
              )}
            </div>
            <p className="text-[13px] sm:text-sm text-gray-700 dark:text-gray-300 whitespace-pre-wrap">
              {comment.content}
            </p>
          </div>
        ))}
      </div>

      {/* Comment Form */}
      <form onSubmit={handleSubmit} className="relative">
        <textarea
          ref={commentRef}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Ajouter un commentaire..."
          className="w-full px-4 py-3 bg-gray-50 dark:bg-gray-700/50 border border-gray-200 dark:border-gray-600 rounded-xl focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:text-white resize-none"
          rows={3}
        />
        <div className="flex justify-end mt-3">
          <button
            type="submit"
            disabled={!newComment.trim() || isSubmitting}
            className="flex items-center gap-2 px-4 py-2 bg-primary-600 hover:bg-primary-700 disabled:bg-gray-400 text-white rounded-lg transition-colors"
          >
            <Send className="w-4 h-4" />
            <span>Commenter</span>
          </button>
        </div>
      </form>
    </div>
  );
}