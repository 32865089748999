import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrivacyModal } from '../components/PrivacyModal';

export function PrivacyPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(true);

  const handleClose = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  return <PrivacyModal isOpen={isModalOpen} onClose={handleClose} />;
}