import React from 'react';
import { Upload, X, Image as ImageIcon, Film } from 'lucide-react';
import { uploadMedia } from '../../lib/media-service';
import { MediaUploadProgress } from '../../types/media';
import { compressImage } from '../../utils/image';

// Minimum dimensions requises
const MIN_IMAGE_WIDTH = 800;
const MIN_IMAGE_HEIGHT = 800;
const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB

interface MediaUploaderProps {
  onUploadComplete: (url: string, type: 'image' | 'video') => void;
  onUploadError: (error: string) => void;
  userId: string;
}

export function MediaUploader({ onUploadComplete, onUploadError, userId }: MediaUploaderProps) {
  const [isDragging, setIsDragging] = React.useState(false);
  const [preview, setPreview] = React.useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = React.useState<MediaUploadProgress | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFile(files[0]);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      handleFile(files[0]);
    }
  };

  const handleFile = async (file: File) => {
    try {
      // Create preview URL before any processing
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);

      // Reset upload progress
      setUploadProgress(null);

      // Reset upload progress
      setUploadProgress(null);

      // Vérifier la taille du fichier
      if (file.type.startsWith('image/') && file.size > MAX_IMAGE_SIZE) {
        // Tenter de compresser l'image
        const compressedBlob = await compressImage(
          file,
          2048, // Max width
          2048, // Max height
          0.7 // Quality
        );
        
        // Vérifier si la compression a suffisamment réduit la taille
        if (compressedBlob.size > MAX_IMAGE_SIZE) {
          throw new Error(`L'image est trop volumineuse. Veuillez utiliser une image de moins de ${MAX_IMAGE_SIZE / (1024 * 1024)}MB`);
        }
        
        // Convertir le blob en File
        file = new File([compressedBlob], file.name, { type: 'image/jpeg' });
      }

      // Vérifier les dimensions pour les images
      if (file.type.startsWith('image/')) {
        const dimensions = await getImageDimensions(file);
        if (dimensions.width < MIN_IMAGE_WIDTH || dimensions.height < MIN_IMAGE_HEIGHT) {
          throw new Error(`L'image doit faire au minimum ${MIN_IMAGE_WIDTH}x${MIN_IMAGE_HEIGHT} pixels pour une bonne qualité d'affichage`);
        }
      }

      // Upload file
      const result = await uploadMedia(file, userId, (progress) => {
        setUploadProgress(progress);
      });

      // Determine media type
      const isVideo = file.type.startsWith('video/');
      
      // Pass the appropriate URL based on media type
      if (isVideo) {
        onUploadComplete(result as string, 'video');
      } else {
        const urls = result as { fullUrl: string; previewUrl: string };
        onUploadComplete(urls.fullUrl, 'image');
      }

      // Cleanup
      setPreview(null);
      setUploadProgress(null);
      URL.revokeObjectURL(objectUrl);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      onUploadError(error instanceof Error ? error.message : 'Erreur lors du téléchargement');
      setPreview(null);
      setUploadProgress(null);
    }
  };

  // Fonction utilitaire pour obtenir les dimensions d'une image
  const getImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
      img.onerror = () => {
        reject(new Error("Impossible de lire les dimensions de l'image"));
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const handleCancel = () => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    setPreview(null);
    setUploadProgress(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="relative">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/png,image/webp,video/mp4,video/quicktime"
        onChange={handleFileSelect}
        className="hidden"
      />

      <div
        className={`relative border-2 border-dashed rounded-xl p-8 text-center transition-all duration-200 ${
          isDragging
            ? 'border-primary-500 bg-primary-50 dark:bg-primary-900/20'
            : 'border-gray-300 dark:border-gray-600 hover:border-primary-500 dark:hover:border-primary-400'
        }`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        {preview ? (
          <div className="relative">
            <img
              src={preview}
              alt="Preview"
              className="max-h-64 mx-auto rounded-lg"
            />
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleCancel();
              }}
              className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex justify-center">
              <div className="p-4 bg-primary-50 dark:bg-primary-900/20 rounded-full">
                <Upload className="w-8 h-8 text-primary-500" />
              </div>
            </div>
            <div>
              <p className="text-base font-medium text-gray-900 dark:text-white">
                Glissez-déposez ou cliquez pour sélectionner
              </p>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400 space-y-1.5">
                <span className="block">Images (JPG, PNG, WebP) - Redimensionnement automatique</span>
                <span className="block">Vidéos (MP4) jusqu'à 150MB</span>
                <span className="block text-xs text-amber-500 dark:text-amber-400 mt-2">
                  Dimensions minimales recommandées : 800×800 pixels
                </span>
              </p>
            </div>
            <div className="flex justify-center gap-4">
              <div className="flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400">
                <ImageIcon className="w-4 h-4" />
                <span>Images</span>
              </div>
              <div className="flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400">
                <Film className="w-4 h-4" />
                <span>Vidéos</span>
              </div>
            </div>
          </div>
        )}

        {uploadProgress && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center rounded-xl">
            <div className="text-center text-white">
              <div className="mb-2">
                {uploadProgress.error ? (
                  <span className="text-red-400">{uploadProgress.error}</span>
                ) : (
                  <span>{Math.round(uploadProgress.progress)}%</span>
                )}
              </div>
              <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-primary-500 transition-all duration-300"
                  style={{ width: `${uploadProgress.progress}%` }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}